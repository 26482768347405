import { BaseService } from "../../../services/BaseService";
import { DefaultResponse } from "../../../services/types";

export class CodePesquisaService extends BaseService {
    public static async get<T>(endpoint: string): Promise<DefaultResponse<T>> {
        return await this.GET<T>(`${BaseService.BASE_URL}/${endpoint}`);
    }

    public static async getId<T>(endpoint: string, id?: number | string): Promise<DefaultResponse<T>> {
        return await this.GET<T>(`${BaseService.BASE_URL}/${endpoint}/${id}`);
    }
}