import { Checkbox } from "flowbite-react";
import { FC } from "react";

export type CondensedCodeDataGridCheckboxColumnProps = {
    checked?: boolean
    className?: string
    onCheck?: (checked: boolean) => void
}

export const CondensedCodeDataGridCheckboxColumn: FC<CondensedCodeDataGridCheckboxColumnProps> = (props) => {
    return (
        <th scope="col" className={`${props.className} px-6 py-1.5`} key='checkbox-column'>
            <Checkbox style={{ cursor: 'pointer' }} onClick={(e) => props.onCheck?.call(this, e.currentTarget.checked)} />
        </th>
    )
}