import { FC } from "react";
import { useCotacaoCredenciadaPage } from "./index.hook";
import { ICodeDataGridColumn } from "../../components/CodeDataGrid/types";
import CodeUtil from "../../utils/CodeUtil";
import CodeSpinner from "../../components/CodeSpinner";
import { CodeDataGrid } from "../../components/CodeDataGrid";
import { CotacaoStatusType } from "../../enums";
import { Popup } from "../../components/Popup";
import { CotacoesCredenciadasCadastroPage } from "../cotacoes_credenciadas_cadastro/CotacoesCredenciadasCadastroPage";
import { ViewCotacaoCredenciadaModel } from "../../models/cotacao/ViewCotacaoCredenciada";
import { SideBarFiltrosCotacoes } from "../../components/SidebarFiltroCotacoes";
import { useBuscarPermissao } from "../../hooks/useBuscarPermissao";

export const CotacoesCredenciadasPage: FC = () => {
    const {
        isLoadingData, dataSource, setShowPopupDelete, showPopupDelete,
        selectedRow: selected, setSelectedRow: setSelected,
        pagination, setPage, modalState, onModalClose, 
        consultarCotacao, cancelarCotacao, inserirCotacao, onFormatStatusColumn,
        filtros, setFiltros, hiddenMoreFilters, setHiddenMoreFilters, onApplyFilter
    } = useCotacaoCredenciadaPage();

    const { permissao } = useBuscarPermissao('cotacoes');

    const dataTableColumns: ICodeDataGridColumn[] = [
        { title: "número", property: "id", alignment: "center", onFormatValue: (value: ViewCotacaoCredenciadaModel) => <span>{String(value.id).padStart(4, "0")}</span> },
        { title: "data", property: "dataCadastro", alignment: "center", onFormatValue: (value: ViewCotacaoCredenciadaModel) => <span>{`${CodeUtil.formatStringDate(value.dataCadastro)}`}</span> },
        { title: "validade", property: "dataValidade", alignment: "center", onFormatValue: (value: ViewCotacaoCredenciadaModel) => <span>{`${CodeUtil.formatStringDate(value.dataValidade)}`}</span> },
        { title: "situação", property: "statusDescricao", alignment: "center", onFormatValue: onFormatStatusColumn },
        { title: "cliente", property: "cliente.razaoSocial" },
        { title: "cnpj", property: "cliente.cnpj", alignment: "left", onFormatValue: (value: ViewCotacaoCredenciadaModel) => <span>{CodeUtil.mask(value.cliente?.cnpj ?? "", "##.###.###/####-##")}</span> },
        { title: "marca", property: "veiculo.marca.nome" },
        { title: "modelo", property: "veiculo.modelo.nome" },
        { title: "placa", property: "veiculo.placa" },
        { title: "renavam", property: "veiculo.renavam" },
        { title: "órgão", property: "veiculo.orgao" },
        { title: "desconto", property: "desconto", alignment: "right", onFormatValue: (value: ViewCotacaoCredenciadaModel) => <span>{CodeUtil.moneyFormat(value.desconto)}</span> },
        { title: "valor total", property: "valorTotal", alignment: "right", onFormatValue: (value: ViewCotacaoCredenciadaModel) => <span>{CodeUtil.moneyFormat(value.valorTotal)}</span> },
    ];

    return (
        <>
            <CodeSpinner hidden={!isLoadingData} />

            <div className="mt-5" hidden={isLoadingData}>
                <CodeDataGrid
                    sort={{ columnIndex: 0, direction: "asc", columnProperty: "id" }}
                    datasource={dataSource}
                    striped={true}
                    header={{
                        title: "Cotações",
                        subtitle: "Listagem de cotações pendentes e aguardando aprovação",
                        filter: true
                    }}
                    actions={[
                        { label: 'Nova Cotação', style: 'colored', disabled: !permissao?.inserir, onClick: () => inserirCotacao() },
                        { label: 'Preencher Cotação', style: 'flat', disabled: !selected || !permissao?.editar, onClick: () => consultarCotacao(false) },
                        { label: 'Cancelar', style: 'destructive', disabled: !selected || !permissao?.excluir || (selected.item as ViewCotacaoCredenciadaModel).status !== CotacaoStatusType.AGUARDANDO, onClick: () => setShowPopupDelete(true) },
                        { label: 'Consultar', style: 'flat', disabled: !selected || !permissao?.consultar, onClick: () => consultarCotacao(true) },
                        { label: 'Mais Filtros', style: 'flat', onClick: () => setHiddenMoreFilters(false) }
                    ]}
                    columns={dataTableColumns}
                    onRowSelect={(item) => setSelected(item.selected ? item : undefined)}
                    pagination={{ ...pagination, onPageChanged: (page) => setPage(page) }}
                />

                {modalState.show &&
                    <CotacoesCredenciadasCadastroPage
                        show={modalState.show}
                        id={modalState.id}
                        state={modalState.state}
                        onClose={onModalClose}
                        onSave={onModalClose}
                        saveButtonTitle="Enviar"
                    />}

                <SideBarFiltrosCotacoes
                    filter={[filtros, setFiltros]}
                    hidden={hiddenMoreFilters}
                    onClose={() => setHiddenMoreFilters(true)}
                    onApplyFilter={onApplyFilter} />

                {showPopupDelete && <Popup
                    isShowing={showPopupDelete}
                    message="Confirma o cancelamento da cotação selecionada?"
                    isDestructive={true}
                    onConfirm={cancelarCotacao}
                    onCancel={() => setShowPopupDelete(false)}
                />}
            </div>
        </>
    );
};