import { AxiosError } from "axios";
import { Checkbox, Label, Textarea, TextInput } from "flowbite-react";
import { FC, useCallback, useEffect, useState } from "react";
import { ShowToast } from "../../../../components/CodeToast";
import { ModalForm } from "../../../../components/ModalForm";
import { ModalFormProps } from "../../../../components/ModalForm/types";
import { FormStateType } from "../../../../enums";
import { defaultServico, ServicoModel } from "../../../../models/servico";
import { ServicoService } from "../../../../services/ServicoService";
import CodeUtil from "../../../../utils/CodeUtil";
import { KeyboardEvent } from "react";
import { findValidationField, FluentValidator } from "../../../../types";
import { CodeLabel } from "../../../../components/CodeLabel";

export const ServicosModal: FC<ModalFormProps> = (props) => {
    const [errors, setErrors] = useState<FluentValidator[]>([]);
    const [saving, setSaving] = useState<boolean>(false);
    const [formLoaded, setFormLoaded] = useState<boolean>(false);
    const [model, setModel] = useState<ServicoModel>(defaultServico);
    const isReadOnly = props.state === FormStateType.view;

    const onFormClose = () => {
        setErrors([]);
        setFormLoaded(false);
        props.onClose?.call(this);
    }

    const onFormLoad = useCallback(async () => {
        if (!props.show || formLoaded) return;

        console.log(props.id)

        if (props.id !== null && props.id !== undefined && props.id > 0) {
            let response = await ServicoService.getId(props.id);
            if (!response.success) {
                ShowToast({ message: CodeUtil.arrayToStr(response.messages) });
                return;
            }

            setModel(response.data[0]);
        } else setModel(defaultServico);

        setFormLoaded(true);
    }, [props.id, props.show, formLoaded, setFormLoaded]);


    const validate = (): FluentValidator[] => {
        let _errors: FluentValidator[] = [];

        if (CodeUtil.isNullOrEmpty(model.nome)) _errors.push({ field: "nome", message: "Preenchimento obrigatório", isValid: false });

        setErrors(_errors);
        return _errors;
    }

    const onFormSave = async (e: React.MouseEvent<HTMLButtonElement> | undefined) => {

        let validationResult = validate();
        if (validationResult.length > 0) return;

        try {
            setSaving(true);
            let response = props.state === FormStateType.add ? await ServicoService.add(model) :
                await ServicoService.update(model);

            if (!response.success) {
                ShowToast({ message: CodeUtil.arrayToStr(response.messages) });
                return;
            }

            props.onSave?.call(this, e);
            onFormClose();
        } catch (error) {
            ShowToast({ message: (error as AxiosError).response?.data as string })
        }
        finally {
            setSaving(false);
        }
    }

    useEffect(() => {
        onFormLoad();
    });

    const handleKeydownEvent = (e: KeyboardEvent<HTMLDivElement>) => {
        if (e.code.toLowerCase() === "escape") {
            onFormClose();
            return;
        }
    }

    if (!props.show) return <></>;

    return (
        <div onKeyDown={handleKeydownEvent}>
            <ModalForm title={props.title ?? "Cadastro de Serviços"}
                show={props.show}
                state={props.state}
                onClose={onFormClose}
                isSaving={saving}
                size="2xl"
                onSave={async (e: React.MouseEvent<HTMLButtonElement> | undefined) => await onFormSave(e)}>

                <div className="grid grid-cols-12 mt-4 mb-2 px-3 pb-4">
                    <div className="col-span-12">
                        <h2 className="ml-auto text-xl font-extrabold text-black/70" hidden={(model.id ?? 0) === 0}>
                            {`ID: #${(model.id ?? 0).toString().padStart(3, "0")}`}
                        </h2>
                    </div>

                    <div className="form-control mt-5 col-span-12 md:col-span-6">
                        <CodeLabel htmlFor="inputNome" value="Serviço:" className="mb-1" />
                        <TextInput id="inputNome"
                            type="text"
                            readOnly={isReadOnly}
                            value={model.nome}
                            onChange={(e) => setModel({ ...model, nome: e.currentTarget.value })}
                            helperText={findValidationField(errors, "nome").message}
                            color={findValidationField(errors, "nome").isValid ? "gray" : "failure"}
                            placeholder="Nome do serviço"
                            maxLength={200} />
                    </div>

                    <div className="form-control mt-5 col-span-12">
                        <CodeLabel htmlFor="inputDescricao" value="Descrição do serviço:" className="mb-1" />
                        <Textarea id="inputDescricao"
                            readOnly={isReadOnly}
                            rows={5}
                            value={model.descricao}
                            onChange={(e) => setModel({ ...model, descricao: e.currentTarget.value })}
                            placeholder="Descrição do serviço"
                            maxLength={2000} />
                    </div>

                    <div className="form-control mt-5 col-span-12 flex space-x-2 items-center">
                        <Checkbox id="checkBoxAtivo"
                            disabled={isReadOnly}
                            checked={model.ativo}
                            onChange={(e) => setModel({ ...model, ativo: e.currentTarget.checked })}
                            placeholder="Checked">
                        </Checkbox>

                        <Label htmlFor="checkBoxAtivo" value="Serviço ativo?" />
                    </div>
                </div>
            </ModalForm>
        </div>
    );
};