import { FC } from "react";
import { ModalFormTabProps } from "../../../components/ModalForm/types";
import styled from "styled-components";
import { CotacaoModel } from "../../../models/cotacao/CotacaoModel";
import { useModalTabCredenciadas } from "./ModalTabCredenciadas.hook";
import { FormStateType, ModelState } from "../../../enums";
import { CredenciadasSearchForm } from "../../../components/CredenciadasSearchForm";
import CodeStaticList from "../../../components/CodeStaticList";
import CodeStaticListItem from "../../../components/CodeStaticList/Item";
import { Button } from "primereact/button";
import { StaticListItemCredenciadas } from "./StaticListItemCredenciadas";

export const ModalTabCredenciadas: FC<ModalFormTabProps<CotacaoModel>> = (props) => {
    const {
        model, readonly,
        showPopupCredenciada, setShowPopupCredenciada,
        handleSelecionarCredenciadas, handleCancelarCotacao
    } = useModalTabCredenciadas(props);

    return (
        <StyledContainer>
            <div className="p-3">
                {model.credenciadas?.filter(_ => _.state !== ModelState.deleted).length > 0 ?
                    <></> :
                    <div className="flex flex-col space-y-3 ml-auto mr-auto p-5 place-items-center">
                        <span className="text-slate-500">Nenhuma credenciada para esta cotação</span>
                    </div>}

                <CodeStaticList>
                    {model.credenciadas?.filter(_ => _.state !== ModelState.deleted).map((item, idx) =>
                        <CodeStaticListItem key={idx}>
                            <StaticListItemCredenciadas cotacaoItens={model.itens} model={item} key={idx} state={props.state} onCancelarCotacao={handleCancelarCotacao} />
                        </CodeStaticListItem>)}
                </CodeStaticList>

                <Button icon="pi pi-plus" visible={!readonly} onClick={() => setShowPopupCredenciada(true)} rounded aria-label="Filter" style={{ position: "absolute", right: 30, bottom: 90 }} />
            </div>

            <CredenciadasSearchForm
                show={showPopupCredenciada}
                state={FormStateType.edit}
                title="Selecionar Credenciadas"
                onSelectItems={handleSelecionarCredenciadas}
                onClose={() => setShowPopupCredenciada(false)} />

        </StyledContainer >
    );
}

const StyledContainer = styled.div`
    min-height: 25rem;
    margin: -1.5rem;
`