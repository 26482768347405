import { AxiosError } from "axios";
import { Select, TextInput } from "flowbite-react";
import { Dispatch, FC, SetStateAction, useCallback, useEffect, useState } from "react";
import { CodeLabel } from "../../../../../../../components/CodeLabel";
import { ShowToast } from "../../../../../../../components/CodeToast";
import { MaskedInput } from "../../../../../../../components/MaskedInput";
import ModalForm from "../../../../../../../components/ModalForm";
import { ModalFormProps } from "../../../../../../../components/ModalForm/types";
import { FormStateType } from "../../../../../../../enums";
import { ClienteVeiculoModel } from "../../../../../../../models/cliente_veiculo/ClienteVeiculoModel";
import { VeiculoMarcaModel } from "../../../../../../../models/veiculo_marca";
import { VeiculoModeloModel } from "../../../../../../../models/veiculo_modelo";
import { VeiculoMarcaService } from "../../../../../../../services/VeiculoMarcaService";
import { VeiculoModeloService } from "../../../../../../../services/VeiculoModeloService";
import { findValidationField, FluentValidator } from "../../../../../../../types";
import CodeUtil from "../../../../../../../utils/CodeUtil";
import { MoneyInput } from "../../../../../../../components/MoneyInput";

export type ClienteTabVeiculosModalProps<T> = {
    model: [T, Dispatch<SetStateAction<T>>]
} & ModalFormProps

export const ClientesTabVeiculosModal: FC<ClienteTabVeiculosModalProps<ClienteVeiculoModel>> = (props) => {
    const [errors, setErrors] = useState<FluentValidator[]>([]);
    const [saving, setSaving] = useState<boolean>(false);
    const [formLoaded, setFormLoaded] = useState<boolean>(false);
    const [model, setModel] = props.model;
    const isReadOnly = props.state === FormStateType.view;

    const [marcas, setMarcas] = useState<VeiculoMarcaModel[]>([]);
    const [modelos, setModelos] = useState<VeiculoModeloModel[]>([]);
    const [years, setYears] = useState<number[]>([]);

    const onLoadMarcas = useCallback(async () => {
        let response = await VeiculoMarcaService.get({ page: 1, limit: 99999999 });
        if (!response.success) {
            ShowToast({ message: CodeUtil.arrayToStr(response.messages) });
            setMarcas([]);
            return;
        }

        setMarcas(response.data);
    }, [setMarcas]);

    const onLoadYearSelect = useCallback(() => {
        const currentYear = (new Date()).getFullYear() + 1;
        setYears(Array.from(new Array(50), (val, index) => currentYear - index));
    }, [setYears]);

    const onLoadModelos = useCallback(async (marcaId: number): Promise<VeiculoModeloModel[]> => {

        let response = await VeiculoModeloService.get({
            page: 1,
            limit: 9999999,
            marcaId: marcaId,
        });

        if (!response.success) {
            ShowToast({ message: CodeUtil.arrayToStr(response.messages) });
            setModelos([]);
            return [];
        }

        setModelos(response.data);
        return response.data ?? [];
    }, [setModelos]);


    const onFormClose = () => {
        setErrors([]);
        setFormLoaded(false);
        props.onClose?.call(this);
    };

    const onFormLoad = useCallback(async () => {
        if (!props.show || formLoaded) return;

        await onLoadMarcas();
        await onLoadModelos(model.marca?.id ?? 0);
        onLoadYearSelect();

        setFormLoaded(true);
    }, [model, props.show, formLoaded, setFormLoaded, onLoadMarcas, onLoadModelos, onLoadYearSelect]);

    const validate = (): FluentValidator[] => {
        let _errors: FluentValidator[] = [];

        if (model.marca.id === 0) _errors.push({ field: "marca", message: "A marca deve ser preenchida", isValid: false });
        if (model.modelo.id === 0) _errors.push({ field: "modelo", message: "O modelo deve ser preenchido", isValid: false });

        if (!CodeUtil.isValidDate('01/01/' + model.anoFabricacao)) _errors.push({ field: "anoFabricacao", message: "Ano Fabricação inválido", isValid: false });
        if (!CodeUtil.isValidDate('01/01/' + model.anoModelo)) _errors.push({ field: "anoModelo", message: "Ano Modelo inválido", isValid: false });

        if (CodeUtil.isNullOrEmpty(model.placa.trim())) _errors.push({ field: "placa", message: "A placa deve ser preenchida", isValid: false });
        if (CodeUtil.isNullOrEmpty(model.renavam?.trim())) _errors.push({ field: "renavam", message: "O RENAVAM deve ser preenchido", isValid: false })
        else if (model.renavam?.length < 9) _errors.push({ field: "renavam", message: "O RENAVAM deve possuir 11 dígitos", isValid: false });

        setErrors(_errors);
        return _errors;
    }

    const onFormSave = async (e: React.MouseEvent<HTMLButtonElement> | undefined) => {
        let validationResult = validate();

        if (validationResult.length > 0) return;

        try {
            setSaving(true);
            setModel({ ...model, id: 0, state: props.state === FormStateType.add ? 'ADDED' : 'UPDATED' });

            props.onSave?.call(this, e);
            onFormClose();
        } catch (error) {
            console.log(error);
            ShowToast({ message: (error as AxiosError).response?.data as string });
        } finally {
            setSaving(false);
        }
    };

    useEffect(() => {
        onFormLoad();
    });

    if (!props.show) return <></>;

    return (
        <ModalForm
            title={props.title ?? "Cadastro de Clientes"}
            show={props.show}
            state={props.state}
            isSaving={saving}
            size="3xl"
            onClose={onFormClose}
            onSave={onFormSave}
        >
            <div className="grid grid-cols-12 px-6 pb-3">
                <div className="form-control mt-5 col-span-12 md:col-span-6 md:mr-6">
                    <CodeLabel className="mb-1" htmlFor="selectMarca" value="Marca:" />
                    <Select
                        id="selectMarca"
                        disabled={isReadOnly}
                        value={model?.marca.id}
                        onChange={(e) => {
                            setModel({
                                ...model, marca:
                                {
                                    ...model.marca,
                                    id: Number(e.currentTarget.value),
                                    nome: marcas.find((m) => m.id === Number(e.currentTarget.value))?.marca ?? ''
                                }
                            });
                            onLoadModelos(Number(e.currentTarget.value))
                        }}
                        helperText={findValidationField(errors, "marca").message}
                        color={findValidationField(errors, "marca").isValid ? "gray" : "failure"}
                    >
                        <option value={0}>Selecione</option>
                        {marcas.map((marca) => (<option key={marca.id} value={marca.id}>{marca.marca}</option>))}
                    </Select>
                </div>

                <div className="form-control mt-5 col-span-12 md:col-span-6">
                    <CodeLabel className="mb-1" htmlFor="selectModelo" value="Modelo:" />
                    <Select
                        id="selectModelo"
                        disabled={isReadOnly}
                        value={model?.modelo.id}
                        helperText={findValidationField(errors, "modelo").message}
                        color={findValidationField(errors, "modelo").isValid ? "gray" : "failure"}
                        onChange={(e) => {
                            setModel({
                                ...model,
                                modelo:
                                {
                                    ...model.modelo,
                                    id: Number(e.currentTarget.value),
                                    nome: modelos.find((m) => m.id === Number(e.currentTarget.value))?.modelo ?? ''
                                }
                            });
                        }}
                    >
                        <option value={0}>Selecione</option>
                        {modelos.map((modelo) => (<option key={modelo.id} value={modelo.id}>{modelo.modelo}</option>))}
                    </Select>
                </div>

                <div className="form-control mt-5 col-span-6 md:col-span-3 md:mr-6">
                    <CodeLabel className="mb-1" htmlFor="selectFabricacao" value="Fabricação:" />
                    <Select
                        id="selectFabricacao"
                        disabled={isReadOnly}
                        value={model?.anoFabricacao}
                        helperText={findValidationField(errors, "anoFabricacao").message}
                        color={findValidationField(errors, "anoFabricacao").isValid ? "gray" : "failure"}
                        onChange={(e) => setModel({ ...model, anoFabricacao: Number(e.currentTarget.value) })}
                    >
                        {years.map((year) => (<option key={year} value={year}>{year}</option>))}
                    </Select>
                </div>


                <div className="form-control mt-5 col-span-6 md:col-span-3 md:mr-6">
                    <CodeLabel className="mb-1" htmlFor="selectModelo" value="Modelo:" />
                    <Select
                        id="selectModelo"
                        disabled={isReadOnly}
                        value={model?.anoModelo}
                        helperText={findValidationField(errors, "anoModelo").message}
                        color={findValidationField(errors, "anoModelo").isValid ? "gray" : "failure"}
                        onChange={(e) => setModel({ ...model, anoModelo: Number(e.currentTarget.value) })}
                    >
                        {years.map((year) => (<option key={year} value={year}>{year}</option>))}
                    </Select>
                </div>

                <div className="form-control mt-5 col-span-6 md:col-span-3 md:mr-6">
                    <CodeLabel className="mb-1" htmlFor="inputPlaca" value="Placa:" />
                    <TextInput
                        id="inputPlaca"
                        type="text"
                        maxLength={7}
                        readOnly={isReadOnly}
                        value={model?.placa}
                        helperText={findValidationField(errors, "placa").message}
                        color={findValidationField(errors, "placa").isValid ? "gray" : "failure"}
                        onChange={(e) => setModel({ ...model, placa: e.currentTarget.value })}
                    />
                </div>

                <div className="form-control mt-5 col-span-6 md:col-span-3">
                    <CodeLabel className="mb-1" htmlFor="inputRenavam" value="Renavam:" />
                    <MaskedInput
                        id="inputRenavam"
                        type="text"
                        pattern="^[0-9]+$"
                        mask="###########"
                        maxLength={11}
                        readOnly={isReadOnly}
                        value={model?.renavam}
                        helperText={findValidationField(errors, "renavam").message}
                        color={findValidationField(errors, "renavam").isValid ? "gray" : "failure"}
                        onChange={(e) => setModel({ ...model, renavam: e })}
                    />
                </div>

                <div className="form-control mt-5 col-span-12 md:col-span-8 md:mr-6">
                    <CodeLabel className="mb-1" htmlFor="inputOrgao" value="Órgão:" />
                    <TextInput
                        id="inputOrgao"
                        type="text"
                        maxLength={255}
                        readOnly={isReadOnly}
                        value={model?.orgao}
                        helperText={findValidationField(errors, "orgao").message}
                        color={findValidationField(errors, "orgao").isValid ? "gray" : "failure"}
                        onChange={(e) => setModel({ ...model, orgao: e.currentTarget.value })}
                    />
                </div>

                <div className="form-control mt-5 col-span-6 md:col-span-4">
                    <CodeLabel className="mb-1" htmlFor="inputValorVenal" value="Valor Venal:" />
                    <MoneyInput id="inputValorVenal"
                        value={CodeUtil.moneyFormat(model?.valorVenal, false)}
                        addon={"R$"}
                        readOnly={isReadOnly}
                        onChange={(_stringValue, numberValue) => setModel({ ...model, valorVenal: numberValue })} />                    
                </div>
            </div>
        </ModalForm>
    );
};