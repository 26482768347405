export enum FormStateType {
    add = 'add',
    edit = 'edit',
    view = 'view',
    unknown = ''
}

export enum UserType {
    admin = 'ADMIN',
    customer = 'CLIENTE',
    dealer = 'CREDENCIADA',    
    gestor = 'GESTORCONTA'
}

export enum ModelState {
    unchanged = 'UNCHANGED',
    added = 'ADDED',
    updated = 'UPDATED',
    deleted = 'DELETED'
}

export enum CotacaoStatusType {
    AGUARDANDO = 0,
    APROVADA = 1,
    REPROVADA = 2,
    CANCELADA = 3,
    COTACAO_ENVIADA = 4,
    ENCERRADA = 5,
    EMEXECUCAO = 6,
    APROVADA_PARCIAL = 7
}

export enum CotacaoTipoItemType {
    ITEM = 0,
    SERVICO = 1
}