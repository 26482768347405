import { FC } from "react";
import styled from "styled-components";
import CodeUtil from "../../../utils/CodeUtil";
import { ViewCotacaoCredenciadaModel } from "../../../models/cotacao/ViewCotacaoCredenciada";
import { SessionManager } from "../../../utils/SessionManager";
import { Tooltip } from "flowbite-react";
import { calcularPercentualVenal } from "../../../models/cliente_veiculo/ClienteVeiculoModel";

export type FooterTotaisProps = {
    model: ViewCotacaoCredenciadaModel
}

export const FooterTotais: FC<FooterTotaisProps> = (props) => {
    const currentUser = SessionManager.getSession().user;
    const tooltipValorVenal = calcularPercentualVenal(props.model.valorVenal, props.model.valorTotal) > 30 ?
        <p>A cotação excede 30% do valor venal do veículo<br/><br/>Valor Venal: {CodeUtil.moneyFormat(props.model.valorVenal)}</p> : 
        <p>Indicador percentual da cotação sobre o valor venal do veículo</p>;

    return (
        <StyledFooter className="border-t-2">
            <div className="content text-sm">
                <div className="flex flex-col w-full px-2 cursor-pointer">
                    <div className="flex flex-row ml-auto">
                        <div className="flex flex-row mr-10 ml-auto">
                            <div className="flex flex-col items-end mr-1.5">
                                <span>Frete:</span>
                                <span>Seguro:</span>
                                <span>Outros:</span>
                            </div>

                            <div className="flex flex-col ml-auto font-semibold">
                                <span>{CodeUtil.moneyFormat(props.model.frete)}</span>
                                <span>{CodeUtil.moneyFormat(props.model.seguro)}</span>
                                <span>{CodeUtil.moneyFormat(props.model.outrasDespesas)}</span>
                            </div>
                        </div>

                        <div className="flex flex-row ml-auto">
                            <div className="flex flex-col mr-1.5 items-end">
                                <span>Desconto (R$):</span>
                                <span className="font-bold">Total Cotação:</span>
                                {currentUser?.credenciadaId && <span className="font-bold text-green-400">À Receber:</span>}
                                {(!currentUser?.credenciadaId && !currentUser?.clienteId) && <span className="font-bold text-green-400">Comissão Rodar:</span>}
                                {!currentUser?.credenciadaId && <Tooltip content={tooltipValorVenal}>
                                    <span className={`font-bold ${calcularPercentualVenal(props.model.valorVenal, props.model.valorTotal) > 30 && 'text-red-700'} `}>PVV:</span>
                                </Tooltip>}
                            </div>

                            <div className="flex flex-col ml-auto font-semibold">
                                <span>{CodeUtil.moneyFormat(props.model.desconto)}</span>
                                <span className="font-bold">{CodeUtil.moneyFormat(props.model.valorTotal)}</span>
                                {currentUser?.credenciadaId && <span className="font-bold text-green-400">{CodeUtil.moneyFormat(props.model.valorTotal - props.model.valorComissao)}</span>}
                                {(!currentUser?.credenciadaId && !currentUser?.clienteId) && <span className="font-bold text-green-400">{CodeUtil.moneyFormat(props.model.valorComissao)}</span>}
                                {!currentUser?.credenciadaId && <span className={`font-bold ${calcularPercentualVenal(props.model.valorVenal, props.model.valorTotal) > 30 && 'text-red-700'} `}>
                                    {CodeUtil.moneyFormat(calcularPercentualVenal(props.model.valorVenal, props.model.valorTotal), false)}%
                                </span>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </StyledFooter>
    );
};

const StyledFooter = styled.div`    
    padding: 0.750rem;
    .content {
        display: flex;
    }
` ;