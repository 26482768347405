import { FC } from "react";
import { ModalFormTabProps } from "../../../components/ModalForm/types";
import { CotacaoModel } from "../../../models/cotacao/CotacaoModel";
import { useModalTabCatalogos } from "./ModalTabCatalogos.hook";
import CodeStaticList from "../../../components/CodeStaticList";
import CodeStaticListItem from "../../../components/CodeStaticList/Item";
import styled from "styled-components";
import { Button } from "flowbite-react";
import { PrimeIcons } from "primereact/api";
import { DocumentViewer } from "../../../components/DocumentViewer";

export const ModalTabCatalogos: FC<ModalFormTabProps<CotacaoModel>> = (props) => {
    const { datasource, docViewerState, setDocViewerState } = useModalTabCatalogos(props);

    return (
        <StyledContainer>
            <div className="p-3">
                {datasource.length > 0 ?
                    <></> :
                    <div className="flex flex-col space-y-3 ml-auto mr-auto p-5 place-items-center">
                        <span className="text-slate-500">Nenhuma catálogo disponível para consulta</span>
                    </div>}

                <CodeStaticList>
                    {datasource.map((item, idx) =>
                        <CodeStaticListItem key={idx}>
                            <div className="grid grid-cols-12 items-center text-sm">
                                <div className="col-span-2 mr-6 flex flex-col">
                                    <span className="text-xs font-semibold text-black/60">Nome</span>
                                    <span className="pt-1 flex-wrap">{item.nome}</span>
                                </div>
                                <div className="col-span-7 mr-6 flex flex-col">
                                    <span className="text-xs font-semibold text-black/60">Descrição</span>
                                    <span className="pt-1">{item.descricao}</span>
                                </div>
                                <div className="mt-3 col-span-3 md:mt-0 mr-4 ml-auto flex flex-col">
                                    <Button key={idx} color={"light"} size={"sm"}
                                        onClick={() => setDocViewerState({ show: true, url: item.url, title: item.nome })}>Visualizar <i className={`ml-1.5 ${PrimeIcons.LINK}`} /></Button>
                                </div>
                            </div>
                        </CodeStaticListItem>)}
                </CodeStaticList>
            </div>

            {docViewerState.show &&
                <DocumentViewer
                    uri={docViewerState.url}
                    onClose={() => setDocViewerState({ url: "", show: false })} />
            }
        </StyledContainer>
    );
}

const StyledContainer = styled.div`
    min-height: 25rem;
    margin: -1.5rem;
`