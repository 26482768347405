import { useCallback, useState } from "react";
import { EstadoModel } from "../models/estado";
import { MunicipioModel } from "../models/municipio";
import CodeUtil from "../utils/CodeUtil";
import { MunicipioService } from "../services/MunicipioService";
import { EstadoService } from "../services/EstadoService";
import { ShowToast } from "../components/CodeToast";
import { ConsultarCepService } from "../services/ConsutarCepService";
import { DefaultCEPResponse } from "../services/types";

export interface ConsultarCepProps {
    onAfterBuscarCep: (response: DefaultCEPResponse | null, municipio?: MunicipioModel | null) => void;
}

export const useConsultarCep = (props: ConsultarCepProps) => {
    const [buscandoCep, setBuscandoCep] = useState<boolean>(false);
    const [municipios, setMunicipios] = useState<MunicipioModel[]>([]);
    const [estados, setEstados] = useState<EstadoModel[]>([]);

    const onLoadEstados = useCallback(async () => {
        let response = await EstadoService.get({ page: 1, limit: 50 });
        if (!response.success) {
            ShowToast({ message: CodeUtil.arrayToStr(response.messages) });
            setEstados([]);
            return;
        }

        setEstados(response.data);
    }, [setEstados]);

    const onLoadMunicipios = useCallback(async (sigla: string): Promise<MunicipioModel[]> => {

        let response = await MunicipioService.get({
            page: 1,
            limit: 9999999,
            ufSigla: sigla?.trim(),
        });

        if (!response.success) {
            ShowToast({ message: CodeUtil.arrayToStr(response.messages) });
            setMunicipios([]);
            return [];
        }

        setMunicipios(response.data);
        return response.data ?? [];
    }, [setMunicipios]);

    const consultarCep = (cep: string): void => {
        if ((CodeUtil.removeSpaces(cep)?.length ?? 0) < 8) {
            setBuscandoCep(false);
            return;
        }

        setBuscandoCep(true);
        setTimeout(() => {
            ConsultarCepService.consultar(cep)
                .then((response) => onAfterBuscarCep(response))
                .finally(() => setBuscandoCep(false));
        }, 1500);
    };

    const onAfterBuscarCep = (response: DefaultCEPResponse | null) => {
        if (response === null) return;

        onLoadMunicipios(response.uf ?? "ND")
            .then((_municipios) => {
                let _municipio = _municipios.find((item) => item.descricao?.toLowerCase() === response.localidade?.toLowerCase());
                props.onAfterBuscarCep.call(this, response, _municipio);
            });
    }

    return {
        estados, municipios,
        buscandoCep, setBuscandoCep,
        consultarCep, onLoadEstados, onLoadMunicipios,
    }
}