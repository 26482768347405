import { Button, Dropdown } from "flowbite-react";
import { DropdownItem } from "flowbite-react/lib/esm/components/Dropdown/DropdownItem";
import { FC } from "react";
import FlatButton from "../../FlatButton";
import { ICodeDataGridActionButton } from "../types";

type CodeDataGridActionButtonProps = {
    show: boolean
    actions?: ICodeDataGridActionButton[]
}

export const CodeDataGridActionButton: FC<CodeDataGridActionButtonProps> = (props) => {
    if (!props.show) return <></>;

    return (
        <div className='flex space-x-2'>
            {props.actions?.map((action, index) => {
                if (action.style === 'flat' || action.style === 'destructive') return (
                    <FlatButton key={index} label={action.label} onClick={action.onClick} buttonstyle={action.style} disabled={action.disabled} isDestructive={action.style === 'destructive'} />
                );

                if (action.style === "colored") {
                    return <Button size={"sm"} key={index} color={'dark'} onClick={action.onClick} disabled={action.disabled}>{action.label}</Button>
                }

                return (
                    <Dropdown size={'sm'} label={action.label} disabled={action.disabled} inline={false}
                        color={'white'} key={index}>
                        {action.dropdownActions?.map((item, index) =>
                            <DropdownItem onClick={() => item.onClick?.call(this)} key={index}>{item.label}</DropdownItem>)}
                    </Dropdown>
                );
            })}
        </div>
    )
}