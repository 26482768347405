import styled from "styled-components";
import { CondensedCodeDataGrid } from "../../../components/CondensedCodeDataGrid";
import { FC } from "react";
import { ModalFormTabProps } from "../../../components/ModalForm/types";
import { ViewCotacaoCredenciadaModel } from "../../../models/cotacao/ViewCotacaoCredenciada";
import { useTabRegistroEntradaSaida } from "./TabRegistroEntradaSaida.hook";

export const TabRegistroEntradaSaida : FC<ModalFormTabProps<ViewCotacaoCredenciadaModel>> = (props) => {
    const { columns, dataSource } = useTabRegistroEntradaSaida(props);
    
    return (
        <StyledContainer>
            <CondensedCodeDataGrid
                columns={columns}
                datasource={dataSource}
                striped
                readonly
                key={"id"} />
        </StyledContainer>
    );
}

const StyledContainer = styled.div`
    min-height: 18rem;
    margin: -1.4rem -1.5rem;
`