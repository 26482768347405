import { FC, useCallback, useEffect, useState } from "react";
import ModalForm from "../../../components/ModalForm";
import { FormStateType } from "../../../enums";
import { CodeLabel } from "../../../components/CodeLabel";
import { Select } from "flowbite-react";
import { IFiltroRelatorioGarantia, ModalFiltroItensGarantiaProps } from "../types";
import { ICodePesquisaValue } from "../../../components/CodePesquisa/types";
import { CredenciadaModel } from "../../../models/credenciada";
import { ClienteModel } from "../../../models/cliente/ClienteModel";
import { CodePesquisa } from "../../../components/CodePesquisa";
import { useCredenciadaColumns } from "../../../models/credenciada/useCredenciadaColumns";
import { useClienteColumns } from "../../../models/cliente/useClienteColumns";
import CodeUtil from "../../../utils/CodeUtil";
import { SessionManager } from "../../../utils/SessionManager";
import { useClienteVeiculoColumns } from "../../../models/cliente_veiculo/useClienteVeiculoColumns";
import { ClienteVeiculoModel } from "../../../models/cliente_veiculo/ClienteVeiculoModel";


export const FiltroRelatorioItensGarantia: FC<ModalFiltroItensGarantiaProps> = (props) => {
    const [filter, setFilter] = props.model;
    const [loaded, setLoaded] = useState<boolean>(false);
    const [credenciadaPesquisa, setCredenciadaPesquisa] = useState<ICodePesquisaValue | null>(null);
    const [clientePesquisa, setClientePesquisa] = useState<ICodePesquisaValue | null>(null);
    const [veiculoPesquisa, setVeiculoPesquisa] = useState<ICodePesquisaValue | null>(null);

    const columnsCredenciada = useCredenciadaColumns();
    const columnsCliente = useClienteColumns();
    const columnsVeiculo = useClienteVeiculoColumns();
    const currentUser = SessionManager.getSession().user;

    const onFormLoaded = useCallback(() => {
        if (loaded) return;

        setLoaded(true);
    }, [loaded]);

    const handleClose = () => props.onClose?.call(this);

    const handleApplyFilter = () => {
        let filtered: IFiltroRelatorioGarantia = {
            ...filter,
            clienteId: clientePesquisa?.value,
            credenciadaId: credenciadaPesquisa?.value,
            veiculoId: veiculoPesquisa?.value,
        };

        if (currentUser?.clienteId) filtered.clienteId = currentUser.clienteId;
        if (currentUser?.credenciadaId) filtered.credenciadaId = currentUser.credenciadaId;
        if (currentUser?.gestorContaId) filtered.gestorContaId = currentUser.gestorContaId;

        setFilter(filtered);
        props.onApplyFilter?.call(this, filtered);
    }

    const handleSelecionarCredenciada = (result: unknown) => {
        if (!result) {
            setCredenciadaPesquisa(null);
            return;
        }

        setCredenciadaPesquisa({
            text: (result as CredenciadaModel).razaoSocial,
            value: (result as CredenciadaModel).id
        });
    }

    const handleSelecionarCliente = (result: unknown) => {
        if (!result) {
            setClientePesquisa(null);
            return;
        }

        setClientePesquisa({
            text: (result as ClienteModel).razaoSocial,
            value: (result as ClienteModel).id
        });
    }

    const handleSelecionarVeiculo = (result: unknown) => {
        if (!result) {
            setVeiculoPesquisa(null);
            return;
        }

        setVeiculoPesquisa({
            text: (result as ClienteVeiculoModel).modeloMarca,
            value: (result as ClienteVeiculoModel).id
        });
    }

    useEffect(() => {
        onFormLoaded();
    }, [onFormLoaded]);

    return (
        <ModalForm
            show={props.show}
            state={FormStateType.edit}
            size="3xl"
            errors={[]}
            title="Filtrar Relatório"
            onClose={handleClose}
            onSave={handleApplyFilter}
            saveButtonTitle={'Gerar'}
            isSaving={false}

        >
            <div className="grid grid-cols-12 gap-x-3 p-5">
                {!currentUser?.credenciadaId &&
                    <div className="col-span-12 mt-5">
                        <CodePesquisa
                            id="cpsCredenciada"
                            valueField={{ label: "Código:", property: "id", value: credenciadaPesquisa?.value === 0 ? "" : credenciadaPesquisa?.value?.toString() }}
                            textField={{ label: "Nome da Credenciada:", property: "razaoSocial", value: credenciadaPesquisa?.text ?? "" }}
                            modal={{
                                title: "Pesquisa de Credenciadas",
                                dataColumns: columnsCredenciada,
                                sort: { columnProperty: "razaoSocial", direction: "asc", columnIndex: 2 }
                            }}
                            onSearch={handleSelecionarCredenciada}
                            endpoint="credenciada" />
                    </div>}

                {!currentUser?.clienteId &&
                    <div className="col-span-12 mt-5">
                        <CodePesquisa
                            id="cpsCliente"
                            valueField={{ label: "Código:", property: "id", value: clientePesquisa?.value === 0 ? "" : clientePesquisa?.value?.toString() }}
                            textField={{ label: currentUser?.gestorContaId ? "Unidade:" : "Nome do Cliente:", property: "razaoSocial", value: clientePesquisa?.text ?? "" }}
                            modal={{
                                title: currentUser?.gestorContaId ? "Pesquisa de Unidades" : "Pesquisa de Clientes", 
                                dataColumns: columnsCliente,
                                sort: { columnProperty: "razaoSocial", direction: "asc", columnIndex: 2 }
                            }}
                            onSearch={handleSelecionarCliente}
                            endpoint="cliente" />
                    </div>}

                {(clientePesquisa?.value || currentUser?.clienteId) &&
                    <div className="col-span-12 mt-4">
                        <CodePesquisa
                            id="cpsVeiculo"
                            valueField={{ label: "Código:", property: "id", value: veiculoPesquisa?.value === 0 ? "" : veiculoPesquisa?.value?.toString() }}
                            textField={{ label: "Marca/Modelo:", property: "modeloMarca", value: veiculoPesquisa?.text ?? "" }}
                            modal={{
                                title: "Pesquisa de Veículos",
                                dataColumns: columnsVeiculo,
                                sort: { columnProperty: "marcaModelo", direction: "asc", columnIndex: 2 }
                            }}
                            onSearch={handleSelecionarVeiculo}
                            endpoint={`cliente/${clientePesquisa?.value ?? currentUser?.clienteId ?? 0}/veiculo`} />
                    </div>}

                <div className="form-control mt-5 col-span-4">
                    <CodeLabel className="mb-1" htmlFor="selectGarantia" value="Situação da Garantia:" />
                    <Select id="selectGarantia"
                        defaultValue={filter.expirada}
                        onChange={(e) => {
                            setFilter({ ...filter, expirada: CodeUtil.parseInt(e.currentTarget.value) ?? 0});
                        }}>
                        <option value={0}>Todas as Situações</option>
                        <option value={1}>Em Garantia</option>
                        <option value={2}>Garantia Expirada</option>
                    </Select>
                </div>
            </div>
        </ModalForm >
    );
};