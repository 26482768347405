import { FC } from "react";
import { CotacaoTipoItemType, FormStateType } from "../../../enums";
import CodeUtil from "../../../utils/CodeUtil";
import { CotacaoCredenciadaItemModel } from "../../../models/cotacao/CotacaoCredenciadaItemModel";

export type StaticListItemCredenciadasItensProps = {
    model: CotacaoCredenciadaItemModel,
    index: number
    state: FormStateType
}


export const StaticListItemCredenciadasItens: FC<StaticListItemCredenciadasItensProps> = (props) => {
    const item = props.model;

    return (
        <div className="grid grid-cols-12 items-center text-sm">
            <div className="col-span-2 ml-6 flex flex-col">
                <span className="text-xs font-semibold text-black/60">Tipo</span>
                <span className="pt-1 flex-wrap">{item.tipo === CotacaoTipoItemType.ITEM ? "PEÇA/PRODUTO" : "SERVIÇO"}</span>
            </div>
            <div className="col-span-2 mr-6 flex flex-col">
                <span className="text-xs font-semibold text-black/60">N/S</span>
                <span className="pt-1">{item.numeroSerie === null || item.numeroSerie === '' ? '-' : item.numeroSerie}</span>
            </div>
            <div className="col-span-1 ml-6 flex flex-col">
                <span className="text-xs font-semibold text-black/60">Código</span>
                <span className="pt-1 flex-wrap">{item.codigoFornecedor}</span>
            </div>
            <div className="col-span-2 ml-6 flex flex-col">
                <span className="text-xs font-semibold text-black/60">Descrição</span>
                <span className="pt-1 flex-wrap">{item.descricao}</span>
            </div>
            <div className="col-span-1 ml-6 flex flex-col">
                <span className="text-xs font-semibold text-black/60">Qtde</span>
                <span className="pt-1 flex-wrap">{CodeUtil.moneyFormat(item.quantidade, true)}</span>
            </div>
            <div className="col-span-1 ml-6 flex flex-col">
                <span className="text-xs font-semibold text-black/60">Valor Unit.</span>
                <span className="pt-1 flex-wrap">{CodeUtil.moneyFormat(item.valorUnitario, true)}</span>
            </div>
            <div className="col-span-1 ml-6 flex flex-col">
                <span className="text-xs font-semibold text-black/60">Desconto</span>
                <span className="pt-1 flex-wrap">{CodeUtil.moneyFormat(item.desconto, true)}</span>
            </div>
            <div className="col-span-1 ml-6 flex flex-col">
                <span className="text-xs font-semibold text-black/60">Valor Total</span>
                <span className="pt-1 flex-wrap">{CodeUtil.moneyFormat(item.valorTotal, true)}</span>
            </div>
        </div>
    );
};