import { createContext, useContext, useState } from "react";
import { SidebarAccountProps, SidebarAccountProviderProps } from "./types";

const SidebarAccountContext = createContext<SidebarAccountProps | undefined>(undefined);
export const useSidebarAccountContext = () => useContext(SidebarAccountContext) as SidebarAccountProps;

export const SidebarAccountContextProvider: React.FC<SidebarAccountProviderProps> = (props) => {
    const [isHidden, setIsHidden] = useState<boolean>(true);
    const setSidebarHidden = (state: boolean) => setIsHidden(state);

    return (
        <SidebarAccountContext.Provider value={{
            sidebarAccountHidden: isHidden,
            setSidebarAccountHidden: setSidebarHidden
        }}>
            {props.children}
        </SidebarAccountContext.Provider>
    )
}

export default SidebarAccountContext;