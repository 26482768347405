import { FC } from "react";
import { CodeLabelProps } from "./types";

export const CodeLabel: FC<CodeLabelProps> = (props) => {
    const color = props.disabled ? "text-gray-300" : "text-black";

    return (
        <div className={props.className}>
            <label className={`text-sm font-medium ${color}`} htmlFor={props.htmlFor}>{props.value}</label>
        </div>
    );
};