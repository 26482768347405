import React from "react";
import ToolbarAvatar from "./Avatar";
import { useSessionContext } from "../../contexts/SessionContext";
import { SessionManager } from "../../utils/SessionManager";
import styled from "styled-components";
import ToolbarToogle from "./Toogle";
import { ToolbarBrand } from "./Brand";

const Toolbar: React.FC = () => {
  const { refreshSession } = useSessionContext()
  const toogleSidebar = () => {
    let _session = SessionManager.getSession();
    SessionManager.setSession({
      ..._session,
      hiddenSidebar: !(_session.hiddenSidebar ?? true),
    });
    refreshSession();
  };

  return (
    <header>
      <StyledToolbar className="px-4 py-4 lg:py-3 fixed min-w-full shadow-sm">
        <div className="flex flex-wrap">
          <ToolbarToogle onToogle={() => toogleSidebar()} />
          <ToolbarBrand />

          <div className="p-2 bg-white flex lg:ml-5 mr-auto">
          </div>

          <div className="flex md:order-2 ml-auto space-x-8 items-center">
            <ToolbarAvatar />
          </div>
        </div>
      </StyledToolbar>
    </header>
  );
};

const StyledToolbar = styled.nav`
    background-color: white;
    min-width: 100%;
    z-index: 49;
    transition: all 1ms;
`

export default Toolbar;
