import { ModalFormProps } from "../../../components/ModalForm/types";
import { CotacaoCredenciadaModel } from "../../../models/cotacao/CotacaoCredenciadaModel";
import { CotacaoItemModel } from "../../../models/cotacao/CotacaoItemModel";

export type ModalTabCredenciadasItemProps = {
    item: CotacaoCredenciadaModel,
    cotacaoItens: CotacaoItemModel[]
} & ModalFormProps

export const useModalTabCredenciadasItem = (props: ModalTabCredenciadasItemProps) => {
    const item = props.item;
    const handleButtonClose = () => props.onClose?.call(this);

    return {
        item,
        handleButtonClose
    }
}