import { FC } from "react";
import styled from "styled-components";

export const InvalidTokenPage: FC = () => {
    return (
        <SPageContainer className="flex place-items-center justify-center bg-gradient-to-br from-red-100 to-red-50">
            <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                <div className="mx-auto max-w-screen-sm text-center">
                    <h1 className="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-red-700">403</h1>
                    <p className="mb-12 text-2xl tracking-tight font-bold text-red-700 md:text-3xl">Ooops! Acesso negado.</p>
                    <p className="mb-4 text-lg font-light text-slate-600">O Token informado é inválido ou está expirado.</p>
                    <a href="/auth/login" className="inline-flex text-white bg-gray-800 hover:bg-gray-700 focus:ring-0 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center my-4">Voltar para o LogIn</a>
                </div>
            </div>
        </SPageContainer>
    );
};

const SPageContainer = styled.div`
      height: 100vh;
      width: 100%;
    `