import { ConfiguracaoModel } from "../models/configuracao/ConfiguracaoModel";
import { BaseService } from "./BaseService";
import { DefaultResponse } from "./types";

const ENDPOINT = `${BaseService.BASE_URL}/configuracao`;

export class ConfiguracaoService extends BaseService {
    public static async get(): Promise<DefaultResponse<ConfiguracaoModel>> {
        return await this.GET<ConfiguracaoModel>(ENDPOINT);
    }

    public static async update(data: unknown): Promise<DefaultResponse> {
        return await this.PATCH(ENDPOINT, data);
    }
}