import { useCallback, useState, MouseEvent, useEffect } from "react";
import { ModalFormProps } from "../../components/ModalForm/types";
import { CatalogoModel } from "../../models/catalogo/CatalogoModel";
import { FluentValidator, FormProps } from "../../types";
import { FormStateType } from "../../enums";
import { CatalogoService } from "../../services/CatalogoService";
import { ShowToast } from "../../components/CodeToast";
import { UNKOWN_EXCEPTION } from "../../utils/Constants";
import { MapAsCommand } from "../../commands/CatalogoCommand";
import CodeUtil from "../../utils/CodeUtil";

export const useCatalogosCadastroPage = (props: ModalFormProps) => {
    const [model, setModel] = useState<CatalogoModel>({ nome: '', url: '' });
    const [formProps, setFormProps] = useState<FormProps>({ loaded: false, readonly: props.state === FormStateType.view });
    const [errors, setErrors] = useState<FluentValidator[]>([]);
    const readonly = props.state === FormStateType.view;

    const onFormLoad = useCallback(async () => {
        if (!props.show || formProps.loaded) return;

        try {
            if (props.id !== null && props.id !== undefined && props.id > 0) {
                const response = await CatalogoService.getId(props.id ?? 0);
                if (!response.success) {
                    ShowToast({ message: response.messages });
                    props.onClose?.call(this);
                    return;
                }


                setModel(response.data[0]);
            } else setModel({ nome: '', url: '' });

            setFormProps({ ...formProps, loaded: true });
        } catch (error) {
            console.log(error);
            ShowToast({ message: UNKOWN_EXCEPTION });
        }
    }, [props, formProps, setFormProps]);

    const onFormClose = () => {
        setFormProps({ ...formProps, loaded: false });
        props.onClose?.call(this);
    };

    const onFormSave = async (e: MouseEvent<HTMLButtonElement> | undefined) => {
        if (!await saveCatalogo()) return;

        props.onSave?.call(this, e);
        setFormProps({ ...formProps, loaded: false });
    };

    const fetchFormProps = (): FormProps => formProps;

    const validate = (): FluentValidator[] => {
        const _errors: FluentValidator[] = [];

        if (CodeUtil.isNullOrEmpty(model.nome)) _errors.push({ field: 'nome', isValid: false, message: 'Preenchimento obrigatório' });

        setErrors(_errors);
        return _errors;
    }

    const saveCatalogo = async (): Promise<boolean> => {
        try {
            setFormProps({ ...formProps, saving: true });
            const _errors = validate();
            if (_errors.length > 0)
                return false;

            if (props.state === FormStateType.edit)
                return await update();

            if (props.state === FormStateType.add)
                return await insert();

            return false;
        } catch (error) {
            console.log(error);
            ShowToast({ message: UNKOWN_EXCEPTION });
            return false;
        } finally {
            setFormProps({ ...formProps, saving: false });
        }
    }

    const insert = async (): Promise<boolean> => {
        try {
            const response = await CatalogoService.add(MapAsCommand(model)!);
            if (!response.success)
                ShowToast({ message: response.messages });
            return response.success;
        } catch (error) {
            console.log(error);
            ShowToast({ message: UNKOWN_EXCEPTION });
            return false;
        }
    };

    const update = async (): Promise<boolean> => {
        try {
            const response = await CatalogoService.update(model.id ?? 0, MapAsCommand(model)!);
            if (!response.success)
                ShowToast({ message: response.messages });
            return response.success;
        } catch (error) {
            console.log(error);
            ShowToast({ message: UNKOWN_EXCEPTION });
            return false;
        }
    };

    useEffect(() => {
        onFormLoad();
    });

    return {
        onFormClose, onFormSave, onFormLoad, fetchFormProps,
        model, setModel,
        readonly,
        errors, setErrors
    }
};