import { ProdutoModel } from '../models/produto';
import { BaseService } from "./BaseService";
import { DefaultResponse, ServiceProps } from "./types";

const ENDPOINT = `${BaseService.BASE_URL}/produto`;

export interface ProdutoServiceProps extends ServiceProps {
    nome?: string
    codigoFornecedor?: string
    fabricante?: number
} 

export class ProdutoService extends BaseService {
    public static async get(props: ProdutoServiceProps = { limit: 100, page: 1 }): Promise<DefaultResponse<ProdutoModel>> {
        let query = `${ENDPOINT}?limit=${props.limit}&page=${props.page}`;
        
        if (props.nome) query += `&nome=${props.nome}`;
        if (props.codigoFornecedor) query += `&codigoFornecedor=${props.codigoFornecedor}`;
        if (props.fabricante) query += `&fabricante=${props.fabricante}`;

        return await this.GET<ProdutoModel>(query);
    }

    public static async getId(id: number): Promise<DefaultResponse<ProdutoModel>> {
        return await this.GET<ProdutoModel>(`${ENDPOINT}/${id}`);
    }

    public static async save(model: ProdutoModel): Promise<DefaultResponse<ProdutoModel>> {
        if (!model.id) return await this.POST<ProdutoModel>(`${ENDPOINT}`, model);
        return await this.PATCH<ProdutoModel>(`${ENDPOINT}/${model.id}`, model);
    }

    public static async importFile(formData: FormData): Promise<DefaultResponse<ProdutoModel>> {
        return await this.POSTFORM<ProdutoModel>(`${ENDPOINT}/import`, formData);
    }

    public static async delete(id: number): Promise<DefaultResponse<ProdutoModel>> {
        return await this.DELETE(`${ENDPOINT}/${id}`);
    }
}