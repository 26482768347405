/* eslint-disable react/style-prop-object */
import { FC } from "react";
import ModalForm from "../../components/ModalForm";
import { CodeLabel } from "../../components/CodeLabel";
import { Tabs, Textarea, TextInput } from "flowbite-react";
import CodeUtil from "../../utils/CodeUtil";
import { useEmpenhoColumns } from "../../hooks";
import { Badge } from "../../components/Badge";
import { MoneyInput } from "../../components/MoneyInput";
import { EmpenhoCadastroPageProps, useEmpenhoCadastroPage } from "./EmpenhoCadastroPage.hook";
import { CondensedCodeDataGrid } from "../../components/CondensedCodeDataGrid";
import { findValidationField } from "../../types";


export const EmpenhoCadastroPage: FC<EmpenhoCadastroPageProps> = (props) => {
    const { getSituacaoColor, columnsHistorico } = useEmpenhoColumns();
    const { saldoConsumido, model,
        form, setForm, saving, errors, readonly,
        handleClose, handleSave } = useEmpenhoCadastroPage(props);

    return (
        <ModalForm
            title={model.id ? `Empenho #${(model.id ?? 0).toString().padStart(5, "0")}` : 'Lançamento de Empenho'}
            errors={[]}
            show={props.show}
            state={props.state}
            isSaving={saving}
            size="4xl"
            onClose={handleClose}
            onSave={handleSave}
        >
            <Tabs.Group aria-label="Abas" style="underline">
                <Tabs.Item title="Dados" active={true} key="dados">
                    <div className="grid grid-cols-12 mr-6 mb-6 space-x-6 space-y-3">
                        <div className="col-span-12" />

                        {model.id &&
                            <div className="col-span-12">
                                <h2 className="ml-auto text-xl font-extrabold text-black/70">{`ID: #${(model.id ?? 0).toString().padStart(5, "0")}`}</h2>
                            </div>}

                        <div className="col-span-6 md:col-span-3">
                            <CodeLabel value="Número:" htmlFor="inputNumero" className="mb-1" />
                            <TextInput
                                id="inputNumero"
                                readOnly={readonly}
                                color={findValidationField(errors, "numero").isValid ? "gray" : "failure"}
                                helperText={findValidationField(errors, "numero").message}
                                style={{ textTransform: "uppercase" }}
                                defaultValue={form.numero}
                                onChange={(e) => setForm({
                                    ...form,
                                    numero: e.currentTarget.value?.toUpperCase()
                                })} />
                        </div>

                        <div className="col-span-4 md:col-span-3">
                            <CodeLabel value="Data:" htmlFor="inputData" className="mb-1" />
                            <TextInput
                                id="inputData"
                                type={"date"}
                                pattern="\d{4}-\d{2}-\d{2}"
                                disabled={readonly}
                                defaultValue={CodeUtil.formatDate(new Date(form.dataEmpenho ?? Date.now()), "YYYY-MM-DD")}
                                onChange={(e) => {
                                    setForm({
                                        ...form,
                                        dataEmpenho: CodeUtil.formatDate(e.currentTarget.value, "YYYY-MM-DD")
                                    });
                                }} />
                        </div>

                        <div className="col-span-4 md:col-span-3">
                            <CodeLabel value="Validade:" htmlFor="inputValidade" className="mb-1" />
                            <TextInput
                                id="inputValidade"
                                type={"date"}
                                disabled={readonly}
                                pattern="\d{4}-\d{2}-\d{2}"
                                defaultValue={CodeUtil.formatDate(new Date(form.dataValidade ?? Date.now()), "YYYY-MM-DD")}
                                onChange={(e) => setForm({
                                    ...form,
                                    dataValidade: CodeUtil.formatDate(e.currentTarget.value, "YYYY-MM-DD")
                                })} />
                        </div>

                        <div className="col-span-3">
                            {model.id &&
                                <>
                                    <CodeLabel value="Situação:" htmlFor="selectSituacao" className="mb-1" />
                                    <div className="py-2.5 flex items-center align-middle">
                                        <Badge color={getSituacaoColor(model.situacao)} value={model.situacao?.toUpperCase() ?? "VIGENTE"} />
                                    </div>
                                </>}
                        </div>

                        <div className="col-span-4">
                            <CodeLabel value="Valor Empenho:" htmlFor="inputValor" className="mb-1" />
                            <MoneyInput
                                id="inputValor"
                                readOnly={readonly}
                                color={findValidationField(errors, "valorEmpenho").isValid ? "gray" : "failure"}
                                helperText={findValidationField(errors, "valorEmpenho").message}
                                value={CodeUtil.moneyFormat(form.valorEmpenho ?? 0, false)}
                                onChange={(_value, numberValue) => setForm({
                                    ...form,
                                    valorEmpenho: numberValue,
                                    saldoConsumido: saldoConsumido(form.saldoDisponivel ?? 0, numberValue)
                                })} />
                        </div>

                        <div className="col-span-4">
                            <CodeLabel value="Valor Disponível:" htmlFor="inputSaldoDisponivel" className="mb-1" />
                            <MoneyInput
                                id="inputSaldoDisponivel"
                                readOnly={readonly}
                                color={findValidationField(errors, "saldoDisponivel").isValid ? "gray" : "failure"}
                                helperText={findValidationField(errors, "saldoDisponivel").message}
                                value={CodeUtil.moneyFormat(form.saldoDisponivel ?? 0, false)}
                                onChange={(_value, numberValue) => setForm({
                                    ...form,
                                    saldoDisponivel: numberValue,
                                    saldoConsumido: saldoConsumido(numberValue, form.valorEmpenho ?? 0)
                                })} />
                        </div>

                        <div className="col-span-4">
                            <CodeLabel value="Valor Utilizado:" htmlFor="inputValorUtilizado" className="mb-1" />
                            <MoneyInput
                                id="inputValorUtilizado"
                                readOnly={true}
                                value={CodeUtil.moneyFormat(form.saldoConsumido ?? 0, false)} />
                        </div>

                        <div className="col-span-12">
                            <CodeLabel value="Descrição:" htmlFor="inputDescricao" className="mb-1" />
                            <TextInput
                                id="inputDescricao"
                                readOnly={readonly}
                                color={findValidationField(errors, "descricao").isValid ? "gray" : "failure"}
                                helperText={findValidationField(errors, "descricao").message}
                                style={{ textTransform: "uppercase" }}
                                defaultValue={form.descricao}
                                onChange={(e) => setForm({
                                    ...form,
                                    descricao: e.currentTarget.value?.toUpperCase()
                                })} />
                        </div>


                        <div className="col-span-12">
                            <CodeLabel value="Observações:" htmlFor="inputObservacoes" className="mb-1" />
                            <Textarea
                                id="inputObservacoes"
                                readOnly={readonly}
                                defaultValue={form.observacao}
                                onChange={(e) => setForm({
                                    ...form,
                                    observacao: e.currentTarget.value
                                })} />
                        </div>
                    </div>
                </Tabs.Item>
                <Tabs.Item title="Histórico" active={true} key="historico">
                    <div className="-m-5">
                        <CondensedCodeDataGrid
                            key="id"
                            sort={{ columnIndex: 0, direction: "desc", columnProperty: "data" }}
                            header={{ filter: true }}
                            datasource={model.historico}
                            columns={columnsHistorico}
                            multiselect={false}
                            readonly={readonly}
                        />
                    </div>
                </Tabs.Item>
            </Tabs.Group>
        </ModalForm>
    )
}