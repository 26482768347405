import { FC } from "react";
import { ModalFormProps } from "../../components/ModalForm/types";
import ModalForm from "../../components/ModalForm";
import { CodeLabel } from "../../components/CodeLabel";
import { Checkbox, Label, Select, Spinner, Textarea, TextInput } from "flowbite-react";
import { MaskedInput } from "../../components/MaskedInput";
import CodeUtil from "../../utils/CodeUtil";
import { Groupbox } from "../../components/Groupbox";
import { useGestorContasCadastroPage } from "./GestorContasCadastroPage.hook";
import { MoneyInput } from "../../components/MoneyInput";

export const GestorContasCadastroPage: FC<ModalFormProps> = (props) => {
    const {
        model, setModel, readonly, formErrors, errors, saving, loaded,
        buscandoCep, estados, municipios, consultarCep, onLoadMunicipios,
        handleSave
    } = useGestorContasCadastroPage(props);

    return (
        <ModalForm
            {...props}
            errors={errors}
            isSaving={saving}
            onSave={handleSave}
            title={`${props.id ? "Gestor de Contas" : "Cadastrar Gestor de Contas"}`}
            size="4xl"
        >
            {loaded && 
            <div className="grid grid-cols-12 m-3">
                <div className="col-span-12">
                    <h2
                        className="ml-auto text-xl font-extrabold text-black/70"
                        hidden={(model?.id ?? 0) === 0}
                    >
                        {`ID: #${(model?.id ?? 0).toString().padStart(3, "0")}`}
                    </h2>
                </div>

                <div className="form-control mt-5 col-span-12 md:col-span-6 md:mr-6">
                    <CodeLabel className="mb-1" htmlFor="inputRazaoSocial" value="Razão Social:" />
                    <TextInput
                        id="inputRazaoSocial"
                        type="text"
                        readOnly={readonly}
                        helperText={formErrors.find(x => x.field === "razaoSocial")?.message}
                        color={(formErrors.find(x => x.field === "razaoSocial")?.isValid ?? true) ? "gray" : "failure"}
                        defaultValue={model?.razaoSocial}
                        onChange={(e) => setModel({ ...model, razaoSocial: e.currentTarget.value })}
                        maxLength={100}
                    />
                </div>

                <div className="form-control mt-5 col-span-12 md:col-span-6">
                    <CodeLabel className="mb-1" htmlFor="inputFantasia" value="Fantasia:" />
                    <TextInput
                        id="inputFantasia"
                        type="text"
                        readOnly={readonly}
                        defaultValue={model?.fantasia ?? ""}
                        onChange={(e) => setModel({ ...model, fantasia: e.currentTarget.value })}
                        maxLength={80}
                    />
                </div>

                <div className="form-control mt-5 col-span-12 md:col-span-3 md:mr-6">
                    <CodeLabel className="mb-1" htmlFor="inputCNPJ" value="CNPJ:" />
                    <MaskedInput
                        id="inputCNPJ"
                        type="text"
                        readOnly={readonly}
                        helperText={formErrors.find(x => x.field === "cnpj")?.message}
                        color={(formErrors.find(x => x.field === "cnpj")?.isValid ?? true) ? "gray" : "failure"}
                        mask="##.###.###/####-##"
                        pattern="^[0-9]+$"
                        value={model?.cnpj}
                        onChange={(value) => setModel({ ...model, cnpj: value })}
                    />
                </div>

                <div className="form-control mt-5 col-span-12 md:col-span-3 md:mr-6">
                    <CodeLabel className="mb-1" htmlFor="inputIE" value="IE:" />
                    <TextInput
                        id="inputIE"
                        type="text"
                        readOnly={readonly}
                        defaultValue={model?.inscricaoEstadual ?? ""}
                        onChange={(e) => setModel({ ...model, inscricaoEstadual: CodeUtil.removeSpecialChars(e.currentTarget.value) })}
                        maxLength={20}
                    />
                </div>

                <div className="form-control mt-5 col-span-12">
                    <CodeLabel className="mb-1" htmlFor="inputEmail" value="E-mail:" />
                    <TextInput
                        addon={"@"}
                        id="inputEmail"
                        type="email"
                        helperText={formErrors.find(x => x.field === "email")?.message}
                        color={(formErrors.find(x => x.field === "email")?.isValid ?? true) ? "gray" : "failure"}
                        readOnly={readonly}
                        defaultValue={model?.email ?? ""}
                        onChange={(e) => setModel({ ...model, email: e.currentTarget.value?.trim() })}
                    />
                </div>

                <Groupbox className="col-span-12 mt-5" label="Endereço">
                    <div className="form-control col-span-4 md:col-span-2 mt-5">
                        <CodeLabel className="mb-1" htmlFor="inputCEP" value="CEP:" />
                        <div className="flex">
                            <MaskedInput
                                id="inputCEP"
                                type="text"
                                mask="#####-###"
                                pattern="^[0-9]+$"
                                readOnly={readonly}
                                value={model?.cep ?? ""}
                                onChange={(value) => {
                                    setModel({ ...model, cep: value ?? "" });
                                    consultarCep(CodeUtil.removeSpecialChars(value));
                                }}
                                maxLength={10}
                            />

                            <div className={"ml-3 mt-2 text-white"} hidden={!buscandoCep}>
                                <Spinner size="sm" light={true} />
                            </div>
                        </div>
                    </div>
                    <div className="col-span-10"></div>

                    <div className="form-control mt-5 col-span-9 mr-6">
                        <CodeLabel className="mb-1" htmlFor="inputLogradouro" value="Logradouro:" />
                        <TextInput
                            id="inputLogradouro"
                            type="text"
                            readOnly={readonly}
                            defaultValue={model?.logradouro ?? ""}
                            onChange={(e) => setModel({ ...model, logradouro: e.currentTarget.value })}
                            maxLength={100}
                        />
                    </div>

                    <div className="form-control mt-5 col-span-3">
                        <CodeLabel className="mb-1" htmlFor="inputLogradouroNumero" value="Número:" />
                        <TextInput
                            id="inputLogradouroNumero"
                            type="text"
                            readOnly={readonly}
                            defaultValue={model?.logradouroNumero ?? ""}
                            onChange={(e) => setModel({ ...model, logradouroNumero: e.currentTarget.value })}
                            maxLength={10}
                        />
                    </div>

                    <div className="form-control mt-5 col-span-12 md:col-span-6 md:mr-6">
                        <CodeLabel className="mb-1" htmlFor="inputBairro" value="Bairro:" />
                        <TextInput
                            id="inputBairro"
                            type="text"
                            readOnly={readonly}
                            defaultValue={model?.bairro ?? ""}
                            onChange={(e) => setModel({ ...model, bairro: e.currentTarget.value })}
                            maxLength={80}
                        />
                    </div>

                    <div className="form-control mt-5 col-span-3 md:col-span-2 mr-6">
                        <CodeLabel className="mb-1" htmlFor="selectUF" value="UF:" />
                        <Select id="selectUF" onChange={(e) => {
                            setModel({ ...model, uf: { ...model?.uf, sigla: e.currentTarget.value } });
                            onLoadMunicipios(e.currentTarget.value)
                        }}
                            disabled={readonly}
                            value={model?.uf?.sigla ?? "ND"}>
                            <option key={'0'} value={'ND'}>ND</option>
                            {estados.map((estado) => (<option key={estado.sigla} value={estado.sigla}>{estado.sigla}</option>))}
                        </Select>
                    </div>

                    <div className="form-control mt-5 col-span-9 md:col-span-4">
                        <CodeLabel className="mb-1" htmlFor="selectMunicipio" value="Município:" />
                        <Select
                            id="selectMunicipio"
                            disabled={readonly}
                            helperText={formErrors.find(x => x.field === "municipioId")?.message}
                            color={(formErrors.find(x => x.field === "municipioId")?.isValid ?? true) ? "gray" : "failure"}
                            onChange={(e) => setModel({
                                ...model,
                                municipioId: CodeUtil.parseInt(e.currentTarget.value),
                                municipio: { ...model?.municipio, id: CodeUtil.parseInt(e.currentTarget.value) ?? 0 }
                            })}
                            value={model?.municipio?.id ?? 0}>
                            <option key={0} value={0}>Selecione um município</option>
                            {municipios.map((municipio) => (
                                <option key={municipio.descricao} value={municipio.id}>
                                    {municipio.descricao}
                                </option>
                            ))}
                        </Select>
                    </div>
                </Groupbox>

                <Groupbox className="col-span-12 mt-5" label="Observações">
                    <div className="form-control col-span-12 mt-5">
                        <CodeLabel className="mb-1" htmlFor="inputObservacoes" value="Observações:" />
                        <Textarea
                            id="inputObservacoes"
                            rows={5}
                            readOnly={readonly}
                            defaultValue={model?.observacoes ?? ""}
                            onChange={(e) => setModel({ ...model, observacoes: e.currentTarget.value })}
                        />
                    </div>
                </Groupbox>

                <Groupbox className="col-span-12 my-5" label="Configurações">
                    <div className="col-span-6 md:col-span-3 mr-6 mt-5">
                        <CodeLabel value={"Comissão Rodar:"} className="mb-1" htmlFor="inputComissaoRodar" />
                        <MoneyInput id="inputComissaoRodar"
                            value={CodeUtil.moneyFormat(model?.percentualComissaoRodar ?? 0, false)}
                            addon={"%"}
                            readOnly={readonly}
                            onChange={(_stringValue, numberValue) => setModel({ ...model, percentualComissaoRodar: numberValue })} />
                    </div>

                    <div className="col-span-6 md:col-span-3 mt-5 md:mr-6">
                        <CodeLabel value={"Desconto em Contrato:"} className="mb-1" htmlFor="inputDescontoContrato" />
                        <MoneyInput id="inputDescontoContrato"
                            value={CodeUtil.moneyFormat(model?.percentualDescontoContrato ?? 0, false)}
                            addon={"%"}
                            readOnly={readonly}
                            onChange={(_stringValue, numberValue) => setModel({ ...model, percentualDescontoContrato: numberValue })} />
                    </div>

                    <div className="form-control mt-12 col-span-12 md:col-span-3 flex space-x-2 items-center">
                        <Checkbox id="checkBoxPermitirCatalogos"
                            disabled={readonly}
                            defaultChecked={model?.permitirVisualizarCatalogos}
                            onChange={(e) => setModel({
                                ...model,
                                permitirVisualizarCatalogos: e.currentTarget.checked
                            })}
                            placeholder="Checked">
                        </Checkbox>

                        <Label htmlFor="checkBoxPermitirCatalogos" value="Permitir Acessar Catálogos?" />
                    </div>

                    <div className="form-control mt-12 col-span-12 md:col-span-3 flex space-x-2 items-center">
                        <Checkbox id="checkBoxBloqueado"
                            disabled={readonly}
                            defaultChecked={model?.bloqueado}
                            onChange={(e) => setModel({
                                ...model,
                                bloqueado: e.currentTarget.checked
                            })}
                            placeholder="Checked">
                        </Checkbox>

                        <Label htmlFor="checkBoxBloqueado" value="Bloqueado?" />
                    </div>
                </Groupbox>
            </div>}
        </ModalForm>
    )
}