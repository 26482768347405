import { TextInput } from "flowbite-react";
import { FocusEvent, ChangeEvent, FC } from "react";
import CodeUtil from "../../utils/CodeUtil";
import { MaskedInputProps } from "./types";

export const MaskedInput: FC<MaskedInputProps> = (props) => {
    const handleChangeValue = (e: ChangeEvent<HTMLInputElement>) => {
        let _value = CodeUtil.removeSpecialChars(e.currentTarget.value);

        if (!CodeUtil.isNullOrEmpty(_value) && props.pattern !== null && !new RegExp(props.pattern!).test(_value?.trim())) {
            e.preventDefault();
            return;
        }

        let _maskedValue = CodeUtil.mask(CodeUtil.removeSpecialChars(_value), props.mask);
        props.onChange?.call(this, _value, _maskedValue);
    }

    const handleBlur = (e: FocusEvent<HTMLInputElement>) => props.onLeave?.call(this, e);

    return (
        <TextInput
            id={props.id}
            value={CodeUtil.mask(CodeUtil.removeSpecialChars(props.value ?? ""), props.mask)}
            readOnly={props.readOnly}
            placeholder={props.placeHolder}
            type={props.type}
            helperText={props.helperText}
            color={props.color}
            maxLength={props.maxLength}
            onChange={handleChangeValue}
            onBlur={handleBlur}
        />
    );
};