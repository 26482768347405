import { useState } from "react";
import { ICodeDataGridColumn } from "../../components/CondensedCodeDataGrid/types";
import { format } from "fecha";
import { VeloeService } from "../../services/VeloeService";
import { ShowToast } from "../../components/CodeToast";
import { UNKOWN_EXCEPTION } from "../../utils/Constants";
import { VeloeVehiclesModel } from "../../models/veloe/VeloeVehiclesModel";
import { VeloeBaseRequest } from "../../models/veloe/VeloeBaseRequest";

export const useVeloeVeiculosPage = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [showFiltros, setShowFiltros] = useState<boolean>(false);
    const [dataSource, setDataSource] = useState<VeloeVehiclesModel[]>([]);
    const [filtroVeiculos, setFiltroVeiculos] = useState<VeloeBaseRequest>();

    const columns: ICodeDataGridColumn[] = [
        { title: "Nome da Filial", property: "branchName" },
        { title: "CNPJ", property: "branchDocumentNumber", alignment: "center" },
        { title: "Perfil da Conta", property: "accountProfileName" },
        { title: "Centro de Custo", property: "costCenter" },
        { title: "Placa", property: "plate" },
        { title: "UF", property: "state" },
        { title: "Município", property: "city" },
        { title: "Tipo de Frota", property: "type" },
        { title: "Data de Cadastro", property: "registrationDate", onFormatValue: (item: VeloeVehiclesModel) => 
            <span>{!item.registrationDate ? "" : format(new Date(item.registrationDate), "DD/MM/YYYY HH:mm")}</span> },
        { title: "Fabricante", property: "manufacturerName" },
        { title: "Modelo", property: "model" },
        { title: "Ano de Fabricação", property: "manufactureYear" },
        { title: "Ano do Modelo", property: "modelYear" },
        { title: "Cor", property: "color" },
        { title: "Status", property: "status" },
        { title: "Motorista Associado", property: "associatedDriverName" },
    ]

    const handleConsultarVeiculos = () => {
        setShowFiltros(false);
        setLoading(true);

        VeloeService.getVeiculos(filtroVeiculos)
            .then(async (response) => {
                if (!response.success) {
                    ShowToast({ message: response.messages });
                    return;
                }

                setDataSource(response.data);
            }).catch((error) => {
                console.log(error);
                ShowToast({ message: UNKOWN_EXCEPTION });
            }).finally(() => {
                setLoading(false);
            });
    }

    return {
        loading, columns,
        showFiltros, setShowFiltros,
        filtroVeiculos, setFiltroVeiculos,
        dataSource,
        handleConsultarVeiculos,
    }
}