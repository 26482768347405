import { format } from "fecha"
import { CotacaoStatusType } from "../../enums"
import { ClienteModel } from "../cliente/ClienteModel"
import { ClienteVeiculoModel } from "../cliente_veiculo/ClienteVeiculoModel"
import { getCotacaoStatus } from "./CotacaoModel"
import { ViewCotacaoCredenciadaItemModel } from "./ViewCotacaoCredenciadaItem"

export interface ViewCotacaoCredenciadaModel {
    id: number | null,
    credenciadaId: number,
    numero: number
    dataCadastro: string
    dataAlteracao: string
    dataValidade: string
    dataCancelamento: string | null
    status: CotacaoStatusType
    statusDescricao: string
    cliente: ClienteModel | null
    veiculo: ClienteVeiculoModel | null
    razaoSocial: string | null
    fantasia: string | null
    cnpj: string | null
    observacoes: string | null
    observacoesCliente: string | null
    desconto: number
    descontoPercentual: number
    frete: number
    seguro: number
    outrasDespesas: number
    valorTotal: number
    valorComissao: number
    notaFiscalUrl?: string | null
    notaFiscalKey?: string | null
    melhorCotacao: boolean
    orgao?: string | undefined
    valorVenal: number
    empenhoId?: number
    itens: ViewCotacaoCredenciadaItemModel[]
}

export const getDefaultViewCotacaoCredenciada = (): ViewCotacaoCredenciadaModel => {
    return {
        id: null,
        credenciadaId: 0,
        numero: 0,
        dataCadastro: format(new Date(Date.now()), 'YYYY-MM-DD'),
        dataAlteracao: format(new Date(Date.now()), 'YYYY-MM-DD'),
        dataValidade: format(new Date(Date.now()), 'YYYY-MM-DD'),
        dataCancelamento: null,
        observacoes: null,
        observacoesCliente: null,
        status: CotacaoStatusType.AGUARDANDO,
        statusDescricao: getCotacaoStatus(CotacaoStatusType.AGUARDANDO),
        cliente: null,
        veiculo: null,
        cnpj: null,
        fantasia: null,
        razaoSocial: null,
        desconto: 0,
        descontoPercentual: 0,
        frete: 0,
        outrasDespesas: 0,
        seguro: 0,
        valorTotal: 0,
        valorComissao: 0,
        itens: [],
        melhorCotacao: false,
        valorVenal: 0
    }
}