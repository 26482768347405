import { useState, useCallback } from "react";
import { ICodeDataGridPagination, ICodeDataGridRow } from "../../components/CodeDataGrid/types";
import { ModalStateType } from "../../components/ModalForm/types";
import { FormStateType } from "../../enums";
import { EmpenhoModel } from "../../models/empenho";
import { EmpenhoService } from "../../services/EmpenhoService";
import { ShowToast } from "../../components/CodeToast";
import { UNKOWN_EXCEPTION } from "../../utils/Constants";
import { SessionManager } from "../../utils/SessionManager";
import { enqueueSnackbar } from "notistack";
import CodeUtil from "../../utils/CodeUtil";

export const useEmpenhoPage = () => {
    const [loaded, setLoaded] = useState<boolean>(false);
    const [showPopupCancelar, setShowPopupCancelar] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [saving, setSaving] = useState<boolean>(false);
    const [selected, setSelected] = useState<ICodeDataGridRow | null>(null);
    const [selectedModel, setSelectedModel] = useState<EmpenhoModel>({
        isSelected: false, state: "Added"
    });
    const [dataSource, setDataSource] = useState<EmpenhoModel[]>([]);
    const [page, setPage] = useState<number>(1);
    const [pagination, setPagination] = useState<Omit<ICodeDataGridPagination, 'onPageChanged'>>({ page: 1, pageCount: 0, limit: 100, recordCount: 0 });
    const [modalState, setModalState] = useState<ModalStateType<EmpenhoModel>>({
        show: false,
        state: FormStateType.add,
    });

    const session = SessionManager.getSession();
    const clienteId = session.user?.clienteId ?? 0;

    const onPageLoad = useCallback(async () => {
        try {
            if (loaded) return;
            setLoading(true);

            const response = await EmpenhoService.get(clienteId, {
                page: page,
                limit: 100,
            });

            if (!response.success) {
                ShowToast({ message: response.messages });
                return;
            }

            setDataSource(response.data);
        } catch (error) {
            console.log(error);
            ShowToast({ message: UNKOWN_EXCEPTION });
        } finally {
            setLoaded(true);
            setLoading(false);
        }

        setLoaded(true);
    }, [clienteId, loaded, page]);

    const handleSave = async () => {
        try {
            const response = await EmpenhoService.save(clienteId, selectedModel);
            if (!response.success) {
                enqueueSnackbar({ message: CodeUtil.arrayToStr(response.messages), variant: 'error' });
                return;
            }

            setLoaded(false);
            setModalState({ ...modalState, show: false });
        } catch (error) {
            console.log(error);
            enqueueSnackbar({ message: UNKOWN_EXCEPTION, variant: 'error' });
        } finally {
            setSaving(false);
        }
    }

    const handleClose = () => {
        setModalState({ ...modalState, show: false });
    }

    const handleCancelarEmpenho = async () => {
        try {
            let response = await EmpenhoService.delete(selected?.item.clienteId, selected?.item.id);
            if (!response.success) {
                console.log(response.messages);
                return;
            }

            setLoaded(false);
        } catch (error) {
            console.log(error);
        } finally {
            setShowPopupCancelar(false);
        }        
    }

    const handleInserir = () => {
        setSelectedModel({ isSelected: true, state: "Added" });
        setModalState({ ...modalState, show: true, state: FormStateType.add });
    }

    const handleConsultar = (readonly: boolean = false) => {
        setSelectedModel({ ...selectedModel, ...selected?.item });
        setModalState({
            ...modalState,
            show: true,
            state: readonly ? FormStateType.view : FormStateType.edit
        });
    }

    return {
        onPageLoad, modalState, loading, dataSource,
        selected, setSelected, saving, setSaving,
        selectedModel, setSelectedModel,
        handleSave, handleClose,
        showPopupCancelar, setShowPopupCancelar,
        handleCancelarEmpenho, handleInserir, handleConsultar,
        pagination, setPagination,
        page, setPage,
    }
}