import { Dispatch, SetStateAction } from "react";
import { CotacaoStatusType, FormStateType } from "../enums";

export type SearchCTAProps<T> = {
    modelState: [T, Dispatch<SetStateAction<T>>];
    onAdd?: () => void;
    onEdit?: () => void;
    onView?: () => void;
    onDelete?: () => void;
    children?: JSX.Element | JSX.Element[];
    readOnly?: boolean;
}

export type FormProps = {
    loaded: boolean
    readonly: boolean
    saving?: boolean
}

export type ModalProps = {
    state: FormStateType;
    show: boolean;
    id?: number | null;
}


export type ModalState = {
    id?: number
    show: boolean,
    state: FormStateType
}


export type FluentValidator = {
    field: string;
    message: string;
    isValid: boolean;
}

export const findValidationField = (validation: FluentValidator[], field: string): FluentValidator => {
    let filterResult = validation.filter(item => item.field === field);
    return filterResult.length === 0 ? { field: '', isValid: true, message: '' } : filterResult[0];
}

export const findValidationMessage = (validation: FluentValidator[], field: string): string | undefined => {
    return findValidationField(validation, field).message;
}

export const findValidationFailure = (validation: FluentValidator[], field: string): string | undefined => {
    return findValidationField(validation, field).isValid ? undefined : "failure";
}

export interface IFiltroCotacao {
    dataInicial?: string
    dataFinal?: string
    orgao?: string
    status?: CotacaoStatusType[]
}