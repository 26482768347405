import { useCallback, useEffect, useState } from "react";
import { ClienteVeiculoModel } from "../../models/cliente_veiculo/ClienteVeiculoModel";
import { ICodeDataGridPagination, ICodeDataGridRow } from "../../components/CodeDataGrid/types";
import { ModalState } from "../../types";
import { FormStateType } from "../../enums";
import { ShowToast } from "../../components/CodeToast";
import { UNKOWN_EXCEPTION } from "../../utils/Constants";
import { ClienteVeiculoService } from "../../services/ClienteVeiculoService";
import { DefaultResponse } from "../../services/types";
import { SessionManager } from "../../utils/SessionManager";

export const useClientesFrotasPage = () => {
    const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
    const [dataSource, setDataSource] = useState<ClienteVeiculoModel[]>([]);
    const [page, setPage] = useState<number>(1);
    const [pagination, setPagination] = useState<Omit<ICodeDataGridPagination, 'onPageChanged'>>({ page: 1, pageCount: 0, limit: 100, recordCount: 0 });
    const [selectedRow, setSelectedRow] = useState<ICodeDataGridRow>();
    const [modalState, setModalState] = useState<ModalState>({ show: false, state: FormStateType.view });
    const [showPopupDelete, setShowPopupDelete] = useState<boolean>(false);

    const inserir = () => {
        setModalState({ show: true, state: FormStateType.add, id: 0 });
    }

    const consultar = (readonly: boolean) => {
        if (!selectedRow || !selectedRow.item) {
            ShowToast({ message: "Nenhum veículo selecionado." });
            return;
        }

        setModalState({ show: true, state: readonly ? FormStateType.view : FormStateType.edit, id: (selectedRow.item as ClienteVeiculoModel).id });
    }


    const excluir = async () => {
        try {
            const response = await ClienteVeiculoService.delete((selectedRow?.item as ClienteVeiculoModel).id!);
            if (!response.success) {
                console.log(response.messages);
                return;
            }

            await onFormLoad();
        } catch (error) {
            console.log(error);
            ShowToast({ message: UNKOWN_EXCEPTION });
        } finally {
            setShowPopupDelete(false);
        }
    };

    const fetchVeiculos = useCallback(async (): Promise<DefaultResponse<ClienteVeiculoModel>> => {
        return await ClienteVeiculoService.get(SessionManager.getSession().user?.clienteId!, {
            page: page,
            limit: 100
        });
    }, [page]);

    const onFormLoad = useCallback(async () => {
        setIsLoadingData(true);
        fetchVeiculos().then((response) => {
            setDataSource(response.data ?? []);

            setPagination({
                page: response.pagination.page,
                pageCount: response.pagination.pageCount,
                limit: response.pagination.limit,
                recordCount: response.pagination.recordCount,
            });
        }).finally(() => setIsLoadingData(false));
    }, [setDataSource, setIsLoadingData, fetchVeiculos, setPagination]);

    const onModalClose = useCallback(async () => {
        setModalState({ ...modalState, id: 0, show: false });
        await onFormLoad();
    }, [modalState, setModalState, onFormLoad]);

    useEffect(() => {
        onFormLoad();
    }, [onFormLoad]);

    return {
        isLoadingData, dataSource, pagination, modalState, selectedRow,
        setPage, setSelectedRow, onModalClose,
        inserir, consultar, excluir,
        showPopupDelete, setShowPopupDelete
    }
}
