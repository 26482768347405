import { FC, useState } from "react"
import { SidebarDropdownListItemProps } from "../types"
import { MdKeyboardArrowDown, MdKeyboardArrowUp} from 'react-icons/md'

export const SidebarDropdownListItems : FC<SidebarDropdownListItemProps> = (props) => {
    const [isExpanded, setIsExpandanded] = useState<boolean>(props.expanded ?? false)
    const _class = `flex items-center p-2 w-full text-base font-normal text-gray-900 rounded-lg transition duration-75 group hover:bg-gray-100 ${props.active ? 'bg-gray-200' : ''}`

    return (
        <li>
            <button type="button" className={_class}
                onClick={() => setIsExpandanded(!isExpanded)}>
                  {props.icon}
                  <span className="flex-1 ml-3 text-left whitespace-nowrap">{props.label}</span>
                  {isExpanded ? <MdKeyboardArrowUp className="w-6 h-6 transition-transform" /> : <MdKeyboardArrowDown className="w-6 h-6  transition-transform" />}
            </button>
            
            <ul className="space-y-2  transition ease-in">
                {isExpanded ? props.children : <></>}
            </ul>
        </li>
    )
}