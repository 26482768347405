import React, { Fragment } from 'react'
import styled from 'styled-components'
import { Sidebar } from '../../components/Sidebar'
import { SidebarAccount } from '../../components/SidebarAccount'
import Toolbar from '../../components/Toolbar'
import { useSessionContext } from '../../contexts/SessionContext'
import { SessionManager } from '../../utils/SessionManager'
import { LayoutProps } from './types'

export const MainLayout: React.FC<LayoutProps> = ({ children }) => {
  const { session } = useSessionContext();

  return (
    <Fragment>
      <div className="flex">
        <Toolbar />

        <Sidebar
          hidden={(session?.hiddenSidebar ?? true)}
          onItemClick={() =>
            SessionManager.setSession({
              ...SessionManager.getSession(),
              hiddenSidebar: true,
            })
          }
        />

        <SidebarAccount />

        <div className="w-full">
          <StyledSideContainer>
            {children}
          </StyledSideContainer>
        </div>
      </div>
    </Fragment>
  );
}



const StyledSideContainer = styled.div`
  /* margin: 4rem 1.75rem 1.75rem;

  @media (min-width: 1024px) {
      margin: 4rem 1.75rem 1.75rem;
  } */

  margin-top: 58px;
`