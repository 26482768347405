import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { ModalFormProps } from "../../../components/ModalForm/types";
import { ViewCotacaoCredenciadaItemModel } from "../../../models/cotacao/ViewCotacaoCredenciadaItem";
import { FormStateType } from "../../../enums";
import { ServicoModel } from "../../../models/servico";
import { ServicoService } from "../../../services/ServicoService";

export type TabItensLancamentoProps = {
    item: [ViewCotacaoCredenciadaItemModel, Dispatch<SetStateAction<ViewCotacaoCredenciadaItemModel>>]
    index: number | null
    onSaveItem?: (item: ViewCotacaoCredenciadaItemModel) => void
} & ModalFormProps;

interface ErrorMessage {
    failure?: boolean
    message?: string
}

export const useTabItensLancamento = (props: TabItensLancamentoProps) => {
    const [loaded, setLoaded] = useState<boolean>(false);
    const [item, setItem] = props.item;
    const readonly = props.state === FormStateType.view;
    const [invalidPreco, setInvalidPreco] = useState<ErrorMessage>();
    const [dataServicos, setDataServicos] = useState<ServicoModel[]>([]);

    const fetchServicos = useCallback(async () => {
        let response = await ServicoService.get({
            limit: 99999,
            page: 1,
            ativo: readonly ? true : undefined
        });

        if (!response.success) {
            console.log(response);
            return setDataServicos([]);
        }

        const servicos = response.data as ServicoModel[];
        return setDataServicos(servicos.sort((a, b) => b.nome > a.nome ? -1 : 1));
    }, [readonly]);

    const onFormLoad = useCallback(async () => {
        if (loaded) return;

        await fetchServicos();
        setLoaded(true);
    }, [loaded, setLoaded, fetchServicos]);

    const validarPrecoFabricate = (): boolean => {
        const precoFabricante = item.produto?.preco ?? 0;
        return precoFabricante === 0 || item.valorUnitario <= precoFabricante;
    }

    const handleSalvarCotacao = () => {
        setInvalidPreco({
            ...invalidPreco,
            failure: false
        });

        if (!validarPrecoFabricate()) {
            setInvalidPreco({
                message: "O preço unitário do item é maior que o preço base do fabricante.",
                failure: true
            });
            return;
        }

        if (item.valorUnitario === 0) {
            setInvalidPreco({
                message: "O preço unitário do item não pode ser zero.",
                failure: true
            });
            return;
        }

        setItem({
            ...item,
            valorTotal: getValorTotal(),
            valorComissao: getValorComissao(),
            desconto: getValorDesconto(item.quantidade, item.valorUnitario)
        });

        props.onSaveItem?.call(this, {
            ...item,
            valorTotal: getValorTotal(),
            valorComissao: getValorComissao(),
            desconto: getValorDesconto(item.quantidade, item.valorUnitario)
        });
    }

    const getValorTotal = (_item?: ViewCotacaoCredenciadaItemModel): number => {
        const itemCalculo = _item ?? item;

        if (!itemCalculo) return 0;

        let despesasAcessorias = itemCalculo.frete + itemCalculo.seguro + itemCalculo.outrasDespesas;
        let quantidade = Number.isNaN(itemCalculo.quantidade) ? 0 : itemCalculo.quantidade;
        let subtotal = quantidade * itemCalculo.valorUnitario;

        let desconto = subtotal * (itemCalculo.descontoPercentual / 100);

        return (subtotal - desconto) + despesasAcessorias;
    };

    const getValorComissao = (): number => {
        if (!item) return 0;

        let percentualComissao = item.percentualComissao / 100;
        let subtotal = getValorTotal();
        let despesasAcessorias = item.frete + item.seguro + item.outrasDespesas;

        return (subtotal - despesasAcessorias) * percentualComissao;
    }

    const getValorDesconto = (quantidade: number, valorUnitario: number): number => {
        if (!item) return 0;

        quantidade = Number.isNaN(quantidade) ? 0 : quantidade;
        valorUnitario = Number.isNaN(valorUnitario) ? 0 : valorUnitario;

        let subtotal = quantidade * valorUnitario;
        let percentualDesconto = item.descontoPercentual / 100;

        return subtotal * percentualDesconto;
    };


    useEffect(() => {
        onFormLoad();
    }, [onFormLoad]);

    return {
        item, setItem, readonly, dataServicos, getValorDesconto, invalidPreco,
        handleSalvarCotacao, getValorTotal
    }
}