/* eslint-disable react/style-prop-object */
import { Checkbox, Label, Tabs, TextInput } from "flowbite-react";
import { FC } from "react";
import { ModalForm } from "../../components/ModalForm";
import { ModalFormProps } from "../../components/ModalForm/types";
import { useUsuariosCadastroPage } from "./UsuariosCadastroPage.hook";
import { CondensedCodeDataGrid } from "../../components/CondensedCodeDataGrid";
import { UsuarioPermissaoModel } from "../../models/usuario_permissao";
import { BsArrowReturnRight } from "react-icons/bs";
import { CodePesquisa } from "../../components/CodePesquisa";
import { useClienteColumns } from "../../models/cliente/useClienteColumns";
import { UserType } from "../../enums";

export const UsuariosCadastroPage: FC<ModalFormProps> = (props) => {
    const {
        handleKeydownEvent, handleChangePermissao, handleSearchCliente, generateUserName,
        onFormClose, onFormSave, sessionUser,
        setModel, errors, saving, model, isReadOnly
    } = useUsuariosCadastroPage(props);

    const clienteColumns = useClienteColumns();

    return (
        <div onKeyDown={handleKeydownEvent}>
            <ModalForm title={props.title ?? "Cadastro de Usuários"}
                errors={errors}
                show={props.show}
                state={props.state}
                onClose={onFormClose}
                isSaving={saving}
                size="3xl"
                onSave={async (e: React.MouseEvent<HTMLButtonElement> | undefined) => await onFormSave(e)}>

                <Tabs.Group aria-label="Abas" style="underline">
                    <Tabs.Item active title="Dados do Usuário" key={"dados"}>
                        <div className="grid grid-cols-12 mb-2">
                            <div className="col-span-12">
                                <h2 className="ml-auto text-xl font-extrabold text-black/70" hidden={(model.id ?? 0) === 0}>
                                    {`ID: #${(model.id ?? 0).toString().padStart(3, "0")}`}
                                </h2>
                            </div>

                            <div className="form-control mt-5 col-span-12 md:col-span-6">
                                <div className="mb-1"><Label htmlFor="inputNome" value="Nome:" /></div>
                                <TextInput id="inputNome"
                                    type="text"
                                    readOnly={isReadOnly}
                                    value={model.nome}
                                    onChange={(e) => setModel({ ...model, nome: e.currentTarget.value })}
                                    onBlur={generateUserName}
                                    placeholder="Nome do usuário"
                                    maxLength={60} />
                            </div>

                            <div className="form-control mt-5 col-span-12 md:col-span-6 md:ml-6">
                                <div className="mb-1"><Label htmlFor="inputSobrenome" value="Sobrenome:" /></div>
                                <TextInput id="inputSobrenome"
                                    type="text"
                                    readOnly={isReadOnly}
                                    value={model.sobrenome ?? ''}
                                    onChange={(e) => {
                                        setModel({ ...model, sobrenome: e.currentTarget.value });
                                    }}
                                    onBlur={generateUserName}
                                    placeholder="Sobrenome do usuário"
                                    maxLength={60} />
                            </div>

                            <div className="form-control mt-5 col-span-12">
                                <div className="mb-1"><Label htmlFor="inputLogin" value="Nome de Usuário:" /></div>
                                <TextInput id="inputLogin"
                                    type="text"
                                    readOnly={isReadOnly}
                                    value={model.nomeUsuario}
                                    onChange={(e) => setModel({ ...model, nomeUsuario: e.currentTarget.value })}
                                    placeholder="Nome de usuário (login)"
                                    maxLength={40} />
                            </div>

                            <div className="form-control mt-5 col-span-12">
                                <div className="mb-1"><Label htmlFor="inputEmail" value="E-mail:" /></div>
                                <TextInput id="inputEmail"
                                    type="email"
                                    readOnly={isReadOnly}
                                    addon="@"
                                    value={model?.email}
                                    onChange={(e) => setModel({ ...model, email: e.currentTarget.value })}
                                    placeholder="email@email.com"
                                    maxLength={200} />
                            </div>

                            {(sessionUser?.tipoUsuario === UserType.gestor) && 
                            <div className="form-control mt-5 col-span-12">
                                <CodePesquisa
                                    id="cpsCliente"
                                    valueField={{ label: "Código:", property: "id", value: model.clienteId ?? "" }}
                                    textField={{ label: "Unidade:", property: "razaoSocial", value: model.cliente?.razaoSocial }}
                                    modal={{
                                        title: "Pesquisa de Unidades",
                                        dataColumns: clienteColumns,
                                        sort: { columnProperty: "razaoSocial", direction: "asc", columnIndex: 2 }
                                    }}
                                    readOnly={isReadOnly}
                                    endpoint="cliente"                                    
                                    onSearch={handleSearchCliente}
                                />
                            </div>}

                            <div className="form-control mt-8 col-span-12 flex space-x-2 items-center">
                                <Checkbox id="checkBoxBloqueado"
                                    disabled={isReadOnly}
                                    defaultChecked={model.isBloqueado}
                                    onClick={(e) => setModel({ ...model, isBloqueado: e.currentTarget.checked })}
                                    placeholder="Checked">
                                </Checkbox>

                                <Label htmlFor="checkBoxBloqueado" value="Usuário bloqueado?" />
                            </div>

                            {(!sessionUser?.credenciadaId && !model.credenciadaId) &&
                                <>
                                    <div className="form-control mt-3 col-span-12 flex space-x-2 items-center">
                                        <Checkbox id="checkBoxAprovar"
                                            disabled={isReadOnly}
                                            defaultChecked={model.permitirAprovarCotacao}
                                            onClick={(e) => setModel({ ...model, permitirAprovarCotacao: e.currentTarget.checked })}
                                            placeholder="Checked">
                                        </Checkbox>

                                        <Label htmlFor="checkBoxAprovar" value="Permitir que o usuário aprove cotações" />
                                    </div>

                                    <div className="form-control mt-3 col-span-12 flex space-x-2 items-center">
                                        <Checkbox id="checkBoxReprovar"
                                            disabled={isReadOnly}
                                            defaultChecked={model.permitirReprovarCotacao}
                                            onClick={(e) => setModel({ ...model, permitirReprovarCotacao: e.currentTarget.checked })}
                                            placeholder="Checked">
                                        </Checkbox>

                                        <Label htmlFor="checkBoxReprovar" value="Permitir que o usuário reprove cotações" />
                                    </div>
                                </>}
                        </div>
                    </Tabs.Item>

                    <Tabs.Item title="Permissões" key={"permissoes"}>
                        <div className="-m-5">
                            <CondensedCodeDataGrid
                                columns={[
                                    {
                                        title: "Menu",
                                        property: "menuLabel",
                                        onFormatValue: (value: UsuarioPermissaoModel) =>
                                            <span className="flex gap-x-1.5 items-center">{value.submenu ? <BsArrowReturnRight /> : <></>} {value.menuLabel}</span>
                                    },
                                    {
                                        title: "permitir",
                                        alignment: "center",
                                        property: "permitir",
                                        onFormatValue: (value: UsuarioPermissaoModel) =>
                                            <Checkbox
                                                defaultChecked={value.permitir}
                                                hidden={value.possuiSubmenu}
                                                disabled={isReadOnly}
                                                onChange={(e) => handleChangePermissao(e, value, "permitir")} />
                                    },
                                    {
                                        title: "inserir",
                                        alignment: "center",
                                        property: "inserir",
                                        onFormatValue: (value: UsuarioPermissaoModel) =>
                                            <>
                                                {!value.possuiSubmenu && <Checkbox
                                                    disabled={!value.permitir || isReadOnly}
                                                    defaultChecked={value.inserir}
                                                    onChange={(e) => handleChangePermissao(e, value, "inserir")} />}
                                            </>
                                    },
                                    {
                                        title: "editar",
                                        alignment: "center",
                                        property: "editar",
                                        onFormatValue: (value: UsuarioPermissaoModel) =>
                                            <>
                                                {!value.possuiSubmenu && <Checkbox
                                                    disabled={!value.permitir || isReadOnly}
                                                    defaultChecked={value.editar}
                                                    onChange={(e) => handleChangePermissao(e, value, "editar")} />}
                                            </>
                                    },
                                    {
                                        title: "consultar",
                                        alignment: "center",
                                        property: "consultar",
                                        onFormatValue: (value: UsuarioPermissaoModel) =>
                                            <>
                                                {!value.possuiSubmenu && <Checkbox
                                                    disabled={!value.permitir || isReadOnly}
                                                    defaultChecked={value.consultar}
                                                    onChange={(e) => handleChangePermissao(e, value, "consultar")} />}
                                            </>
                                    },
                                    {
                                        title: "excluir",
                                        alignment: "center",
                                        property: "excluir",
                                        onFormatValue: (value: UsuarioPermissaoModel) =>
                                            <>
                                                {!value.possuiSubmenu && <Checkbox
                                                    disabled={!value.permitir}
                                                    defaultChecked={value.excluir}
                                                    onChange={(e) => handleChangePermissao(e, value, "excluir")} />}
                                            </>
                                    },
                                ]}
                                readonly
                                striped
                                overflow="overflow-x-clip"
                                datasource={model.permissoes}
                                height={'50vh'}
                            />
                        </div>
                    </Tabs.Item>
                </Tabs.Group>
            </ModalForm>
        </div>
    );
};