import { ClienteVeiculoCommand } from '../commands/ClienteVeiculoCommand';
import { ClienteVeiculoModel } from '../models/cliente_veiculo/ClienteVeiculoModel';
import { BaseService } from "./BaseService";
import { DefaultResponse, ServiceProps } from "./types";

const ENDPOINT = `${BaseService.BASE_URL}/cliente/veiculo`;

export class ClienteVeiculoService extends BaseService {
    public static async get(clienteId: number, props: ServiceProps = { limit: 100, page: 1 }): Promise<DefaultResponse<ClienteVeiculoModel>> {
        return await this.GET<ClienteVeiculoModel>(`${ENDPOINT}?clienteId=${clienteId}&limit=${props.limit}&page=${props.page}`);
    }

    public static async getId(id: number): Promise<DefaultResponse<ClienteVeiculoModel>> {
        return await this.GET<ClienteVeiculoModel>(`${ENDPOINT}/${id}`);
    }

    public static async add(model: ClienteVeiculoCommand): Promise<DefaultResponse<ClienteVeiculoModel>> {
        return await this.POST<ClienteVeiculoCommand>(`${ENDPOINT}`, model);
    }

    public static async update(model: ClienteVeiculoCommand): Promise<DefaultResponse<ClienteVeiculoModel>> {
        return await this.PATCH<ClienteVeiculoCommand>(`${ENDPOINT}/${model.id}`, model);
    }

    public static async delete(id: number): Promise<DefaultResponse<ClienteVeiculoModel>> {
        return await this.DELETE(`${ENDPOINT}/${id}`);
    }
}