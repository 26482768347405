import { Badge } from "flowbite-react";
import { HiCheck } from "react-icons/hi";
import { IoMdClose } from "react-icons/io";
import { ICodeDataGridColumn } from "../../components/CodeDataGrid/types";
import { ClienteModel } from "./ClienteModel";
import CodeUtil from "../../utils/CodeUtil";

const useClienteColumns = (): ICodeDataGridColumn[] => {
    return [
        { property: "id", title: "Código", alignment: "center", onFormatValue(value: ClienteModel) { return (<span>{(value.id as Number).toString().padStart(3, "0")}</span>) } },
        {
            property: "bloqueada", title: "Ativo", alignment: "center", onFormatValue(value: ClienteModel) {
                return (
                    <div className="flex justify-center flex-wrap items-center gap-2">
                        {(value.bloqueado as boolean) ? (
                            <Badge size="sm" color="failure" icon={IoMdClose} />
                        ) : (
                            <Badge icon={HiCheck} size="sm" color="success" />
                        )}
                    </div>
                );
            },
        },
        { property: "razaoSocial", title: "Razão Social" },
        { property: "fantasia", title: "Fantasia" },
        { property: "cnpj", title: "CNPJ", onFormatValue(value: ClienteModel) { return (<span>{CodeUtil.mask(value.cnpj, "##.###.###/####-##")}</span>) } },
        { property: "municipio.descricao", title: "Município" },
        { property: "uf.sigla", title: "UF" },
    ]
}

export { useClienteColumns };