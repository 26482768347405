import { ModalFormTabProps } from "../../../components/ModalForm/types";
import { CotacaoModel } from "../../../models/cotacao/CotacaoModel";
import { useState } from "react";
import { ICodeDataGridRow } from "../../../components/CodeDataGrid/types";
import { FormStateType, ModelState } from "../../../enums";
import { CredenciadaModel } from "../../../models/credenciada";
import { getCotacaoCredenciadaDefault } from "../../../models/cotacao/CotacaoCredenciadaModel";


export const useModalTabCredenciadas = (props: ModalFormTabProps<CotacaoModel>) => {

    const [model, setModel] = props.model;
    const [showPopupCredenciada, setShowPopupCredenciada] = useState<boolean>(false);
    const readonly = props.state === FormStateType.view;

    const handleSelecionarCredenciadas = (items: ICodeDataGridRow[]) => {
        if (items.length === 0) return;

        let credenciadas = model.credenciadas ?? [];
        items.forEach(item => {
            let credenciada = item.item as CredenciadaModel;

            if (!credenciadas.find(_ => _.credenciadaId === credenciada.id && _.state !== ModelState.deleted)) {
                credenciadas.push({
                    ...getCotacaoCredenciadaDefault(),
                    cotacaoId: model.id,
                    credenciadaId: credenciada.id!,
                    razaoSocial: credenciada.razaoSocial,
                    fantasia: credenciada.fantasia,
                    state: ModelState.added
                });
            }
        });

        setModel({ ...model, credenciadas: [...credenciadas] });
        setShowPopupCredenciada(false);
    };

    const handleMarcarCredenciada = (checked: boolean, idx: number) => {
        let _items = model.credenciadas;
        _items[idx].isSelected = checked;

        setModel({
            ...model,
            credenciadas: _items
        });
    }

    const handleCancelarCotacao = (credenciadaId: number) => {
        let _items = model.credenciadas;
        let idx = _items.findIndex(_ => _.credenciadaId === credenciadaId && _.state !== ModelState.deleted);

        if (idx < 0) return;

        if (_items[idx].cotacaoId === undefined || _items[idx].cotacaoId === null || _items[idx].cotacaoId === 0)
            _items.splice(idx, 1);
        else
            _items[idx].state = ModelState.deleted;

        setModel({
            ...model,
            credenciadas: _items
        });
    }

    return {
        readonly,
        showPopupCredenciada, setShowPopupCredenciada,
        handleSelecionarCredenciadas,
        handleMarcarCredenciada, handleCancelarCotacao,
        model
    };
}