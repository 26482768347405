import { ClienteModel } from '../models/cliente/ClienteModel';
import { BaseService } from "./BaseService";
import { DefaultResponse, ServiceProps } from "./types";

const ENDPOINT = `${BaseService.BASE_URL}/cliente`;

export class ClienteService extends BaseService {
    public static async get(props: ServiceProps = { limit: 100, page: 1 }): Promise<DefaultResponse<ClienteModel>> {
        return await this.GET<ClienteModel>(`${ENDPOINT}?limit=${props.limit}&page=${props.page}`);
    }

    public static async getId(id: number): Promise<DefaultResponse<ClienteModel>> {
        return await this.GET<ClienteModel>(`${ENDPOINT}/${id}`);
    }

    public static async add(model: ClienteModel): Promise<DefaultResponse<ClienteModel>> {
        return await this.POST<ClienteModel>(`${ENDPOINT}`, model);
    }

    public static async update(model: ClienteModel): Promise<DefaultResponse<ClienteModel>> {
        return await this.PATCH<ClienteModel>(`${ENDPOINT}/${model.id}`, model);
    }

    public static async delete(id: number): Promise<DefaultResponse<ClienteModel>> {
        return await this.DELETE(`${ENDPOINT}/${id}`);
    }
}