import { FC } from "react"
import { CodeDataGridCheckboxColumn } from "./CodeDataGridCheckboxColumn"

export interface CodeDataGridColumnHeaderProps {
    className?: string
    multiselect?: boolean
    selectedAll?: boolean
    readOnly?: boolean
    children?: JSX.Element | JSX.Element[]
    onSelectAll?: (selected: boolean) => void
}

export const CodeDataGridColumnHeader: FC<CodeDataGridColumnHeaderProps> = (props) => {

    const renderCheckboxColumn = () => {
        return !!props.multiselect ?
            <CodeDataGridCheckboxColumn checked={props.selectedAll ?? false} onCheck={props.onSelectAll} /> :
            <th className="px-6" />;
    };

    return (
        <thead className={`${props.className ?? ''} text-xs text-gray-700 uppercase bg-gray-50 border-b-2`}>
            <tr>
                {props.readOnly ? <></> : renderCheckboxColumn()}
                {props.children}
            </tr>
        </thead>
    )
}