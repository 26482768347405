import { ICodeDataGridColumn } from "../components/CodeDataGrid/types";
import { ProdutoModel } from "../models/produto";
import CodeUtil from "../utils/CodeUtil";

export const useProdutoColumns = () => {
    const columns: ICodeDataGridColumn[] = [
        { property: "id", title: "Código", alignment: "right", onFormatValue: (value: ProdutoModel) => value.id?.toString().padStart(4, "0") },
        { property: "codigoFornecedor", title: "CÓDIGO FORNECEDOR" },
        { property: "nome", title: "Nome" },
        { property: "fabricante.marca", title: "fabricante", onFormatValue: (value: ProdutoModel) => value.fabricante?.marca },
        { property: "preco", title: "preço", alignment: "right", onFormatValue: (value: ProdutoModel) => CodeUtil.moneyFormat(value.preco) },
    ];
    
    return {
        columns
    }
}