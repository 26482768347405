import { CotacaoStatusType, CotacaoTipoItemType, ModelState } from "../../enums"
import { BaseModel } from "../base"
import { ProdutoModel } from "../produto"
import { ServicoModel } from "../servico"
import { getCotacaoStatus } from "./CotacaoModel"

export interface ViewCotacaoCredenciadaItemModel extends BaseModel {
    cotacaoId: number
    credenciadaId: number
    codigoFornecedor: string
    status: CotacaoStatusType
    statusDescricao: string
    servico: ServicoModel | null
    descricao: string
    numeroSerie: string
    quantidade: number
    percentualComissao: number
    desconto: number
    descontoPercentual: number
    frete: number
    seguro: number
    outrasDespesas: number
    valorUnitario: number
    valorTotal: number
    valorComissao: number
    observacoes: string | null
    observacoesCliente: string | null
    diasGarantia: number 
    produtoId?: number
    precoFabricante?: number
    produto?: ProdutoModel | null
    tipo: CotacaoTipoItemType
    state: ModelState,
}

export const getDefaultViewCotacaoCredenciadaItem = (): ViewCotacaoCredenciadaItemModel => {
    return {
        cotacaoId: 0,
        codigoFornecedor: "",
        descricao: "",
        numeroSerie: "",
        quantidade: 1,
        status: CotacaoStatusType.AGUARDANDO,
        statusDescricao: getCotacaoStatus(CotacaoStatusType.AGUARDANDO),
        observacoes: null,
        observacoesCliente: null,
        percentualComissao: 0,
        tipo: CotacaoTipoItemType.ITEM,
        state: ModelState.unchanged,
        servico: null,
        credenciadaId: 0,
        desconto: 0,
        descontoPercentual: 0,
        frete: 0,
        outrasDespesas: 0,
        seguro: 0,
        valorUnitario: 0,
        valorComissao: 0,
        valorTotal: 0,
        diasGarantia: 0,
        isSelected: true
    }
}