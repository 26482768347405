import { FC, useState, MouseEvent } from "react";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";

type CodeDataGridTextColumnProps = {
    property?: string
    title: string
    sorted?: boolean
    sortDirection?: 'asc' | 'desc'
    alignment?: 'left' | 'center' | 'right' | 'justify'
    className?: string
    onSort?: (direction: 'asc' | 'desc') => void
}

export const CodeDataGridTextColumn: FC<CodeDataGridTextColumnProps> = (props) => {
    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>(props.sortDirection ?? 'asc');
    const columnAlingment = props.alignment === 'left' ? 'text-left' :
        props.alignment === 'right' ? 'text-right' :
            props.alignment === 'center' ? 'text-center' :
                props.alignment === 'justify' ? 'text-justify' : '';

    const renderSortedIndicator = (): JSX.Element | undefined => {
        if (!props.sorted) return <></>;

        return sortDirection === 'asc' ? <FaArrowUp className="inline ml-2" /> : <FaArrowDown className="inline ml-2" />;
    }

    const handleClick = (e: MouseEvent<HTMLTableCellElement>) => {
        setSortDirection(sortDirection === "asc" ? "desc" : "asc");
        props.onSort?.call(this, sortDirection);
    }

    return (
        <th scope="col" className={`${props.className} ${columnAlingment} cursor-pointer px-6 py-2 max-w-full flex-nowrap whitespace-nowrap`} onClick={handleClick}>
            {props.title}
            {renderSortedIndicator()}
        </th>
    )
}