import axios, { AxiosError } from "axios";
import { UsuarioModel } from "../models/usuario";
import { SessionManager } from "../utils/SessionManager";
import { AuthRequest } from "./requests/index.";
import { DefaultResponse, AsDefaultResponse } from "./types";
import { BaseService } from "./BaseService";

const ENDPOINT = `${BaseService.BASE_URL}/oauth`;

export default class AuthService {
    public static async auth(vm: AuthRequest): Promise<DefaultResponse<UsuarioModel>> {
        try {
            var response = (await axios.post(ENDPOINT, vm)).data as DefaultResponse<UsuarioModel>;
            if (response.success) {
                axios.defaults.headers.Authorization = `Bearer ${response.data![0].token}`;
                SessionManager.setSession({
                    user: response.data![0] as UsuarioModel,
                    signed: true,
                    redirectTo: '/home',
                    rememberMe: vm.lembrarMe
                })
            }

            return response;

        } catch (error: any) {
            let axiosError = (error as AxiosError);
            if (axiosError.response?.data === null || axiosError.response?.data === undefined) {
                return AsDefaultResponse((error as AxiosError).message, []);
            }

            return axiosError.response!.data as DefaultResponse<UsuarioModel>;
        }
    }

    public static async checkPassword(password: string): Promise<boolean> {
        try {
            var response = (await axios.post(ENDPOINT, {
                login: SessionManager.getSession().user?.email,
                senha: password,
                tipoAutenticacao: 0
            })).data as DefaultResponse<UsuarioModel>;

            return response.success;

        } catch (error: any) {
            return false;
        }
    }

    public static logout(): void {

        let currentSession = SessionManager.getSession()

        SessionManager.setSession({
            signed: false,
            redirectTo: '/auth/login',
            rememberMe: currentSession.rememberMe,
            user: !currentSession.rememberMe ? null :
                (Boolean(currentSession.user) ? currentSession.user!.token = undefined : undefined)
        })
    }

    public static async forgotPassword(email: string): Promise<boolean> {
        try {
            var response = (await axios.post(`${ENDPOINT}/forgot-password`, { email })).data;
            return response.success;
        } catch (error: any) {
            return false;
        }
    }

    public static async resetPassword(novaSenha: string, token: string): Promise<DefaultResponse<unknown>> {
        try {
            var response = (await axios.post(`${ENDPOINT}/reset-password`, {
                novaSenha,
                token
            })).data;
            
            return response;
        } catch (error: any) {
            return { ...AsDefaultResponse("Token inválido ou expirado.", []), success: false };
        }
    }
}