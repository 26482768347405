import { FC } from "react";
import { useGestorContasPage } from "./GestorContasPage.hook";
import CodeSpinner from "../../components/CodeSpinner";
import { GestorContasCadastroPage } from "./GestorContasCadastroPage";
import { CodeDataGrid } from "../../components/CodeDataGrid";
import { Popup } from "../../components/Popup";

export const GestorContasPage: FC = () => {
    const {
        loading,
        dataSource, formButtons, columns,
        modalState,
        pagination,
        showPopupDelete,
        handleOnKeyDown, handleRowSelect,
        handleFormCadastroClose, handleFormCadastroSave,
        handleOnConfirmDelete, handleOnCancelDelete
    } = useGestorContasPage();

    return (
        <div onKeyDown={handleOnKeyDown}>
            <CodeSpinner hidden={!loading} fullPage={true} />

            <CodeDataGrid
                sort={{ columnIndex: 2, direction: "asc", columnProperty: "razaoSocial" }}
                datasource={dataSource}
                striped={true}
                header={{
                    title: "Gestores de Conta",
                    subtitle: "Listagem de gestores de contas",
                    filter: true
                }}
                actions={formButtons}
                columns={columns}
                onRowSelect={handleRowSelect}
                pagination={pagination}
            />

            {modalState.show && <GestorContasCadastroPage
                show
                id={modalState.id}
                state={modalState.state}
                onClose={handleFormCadastroClose}
                onSave={handleFormCadastroSave}
            />}

            {showPopupDelete && <Popup
                isShowing
                message="Confirma a exclusão do registro selecionado?"
                isDestructive={true}
                onConfirm={handleOnConfirmDelete}
                onCancel={handleOnCancelDelete}
            />}
        </div>
    );
}