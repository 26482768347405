import { Alert } from "flowbite-react";
import React from "react";
import styled from "styled-components";
import { ErrorBadgeProps } from "./types";

const ErrorBadge: React.FC<ErrorBadgeProps> = (props) => {
  return (
    <div>
      {(props.errors?.length ?? 0) === 0 ? (
        <></>
      ) : (
        <Alert color="failure">
          <StyledErrorBadge>
            {props.errors?.map((error, idx) => <li key={idx}>{error?.trim()}</li>)}
          </StyledErrorBadge>
        </Alert>
      )}
    </div>
  );
};

const StyledErrorBadge = styled.ul`
    list-style-type: disc;
    padding: 0px 20px;
`

export default ErrorBadge;
