import styled from "styled-components";
import { FlatButtonProps } from "./types";

const FlatButton: React.FC<FlatButtonProps> = (props) => {
    if (props.isDestructive ?? false) {
        return (
            <StyledDangerFlatButton onClick={props.disabled ? undefined : props.onClick} type="button" disabled={props.disabled} {...props}>
                <span>{props.label}</span> {props.children}
            </StyledDangerFlatButton>
        )
    }

    if (props.buttonstyle === 'colored') {
        return (
            <StyledColoredFlatButton onClick={props.disabled ? undefined : props.onClick} type="button" disabled={props.disabled} {...props}>
                <span>{props.label}</span> {props.children}
            </StyledColoredFlatButton>
        );
    }

    return (
        <StyledFlatButton onClick={props.disabled ? undefined : props.onClick} type="button" disabled={props.disabled} {...props}>
            <span>{props.label}</span> {props.children}
        </StyledFlatButton>
    )
}

const StyledFlatButton = styled.button`
    border-radius: 0.5rem;
    border: none;
    outline: none;    
    padding-top: 0.375rem;
    padding-bottom: 0.5rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    align-items: center;
    text-transform: none;

    > span {
        font-weight: 500;
        text-align: center;
    }

    :hover {
        background-color: rgb(226, 232, 240);
    }

    :active, :focus {
        background-color: rgb(203, 213, 225);
    }

    :disabled {
        background-color: transparent;
        opacity: 0.5;
        cursor: no-drop
    }

`

const StyledDangerFlatButton = styled(StyledFlatButton)`

    :hover {
        background-color: rgb(251, 213, 213);
    }

    :active, :focus {
        background-color: rgb(240, 82, 82);
        color: white;
    }

    :disabled {
        background-color: transparent;
        color: inherit;
        opacity: 0.5;
        cursor: no-drop
    }
`

const StyledColoredFlatButton = styled(StyledFlatButton)`

    background-color: rgb(31, 41, 55);
    color: white;

    :hover, :active, :focus {
        background-color: rgb(17, 24, 39);
    }

    :disabled {
        background-color: rgba(17, 24, 39, 0.5);
        color: inherit;
        opacity: 0.5;
        cursor: no-drop
    }
`

export default FlatButton;