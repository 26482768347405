import { CotacaoStatusType, CotacaoTipoItemType, ModelState } from "../enums"
import { CotacaoCredenciadaModel } from "../models/cotacao/CotacaoCredenciadaModel"
import { CotacaoItemModel } from "../models/cotacao/CotacaoItemModel"
import { CotacaoModel } from "../models/cotacao/CotacaoModel"
import { EmpenhoModel } from "../models/empenho"
import { ProdutoModel } from "../models/produto"

export interface CotacaoCommand {
    id: number | null,
    dataCadastro: string,
    dataAlteracao: string,
    dataValidade: string,
    dataCancelamento: string | null,
    status: CotacaoStatusType,
    clienteId: number | null,
    veiculoId: number | null,
    observacoes: string | null,
    empenho?: EmpenhoModel | null,
    empenhoId?: number | null,
    itens: CotacaoItemCommand[],
    credenciadas: CotacaoCredenciadaCommand[]
}

export interface CotacaoItemCommand {
    id: number | null,
    cotacaoId: number | null,
    codigoFornecedor: string | null,
    descricao: string,
    numeroSerie: string | null,
    quantidade: number,
    tipo: CotacaoTipoItemType,
    observacoes: string | null,
    state: ModelState,
    servicoId: number | null,
    produto?: ProdutoModel | null,
    produtoId: number | null,
    precoFabricante?: number | null,
    percentualComissao?: number | null,
}

export interface CotacaoCredenciadaCommand {
    credenciadaId: number
    dataCadastro: string | null,
    dataAlteracao: string | null,
    status: CotacaoStatusType
    desconto: number
    descontoPercentual: number
    frete: number
    seguro: number
    outrasDespesas: number
    valorTotal: number
    valorComissao: number
    observacoes: string | null
    state: ModelState
}

export interface CotacaoExecutarCommand {
    cotacaoId: number 
    credenciadaId: number 
    quilometragem: number
    marcaBateria?: string | null
    observacoes?: string | null
    codigoAutorizacao: string
    entradaSaida: "Entrada"    
}


export interface CotacaoEncerrarCommand {
    cotacaoId: number 
    credenciadaId: number 
    quilometragem: number
    marcaBateria?: string | null
    observacoes?: string | null
    codigoAutorizacao: string
    entradaSaida: "Saída"    
}

export const Map = (model?: CotacaoModel | null): CotacaoCommand | null => {
    if (model === null || model === undefined)
        return null;

    return {
        id: model.id === undefined ? null : model.id,
        dataCadastro: model.dataCadastro ?? new Date(Date.now()),
        dataAlteracao: model.dataAlteracao ?? new Date(Date.now()),
        dataValidade: model.dataValidade ?? new Date(Date.now()),
        dataCancelamento: model.dataCancelamento,
        status: model.status,
        clienteId: model.cliente?.id ?? null,
        veiculoId: model.veiculo?.id ?? null,
        observacoes: model.observacoes,
        empenho: model.empenho,
        empenhoId: model.empenhoId ?? null,
        itens: MapItens(model.itens),
        credenciadas: MapCredenciadas(model.credenciadas)
    }
}

export const MapItens = (items: CotacaoItemModel[]): CotacaoItemCommand[] => {
    let mappedItens: CotacaoItemCommand[] = [];
    items.map((model) => {
        return mappedItens.push({
            id: model.id ?? null,
            codigoFornecedor: model.codigoFornecedor,
            cotacaoId: model.cotacaoId,
            descricao: model.descricao,
            numeroSerie: model.numeroSerie,
            observacoes: model.observacoes,
            quantidade: model.quantidade,
            servicoId: model.servico?.id ?? null,
            state: model.state,
            tipo: model.tipo,
            produto: model.produto,
            produtoId: model.produtoId ?? null,
            precoFabricante: model.precoFabricante,
            percentualComissao: model.percentualComissao,
        });
    });

    return mappedItens;
}

export const MapCredenciadas = (items: CotacaoCredenciadaModel[]): CotacaoCredenciadaCommand[] => {
    let mappedItens: CotacaoCredenciadaCommand[] = [];
    items.map((model) => mappedItens.push({
        ...model,
        dataCadastro: null,
        dataAlteracao: null,
    }));
    return mappedItens;
}