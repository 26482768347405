import { FC, useCallback, useEffect, useState } from "react";
import { ClienteTabCondutoresModalProps } from "./types";
import { ClienteCondutorModel } from "../../../../../models/cliente_condutor/ClienteCondutorModel";
import { findValidationField, FluentValidator } from "../../../../../types";
import { FormStateType } from "../../../../../enums";
import CodeUtil from "../../../../../utils/CodeUtil";
import { ShowToast } from "../../../../../components/CodeToast";
import { AxiosError } from "axios";
import ModalForm from "../../../../../components/ModalForm";
import { CodeLabel } from "../../../../../components/CodeLabel";
import { TextInput } from "flowbite-react";
import { MaskedInput } from "../../../../../components/MaskedInput";

export const ClientesTabCondutoresModal : FC<ClienteTabCondutoresModalProps<ClienteCondutorModel>> = (props) => {
    const [errors, setErrors] = useState<FluentValidator[]>([]);
    const [saving, setSaving] = useState<boolean>(false);
    const [formLoaded, setFormLoaded] = useState<boolean>(false);
    const [model, setModel] = props.model;
    const isReadOnly = props.state === FormStateType.view;

    const onFormClose = () => {
        setErrors([]);
        setFormLoaded(false);
        props.onClose?.call(this);
    };

    const onFormLoad = useCallback(async () => {
        if (!props.show || formLoaded) return;

        setFormLoaded(true);
    }, [props.show, formLoaded, setFormLoaded]);

    const validate = (): FluentValidator[] => {
        let _errors: FluentValidator[] = [];

        if (CodeUtil.isNullOrEmpty(model.nome)) _errors.push({ field: "nome", message: "O nome deve ser preenchido", isValid: false });
        if (CodeUtil.isNullOrEmpty(model.sobrenome)) _errors.push({ field: "sobrenome", message: "O sobrenome deve ser preenchido", isValid: false });
        if (CodeUtil.isNullOrEmpty(model.cpf)) _errors.push({ field: "cpf", message: "O CPF deve ser preenchido", isValid: false });
        if (CodeUtil.isNullOrEmpty(model.email) || !CodeUtil.isValidEmail(model.email)) _errors.push({ field: "email", message: "O e-mail informado é inválido", isValid: false });

        setErrors(_errors);
        return _errors;
    }

    const onFormSave = async (e: React.MouseEvent<HTMLButtonElement> | undefined) => {
        let validationResult = validate();

        if (validationResult.length > 0) return;

        try {
            setSaving(true);
            await setModel({ ...model, state: props.state === FormStateType.add ? 'ADDED' : 'UPDATED' });

            props.onSave?.call(this, e);
            onFormClose();
        } catch (error) {
            console.log(error);
            ShowToast({ message: (error as AxiosError).response?.data as string });
        } finally {
            setSaving(false);
        }
    };

    useEffect(() => {
        onFormLoad();
    });

    if (!props.show) return <></>;

    return (
        <ModalForm
            title={props.title ?? "Cadastro de Condutores"}
            show={props.show}
            state={props.state}
            isSaving={saving}
            size="3xl"
            onClose={onFormClose}
            onSave={onFormSave}
        >
            <div className="grid grid-cols-12 px-6 pb-3">
                <div className="form-control mt-5 col-span-12 md:col-span-6 md:mr-6">
                    <CodeLabel className="mb-1" htmlFor="inputNome" value="Nome:" />
                    <TextInput
                        id="inputNome"
                        type="text"
                        readOnly={isReadOnly}
                        value={model?.nome}
                        helperText={findValidationField(errors, "nome").message}
                        color={findValidationField(errors, "nome").isValid ? "gray" : "failure"}
                        onChange={(e) => setModel({ ...model, nome: e.currentTarget.value })}
                        maxLength={200}
                    />
                </div>

                <div className="form-control mt-5 col-span-12 md:col-span-6">
                    <CodeLabel className="mb-1" htmlFor="inputSobrenome" value="Sobrenome:" />
                    <TextInput
                        id="inputSobrenome"
                        type="text"
                        readOnly={isReadOnly}
                        value={model?.sobrenome}
                        helperText={findValidationField(errors, "sobrenome").message}
                        color={findValidationField(errors, "sobrenome").isValid ? "gray" : "failure"}
                        onChange={(e) => setModel({ ...model, sobrenome: e.currentTarget.value })}
                        maxLength={200}
                    />
                </div>

                <div className="form-control mt-5 col-span-12">
                    <CodeLabel className="mb-1" htmlFor="inputEmail" value="E-mail:" />
                    <TextInput
                        id="inputEmail"
                        type="email"
                        placeholder="seumelhoremail@email.com.br"
                        helperText={findValidationField(errors, "email").message}
                        color={findValidationField(errors, "email").isValid ? "gray" : "failure"}
                        readOnly={isReadOnly}
                        value={model?.email}
                        onChange={(e) => setModel({ ...model, email: e.currentTarget.value })}
                        maxLength={255}
                    />
                </div>

                <div className="form-control mt-5 col-span-12 md:col-span-6 md:mr-6">
                    <CodeLabel className="mb-1" htmlFor="inputCPF" value="CPF:" />
                    <MaskedInput
                        id="inputCPF"
                        type="text"
                        pattern="^[0-9]+$"
                        placeHolder="999.999.999-99"
                        mask="###.###.###-##"
                        readOnly={isReadOnly}
                        helperText={findValidationField(errors, "cpf").message}
                        color={findValidationField(errors, "cpf").isValid ? "gray" : "failure"}
                        value={model?.cpf}
                        onChange={(e) => setModel({ ...model, cpf: e })}
                    />
                </div>                

                <div className="form-control mt-5 col-span-12 md:col-span-6">
                    <CodeLabel className="mb-1" htmlFor="inputMatricula" value="Matrícula:" />
                    <TextInput
                        id="inputMatricula"
                        placeholder="Número da Matrícula"
                        readOnly={isReadOnly}
                        value={model?.matricula}
                        onChange={(e) => setModel({ ...model, matricula: e.currentTarget.value })}
                        maxLength={20}
                    />
                </div>

                <div className="form-control mt-5 col-span-12 md:col-span-6 md:mr-6">
                    <CodeLabel className="mb-1" htmlFor="inputHabilitacao" value="Nº Habilitação:" />
                    <TextInput
                        id="inputHabilitacao"
                        placeholder="999999999"
                        readOnly={isReadOnly}
                        value={model?.numeroHabilitacao}
                        onChange={(e) => setModel({ ...model, numeroHabilitacao: e.currentTarget.value })}
                        maxLength={9}
                    />
                </div>

                <div className="form-control mt-5 col-span-12 md:col-span-6">
                    <CodeLabel className="mb-1" htmlFor="inputCelular" value="Celular:" />
                    <MaskedInput
                        id="inputCelular"
                        type="text"
                        pattern="^[0-9]+$"
                        mask="(##) #####-####"
                        readOnly={isReadOnly}
                        value={model?.celular}
                        onChange={(e) => setModel({ ...model, celular: e })}
                    />
                </div>
            </div>
        </ModalForm>
    );
};