import { CotacaoTipoItemType, ModelState } from "../../enums";
import { CotacaoItemModel } from "./CotacaoItemModel";

export interface CotacaoCredenciadaItemModel extends CotacaoItemModel {
    credenciadaId: number,
    cotacaoItemId?: number | null,
    desconto: number
    descontoPercentual: number
    frete: number
    seguro: number
    outrasDespesas: number
    valorUnitario: number
    valorTotal: number
    valorComissao: number
}

export const getCotacaoCredenciadaItemDefault = (): CotacaoCredenciadaItemModel => {
    return {
        cotacaoId: 0,
        codigoFornecedor: "",
        descricao: "",
        numeroSerie: "",
        quantidade: 1,
        observacoes: null,
        isSelected: false,
        tipo: CotacaoTipoItemType.ITEM,
        state: ModelState.unchanged,
        servico: null,
        credenciadaId: 0,
        desconto: 0,
        descontoPercentual: 0,
        frete: 0,
        outrasDespesas: 0,
        seguro: 0,
        valorUnitario: 0,
        valorTotal: 0,
        valorComissao: 0,
        percentualComissao: 0
    };
}