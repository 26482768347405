import { UserType } from "../../enums";
import CodeUtil from "../../utils/CodeUtil";
import { UsuarioModel } from "./UsuarioModel";

export type {
    UsuarioModel,
}

export const defaultUsuario: UsuarioModel = {
    nome: '',
    email: '',
    nomeUsuario: '',
    tipoUsuario: UserType.customer,
    isBloqueado: false,
    isSelected: true,
}

export const userTypeToStr = (nivelPermissao: UserType) => {
    switch (nivelPermissao) {
        case UserType.customer:
            return "Cliente";
        case UserType.dealer:
            return "Credenciada";
        case UserType.gestor:
            return "Gestor de Conta";
        case UserType.admin:
            return "Administrador";
        default:
            return "Usuário do Sistema";
    }
}

export const validateUsuario = (model: UsuarioModel): string[] => {
    let result = [] as string[];

    if (CodeUtil.isNullOrEmpty(model.nome)) {
        result.push("O campo Nome deve ser preenchido.");
    }

    if (CodeUtil.isNullOrEmpty(model.nomeUsuario)) {
        result.push("O campo Nome de Usuário deve ser preenchido.");
    }

    if (CodeUtil.isNullOrEmpty(model.email)) {
        result.push("O campo E-mail deve ser preenchido.");
    }

    if (!CodeUtil.isNullOrEmpty(model.email) && !CodeUtil.isValidEmail(model.email)) {
        result.push("O campo E-mail não possui um e-mail válido.");
    }

    return result;
}