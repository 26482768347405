import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from "react";
import { CotacaoStatusType } from "../../enums";
import { format } from "fecha";
import { IFiltroCotacao } from "../../types";
import { FILTRO_ORDENS_SERVICO } from "../../utils/Constants";

export type SideBarFiltrosOrdensServicoProps = {
    hidden: boolean
    filter: [IFiltroCotacao, Dispatch<SetStateAction<IFiltroCotacao>>]
    onClose?: () => void
    onApplyFilter?: (value: IFiltroCotacao) => void
}

export const useSidebarFiltroOrdensServico = (props: SideBarFiltrosOrdensServicoProps) => {
    const STATUS_ITEMS = [CotacaoStatusType.APROVADA, CotacaoStatusType.EMEXECUCAO, CotacaoStatusType.CANCELADA, CotacaoStatusType.ENCERRADA];
    const inputDataInicial = useRef<HTMLInputElement>(null);
    const inputDataFinal = useRef<HTMLInputElement>(null);
    const [status, setStatus] = useState<CotacaoStatusType[]>([]);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [filter, setFilter] = props.filter;

    const loadDefaultValues = useCallback(() => {
        inputDataInicial.current!.value = filter.dataInicial ?? format(new Date(Date.now()), "YYYY-MM-DD");
        inputDataFinal.current!.value = filter.dataFinal ?? format(new Date(Date.now()), "YYYY-MM-DD");
        setStatus(filter?.status ?? []);
    }, [filter, setStatus]);

    const onFormLoaded = useCallback(() => {
        if (loaded) return;

        loadDefaultValues();
        setLoaded(true);
    }, [loaded, loadDefaultValues]);

    const handleSelectStatus = (model: unknown) => {
        const value = model as CotacaoStatusType;
        if (status?.includes(value))
            setStatus(status.filter((element) => element !== value!) ?? []);
        else
            setStatus(prev => [...prev ?? [], value]);
    };

    const handleClose = () => {
        loadDefaultValues();
        props.onClose?.call(this);
    }

    const handleApplyFilter = () => {
        setFilter({
            dataInicial: inputDataInicial.current?.value,
            dataFinal: inputDataFinal.current?.value,
            status: status
        });

        localStorage.setItem(FILTRO_ORDENS_SERVICO, JSON.stringify({
            ...filter,
            dataInicial: inputDataInicial.current?.value,
            dataFinal: inputDataFinal.current?.value,
            status: status
        }));

        props.onApplyFilter?.call(this, {
            ...filter,
            dataInicial: inputDataInicial.current?.value,
            dataFinal: inputDataFinal.current?.value,
            status: status
        });
    }

    useEffect(() => {
        onFormLoaded();
    }, [onFormLoaded]);

    return {
        STATUS_ITEMS, handleSelectStatus, handleApplyFilter, handleClose,
        inputDataInicial, inputDataFinal, status
    };
};