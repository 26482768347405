import { enqueueSnackbar } from "notistack";

type CodeToastProps = {
    message: string | string[] | undefined;
    timer?: number;
    error?: boolean;
};

export const ShowToast = (props: CodeToastProps) => {
    enqueueSnackbar({ message: props.message, autoHideDuration: 5000, className: `${props.error && 'bg-red-500'}` });    
}