import { BaseModel } from "../base";

export interface CredenciadaContatoModel extends BaseModel {
    credenciadaId: number,
    nome: string,
    email?: string,
    telefone?: string,
    telefone2?: string,
    celular?: string,
    celular2?: string,
    setor?: string,
    state: 'UNCHANGED' | 'ADDED' | 'UPDATED' | 'DELETED'
}

export const defaultCredenciadaContato: CredenciadaContatoModel = {
    credenciadaId: 0,
    nome: "",
    email: "",
    telefone: "",
    telefone2: "",
    celular: "",
    celular2: "",
    id: 0,
    setor: "",
    isSelected: true,
    state: 'UNCHANGED'
}