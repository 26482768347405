import { FC, useEffect } from "react";
import { EmpenhoCadastroPage } from "./EmpenhoCadastroPage";
import { Popup } from "../../components/Popup";
import { useEmpenhoColumns } from "../../hooks";
import { useEmpenhoPage } from "./EmpenhoPage.hook";
import CodeSpinner from "../../components/CodeSpinner";
import { CodeDataGrid } from "../../components/CodeDataGrid";
import { EmpenhoModel } from "../../models/empenho";
import { useBuscarPermissao } from "../../hooks/useBuscarPermissao";

export const EmpenhoPage: FC = () => {
    const { columns } = useEmpenhoColumns();
    const { onPageLoad, modalState, loading, dataSource,
        selected, setSelected, selectedModel, setSelectedModel,
        saving, setSaving,
        showPopupCancelar, setShowPopupCancelar, handleCancelarEmpenho,
        handleSave, handleClose, handleInserir, handleConsultar,
        pagination, setPage
    } = useEmpenhoPage();

    const { permissao } = useBuscarPermissao('clientes_empenhos');

    useEffect(() => { onPageLoad() }, [onPageLoad]);

    return (
        <>
            <CodeSpinner hidden={!loading} />

            {!loading && <div className="mt-5">
                <CodeDataGrid
                    sort={{ columnIndex: 1, direction: "desc", columnProperty: "numero" }}
                    datasource={dataSource}
                    striped={true}
                    header={{
                        title: "Empenhos",
                        subtitle: "Listagem de empenhos",
                        filter: true
                    }}
                    actions={[
                        { label: 'Novo Empenho', style: 'colored', disabled: !permissao?.inserir, onClick: handleInserir },
                        { label: 'Editar', style: 'flat', disabled: !selected?.selected || !permissao?.editar, onClick: () => handleConsultar() },
                        {
                            label: 'Cancelar', style: 'destructive', disabled: !selected?.selected || !permissao?.excluir || (selected.item as EmpenhoModel).situacao !== "Cancelado",
                            onClick: () => setShowPopupCancelar(true)
                        },
                        { label: 'Consultar', style: 'flat', disabled: !selected?.selected || !permissao?.consultar, onClick: () => handleConsultar(true) },
                    ]}
                    columns={columns}
                    onRowSelect={(item) => setSelected(item)}
                    pagination={{ ...pagination, onPageChanged: (page) => setPage(page) }}
                />
            </div>}

            {modalState.show && <EmpenhoCadastroPage
                show={modalState.show}
                title="Empenho"
                state={modalState.state}
                saving={[saving, setSaving]}
                model={[selectedModel, setSelectedModel]}
                onSave={handleSave}
                onClose={handleClose}
            />}

            {showPopupCancelar && <Popup
                isShowing={true}
                message="Confirma o cancelamento do empenho selecionado?"
                isDestructive={true}
                onConfirm={handleCancelarEmpenho}
                onCancel={() => setShowPopupCancelar(false)}
            />}
        </>
    )
}