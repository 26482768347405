import { format } from "fecha";
import { ICodeDataGridColumn } from "../../../components/CodeDataGrid/types";
import { ModalFormTabProps } from "../../../components/ModalForm/types"
import { ViewCotacaoCredenciadaModel } from "../../../models/cotacao/ViewCotacaoCredenciada"
import { EntradaSaidaVeiculoModel } from "../../../models/entrada_saida_veiculo";
import { useCallback, useEffect, useState } from "react";
import { ShowToast } from "../../../components/CodeToast";
import { UNKOWN_EXCEPTION } from "../../../utils/Constants";
import { EntradaSaidaVeiculoService } from "../../../services/EntradaSaidaVeiculoService";
import { Badge } from "../../../components/Badge";
import CodeUtil from "../../../utils/CodeUtil";

export const useTabRegistroEntradaSaida = (props: ModalFormTabProps<ViewCotacaoCredenciadaModel>) => {
    const [cotacaoModel, setCotacaoModel] = props.model;
    const [loaded, setLoaded] = useState<boolean>(false);
    const [dataSource, setDataSource] = useState<EntradaSaidaVeiculoModel[]>([]);

    const onFormatStatusColumn = (item: EntradaSaidaVeiculoModel): JSX.Element => {
        return <span><Badge color={item.entradaSaida === "Entrada" ? "indigo" : "yellow" } value={item.entradaSaida.toUpperCase()} /></span>;
    }

    const columns: ICodeDataGridColumn[] = [
        { title: 'Data', property: 'data', onFormatValue: (item: EntradaSaidaVeiculoModel) =>  <span>{`${format(new Date(item.data), 'DD/MM/YYYY HH:mm')}`}</span> },
        { title: 'E/S', property: 'entradaSaida', alignment: 'center', onFormatValue: onFormatStatusColumn },
        { title: 'Condutor', property: 'condutor.nome', onFormatValue: (item: EntradaSaidaVeiculoModel) =>  <span>{`${item.condutor?.nome} ${item.condutor?.sobrenome}`.trim()}</span> },
        { title: 'CPF', property: 'condutor.cpf', onFormatValue: (item: EntradaSaidaVeiculoModel) =>  <span>{`${CodeUtil.mask(item.condutor?.cpf ?? '', '###.###.###-##')}`}</span> },
        { title: 'Veículo', property: 'veiculo.modeloMarca' },
        { title: 'Placa', property: 'veiculo.placa', alignment: 'center' },
        { title: 'Km', property: 'quilometragem', alignment: 'center' },
        { title: 'Bateria', property: 'marcaBateria' },
        { title: 'Observações', property: 'observacoes' },
    ];

    const fetchData = useCallback(async () => {
        try {
            if (loaded) return;

            let response = await EntradaSaidaVeiculoService.get(cotacaoModel.id ?? 0, cotacaoModel.credenciadaId ?? 0);
            if (!response.success) {
                ShowToast({ message: response.messages });
                return;
            }

            setDataSource(response.data);
        } catch (error) {
            ShowToast({ message: UNKOWN_EXCEPTION });
            console.log(error);
        } finally {
            setLoaded(true);
        }
    }, [cotacaoModel.credenciadaId, cotacaoModel.id, loaded]);

    useEffect(() => {
        fetchData();
    }, [fetchData])

    return {
        cotacaoModel, setCotacaoModel, columns, dataSource
    }
}