import React, { useState } from "react";
import SidePanel from "../../../components/SidePanel";
import Logo from "../../../images/logo.png";
import { Select, Label, TextInput, Checkbox } from "flowbite-react";
import ErrorBadge from "../../../components/ErrorBadge";
import { InputLoginProps, LoginViewModel } from "./types";
import { LoginForm } from "./components/LoginForm";

export enum AuthType {
  EMAIL = 0,
  DOCUMENT = 1,
  USERNAME = 2
}

export const LoginPage: React.FC = () => {
  const [errors, setErrors] = useState<string[] | undefined | null>()
  const [formData, setFormData] = useState<LoginViewModel>({ login: '', password: '', authType: AuthType.EMAIL, rememberMe: false })
  const [inputLogin, setInputLogin] = useState<InputLoginProps>({ label: "E-mail:", placeHolder: "email@email.com.br", type: "email" })

  const handleTipoLoginChange: React.ChangeEventHandler<HTMLSelectElement> = (ev) => {
    ev.preventDefault()

    let _type = Number.parseInt(ev.currentTarget.value) as AuthType
    setFormData({ ...formData, authType: _type, login: '', password: '' })

    switch (_type) {
      case AuthType.EMAIL:
      default:
        setInputLogin(prevProps => ({ ...prevProps, label: "E-mail:", placeHolder: "email@email.com.br", type: "email" }))
        break
      case AuthType.DOCUMENT:
        setInputLogin(prevProps => ({ ...prevProps, label: "Documento (somente números):", placeHolder: " CPF, CNPJ ou Passaporte", type: "text" }))
        break
      case AuthType.USERNAME:
        setInputLogin(prevProps => ({ ...prevProps, label: "Nome de usuário:", placeHolder: "Login", type: "text" }))
        break
    }
  }

  const handleLoginTextChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    e.preventDefault()

    if (formData.authType === AuthType.DOCUMENT && !e.currentTarget.validity.valid)
      return

    setFormData({ ...formData, login: e.currentTarget.value })
  }

  return (
    <div className="login-page">
      <SidePanel>
        <LoginForm viewModel={formData} onValidate={(e) => setErrors(e)}>
          <div className="flex justify-center">
            <img src={Logo} className="h-24 md:h-20" alt="Rodar Logo" />
          </div>

          <div className={(errors?.length ?? 0) > 0 ? "my-5" : ""}>
            <ErrorBadge errors={errors} />
          </div>

          <div className="form-control">
            <div className="mb-1">
              <Label htmlFor="selectTipo" value="Tipo de autenticação:" />
            </div>

            <Select id="selectTipo" onChange={handleTipoLoginChange} defaultValue={formData?.authType}>
              <option value={0}>E-mail</option>
              <option value={1}>Documento (CPF, CNPJ ou Passaporte)</option>
              <option value={2}>Nome de usuário</option>
            </Select>
          </div>

          <div className="form-control mt-5">
            <div className="mb-1">
              <Label htmlFor="inputLogin" value={inputLogin.label} />
            </div>
            <TextInput id="inputLogin"
              type={inputLogin.type}
              pattern={formData.authType === AuthType.DOCUMENT ? "[0-9]*" : undefined}
              value={formData.login}
              onChange={handleLoginTextChange}
              placeholder={inputLogin.placeHolder}
              maxLength={formData.authType === AuthType.DOCUMENT ? 14 : 256} />
          </div>

          <div className="form-control mt-5">
            <div className="mb-1">
              <Label htmlFor="inputSenha" value="Senha:" />
            </div>

            <TextInput id="inputSenha" type="password" placeholder="Digite sua senha"
              value={formData.password} onChange={(e) => setFormData({ ...formData, password: e.currentTarget.value })} />
          </div>

          <div className="mt-6">
            <div className="flex justify-start gap-2">
              <Checkbox checked={formData.rememberMe} id="remember"
                onChange={(e) => setFormData({ ...formData, rememberMe: Boolean(e.currentTarget.checked) })} />
              <Label htmlFor="remember">Lembrar meu login</Label>
            </div>
          </div>
        </LoginForm>
      </SidePanel>
    </div>
  );
};
