import { Button, Spinner, TextInput } from "flowbite-react";
import { StyledReportPage } from "../_styles/StyledReportPage";
import { FC } from "react";
import { CondensedCodeDataGrid } from "../../components/CondensedCodeDataGrid";
import { CodeLabel } from "../../components/CodeLabel";
import { useVeloeAbastecimentoPage } from "./VeloeAbastecimentoPage.hook";
import { format } from "fecha";

export const VeloeAbastecimentoPage: FC = () => {
    const {
        loading, columns, dataSource, handlePesquisar, setFiltro, filtro
    } = useVeloeAbastecimentoPage();

    return (
        <StyledReportPage className="shadow rounded-md py-0.5">
            <div className="py-5">
                <h2 className="px-5 mb-4 text-lg font-semibold">Histórico de Abastecimento</h2>
                <hr />
            </div>

            <div className="flex gap-x-3 px-3 -mt-2 -mb-3 items-end">
                <div>
                    <CodeLabel className="mb-1" htmlFor="inputBases" value="Base:" />
                    <TextInput
                        id="inputBases"
                        type="text"
                        sizing={"sm"}
                        placeholder="005,006"
                        onChange={(e) => setFiltro({ ...filtro, bases: e.currentTarget.value.split(',') ?? [] })}
                    />
                </div>

                <div>
                    <CodeLabel className="mb-1" htmlFor="inputPlacas" value="Placa:" />
                    <TextInput
                        id="inputPlacas"
                        type="text"
                        sizing={"sm"}
                        required
                        placeholder="XXX9999,ZZZ9999"
                        onChange={(e) => setFiltro({ ...filtro, veihclePlates: e.currentTarget.value.split(',') ?? [] })}
                    />
                </div>

                <div>
                    <CodeLabel className="mb-1" htmlFor="dateInicio" value="Início:" />
                    <TextInput
                        id="dateInicio"
                        sizing={"sm"}
                        required
                        type={"date"}
                        pattern="\d{4}-\d{2}-\d{2}"
                        value={format(new Date(filtro?.startDate ?? new Date()), 'YYYY-MM-DD')}
                        onChange={(e) => setFiltro({ ...filtro, startDate: format(e.currentTarget.valueAsDate ?? new Date(), 'DD-MM-YYYY') })}
                    />
                </div>

                <div>
                    <CodeLabel className="mb-1" htmlFor="dateTermino" value="Término:" />
                    <TextInput
                        id="dateTermino"
                        sizing={"sm"}
                        required
                        type={"date"}
                        pattern="\d{4}-\d{2}-\d{2}"
                        value={format(new Date(filtro?.startDate ?? new Date()), 'YYYY-MM-DD')}
                        onChange={(e) => setFiltro({ ...filtro, endDate: format(e.currentTarget.valueAsDate ?? new Date(), 'DD-MM-YYYY') })}
                    />
                </div>

                <Button
                    size={'sm'}
                    disabled={loading}
                    onClick={handlePesquisar}
                    color={'light'}>

                    {loading && <div className={(loading ?? false) ? "mr-3 text-white" : "text-white"}>
                        <Spinner size="sm" light={true} />
                    </div>}

                    {!loading && <span><i className="text-sm" /> Pesquisar</span>}
                    {loading && <span>Pesquisando...</span>}
                </Button>
            </div>

            <CondensedCodeDataGrid
                columns={columns}
                datasource={dataSource}
                header={{ filter: false }}
                readonly={true}
                pagination={{
                    limit: 500,
                    page: 1,
                    pageCount: 1,
                    recordCount: 0
                }}
                hoverable={true}
                striped={true} />

        </StyledReportPage>
    );
}