import { FC } from "react";
import { ProdutoCadastroPageProps, useProdutoCadastroPage } from "./ProdutoCadastroPage.hook";
import ModalForm from "../../components/ModalForm";
import { CodeLabel } from "../../components/CodeLabel";
import { Select, TextInput } from "flowbite-react";
import { MoneyInput } from "../../components/MoneyInput";
import CodeUtil from "../../utils/CodeUtil";

export const ProdutoCadastroPage: FC<ProdutoCadastroPageProps> = (props) => {
    const {
        saving, readonly, form, setForm, errors, fabricantes,
        handleClose, handleSave
    } = useProdutoCadastroPage(props);

    const modelId = (props.id ?? 0).toString().padStart(4, "0");

    return (
        <ModalForm
            title={props.id ? `Produto #${modelId}` : 'Cadastro de Produto'}
            errors={errors}
            show={props.show}
            state={props.state}
            isSaving={saving}
            size="3xl"
            onClose={handleClose}
            onSave={handleSave}
        >
            <div className="grid grid-cols-12 m-6 gap-x-6 gap-y-3">
                {props.id &&
                    <div className="col-span-12">
                        <h2 className="ml-auto text-xl font-extrabold text-black/70">{`ID: #${modelId}`}</h2>
                    </div>}

                <div className="col-span-6 md:col-span-3">
                    <CodeLabel value="Código Fornecedor:" htmlFor="inputCodigo" className="mb-1" />
                    <TextInput
                        id="inputCodigo"
                        readOnly={readonly}
                        style={{ textTransform: "uppercase" }}
                        defaultValue={form?.codigoFornecedor}
                        onChange={(e) => setForm({
                            ...form,
                            codigoFornecedor: e.currentTarget.value?.toUpperCase()
                        })} />
                </div>

                <div className="col-span-6 md:col-span-9">
                    <CodeLabel value="Fabricante:" htmlFor="inputFabricante" className="mb-1" />
                    <Select
                        id="inputFabricante"
                        disabled={readonly}
                        style={{ textTransform: "uppercase" }}
                        value={form?.fabricanteId}
                        onChange={(e) => setForm({
                            ...form,
                            fabricanteId: e.currentTarget.value ? parseInt(e.currentTarget.value) : undefined
                        })}>
                        <option value={0}>Selecione um fabricante</option>
                        {fabricantes.map((fabricante, key) => <option key={key} value={fabricante.id}>{fabricante.marca}</option>)}
                    </Select>
                </div>

                <div className="col-span-12">
                    <CodeLabel value="Nome:" htmlFor="inputNome" className="mb-1" />
                    <TextInput
                        id="inputNome"
                        readOnly={readonly}
                        style={{ textTransform: "uppercase" }}
                        defaultValue={form?.nome}
                        onChange={(e) => setForm({
                            ...form,
                            nome: e.currentTarget.value?.toUpperCase()
                        })} />
                </div>

                <div className="col-span-6 md:col-span-4">
                    <CodeLabel value="Preço:" htmlFor="inputPreco" className="mb-1" />
                    <MoneyInput
                        id="inputPreco"
                        readOnly={readonly}
                        value={CodeUtil.moneyFormat(form?.preco, false)}
                        onChange={(_value, numberValue) => setForm({
                            ...form,
                            preco: numberValue
                        })} />
                </div>
            </div>

        </ModalForm>
    )
}