import { AxiosError } from "axios";
import { Badge, Button, Checkbox, Label, TextInput } from "flowbite-react";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { ShowToast } from "../../../../components/CodeToast";
import { ModalForm } from "../../../../components/ModalForm";
import { ModalFormProps, ModalStateType } from "../../../../components/ModalForm/types";
import { FormStateType } from "../../../../enums";
import { defaultMarca, VeiculoMarcaModel } from "../../../../models/veiculo_marca";
import { VeiculoMarcaService } from "../../../../services/VeiculoMarcaService";
import CodeUtil from "../../../../utils/CodeUtil";
import { KeyboardEvent } from "react";
import { findValidationField, FluentValidator } from "../../../../types";
import { CodeLabel } from "../../../../components/CodeLabel";
import { defaultModelo, VeiculoModeloModel } from "../../../../models/veiculo_modelo";
import { VeiculosModelosModal } from "./components/Modal";
import { Popup } from "../../../../components/Popup";
import { HiCheck } from "react-icons/hi";
import { IoMdClose } from "react-icons/io";
import { CondensedCodeDataGrid } from "../../../../components/CondensedCodeDataGrid";
import { ICodeDataGridRow } from "../../../../components/CondensedCodeDataGrid/types";
import { ContextMenu } from "primereact/contextmenu";
import { PrimeIcons } from "primereact/api";

export const VeiculosMarcasModal: FC<ModalFormProps> = (props) => {
    const [errors, setErrors] = useState<FluentValidator[]>([]);
    const [saving, setSaving] = useState<boolean>(false);
    const [formLoaded, setFormLoaded] = useState<boolean>(false);
    const [model, setModel] = useState<VeiculoMarcaModel>(defaultMarca);
    const isReadOnly = props.state === FormStateType.view;
    const [selectedModelo, setSelectedModelo] = useState<VeiculoModeloModel>(defaultModelo);
    const [selectedRow, setSelectedRow] = useState<ICodeDataGridRow | null>(null);
    const [showPopupDelete, setShowPopupDelete] = useState<boolean>(false);
    const [stateModalModelo, setStateModalModelo] = useState<ModalStateType<VeiculoModeloModel>>({
        show: false,
        state: FormStateType.add,
    });
    const cm = useRef<ContextMenu>(null);

    const onFormClose = () => {
        setErrors([]);
        setFormLoaded(false);
        props.onClose?.call(this);
    }

    const onFormLoad = useCallback(async () => {
        if (!props.show || formLoaded) return;

        if (props.id !== null && props.id !== undefined && props.id > 0) {
            let response = await VeiculoMarcaService.getId(props.id);
            if (!response.success) {
                ShowToast({ message: CodeUtil.arrayToStr(response.messages) });
                return;
            }

            setModel(response.data[0]);
        } else setModel(defaultMarca);

        setFormLoaded(true);
    }, [props.id, props.show, formLoaded, setFormLoaded]);


    const validate = (): FluentValidator[] => {
        let _errors: FluentValidator[] = [];

        if (CodeUtil.isNullOrEmpty(model.marca)) _errors.push({ field: "marca", message: "Preenchimento obrigatório", isValid: false });

        setErrors(_errors);
        return _errors;
    }

    const onFormSave = async (e: React.MouseEvent<HTMLButtonElement> | undefined) => {

        let validationResult = validate();
        if (validationResult.length > 0) return;

        try {
            setSaving(true);
            let response = props.state === FormStateType.add ? await VeiculoMarcaService.add(model) :
                await VeiculoMarcaService.update(model);

            if (!response.success) {
                ShowToast({ message: CodeUtil.arrayToStr(response.messages) });
                return;
            }

            props.onSave?.call(this, e);
            onFormClose();
        } catch (error) {
            ShowToast({ message: (error as AxiosError).response?.data as string })
        }
        finally {
            setSaving(false);
        }
    }

    useEffect(() => {
        onFormLoad();
    });

    const handleKeydownEvent = (e: KeyboardEvent<HTMLDivElement>) => {
        if (e.code.toLowerCase() === "escape") {
            onFormClose();
            return;
        }
    }

    const showModal = (formState: FormStateType): void => {
        if (formState === FormStateType.add)
            setSelectedModelo(defaultModelo);

        setStateModalModelo({
            show: true,
            state: formState
        });
    }

    const updateItem = () => {
        setStateModalModelo({ ...stateModalModelo, show: false });

        if (stateModalModelo.state === FormStateType.add) {
            setModel({ ...model, modelos: [{ ...selectedModelo, state: 'ADDED', id: 0 }, ...model.modelos] });
        } else {
            let index = selectedRow?.rowindex ?? -1;
            if (index === null || index < 0) return;

            let updatedModelos = model.modelos;
            updatedModelos[index] = selectedModelo;
            updatedModelos[index].state = 'UPDATED';
            setModel({ ...model, modelos: updatedModelos });
        }
    }

    const removeItem = () => {
        let index = selectedRow?.rowindex ?? -1;
        if (index === null || index < 0) return;

        let updatedModelos = model.modelos;
        updatedModelos[index] = selectedModelo;
        updatedModelos[index].state = 'DELETED';

        setModel({ ...model, modelos: updatedModelos });
        setShowPopupDelete(false);
        setSelectedModelo(defaultModelo);
    }

    if (!props.show) return <></>;

    return (
        <div onKeyDown={handleKeydownEvent}>
            <ContextMenu ref={cm} model={[
                { label: "Novo Modelo", icon: PrimeIcons.PLUS, disabled: isReadOnly, command: () => showModal(FormStateType.add) },
                { label: "Editar", icon: PrimeIcons.PENCIL, disabled: isReadOnly || (selectedModelo?.id ?? 0) === 0, command: () => showModal(FormStateType.edit) },
                { label: "Consultar", icon: PrimeIcons.EYE, disabled: (selectedModelo?.id ?? 0) === 0, command: () => showModal(FormStateType.view) },
                { separator: true },
                {
                    label: "Excluir", icon: PrimeIcons.TRASH, disabled: isReadOnly || (selectedModelo?.id ?? 0) === 0, command: () => () => {
                        if (selectedModelo === undefined) {
                            ShowToast({ message: 'Nenhum registro selecionado para exclusão' });
                            return;
                        }
                        setShowPopupDelete(true)
                    }
                },
            ]} />

            <ModalForm title={props.title ?? "Cadastro de Marcas e Modelos"}
                show={props.show}
                state={props.state}
                onClose={onFormClose}
                isSaving={saving}
                size="4xl"
                onSave={async (e: React.MouseEvent<HTMLButtonElement> | undefined) => await onFormSave(e)}>

                <div className="grid grid-cols-12 mt-4 mb-2 px-3 pb-4">
                    <div className="col-span-12">
                        <h2 className="ml-auto text-xl font-extrabold text-black/70" hidden={(model.id ?? 0) === 0}>
                            {`ID: #${(model.id ?? 0).toString().padStart(3, "0")}`}
                        </h2>
                    </div>

                    <div className="form-control mt-5 col-span-12 md:col-span-6">
                        <CodeLabel htmlFor="inputMarca" value="Marca:" className="mb-1" />
                        <TextInput id="inputMarca"
                            type="text"
                            readOnly={isReadOnly}
                            value={model.marca}
                            onChange={(e) => setModel({ ...model, marca: e.currentTarget.value })}
                            helperText={findValidationField(errors, "marca").message}
                            color={findValidationField(errors, "marca").isValid ? "gray" : "failure"}
                            placeholder="Marca, Montadora ou Fabricante"
                            maxLength={200} />
                    </div>

                    <div className="form-control mt-5 col-span-12 flex space-x-2 items-center">
                        <Checkbox id="checkBoxMarcaAtiva"
                            disabled={isReadOnly}
                            checked={model.ativa}
                            onChange={(e) => setModel({ ...model, ativa: e.currentTarget.checked })}
                            placeholder="Checked">
                        </Checkbox>

                        <Label htmlFor="checkBoxMarcaAtiva" value="Marca ativa?" />
                    </div>

                    <div className="mt-3 col-span-12">
                        <div className="p-0 -mx-6 mt-3 -mb-6">
                            {model.modelos.length <= 0 ?
                                <div className="flex flex-col space-y-3 ml-auto mr-auto p-5 place-items-center">
                                    <span className="text-slate-500">Esta marca não possui modelos cadastrados</span>
                                    <Button disabled={isReadOnly} onClick={() => showModal(FormStateType.add)} color="dark" size="sm">Novo modelo</Button>
                                </div>
                                : <></>
                            }

                            {model.modelos.length > 0 ?
                                <CondensedCodeDataGrid
                                    key="id"
                                    header={{ filter: true }}
                                    onContextMenu={(e) => cm.current?.show(e)}
                                    sort={{ columnIndex: 1, direction: "asc", columnProperty: "modelo" }}
                                    datasource={model.modelos.filter((e) => e.state !== 'DELETED')}
                                    columns={[
                                        { property: "id", title: "Código", alignment: 'right', onFormatValue: (value: VeiculoModeloModel) => (value.id as Number).toString().padStart(3, "0") },
                                        { property: "modelo", title: "Modelo" },
                                        {
                                            property: "ativo", title: "Ativo", alignment: "center", onFormatValue(value: VeiculoModeloModel) {
                                                return (
                                                    <div className="flex justify-center flex-wrap items-center gap-2">
                                                        {(value.ativo) ? <Badge icon={HiCheck} size="sm" color="success" /> :
                                                            <Badge size="sm" color="failure" icon={IoMdClose} />}
                                                    </div>
                                                );
                                            },
                                        },
                                    ]}
                                    onRowSelect={(selectedRow) => {
                                        setSelectedModelo((!selectedRow?.selected ?? false) ? defaultModelo : selectedRow.item as VeiculoModeloModel);
                                        setSelectedRow(selectedRow);
                                    }}
                                    multiselect={false}
                                /> : <></>
                            }
                        </div>
                    </div>
                </div>
            </ModalForm>

            <Popup
                isShowing={showPopupDelete}
                message="Confirma a exclusão do registro selecionado?"
                isDestructive={true}
                onConfirm={() => removeItem()}
                onCancel={() => setShowPopupDelete(false)}
            />

            <VeiculosModelosModal
                show={stateModalModelo.show}
                title="Modelo"
                state={stateModalModelo.state}
                model={[selectedModelo, setSelectedModelo]}
                onSave={() => updateItem()}
                onClose={() => setStateModalModelo({ ...stateModalModelo, show: false })}
            />
        </div>
    );
};
