import { FC } from "react";
import { ClientesCondutoresCadastroFormProps } from "./types";
import { useClientesCondutoresCadastroPage } from "./ClientesCondutoresCadastroPage.hook";
import ModalForm from "../../components/ModalForm";
import { MaskedInput } from "../../components/MaskedInput";
import { CodeLabel } from "../../components/CodeLabel";
import { TextInput } from "flowbite-react";
import { findValidationField } from "../../types";

export const ClientesCondutoresCadastroPage: FC<ClientesCondutoresCadastroFormProps> = (props) => {
    const {
        onFormClose, onFormSave, fetchFormProps,
        model, setModel, readonly, errors,
    } = useClientesCondutoresCadastroPage(props);

    return (
        <ModalForm
            title={'Cadastro de condutor'}
            errors={[]}
            show={props.show}
            state={props.state}
            isSaving={fetchFormProps().saving}
            size="4xl"
            onClose={onFormClose}
            onSave={onFormSave}
        >
            <div className="grid grid-cols-12 px-6 pb-3">
                <div className="form-control mt-5 col-span-12 md:col-span-6 md:mr-6">
                    <CodeLabel className="mb-1" htmlFor="inputNome" value="Nome:" />
                    <TextInput
                        id="inputNome"
                        type="text"
                        readOnly={readonly}
                        value={model?.nome}
                        helperText={findValidationField(errors, "nome").message}
                        color={findValidationField(errors, "nome").isValid ? "gray" : "failure"}
                        onChange={(e) => setModel({ ...model, nome: e.currentTarget.value })}
                        maxLength={200}
                    />
                </div>

                <div className="form-control mt-5 col-span-12 md:col-span-6">
                    <CodeLabel className="mb-1" htmlFor="inputSobrenome" value="Sobrenome:" />
                    <TextInput
                        id="inputSobrenome"
                        type="text"
                        readOnly={readonly}
                        value={model?.sobrenome}
                        helperText={findValidationField(errors, "sobrenome").message}
                        color={findValidationField(errors, "sobrenome").isValid ? "gray" : "failure"}
                        onChange={(e) => setModel({ ...model, sobrenome: e.currentTarget.value })}
                        maxLength={200}
                    />
                </div>

                <div className="form-control mt-5 col-span-12">
                    <CodeLabel className="mb-1" htmlFor="inputEmail" value="E-mail:" />
                    <TextInput
                        id="inputEmail"
                        type="email"
                        placeholder="seumelhoremail@email.com.br"
                        helperText={findValidationField(errors, "email").message}
                        color={findValidationField(errors, "email").isValid ? "gray" : "failure"}
                        readOnly={readonly}
                        value={model?.email}
                        onChange={(e) => setModel({ ...model, email: e.currentTarget.value })}
                        maxLength={255}
                    />
                </div>

                <div className="form-control mt-5 col-span-12 md:col-span-6 md:mr-6">
                    <CodeLabel className="mb-1" htmlFor="inputCPF" value="CPF:" />
                    <MaskedInput
                        id="inputCPF"
                        type="text"
                        pattern="^[0-9]+$"
                        placeHolder="999.999.999-99"
                        mask="###.###.###-##"
                        readOnly={readonly}
                        helperText={findValidationField(errors, "cpf").message}
                        color={findValidationField(errors, "cpf").isValid ? "gray" : "failure"}
                        value={model?.cpf}
                        onChange={(e) => setModel({ ...model, cpf: e })}
                    />
                </div>

                <div className="form-control mt-5 col-span-12 md:col-span-6">
                    <CodeLabel className="mb-1" htmlFor="inputMatricula" value="Matrícula:" />
                    <TextInput
                        id="inputMatricula"
                        placeholder="Número da Matrícula"
                        readOnly={readonly}
                        value={model?.matricula}
                        onChange={(e) => setModel({ ...model, matricula: e.currentTarget.value })}
                        maxLength={20}
                    />
                </div>

                <div className="form-control mt-5 col-span-12 md:col-span-6 md:mr-6">
                    <CodeLabel className="mb-1" htmlFor="inputHabilitacao" value="Nº Habilitação:" />
                    <TextInput
                        id="inputHabilitacao"
                        placeholder="999999999"
                        readOnly={readonly}
                        value={model?.numeroHabilitacao}
                        onChange={(e) => setModel({ ...model, numeroHabilitacao: e.currentTarget.value })}
                        maxLength={9}
                    />
                </div>

                <div className="form-control mt-5 col-span-12 md:col-span-6">
                    <CodeLabel className="mb-1" htmlFor="inputCelular" value="Celular:" />
                    <MaskedInput
                        id="inputCelular"
                        type="text"
                        pattern="^[0-9]+$"
                        mask="(##) #####-####"
                        readOnly={readonly}
                        value={model?.celular}
                        onChange={(e) => setModel({ ...model, celular: e })}
                    />
                </div>
            </div>
        </ModalForm>
    );
};