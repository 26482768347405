import { FC } from "react";
import ModalForm from "../../components/ModalForm";
import { ProdutoImportacaoPageProps, useProdutoImportacaoPage } from "./ProdutoImportacaoPage.hook";
import { FormStateType } from "../../enums";
import { CodeLabel } from "../../components/CodeLabel";
import { FileInput, Select } from "flowbite-react";

export const ProdutoImportacaoPage: FC<ProdutoImportacaoPageProps> = (props) => {

    const { fabricantes, errors, saving, form, setForm, inputFile, handleImportar, handleClose } = useProdutoImportacaoPage(props);

    return (
        <ModalForm
            title={'Importação de Produtos'}
            errors={errors}
            show={true}
            state={FormStateType.add}
            size="2xl"
            isSaving={saving}
            saveButtonTitle="Importar"
            onClose={handleClose}
            onSave={handleImportar}
        >
            <div className="grid grid-cols-12 m-6 gap-x-6 gap-y-3">
                <div className="col-span-6">
                    <CodeLabel value="Fabricante:" htmlFor="inputFabricante" className="mb-1" />
                    <Select
                        id="inputFabricante"
                        style={{ textTransform: "uppercase" }}
                        onChange={(e) => setForm({
                            ...form,
                            fabricanteId: e.currentTarget.value ? parseInt(e.currentTarget.value) : undefined
                        })}>
                        <option value={0}>Selecione um fabricante</option>
                        {fabricantes.map((fabricante, key) => <option key={key} value={fabricante.id}>{fabricante.marca}</option>)}
                    </Select>
                </div>

                <div className="col-span-12">
                    <CodeLabel value="Arquivo:" htmlFor="inputArquivo" className="mb-1" />
                    <FileInput
                        ref={inputFile}
                        id="inputArquivo"
                        multiple={false}
                        accept=".xls,.xlsx"/>
                </div>
            </div>
        </ModalForm>
    );
}