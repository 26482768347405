import * as RiIconLibrary from 'react-icons/ri';
import * as FaIconLibrary from 'react-icons/fa';
import * as GiIconLibrary from 'react-icons/gi';
import * as MdIconLibrary from 'react-icons/md';


type IconProps = {
    iconName: string;
    menu?: boolean;
    className?: string;
};

const selectIconLib = (lib: string): any => {
    switch (lib) {
        case 'fa':
        default:
            return FaIconLibrary;
        case 'ri':
            return RiIconLibrary;
        case 'md':
            return MdIconLibrary;
        case 'gi':
            return GiIconLibrary;
    }
}

const CodeIcon: React.FC<IconProps> = ({ iconName, menu, className }) => {
    const $className = (menu ?? true) ? 'w-5 h-5 transition duration-75 text-gray-500' : '';

    if (iconName.includes('/')) {
        const lib = iconName.split('/')[0];
        const name = iconName.split('/')[1];
        const IconComponent = selectIconLib(lib)[name];
        if (!IconComponent) return <></>;
        return <IconComponent className={`${$className} ${className}`} />;
    }

    const IconComponent = selectIconLib('fa')[iconName];
    if (!IconComponent) return <></>;
    return <IconComponent className={`${$className} ${className}`} />;
};

export default CodeIcon;