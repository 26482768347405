import { FC } from "react";
import { useVeloeRecargaModalPage, VeloeRecargaModalProps } from "./VeloeRecargaModalPage.hook";
import ModalForm from "../../components/ModalForm";
import { CodeLabel } from "../../components/CodeLabel";
import { MoneyInput } from "../../components/MoneyInput";
import CodeUtil from "../../utils/CodeUtil";
import { FormStateType } from "../../enums";
import { Select, TextInput, Textarea } from "flowbite-react";


export const VeloeRecargaModalPage: FC<VeloeRecargaModalProps> = (props) => {

    const readonly = props.state === FormStateType.view;
    const { handleFormClose, handleFormSave, saving, setValue, getValues } = useVeloeRecargaModalPage(props);

    return (
        <ModalForm
            title={"Recarga Complementar"}
            errors={[]}
            show={props.show}
            state={props.state}
            isSaving={saving}
            size="3xl"
            onClose={handleFormClose}
            onSave={handleFormSave}
        >
            <div className="grid grid-cols-12 p-6 gap-x-6 gap-y-3">
                <div className="col-span-12 md:col-span-4">
                    <CodeLabel className="mb-1" htmlFor="inputCartao" value="Nº do Cartão:" />
                    <TextInput
                        id="inputCartao"
                        type="text"
                        value={getValues.cardNumber ?? ""}
                        placeholder="Número do Cartão"
                        onChange={(e) => setValue({ ...getValues, cardNumber: e.currentTarget.value })}
                    />
                </div>
                
                <div className="col-span-12 md:col-span-4">
                    <CodeLabel value={"Valor:"} className="mb-1" htmlFor="inputValor" />
                    <MoneyInput id="inputValor"
                        value={CodeUtil.moneyFormat(getValues.amount, false)}
                        addon={"R$"}
                        readOnly={readonly}
                        onChange={(_stringValue, numberValue) => setValue({ ...getValues, amount: numberValue })} />
                </div>

                <div className="col-span-12 md:col-span-4">
                    <CodeLabel value={"Tipo da Operação:"} className="mb-1" htmlFor="selectCreditoDebito" />
                    <Select id="selectCreditoDebito"
                        value={getValues.operationType}
                        onChange={(e) => setValue({ ...getValues, operationType: e.currentTarget.value ?? "C" })}>
                        <option disabled>Tipo da Operação</option>
                        <option value={"C"}>Crédito</option>
                        <option value={"D"}>Débito</option>
                    </Select>
                </div>

                <div className="col-span-12">
                    <CodeLabel value={"Observações:"} className="mb-1" htmlFor="inputObservacoes" />
                    <Textarea id="inputObservacoes"
                        value={getValues.description ?? ""}
                        onChange={(e) => setValue({ ...getValues, description: e.currentTarget.value })} />
                </div>
            </div>

        </ModalForm>
    )
}