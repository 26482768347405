import { FC } from 'react';
import { BadgeProps } from './types';

export const Badge: FC<BadgeProps> = (props) => {
    switch (props.color) {
        case `default`:
        default: return <span className={`bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300 ${props.className}`}>{props.value}</span>;
        case `dark`: return <span className={`bg-gray-100 text-gray-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300 ${props.className}`}>{props.value}</span>;
        case `red`: return <span className={`bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300 ${props.className}`}>{props.value}</span>;
        case `green`: return <span className={`bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-green-700 dark:text-green-300 ${props.className}`}>{props.value}</span>;
        case `yellow`: return <span className={`bg-yellow-100 text-yellow-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-yellow-700 dark:text-yellow-300 ${props.className}`}>{props.value}</span>;
        case `indigo`: return <span className={`bg-indigo-100 text-indigo-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-indigo-700 dark:text-indigo-300 ${props.className}`}>{props.value}</span>;
        case `purple`: return <span className={`bg-purple-100 text-purple-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-purple-700 dark:text-purple-300 ${props.className}`}>{props.value}</span>;
        case `pink`: return <span className={`bg-pink-100 text-pink-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-pink-700 dark:text-pink-300 ${props.className}`}>{props.value}</span>;
    }
};