import { AxiosError } from "axios";
import { Tabs } from "flowbite-react";
import { FC, useCallback, useEffect, useState } from "react";
import { ShowToast } from "../../../../components/CodeToast";
import { ModalForm } from "../../../../components/ModalForm";
import { ModalFormProps } from "../../../../components/ModalForm/types";
import { FormStateType } from "../../../../enums";
import { defaultCredenciada, CredenciadaModel, validateCredenciada } from "../../../../models/credenciada";
import { CredenciadaService } from "../../../../services/CredenciadaService";
import CodeUtil from "../../../../utils/CodeUtil";
import { CredenciadasTabContatos } from "./TabContatos";
import { CredenciadasTabDados } from "./TabDados";
import { CredenciadasTabServicos } from "./TabServicos";

export const CredenciadasModal: FC<ModalFormProps> = (props) => {
  const [errors, setErrors] = useState<string[]>([]);
  const [saving, setSaving] = useState<boolean>(false);
  const [formLoaded, setFormLoaded] = useState<boolean>(false);
  const [model, setModel] = useState<CredenciadaModel>(defaultCredenciada);

  const onFormClose = () => {
    setErrors([]);
    setFormLoaded(false);
    props.onClose?.call(this);
  };

  const onFormLoad = useCallback(async () => {
    if (!props.show || formLoaded) return;

    if (props.id !== null && props.id !== undefined && props.id > 0) {
      let response = await CredenciadaService.getId(props.id);
      if (!response.success) {
        ShowToast({ message: CodeUtil.arrayToStr(response.messages) });
        return;
      }

      setModel(response.data[0]);
    } else setModel(defaultCredenciada);

    setFormLoaded(true);
  }, [props.id, props.show, formLoaded, setFormLoaded]);

  const onFormSave = async (e: React.MouseEvent<HTMLButtonElement> | undefined) => {
    let validationResult = validateCredenciada(model);
    setErrors(validationResult);

    if (validationResult.length > 0) return;

    try {
      setSaving(true);
      let response = props.state === FormStateType.add ? await CredenciadaService.add(model) : await CredenciadaService.update(model);

      if (!response.success) {
        setErrors(response.messages);
        return;
      }

      props.onSave?.call(this, e);
      onFormClose();
    } catch (error) {
      setErrors([(error as AxiosError).response?.data as string]);
    } finally {
      setSaving(false);
    }
  };

  useEffect(() => {
    onFormLoad();
  });

  if (!props.show) return <></>;

  return (
    <ModalForm
      title={props.title ?? "Cadastro de Credenciadas"}
      errors={errors}
      show={props.show}
      state={props.state}
      isSaving={saving}
      size="5xl"
      onClose={onFormClose}
      onSave={onFormSave}
    >
      {/* eslint-disable-next-line */}
      <Tabs.Group aria-label="Abas" style="underline">
        <Tabs.Item title="Credenciada" active={true} key="credenciada">
          <CredenciadasTabDados {...props}
            model={[model, setModel]}
            errors={[errors, setErrors]}
          />
        </Tabs.Item>

        <Tabs.Item title="Serviços" key="servicos">
          <CredenciadasTabServicos {...props}
            model={[model, setModel]}
            errors={[errors, setErrors]}
          />
        </Tabs.Item>
        <Tabs.Item title="Contatos" key="contatos">
          <CredenciadasTabContatos {...props}
            model={[model, setModel]}
            errors={[errors, setErrors]}
          />
        </Tabs.Item>
      </Tabs.Group>
    </ModalForm>
  );
};
