import { useCallback, useEffect, useState } from "react";
import { getDefaultViewCotacaoCredenciada, ViewCotacaoCredenciadaModel } from "../../../models/cotacao/ViewCotacaoCredenciada";
import { RegistroVeiculoFormFields, IniciarServicoModalProps } from "../types"
import { FluentValidator } from "../../../types";
import { SessionManager } from "../../../utils/SessionManager";
import { CotacaoCredenciadaService } from "../../../services/CotacaoCredenciadaService";
import { ShowToast } from "../../CodeToast";
import { UNKOWN_EXCEPTION } from "../../../utils/Constants";
import CodeUtil from "../../../utils/CodeUtil";
import { CotacaoService } from "../../../services/CotacaoService";

export const useIniciarServicoModal = (props: IniciarServicoModalProps) => {
    const [model, setModel] = useState<ViewCotacaoCredenciadaModel>(getDefaultViewCotacaoCredenciada());
    const [registroEntrada, setRegistroEntrada] = useState<RegistroVeiculoFormFields>({
        codigoAutorizacao: '',
        marcaBateria: '',
        observacoes: '',
        quilometragem: 0
    });
    const [loaded, setLoaded] = useState<boolean>(false);
    const [validation, setValidation] = useState<FluentValidator[]>([]);
    const [saving, setSaving] = useState<boolean>(false);
    const credenciadaId: number = SessionManager.getSession().user?.credenciadaId ?? props.credenciadaId ?? 0;

    const onFormLoad = useCallback(async () => {
        if (!props.show || loaded) return;

        try {
            if (props.id === undefined || props.id === 0) {
                setModel(getDefaultViewCotacaoCredenciada());
                setLoaded(false);
                return;
            }

            const response = await CotacaoCredenciadaService.getId(props.id ?? 0, credenciadaId);

            if (!response.success) {
                ShowToast({ message: response.messages });
                props.onClose?.call(this);
                return;
            }

            setModel(response.data);
            setLoaded(true);
        } catch (error) {
            console.log(error);
            ShowToast({ message: UNKOWN_EXCEPTION });
        }
    }, [props, credenciadaId, loaded, setLoaded]);


    const validate = (): boolean => {
        let _errors: FluentValidator[] = [];

        if (registroEntrada.quilometragem === 0) _errors.push({ field: "quilometragem", message: "Informe a quilometragem do veículo", isValid: false });
        if (CodeUtil.isNullOrEmpty(registroEntrada.codigoAutorizacao)) _errors.push({ field: "codigoAutorizacao", message: "O código de autorizaçao do condutor deve ser preenchido", isValid: false });

        setValidation(_errors);
        return _errors.length === 0;
    }

    const onIniciarOrdemServico = async () => {
        try {
            setSaving(true);
            if (!validate()) return;

            const response = await CotacaoService.executarCotacao({
                ...registroEntrada,
                credenciadaId: model.credenciadaId,
                entradaSaida: "Entrada",
                cotacaoId: model.id ?? 0
            });

            if (!response.success) {
                ShowToast({ message: response.messages });
                return;
            }

            props.onSave?.call(this, undefined);

        } catch (error) {
            ShowToast({ message: UNKOWN_EXCEPTION });
            console.log(error);
        } finally {
            setSaving(false);
        }
    }

    useEffect(() => {
        onFormLoad();
    }, [onFormLoad]);

    return {
        model, setModel,
        registroEntrada, setRegistroEntrada,
        validation, saving,
        onIniciarOrdemServico
    }
}