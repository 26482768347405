import { FC } from "react";
import { SideBarFiltrosOrdensServicoProps, useSidebarFiltroOrdensServico } from "./index.hook";
import { CodeLabel } from "../CodeLabel";
import { Button, TextInput } from "flowbite-react";
import { format } from "fecha";
import { CodeSelectableChip } from "../CodeSelectableChip";
import FlatButton from "../FlatButton";
import { getCotacaoStatus } from "../../models/cotacao/CotacaoModel";

export const SideBarFiltrosOrdensServico: FC<SideBarFiltrosOrdensServicoProps> = (props) => {
    const { STATUS_ITEMS, inputDataInicial, inputDataFinal, status,
        handleSelectStatus, handleApplyFilter, handleClose } = useSidebarFiltroOrdensServico(props);

    return (
        <div className={`${props.hidden ? "hidden -z-50" : "fixed h-screen w-screen z-50"}`}>
            <div className="bg-black/30 shadow overflow-y-auto fixed w-screen h-screen z-50 left-0 top-0 transition-transform" onClickCapture={handleClose} />

            <div className="bg-white shadow overflow-y-auto fixed w-80 h-screen z-50 right-0 top-0 transition-transform p-6">
                <h1 className="pt-6 text-3xl font-bold">Mais Filtros</h1>
                <hr className="mt-3 mb-5" />

                <div className="grid grid-cols-12 gap-x-3">
                    <div className="col-span-6">
                        <CodeLabel value="Inicio:" className="mb-1" htmlFor="inputDataInicial" />
                        <TextInput type={"date"}
                            id="inputDataInicial"
                            ref={inputDataInicial}
                            defaultValue={format(new Date(Date.now()), 'YYYY-MM-DD')} />
                    </div>

                    <div className="col-span-6">
                        <CodeLabel value="Término:" className="mb-1" htmlFor="inputDataFinal" />
                        <TextInput type={"date"}
                            id="inputDataFinal"
                            ref={inputDataFinal}
                            defaultValue={format(new Date(Date.now()), 'YYYY-MM-DD')} />
                    </div>

                    <div className="col-span-12 mt-6 gap-x-3">
                        <CodeLabel value="Status:" className="mb-1" />
                        {STATUS_ITEMS.map((item) => <CodeSelectableChip
                            key={item}
                            model={item}
                            className="m-0.5 text-sm"
                            selected={status?.includes(item)}
                            onSelectChip={handleSelectStatus}
                            label={getCotacaoStatus(item)} />)}
                    </div>

                    <div className="col-span-12">
                        <hr className="mt-5 mb-3" />
                        <div className="flex ml-auto items-center gap-x-1.5">
                            <div className="ml-auto">
                                <FlatButton label="Cancelar" onClick={handleClose} />
                            </div>
                            <Button size={"sm"} color="dark" onClick={handleApplyFilter}>Filtrar</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}