import { ClienteCondutorCommand } from '../commands/ClienteCondutorCommand';
import { ClienteCondutorModel } from '../models/cliente_condutor/ClienteCondutorModel';
import { BaseService } from "./BaseService";
import { DefaultResponse, ServiceProps } from "./types";

const ENDPOINT = `${BaseService.BASE_URL}/cliente/condutor`;

export class ClienteCondutorService extends BaseService {
    public static async get(clienteId: number, props: ServiceProps = { limit: 100, page: 1 }): Promise<DefaultResponse<ClienteCondutorModel>> {
        return await this.GET<ClienteCondutorModel>(`${ENDPOINT}?clienteId=${clienteId}&limit=${props.limit}&page=${props.page}`);
    }

    public static async getId(id: number): Promise<DefaultResponse<ClienteCondutorModel>> {
        return await this.GET<ClienteCondutorModel>(`${ENDPOINT}/${id}`);
    }

    public static async add(model: ClienteCondutorCommand): Promise<DefaultResponse<ClienteCondutorModel>> {
        return await this.POST<ClienteCondutorCommand>(`${ENDPOINT}`, model);
    }

    public static async update(model: ClienteCondutorCommand): Promise<DefaultResponse<ClienteCondutorModel>> {
        return await this.PATCH<ClienteCondutorCommand>(`${ENDPOINT}/${model.id}`, model);
    }

    public static async delete(id: number): Promise<DefaultResponse<ClienteCondutorModel>> {
        return await this.DELETE(`${ENDPOINT}/${id}`);
    }

    public static async reenviarCredencial(id: number): Promise<DefaultResponse<ClienteCondutorModel>> {
        return await this.POST(`${ENDPOINT}/${id}/reset-password`, null);
    }
}