import { FC } from "react";
import { ICodeDataGridPagination } from "../types";
import { CondensedCodeDataGridPagination } from "./CondensedCodeDataGridPagination";

type CondensedCodeDataGridFooterProps = {
    pagination?: ICodeDataGridPagination
}

export const CondensedCodeDataGridFooter: FC<CondensedCodeDataGridFooterProps> = (props) => {
    return (
        <div className="bg-gray-50 py-0 px-6 border-t-2 flex place-content-center">
            {props.pagination !== undefined ? <CondensedCodeDataGridPagination {...props.pagination} /> : <></>}
        </div>
    );
};