import { Badge } from "flowbite-react";
import { HiCheck } from "react-icons/hi";
import { IoMdClose } from "react-icons/io";
import { ICodeDataGridColumn } from "../../components/CodeDataGrid/types";
import CodeUtil from "../../utils/CodeUtil";
import { ClienteCondutorModel } from "./ClienteCondutorModel";

const useCondutorColumns = (): ICodeDataGridColumn[] => {
    return [
        { title: "número", property: "id", alignment: "center", onFormatValue: (value: ClienteCondutorModel) => <span>{String(value.id).padStart(4, "0")}</span> },
        {
            property: "bloqueado", title: "Bloqueado", alignment: "center", onFormatValue(value: ClienteCondutorModel) {
                return (
                    <div className="flex justify-center flex-wrap items-center gap-2">
                        {(value.bloqueado as boolean) ? (
                            <Badge size="sm" color="failure" icon={IoMdClose} />
                        ) : (
                            <Badge icon={HiCheck} size="sm" color="success" />
                        )}
                    </div>
                );
            },
        },
        { property: "nome", title: "Nome" },
        { property: "sobrenome", title: "Sobrenome" },
        { property: "email", title: "E-mail" },
        { property: "cpf", title: "CPF" , onFormatValue: (value: ClienteCondutorModel) => CodeUtil.mask(value.cpf ?? "", "###.###.###-##") },
        { property: "matricula", title: "Matrícula" },
        { property: "numeroHabilitacao", title: "Habilitação" },
        { property: "celular", title: "Celular", onFormatValue: (value: ClienteCondutorModel) => CodeUtil.mask(value.celular ?? "", "(##) #####-####") },
    ]
}

export { useCondutorColumns }