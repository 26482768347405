import { FC, useRef } from "react";
import { CotacaoItemModel } from "../../../models/cotacao/CotacaoItemModel";
import { CotacaoTipoItemType, FormStateType } from "../../../enums";
import CodeUtil from "../../../utils/CodeUtil";
import { TieredMenu } from "primereact/tieredmenu";
import { Button } from "flowbite-react";
import { PrimeIcons } from "primereact/api";
import { useStaticListItemPecasServicos } from "./StaticListItemPecasServicos.hook";

export type StaticListItemPecasServicosProps = {
    model: CotacaoItemModel,
    index: number
    state: FormStateType
    onVisualizar?: (item: CotacaoItemModel) => void
    onExcluir?: (item: CotacaoItemModel) => void
}


export const StaticListItemPecasServicos: FC<StaticListItemPecasServicosProps> = (props) => {

    const cm = useRef<TieredMenu>(null);
    const { item, useContextMenuItems } = useStaticListItemPecasServicos(props);

    return (
        <div className="grid grid-cols-12 items-center text-sm">
            <div className="col-span-2 ml-6 flex flex-col">
                <span className="text-xs font-semibold text-black/60">Tipo</span>
                <span className="pt-1 flex-wrap">{item.tipo === CotacaoTipoItemType.ITEM ? "PEÇA" : "SERVIÇO"}</span>
            </div>
            <div className="col-span-1 mr-6 flex flex-col">
                <span className="text-xs font-semibold text-black/60">N/S</span>
                <span className="pt-1">{item.numeroSerie === null || item.numeroSerie === '' ? '-' : item.numeroSerie}</span>
            </div>
            <div className="col-span-2 ml-6 flex flex-col">
                <span className="text-xs font-semibold text-black/60">Código</span>
                <span className="pt-1 flex-wrap">{item.codigoFornecedor}</span>
            </div>
            <div className="col-span-3 ml-6 flex flex-col">
                <span className="text-xs font-semibold text-black/60">Descrição</span>
                <span className="pt-1 flex-wrap">{item.descricao}</span>
            </div>
            <div className="col-span-1 ml-6 flex flex-col">
                <span className="text-xs font-semibold text-black/60 text-right">Quantidade</span>
                <span className="pt-1 flex-wrap text-right">{CodeUtil.moneyFormat(item.quantidade, false)}</span>
            </div>
            <div className="mt-3 col-span-3 md:mt-0 mr-4 ml-auto flex flex-col">
                <TieredMenu model={useContextMenuItems()} popup ref={cm} breakpoint="767px" />
                <Button key={props.index} color={"light"} size={"sm"}
                    onClick={(e) => cm.current?.toggle(e)}>Mais Opções <i className={`ml-1.5 ${PrimeIcons.CHEVRON_DOWN}`} /></Button>
            </div>
        </div>
    );
};