import { useState } from "react";
import { MenuItem } from "primereact/menuitem";
import { PrimeIcons } from "primereact/api";
import { CotacaoStatusType, FormStateType } from "../../../enums";
import { StaticListItemCredenciadasProps } from "./StaticListItemCredenciadas";

export const useStaticListItemCredenciadas = (props: StaticListItemCredenciadasProps) => {
    const [showCancelDialog, setShowCancelDialog] = useState<boolean>(false);
    const [showModalItens, setShowModalItens] = useState<boolean>(false);
    const readonly = props.state === FormStateType.view;

    const useContextMenuItems = (): MenuItem[] => [
        { label: "Consultar Itens", icon: PrimeIcons.EYE, command: () => { setShowModalItens(true); } },
        { separator: true },
        {
            label: "Cancelar Cotação", icon: PrimeIcons.TRASH,
            disabled: readonly || props.model.status !== CotacaoStatusType.AGUARDANDO,
            command: () => { setShowCancelDialog(true); }
        }
    ];

    const handleCancelarCotacao = () => {
        setShowCancelDialog(false);
        props.onCancelarCotacao?.call(this, props.model.credenciadaId);
    }

    const handleCloseCancelDialog = () => setShowCancelDialog(false);

    const handleCloseModalItens = () => setShowModalItens(false);

    return {
        showCancelDialog, showModalItens,
        useContextMenuItems, handleCancelarCotacao, handleCloseCancelDialog,
        handleCloseModalItens
    }
}