import { useState } from "react";
import { ICodeDataGridColumn } from "../../components/CondensedCodeDataGrid/types";
import { VeloeService } from "../../services/VeloeService";
import { ShowToast } from "../../components/CodeToast";
import { UNKOWN_EXCEPTION } from "../../utils/Constants";
import { VeloeFuelSupplyTransactionRequest } from "../../models/veloe/VeloeFuelSupplyTransactionRequest";
import { VeloeFuelSupplyTransactionsModel } from "../../models/veloe/VeloeFuelSupplyTransactionsModel";

export const useVeloeTransacoesPage = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [showFiltros, setShowFiltros] = useState<boolean>(false);
    const [dataSource, setDataSource] = useState<VeloeFuelSupplyTransactionsModel[]>([]);
    const [filtro, setFiltro] = useState<VeloeFuelSupplyTransactionRequest>();

    const columns: ICodeDataGridColumn[] = [
        { title: "CNPJ da Empresa", property: "companyDocumentNumber" },
        { title: "Contrato", property: "contract", alignment: "center" },
        { title: "Código da Base", property: "branchCode" },
        { title: "Nome da Base", property: "branchName" },
        { title: "Placa", property: "transactionStatus" },
        { title: "UF", property: "transactionType" },
        { title: "Município", property: "merchantName" },
        { title: "Tipo de Frota", property: "merchantDocumentNumber" },
        { title: "Data de Cadastro", property: "merchantCity" },
        { title: "Fabricante", property: "merchantState" },
        { title: "Modelo", property: "merchantFlag" },
        { title: "Data", property: "transactionDate" , onFormatValue: (item: VeloeFuelSupplyTransactionsModel) => <span>{item.transactionDate}</span> },
        { title: "Autorização", property: "authorization" },
        { title: "Valor", property: "transactionValue", alignment: "right" },
        { title: "Tipo Mercadoria", property: "merchandiseType" },
        { title: "Mercadoria", property: "merchandise" },
        { title: "Qtd. Mercadoria", property: "merchandiseQuantity" },
        { title: "Placa", property: "plate" },
        { title: "Modelo", property: "vehicleModel" },
        { title: "Documento Mot.", property: "driverDocumentNumber" },
        { title: "Motorista", property: "driverName" },
        { title: "Matrícula", property: "registration" },
        { title: "Tipo Cartão", property: "cardType" },
        { title: "N. Cartão", property: "cardNumber" },
        { title: "Perfil de Uso", property: "profileName" },
        { title: "Centro de Custo", property: "costCenter" },
        { title: "Horímetro", property: "orimeter" },
        { title: "Horímetro Ant.", property: "lastOrimeter" },
        { title: "Hodômetro", property: "odometer" },
        { title: "Hodômetro Ant.", property: "lastOdometer" },
        { title: "Km percorrido", property: "kmTraveled" },
        { title: "Média de consumo efetiva", property: "effectiveAverage" },
        { title: "Média de consumo padrão", property: "standardAverage" },
        { title: "Custo Km", property: "costKmTraveled" },
        { title: "Valor Unitário da Mercadoria", property: "merchandiseUnitaryValue" },
        { title: "Tolerância de rendimento", property: "vehicleTolerance" },
        { title: "% Desvio Aferido", property: "measuredDeviation" },
    ]

    const handlePesquisar = () => {
        setShowFiltros(false);
        setLoading(true);

        VeloeService.getHistoricoTransacoes(filtro)
            .then(async (response) => {
                if (!response.success) {
                    ShowToast({ message: response.messages });
                    return;
                }

                setDataSource(response.data);
            }).catch((error) => {
                console.log(error);
                ShowToast({ message: UNKOWN_EXCEPTION });
            }).finally(() => {
                setLoading(false);
            });
    }

    return {
        loading, columns,
        showFiltros, setShowFiltros,
        filtro, setFiltro,
        dataSource,
        handlePesquisar,
    }
}