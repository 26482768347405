import { useCallback, useEffect, useState } from "react";
import { CatalogoModel } from "../../models/catalogo/CatalogoModel";
import { ICodeDataGridPagination, ICodeDataGridRow } from "../../components/CodeDataGrid/types";
import { ModalState } from "../../types";
import { FormStateType } from "../../enums";
import { ShowToast } from "../../components/CodeToast";
import { UNKOWN_EXCEPTION } from "../../utils/Constants";
import { DefaultResponse } from "../../services/types";
import { CatalogoService } from "../../services/CatalogoService";
import { DocumentViewerSate } from "../../components/DocumentViewer";

export const useCatalogosPage = () => {
    const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
    const [dataSource, setDataSource] = useState<CatalogoModel[]>([]);
    const [page, setPage] = useState<number>(1);
    const [pagination, setPagination] = useState<Omit<ICodeDataGridPagination, 'onPageChanged'>>({ page: 1, pageCount: 0, limit: 100, recordCount: 0 });
    const [selectedRow, setSelectedRow] = useState<ICodeDataGridRow>();
    const [modalState, setModalState] = useState<ModalState>({ show: false, state: FormStateType.view });
    const [showPopupDelete, setShowPopupDelete] = useState<boolean>(false);
    const [docViewerState, setDocViewerState] = useState<DocumentViewerSate>({ show: false });

    const inserir = () => {
        setModalState({ show: true, state: FormStateType.add, id: 0 });
    }

    const consultar = (readonly: boolean) => {
        if (!selectedRow || !selectedRow.item) {
            ShowToast({ message: "Nenhum catálogo selecionado." });
            return;
        }

        setModalState({ show: true, state: readonly ? FormStateType.view : FormStateType.edit, id: (selectedRow.item as CatalogoModel).id ?? undefined });
    }


    const excluir = async () => {
        try {
            const response = await CatalogoService.delete((selectedRow?.item as CatalogoModel).id!);
            if (!response.success) {
                console.log(response.messages);
                return;
            }

            await onFormLoad();
        } catch (error) {
            console.log(error);
            ShowToast({ message: UNKOWN_EXCEPTION });
        } finally {
            setShowPopupDelete(false);
        }
    };

    const fetchCatalogos = useCallback(async (): Promise<DefaultResponse<CatalogoModel>> => {
        return await CatalogoService.get({
            page: page,
            limit: 100
        });
    }, [page]);

    const onFormLoad = useCallback(async () => {
        setIsLoadingData(true);
        fetchCatalogos().then((response) => {
            setDataSource(response.data ?? []);

            setPagination({
                page: response.pagination.page,
                pageCount: response.pagination.pageCount,
                limit: response.pagination.limit,
                recordCount: response.pagination.recordCount,
            });
        }).finally(() => setIsLoadingData(false));
    }, [setDataSource, setIsLoadingData, fetchCatalogos, setPagination]);

    const onModalClose = useCallback(async () => {
        setModalState({ ...modalState, id: 0, show: false });
        await onFormLoad();
    }, [modalState, setModalState, onFormLoad]);

    const visualizarCatalogo = async (catalogo?: CatalogoModel) => {
        setDocViewerState({
            show: true,
            url: `${catalogo?.url}`,
            title: catalogo?.nome
        });
    }

    useEffect(() => {
        onFormLoad();
    }, [onFormLoad]);

    return {
        isLoadingData, dataSource, pagination, modalState, selectedRow,
        setPage, setSelectedRow, onModalClose,
        inserir, consultar, excluir,
        showPopupDelete, setShowPopupDelete,
        docViewerState, setDocViewerState, visualizarCatalogo
    }
}