import { EmpenhoModel } from '../models/empenho';
import { BaseService } from "./BaseService";
import { DefaultResponse, ServiceProps } from "./types";

const ENDPOINT = `${BaseService.BASE_URL}/cliente`;

export class EmpenhoService extends BaseService {
    public static async get(clienteId: number, props: ServiceProps = { limit: 100, page: 1 }): Promise<DefaultResponse<EmpenhoModel>> {
        return await this.GET<EmpenhoModel>(`${ENDPOINT}/${clienteId}/empenho?limit=${props.limit}&page=${props.page}`);
    }

    public static async getId(clienteId: number, id: number): Promise<DefaultResponse<EmpenhoModel>> {
        return await this.GET<EmpenhoModel>(`${ENDPOINT}/${clienteId}/empenho/${id}`);
    }

    public static async save(clienteId: number, model: EmpenhoModel): Promise<DefaultResponse<EmpenhoModel>> {
        if (!model.id) return await this.POST<EmpenhoModel>(`${ENDPOINT}/${clienteId}/empenho`, model);
        return await this.PATCH<EmpenhoModel>(`${ENDPOINT}/${clienteId}/empenho/${model.id}`, model);
    }

    public static async delete(clienteId: number, id: number): Promise<DefaultResponse<EmpenhoModel>> {
        return await this.DELETE(`${ENDPOINT}/${clienteId}/empenho/${id}`);
    }
}