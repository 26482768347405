import { useState } from "react";
import CodeUtil from "../../utils/CodeUtil";
import { ModalFormProps } from "../../components/ModalForm/types";
import { ShowToast } from "../../components/CodeToast";
import { UNKOWN_EXCEPTION } from "../../utils/Constants";
import { CotacaoService } from "../../services/CotacaoService";

export interface FormProps {
    mensagem: string | undefined;
}

export interface CotacaoCredenciadaSolicitarReavaliacaoPageProps extends ModalFormProps {
    cotacaoId: number;
    credenciadaId: number;
}

export const useCotacaoCredenciadaSolicitarReavaliacaoPage = (props: CotacaoCredenciadaSolicitarReavaliacaoPageProps) => {
    const [form, setForm] = useState<FormProps>();
    const [error, setError] = useState<string>();
    const [saving, setSaving] = useState<boolean>(false);

    const handleSolicitarReavalicacao = async (e: React.MouseEvent<HTMLButtonElement> | undefined) => {
        if (CodeUtil.isNullOrEmpty(form?.mensagem)) {
            setError("Informe o motivo da solicitação de reavaliação.");
            return;
        }

        try {
            setSaving(true);

            const response = await CotacaoService
                .solicitarReavalicacao(props.cotacaoId, props.credenciadaId, form?.mensagem!);

            if (!response.success) {
                ShowToast({ message: response.messages, error: true });
                return;
            }
            
            ShowToast({ message: "Sua solicitação foi enviada com sucesso!" });
            props.onSave?.call(this, e);
        }
        catch (error) {
            ShowToast({ message: UNKOWN_EXCEPTION, error: true });
        } finally {
            setSaving(false);
        }
    }

    return {
        handleSolicitarReavalicacao, form, setForm, error, saving
    }
}