import { useState } from "react";
import { ModalFormProps } from "../ModalForm/types";
import AuthService from "../../services/AuthService";
import { ShowToast } from "../CodeToast";
import { UNKOWN_EXCEPTION } from "../../utils/Constants";

export const useTwoFactorPopup = (props: ModalFormProps) => {

    const [password, setPassword] = useState<string | undefined>();
    const [invalid, setInvalid] = useState<boolean>(false);
    const [validating, setValidating] = useState<boolean>(false);

    const onAuthenticate = () => {
        setInvalid(false);
        setValidating(true);

        AuthService.checkPassword(password ?? "")
            .then((result) => {
                setPassword("");

                if (result) {
                    props.onSave?.call(this, undefined);
                    return;
                }

                setInvalid(true);
                setValidating(false)
            }).catch(() => {
                ShowToast({ message: UNKOWN_EXCEPTION });
            });
    }

    return {
        password, setPassword, invalid, validating,
        onAuthenticate
    }
}