import { TextInput } from "flowbite-react";
import { FC } from "react";
import { FiSearch } from "react-icons/fi";
import CodeUtil from "../../../utils/CodeUtil";
import { ICodeDataGridActionButton, ICodeDataGridHeader } from "../types";
import { CodeDataGridActionButton } from "./CodeDataGridActionButton";

export type CodeDataGridHeaderProps = {
    show: boolean
    actions?: ICodeDataGridActionButton[]
    readonly?: boolean
    simplified?: boolean
    onFilter?: (query: string) => void
} & ICodeDataGridHeader

export const CodeDataGridHeader: FC<CodeDataGridHeaderProps> = (props) => {
    if (!props.show) return <></>;

    if (props.simplified) {
        return (<div className="bg-white p-5 rounded-t-md">
            <div className="block items-center">
                <div className="w-full">
                    {!!!props.filter ? <></> : <TextInput onChange={(e) => props.onFilter?.call(this, e.currentTarget.value?.trim())}
                        addon={<FiSearch />} sizing={"sm"}
                        placeholder="Filtrar pesquisa" />}
                </div>
            </div>
        </div>);
    };

    return (
        <div className="bg-white p-5 rounded-t-md">
            {CodeUtil.isNullOrEmpty(props.title) ? <></> : <h2 className="text-lg font-semibold">{props.title}</h2>}
            {CodeUtil.isNullOrEmpty(props.subtitle) ? <></> : <p className="text-sm mt-1">{props.subtitle}</p>}

            <div className={`block md:flex items-center ${CodeUtil.isNullOrEmpty(props.title) ? "pt-0" : "pt-6"}`}>
                <div className="md:mr-auto md:mt-0 mt-3">
                    <CodeDataGridActionButton actions={props.actions} show={props.actions !== undefined && props.actions.length > 0 && !props.readonly} />
                </div>

                <div className="md:ml-auto md:mt-0 mt-3">
                    {!!!props.filter ? <></> : <TextInput onChange={(e) => props.onFilter?.call(this, e.currentTarget.value?.trim())}
                        addon={<FiSearch />} sizing={"sm"}
                        placeholder="Filtrar pesquisa" />}
                </div>
            </div>
        </div>
    );
}