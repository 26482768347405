import { CredenciadaModel } from '../models/credenciada';
import { BaseService } from "./BaseService";
import { DefaultResponse, ServiceProps } from "./types";

const ENDPOINT = `${BaseService.BASE_URL}/credenciada`;

export class CredenciadaService extends BaseService {
    public static async get(props: ServiceProps = { limit: 100, page: 1 }): Promise<DefaultResponse<CredenciadaModel>> {
        return await this.GET<CredenciadaModel>(`${ENDPOINT}?limit=${props.limit}&page=${props.page}`);
    }

    public static async getId(id: number): Promise<DefaultResponse<CredenciadaModel>> {
        return await this.GET<CredenciadaModel>(`${ENDPOINT}/${id}`);
    }

    public static async add(model: CredenciadaModel): Promise<DefaultResponse<CredenciadaModel>> {
        return await this.POST<CredenciadaModel>(`${ENDPOINT}`, model);
    }

    public static async update(model: CredenciadaModel): Promise<DefaultResponse<CredenciadaModel>> {
        return await this.PATCH<CredenciadaModel>(`${ENDPOINT}/${model.id}`, model);
    }

    public static async delete(id: number): Promise<DefaultResponse<CredenciadaModel>> {
        return await this.DELETE(`${ENDPOINT}/${id}`);
    }
}