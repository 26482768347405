import { MenuModel } from "../models/menu";
import { BaseService } from "./BaseService";
import { DefaultResponse } from "./types";

const ENDPOINT = `${BaseService.BASE_URL}/menu/usuario`;

export class MenuService extends BaseService {
    public static async get(usuario: number): Promise<DefaultResponse<MenuModel>> {
        return await this.GET<MenuModel>(`${ENDPOINT}/${usuario}`);
    }
}