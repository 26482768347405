import { useState, MouseEvent } from "react";
import { ModalFormProps } from "../../components/ModalForm/types";
import { VeloeRechargeAccountCommand } from "../../models/veloe/VeloeRechargeAccountCommand";
import { ShowToast } from "../../components/CodeToast";
import { VeloeService } from "../../services/VeloeService";
import { UNKOWN_EXCEPTION } from "../../utils/Constants";
import CodeUtil from "../../utils/CodeUtil";

export interface VeloeRecargaModalProps extends Omit<ModalFormProps, "onSave"> {
    onSave?: (e: MouseEvent<HTMLButtonElement> | undefined, processingNumber?: string | null) => void
}

export const useVeloeRecargaModalPage = (props: VeloeRecargaModalProps) => {
    const [saving, setSaving] = useState<boolean>(false);
    const [getValues, setValue] = useState<VeloeRechargeAccountCommand>({
        amount: 0,
        operationType: "C",
        cardNumber: null,
        description: null
    });

    const handleFormClose = () => {
        props.onClose?.call(this);
    }

    const validate = (): boolean => {
        if (CodeUtil.isNullOrEmpty(getValues.cardNumber?.trim())) {
            ShowToast({ message: "O número do cartão deve ser informado." });
            return false;
        }

        if (!/^\d+$/.test(getValues.cardNumber?.trim() ?? "")) {
            ShowToast({ message: "O número do cartão deve conter apenas números." });
            return false;
        }

        if (getValues.amount === 0) {
            ShowToast({ message: "O valor da recarga deve ser maior que zero." });
            return false;
        }

        return true;
    }

    const handleFormSave = (e: MouseEvent<HTMLButtonElement> | undefined) => {
        if (!validate()) return;

        setSaving(true);
        VeloeService.recargaComplementar(getValues)
            .then(async (response) => {
                if (!response.success) {
                    ShowToast({ message: `O cartão ${getValues.cardNumber} não pertence a este contrato` });
                    return;
                }

                ShowToast({ message: `Número do processo de recarga: ${response.data?.processingNumber}` })
                props.onSave?.call(this, e, response.data?.processingNumber);
            }).catch((error) => {
                console.log(error);
                ShowToast({ message: UNKOWN_EXCEPTION });
            }).finally(() => {
                setSaving(false);
            });
    }

    return {
        handleFormClose,
        handleFormSave,
        saving,
        setValue,
        getValues
    }
}