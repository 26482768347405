import { FC } from "react";
import { TabItensLancamentoProps, useTabItensLancamento } from "./TabItensLancamento.hook";
import ModalForm from "../../../components/ModalForm";
import { CodeLabel } from "../../../components/CodeLabel";
import { Select, TextInput, Textarea } from "flowbite-react";
import { CotacaoTipoItemType, FormStateType } from "../../../enums";
import { MoneyInput } from "../../../components/MoneyInput";
import CodeUtil from "../../../utils/CodeUtil";
import { Groupbox } from "../../../components/Groupbox";

export const TabItensLancamento: FC<TabItensLancamentoProps> = (props) => {
    const { item, setItem, readonly,
        getValorTotal, getValorDesconto, invalidPreco,
        handleSalvarCotacao, dataServicos
    } = useTabItensLancamento(props);

    return (
        <ModalForm {...props}
            onClose={props.onClose}
            onSave={handleSalvarCotacao}
            title={props.title}
            size="4xl">
            <div className="grid grid-cols-12 py-6 px-3 gap-y-4 gap-x-3">
                <div className="col-span-6 md:col-span-3">
                    <CodeLabel value="Código:" className="mb-1" htmlFor="inputCodigoItem" />
                    <TextInput id="inputCodigoItem"
                        value={item?.codigoFornecedor ?? ""}
                        onChange={(e) => setItem({ ...item, codigoFornecedor: e.currentTarget.value })}
                        readOnly={props.state !== FormStateType.add} />
                </div>
                <div className="col-span-6 md:col-span-4">
                    <CodeLabel value="Número de Série:" className="mb-1" htmlFor="inputNumeroSerie" />
                    <TextInput id="inputNumeroSerie"
                        value={item?.numeroSerie ?? ""}
                        onChange={(e) => setItem({ ...item, numeroSerie: e.currentTarget.value })}
                        readOnly={props.state !== FormStateType.add} />
                </div>
                <div className="hidden md:block md:col-span-5"></div>

                <div className="col-span-6 md:col-span-3">
                    <CodeLabel value="Tipo:" className="mb-1" htmlFor="selectTipoItem" />
                    <Select
                        id="selectTipoItem"
                        value={item.tipo}
                        onChange={(e) => setItem({ ...item, tipo: e.currentTarget.selectedIndex })}
                        contentEditable={false}
                        disabled={props.state !== FormStateType.add}>
                        <option key={CotacaoTipoItemType.ITEM} value={CotacaoTipoItemType.ITEM}>Peça/Produto</option>
                        <option key={CotacaoTipoItemType.SERVICO} value={CotacaoTipoItemType.SERVICO}>Serviço</option>
                    </Select>
                </div>

                <div className="col-span-6 md:col-span-9">
                    <CodeLabel value="Serviço:" className="mb-1" htmlFor="selectServicos" />
                    <Select
                        id="selectServicos"
                        value={item.servico?.id}
                        contentEditable={false}
                        disabled={item.tipo === CotacaoTipoItemType.ITEM || props.state !== FormStateType.add}
                        onChange={(e) => setItem({
                            ...item,
                            servico: {
                                id: parseInt(e.currentTarget.selectedOptions[0].value ?? 0),
                                nome: e.currentTarget.selectedOptions[0].text ?? '',
                                ativo: true,
                                isSelected: false
                            }
                        })}>
                        {dataServicos.map((item) => <option key={item.id} value={item.id}>{item.nome}</option>)}
                    </Select>
                </div>

                <div className="col-span-12 md:col-span-7">
                    <CodeLabel value="Descrição:" className="mb-1" htmlFor="inputDescricao" />
                    <TextInput id="inputDescricao"
                        value={item?.descricao ?? ""}
                        onChange={(e) => setItem({ ...item, descricao: e.currentTarget.value })}
                        readOnly={props.state !== FormStateType.add} />
                </div>

                <div className="col-span-5 md:col-span-2">
                    <CodeLabel value={`${(item.tipo === CotacaoTipoItemType.ITEM) ? "Qtde Itens:" : "Qtde Horas:"}`} className="mb-1" htmlFor="inputQuantidade" />
                    <TextInput id="inputQuantidade"
                        value={item?.quantidade ?? 0}
                        type={"number"}
                        min={0}
                        onChange={(e) => setItem({
                            ...item, 
                            quantidade: e.currentTarget.valueAsNumber,
                            desconto: getValorDesconto(e.currentTarget.valueAsNumber, item.valorUnitario),
                            valorTotal: getValorTotal({ ...item, quantidade: e.currentTarget.valueAsNumber })
                        })}
                        max={Number.MAX_VALUE}
                        readOnly={readonly} />
                </div>

                <div className="col-span-7 md:col-span-3">
                    <CodeLabel value={"Valor Unitário:"} className="mb-1" htmlFor="inputValorUnitario" />
                    <MoneyInput id="inputValorUnitario"
                        value={CodeUtil.moneyFormat(item?.valorUnitario ?? 0, false)}
                        addon={"R$"}
                        color={invalidPreco?.failure ? "failure" : "gray"}
                        helperText={invalidPreco?.message}
                        readOnly={readonly}
                        onChange={(_stringValue, numberValue) => setItem({
                            ...item, 
                            valorUnitario: numberValue,
                            desconto: getValorDesconto(item.quantidade, numberValue),
                            valorTotal: getValorTotal({ ...item, valorUnitario: numberValue })
                        })} />
                </div>

                <div className="col-span-6 md:col-span-3">
                    <CodeLabel value={'Prazo de Garantia (em dias):'} className="mb-1" htmlFor="inputGarantia" />
                    <TextInput id="inputGarantia"
                        value={item?.diasGarantia}
                        type={"number"}
                        onChange={(e) => setItem({ ...item, diasGarantia: e.currentTarget.valueAsNumber })}
                        min={0}
                        max={Number.MAX_VALUE}
                        readOnly={readonly} />
                </div>

                <div className="col-span-12">
                    <Groupbox label="Descontos">
                        <div className="col-span-6 md:col-span-3 mr-3">
                            <CodeLabel value={"Desconto:"} className="mb-1" htmlFor="inputDescontoPercentual" />
                            <MoneyInput id="inputDescontoPercentual"
                                value={CodeUtil.moneyFormat(item?.descontoPercentual ?? 0, false)}
                                addon={"%"}
                                readOnly />
                        </div>

                        <div className="col-span-6 md:col-span-3">
                            <CodeLabel value={"Desconto:"} className="mb-1" htmlFor="inputDesconto" />
                            <MoneyInput id="inputDesconto"
                                value={CodeUtil.moneyFormat(item?.desconto ?? 0, false)}
                                addon={"R$"}
                                readOnly />
                        </div>
                    </Groupbox>
                </div>

                <div className="col-span-12">
                    <Groupbox label="Despesas Acessórias">
                        <div className="col-span-4 md:col-span-3 mr-3">
                            <CodeLabel value={"Frete:"} className="mb-1" htmlFor="inputFrete" />
                            <MoneyInput id="inputFrete"
                                value={CodeUtil.moneyFormat(item?.frete ?? 0, false)}
                                addon={"R$"}
                                readOnly={readonly}
                                onChange={(_stringValue, numberValue) => setItem({ ...item, frete: numberValue })} />
                        </div>

                        <div className="col-span-4 md:col-span-3 mr-3">
                            <CodeLabel value={"Seguro:"} className="mb-1" htmlFor="inputSeguro" />
                            <MoneyInput id="inputSeguro"
                                value={CodeUtil.moneyFormat(item?.seguro ?? 0, false)}
                                addon={"R$"}
                                readOnly={readonly}
                                onChange={(_stringValue, numberValue) => setItem({ ...item, seguro: numberValue })} />
                        </div>

                        <div className="col-span-4 md:col-span-3 mr-3">
                            <CodeLabel value={"Outras Despesas:"} className="mb-1" htmlFor="inputOutrasDespesas" />
                            <MoneyInput id="inputOutrasDespesas"
                                value={CodeUtil.moneyFormat(item?.outrasDespesas ?? 0, false)}
                                addon={"R$"}
                                readOnly={readonly}
                                onChange={(_stringValue, numberValue) => setItem({ ...item, outrasDespesas: numberValue })} />
                        </div>
                    </Groupbox>
                </div>

                <div className="col-span-4 md:col-span-3">
                    <CodeLabel value={"Valor Total:"} className="mb-1" htmlFor="inputSubtotal" />
                    <MoneyInput id="inputSubtotal"
                        value={CodeUtil.moneyFormat(getValorTotal(), false)}
                        addon={"R$"}
                        readOnly={props.state !== FormStateType.add} />
                </div>

                <div className="col-span-8 md:col-span-9" />

                <div className="col-span-12">
                    <Groupbox label="Observações">
                        <div className="col-span-6 mr-3">
                            <CodeLabel value="Observações do Cliente:" className="mb-1" htmlFor="inputObservacoesCliente" />
                            <Textarea id="inputObservacoesCliente"
                                value={item.observacoesCliente ?? ""}
                                readOnly={true}
                                draggable={false}
                                rows={5} />
                        </div>

                        <div className="col-span-6">
                            <CodeLabel value="Observações:" className="mb-1" htmlFor="inputObservacoes" />
                            <Textarea id="inputObservacoes"
                                value={item.observacoes ?? ""}
                                readOnly={readonly}
                                draggable={false}
                                rows={5}
                                onChange={(e) => setItem({ ...item, observacoes: e.currentTarget.value })} />
                        </div>
                    </Groupbox>
                </div>
            </div>
        </ModalForm>
    );
};