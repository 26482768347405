import { AxiosError } from "axios";
import { useCallback, useEffect, useState } from "react";
import { ShowToast } from "../../components/CodeToast";
import { FormStateType } from "../../enums";
import { defaultUsuario, UsuarioModel, validateUsuario } from "../../models/usuario";
import { UsuarioService } from "../../services/UsuarioService";
import CodeUtil from "../../utils/CodeUtil";
import { KeyboardEvent } from "react";
import { SessionManager } from "../../utils/SessionManager";
import { ModalFormProps } from "../../components/ModalForm/types";
import { UsuarioPermissaoModel } from "../../models/usuario_permissao";
import { ClienteModel, defaultCliente } from "../../models/cliente/ClienteModel";

export const useUsuariosCadastroPage = (props: ModalFormProps) => {
    const [errors, setErrors] = useState<string[]>([]);
    const [saving, setSaving] = useState<boolean>(false);
    const [formLoaded, setFormLoaded] = useState<boolean>(false);
    const [model, setModel] = useState<UsuarioModel>(defaultUsuario);
    const isReadOnly = props.state === FormStateType.view;
    const sessionUser = SessionManager.getSession().user;

    const onFormClose = () => {
        setErrors([]);
        setFormLoaded(false);
        props.onClose?.call(this);
    }

    const onFormLoad = useCallback(async () => {
        if (!props.show || formLoaded) return;

        if (props.id !== null && props.id !== undefined && props.id > 0) {
            let response = await UsuarioService.getId(props.id);
            if (!response.success) {
                ShowToast({ message: CodeUtil.arrayToStr(response.messages) });
                return;
            }

            setModel(response.data[0]);
        } else setModel(defaultUsuario);

        setFormLoaded(true);
    }, [props.id, props.show, formLoaded, setFormLoaded]);

    const onFormSave = async (e: React.MouseEvent<HTMLButtonElement> | undefined) => {

        let validationResult = validateUsuario(model);
        setErrors(validationResult);

        if (validationResult.length > 0) return;

        try {
            setSaving(true);

            const postModel = model;
            postModel.permitirAprovarCotacao ??= false;
            postModel.permitirReprovarCotacao ??= false;
            postModel.isBloqueado ??= false;
            postModel.permissoes = model.permissoes;

            if (sessionUser?.clienteId) postModel.clienteId = model.clienteId ?? sessionUser.clienteId;
            if (sessionUser?.credenciadaId) postModel.credenciadaId = model.credenciadaId ?? sessionUser.credenciadaId;
            if (sessionUser?.gestorContaId && !model.clienteId) 
                postModel.gestorContaId = model.gestorContaId ?? sessionUser.gestorContaId;
            else
                postModel.gestorContaId = null;
            

            if (process.env.NODE_ENV !== "production") console.log(postModel);

            let response = props.state === FormStateType.add ?
                await UsuarioService.add(postModel) :
                await UsuarioService.update(postModel);

            if (!response.success) {
                setErrors(response.messages);
                return;
            }

            props.onSave?.call(this, e);
            onFormClose();
        } catch (error) {
            setErrors([(error as AxiosError).response?.data as string])
        }
        finally {
            setSaving(false);
        }
    }

    const generateUserName = () => {
        if (props.state !== FormStateType.add) return;

        let nomeUsuario = CodeUtil.removeSpaces(`${model?.nome.toLowerCase()}.${model?.sobrenome?.toLowerCase()}`);
        if (!CodeUtil.isNullOrEmpty(model?.nome) && !CodeUtil.isNullOrEmpty(model?.sobrenome)) {
            setModel({ ...model, nomeUsuario: nomeUsuario ?? '' })
        }
    }

    useEffect(() => {
        onFormLoad();
    });

    const handleKeydownEvent = (e: KeyboardEvent<HTMLDivElement>) => {
        if (e.code.toLowerCase() === "escape") {
            onFormClose();
            return;
        }
    }

    const handleChangePermissao = (e: React.ChangeEvent<HTMLInputElement>, value: UsuarioPermissaoModel, permissao: string) => {
        if (!model.permissoes) return;

        const permissaoIndex = model.permissoes?.findIndex(x => x.menu === value.menu);
        if (permissaoIndex === undefined || permissaoIndex < 0) return;

        const permissaoItem = model.permissoes![permissaoIndex];
        if (!permissaoItem) return;

        (permissaoItem as any)[permissao] = e.target.checked;
        permissaoItem.state = 'Modified';
    }

    const handleSearchCliente = (result: unknown): void => {
        if (!result) {
            setModel({
                ...model,
                cliente: null,
                clienteId: null
            });
            return;
        }

        setModel({
            ...model,
            clienteId: (result as ClienteModel).id,
            cliente: {
                ...defaultCliente,
                ...(result as ClienteModel),
                id: (result as ClienteModel).id,
            },
        });
    };
    return {
        handleKeydownEvent, generateUserName,
        onFormClose, onFormLoad, onFormSave, sessionUser,
        setModel, setErrors, setSaving, setFormLoaded,
        errors, saving, formLoaded, model, isReadOnly, handleChangePermissao, handleSearchCliente
    }
}