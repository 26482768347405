import { Select, Textarea, TextInput } from "flowbite-react";
import { FC } from "react";
import { CodeLabel } from "../../../components/CodeLabel";
import ModalForm from "../../../components/ModalForm";
import { CotacaoTipoItemType, FormStateType, UserType } from "../../../enums";
import { findValidationFailure, findValidationMessage } from "../../../types";
import { ModalTabItensLancamentoProps, useModalTabItensLancamento } from "./ModalTabItensLancamento.hook";
import CodeUtil from "../../../utils/CodeUtil";
import { MoneyInput } from "../../../components/MoneyInput";
import { useSessionContext } from "../../../contexts/SessionContext";
import { CodePesquisa } from "../../../components/CodePesquisa";
import { useProdutoColumns } from "../../../hooks";

export const ModalTabItensLancamento: FC<ModalTabItensLancamentoProps> = (props) => {
    const readonly = props.state === FormStateType.view;
    const {
        item, setItem, dataServicos, visualizarCatalogo,
        handleButtonClose, handleButtonSave, onSearchProduto, errors
    } = useModalTabItensLancamento(props);
    const currentSession = useSessionContext();
    const userSession = currentSession.session?.user;
    const { columns: produtoColumns } = useProdutoColumns();

    return (
        <ModalForm {...props}
            onClose={handleButtonClose}
            onSave={handleButtonSave}
            title="Detalhamento do Item"
            size="3xl">
            <div className="grid grid-cols-12 py-6 px-3 gap-y-4 gap-x-3">
                {!visualizarCatalogo && <div className="col-span-6 md:col-span-3">
                    <CodeLabel value="Código:" className="mb-1" htmlFor="inputCodigoItem" />

                    <TextInput id="inputCodigoItem"
                        value={item?.codigoFornecedor ?? ""}
                        maxLength={40}
                        helperText={findValidationMessage(errors, "codigoFornecedor")}
                        color={findValidationFailure(errors, "codigoFornecedor")}
                        readOnly={readonly}
                        onChange={(e) => setItem({ ...item, codigoFornecedor: e.target.value })} />
                </div>}

                {visualizarCatalogo &&
                    <div className="col-span-6 md:col-span-3">
                        <CodePesquisa
                            endpoint={"produto"}
                            params={`&fabricante=${props.fabricanteId}`}
                            endpointValue={`produto/fabricante/${props.fabricanteId}/codigo`}
                            modal={{
                                title: "Selecione o produto",
                                dataColumns: produtoColumns
                            }}
                            valueField={{ label: "Código:", property: "codigoFornecedor", value: item.codigoFornecedor ?? "" }}
                            textField={{ label: "Nome:", property: "nome", value: item.produto?.nome }}
                            onSearch={onSearchProduto}
                            hiddenTextField
                            disableAutoModal />
                    </div>}

                <div className="col-span-6 md:col-span-4">
                    <CodeLabel value="Número de Série:" className="mb-1" htmlFor="inputNumeroSerie" />
                    <TextInput id="inputNumeroSerie"
                        value={item?.numeroSerie ?? ""}
                        maxLength={100}
                        helperText={findValidationMessage(errors, "numeroSerie")}
                        color={findValidationFailure(errors, "numeroSerie")}
                        readOnly={readonly || item.tipo === CotacaoTipoItemType.SERVICO}
                        onChange={(e) => setItem({ ...item, numeroSerie: e.currentTarget.value?.toUpperCase() })} />
                </div>
                <div className="hidden md:block md:col-span-5"></div>

                <div className="col-span-6 md:col-span-3">
                    <CodeLabel value="Tipo:" className="mb-1" htmlFor="selectTipoItem" />
                    <Select
                        id="selectTipoItem"
                        value={item.tipo}
                        contentEditable={false}
                        disabled={props.state === FormStateType.view}
                        onChange={(e) => setItem({ ...item, tipo: e.currentTarget.selectedIndex })}>
                        <option key={CotacaoTipoItemType.ITEM} value={CotacaoTipoItemType.ITEM}>Peça/Produto</option>
                        <option key={CotacaoTipoItemType.SERVICO} value={CotacaoTipoItemType.SERVICO}>Serviço</option>
                    </Select>
                </div>

                <div className="col-span-6 md:col-span-9">
                    <CodeLabel value="Serviço:" className="mb-1" htmlFor="selectServicos" />
                    <Select
                        id="selectServicos"
                        value={item.servico?.id}
                        contentEditable={false}
                        disabled={props.state === FormStateType.view || item.tipo === CotacaoTipoItemType.ITEM}
                        onChange={(e) => setItem({
                            ...item,
                            descricao: CodeUtil.isNullOrEmpty(item.descricao) ? e.currentTarget.selectedOptions[0].text ?? '' : item.descricao,
                            servico: { id: parseInt(e.currentTarget.selectedOptions[0].value ?? 0), nome: e.currentTarget.selectedOptions[0].text ?? '' }
                        })}>
                        {dataServicos.map((item) => <option key={item.id} value={item.id}>{item.nome}</option>)}
                    </Select>
                </div>

                <div className="col-span-12 md:col-span-7">
                    <CodeLabel value="Descrição:" className="mb-1" htmlFor="inputDescricao" />
                    <TextInput id="inputDescricao"
                        value={item?.descricao ?? ""}
                        readOnly={readonly}
                        maxLength={120}
                        helperText={findValidationMessage(errors, "descricao")}
                        color={findValidationFailure(errors, "descricao")}
                        onChange={(e) => setItem({ ...item, descricao: e.currentTarget.value?.toUpperCase() })} />
                </div>

                <div className="col-span-5 md:col-span-2">
                    <CodeLabel value={`${(item.tipo === CotacaoTipoItemType.ITEM) ? "Qtde Itens:" : "Qtde Horas:"}`} className="mb-1" htmlFor="inputQuantidade" />
                    <TextInput id="inputQuantidade"
                        value={item?.quantidade ?? 0}
                        type={"number"}
                        min={0}
                        max={Number.MAX_VALUE}
                        readOnly={readonly}
                        onChange={(e) => setItem({ ...item, quantidade: e.currentTarget.valueAsNumber ?? 0 })} />
                </div>

                {userSession?.tipoUsuario !== UserType.dealer &&
                    <div className="col-span-7 md:col-span-3">
                        <CodeLabel value={"Preço Fabricante:"} className="mb-1" htmlFor="inputPrecoFabricante" />
                        <MoneyInput id="inputPrecoFabricante"
                            value={CodeUtil.moneyFormat(item?.precoFabricante ?? 0, false)}
                            addon={"R$"}
                            readOnly={Boolean(item.produto?.id)}
                            onChange={(_stringValue, numberValue) => setItem({ ...item, precoFabricante: numberValue })} />
                    </div>}

                {userSession?.tipoUsuario === UserType.admin &&
                    <div className="col-span-5 md:col-span-3">
                        <CodeLabel value={"Comissão Rodar:"} className="mb-1" htmlFor="inputComissao" />
                        <MoneyInput id="inputComissao"
                            value={CodeUtil.moneyFormat(item?.percentualComissao ?? 0, false)}
                            addon={"%"}
                            readOnly={readonly}
                            onChange={(_stringValue, numberValue) => setItem({ ...item, percentualComissao: numberValue })} />
                    </div>}

                <div className="col-span-12">
                    <CodeLabel value="Observações:" className="mb-1" htmlFor="inputObservacoes" />
                    <Textarea id="inputObservacoes"
                        value={item.observacoes ?? ""}
                        readOnly={readonly}
                        draggable={false}
                        rows={5}
                        onChange={(e) => setItem({ ...item, observacoes: e.currentTarget.value })} />
                </div>
            </div>
        </ModalForm >
    );
};