import { useCallback, useEffect, useState } from "react";
import { CatalogoModel } from "../../../models/catalogo/CatalogoModel";
import { ModalFormTabProps } from "../../../components/ModalForm/types";
import { CotacaoModel } from "../../../models/cotacao/CotacaoModel";
import { CatalogoService } from "../../../services/CatalogoService";
import { DefaultResponse } from "../../../services/types";
import { DocumentViewerSate } from "../../../components/DocumentViewer";

export const useModalTabCatalogos = (props: ModalFormTabProps<CotacaoModel>) => {
    const [datasource, setDatasource] = useState<CatalogoModel[]>([]);
    const [formLoaded, setFormLoaded] = useState<boolean>(false);
    const [docViewerState, setDocViewerState] = useState<DocumentViewerSate>({ show: false });

    const fetchCatalogos = useCallback(async (): Promise<DefaultResponse<CatalogoModel>> => {
        return await CatalogoService.get();
    }, []);

    const onFormLoad = useCallback(async () => {
        if (formLoaded) return;

        fetchCatalogos().then((response) => {
            setDatasource(response.data ?? []);
        }).finally(() => setFormLoaded(true));
    }, [fetchCatalogos, formLoaded, setFormLoaded]);
    
    useEffect(() => {
        onFormLoad();
    });

    return {
        datasource, docViewerState, setDocViewerState
    }
}