import { FC } from "react";
import { MdOutlineArrowBackIos, MdOutlineArrowForwardIos } from "react-icons/md";
import { ICodeDataGridPagination } from "../types";

export type CodeDataGridPaginationProps = {

} & ICodeDataGridPagination

export const CodeDataGridPagination: FC<CodeDataGridPaginationProps> = (props) => {
    const from = ((props.page * props.limit) - props.limit) + 1;
    const to = (props.page * props.limit << props.recordCount) && (props.page === props.pageCount) ? props.recordCount : props.page * props.limit;

    return (
        <div className="flex w-full place-items-center py-1.5">
            <div className="mr-auto">
                <span className="text-sm">Exibindo página <span className="font-bold">{props.page}</span> de <span className="font-bold">{props.pageCount}</span></span>
            </div>

            <div className="ml-auto">
                <div className="flex items-center space-x-6">
                    <span className="text-sm"><span className="font-bold">{from}</span>-<span className="font-bold">{to}</span> de <span className="font-bold">{props.recordCount}</span></span>
                    <span className="flex space-x-1.5">
                        <button disabled={props.page <= 1} type="button" className="flex items-center place-items-center enabled:hover:bg-gray-200 enabled:active:bg-gray-300 rounded-full p-2.5 
                    cursor-pointer disabled:text-gray-300 disabled:cursor-not-allowed" onClick={() => props.onPageChanged?.call(this, props.page - 1)}>
                            <MdOutlineArrowBackIos />
                        </button>
                        <button disabled={props.page >= props.pageCount} type="button" className="flex items-center place-items-center enabled:hover:bg-gray-200 enabled:active:bg-gray-300 rounded-full p-2.5 
                    cursor-pointer disabled:text-gray-300 disabled:cursor-not-allowed" onClick={() => props.onPageChanged?.call(this, props.page + 1)}>
                            <MdOutlineArrowForwardIos />
                        </button>
                    </span>
                </div>
            </div>
        </div>
    );
};