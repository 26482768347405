import { TextInput } from "flowbite-react";
import { ChangeEvent, FocusEvent, FC } from "react";
import CodeUtil from "../../utils/CodeUtil";
import { MoneyInputProps } from "./types";

export const MoneyInput: FC<MoneyInputProps> = (props) => {
    const handleChangeValue = (e: ChangeEvent<HTMLInputElement>) => {
        let value = CodeUtil.removeSpecialChars(e.currentTarget.value.replaceAll(".", "").replaceAll(",", ""));
        value = value.padStart((props.precision ?? 2) + 1, "0");

        if (!CodeUtil.isNullOrEmpty(value) && !/^[0-9]+$/.test(value?.trim())) {
            e.preventDefault();
            return;
        }

        let preComma = value.substring(0, value.length - (props.precision ?? 2));
        let postComma = value.substring(value.length - (props.precision ?? 2), value.length);

        props.onChange?.call(this, `${preComma},${postComma}`, Number(`${preComma}.${postComma}`));
    }

    const handleBlur = (e: FocusEvent<HTMLInputElement>) => props.onLeave?.call(this, e);

    return (
        <TextInput
            addon={props.addon ?? "R$"}
            id={props.id}
            value={props.value}
            readOnly={props.readOnly}
            placeholder={props.placeHolder}
            type={props.type}
            // pattern="^[0-9]+$"
            maxLength={props.maxLength}
            disabled={props.disabled}
            helperText={props.helperText}
            color={props.color}
            onChange={handleChangeValue}
            onBlur={handleBlur}
        />
    );
};