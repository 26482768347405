import { FC } from "react";
import SidePanel from "../../../components/SidePanel";
import Logo from "../../../images/logo.png";
import { useForgotPasswordPage } from "./ForgotPasswordPage.hook";
import { Button, Label, Spinner, TextInput } from "flowbite-react";

export const ForgotPasswordPage: FC = () => {
    const { errors, register, resetPassword, handleSubmit, isLoading } = useForgotPasswordPage();

    return (
        <div className="login-page">
            <SidePanel>
                <form id="formResetPassword" className="mt-8 w-full" onSubmit={handleSubmit(resetPassword)}>
                    <div className="flex justify-center">
                        <img src={Logo} className="h-24 md:h-20 mb-12" alt="Rodar Logo" />
                    </div>

                    <div className="form-control mt-5">
                        <div className="mb-1">
                            <Label htmlFor="inputSenha" value="Nova Senha:" />
                        </div>
                        <TextInput id="inputSenha" type="password" placeholder="Digite sua nova senha" {...register('novaSenha', {
                            required: {
                                message: "Preenchimento obrigatório", value: true
                            }
                        })} />

                        {errors.novaSenha?.message && <label className="text-red-600 text-xs ml-1.5" children={errors.novaSenha.message} />}
                    </div>

                    <div className="form-control mt-5">
                        <div className="mb-1">
                            <Label htmlFor="inputSenhaConfirmacao" value="Confirme a Senha:" />
                        </div>
                        <TextInput id="inputSenhaConfirmacao" type="password" placeholder="Confirme sua senha" {...register('novaSenhaConfirmacao', { required: false })} />
                        {errors.novaSenhaConfirmacao?.message && <label className="text-red-600 text-xs ml-1.5" children={errors.novaSenhaConfirmacao.message} />}
                    </div>


                    <div className="mt-5 flex justify-end">
                        <Button size="sm" color="dark" type="submit" disabled={isLoading}>
                            {isLoading &&
                                <div className="mr-3">
                                    <Spinner size="sm" light />
                                </div>}
                            Redefinir Senha
                        </Button>
                    </div>
                </form>
            </SidePanel>
        </div>
    )
}