import { useCallback, useEffect, useState, MouseEvent } from "react";
import { ClienteFrotaCadastroFormProps } from "./types"
import { ClienteVeiculoModel, defaultClienteVeiculo } from "../../models/cliente_veiculo/ClienteVeiculoModel";
import { FluentValidator, FormProps } from "../../types";
import { FormStateType } from "../../enums";
import { ClienteVeiculoService } from "../../services/ClienteVeiculoService";
import { ShowToast } from "../../components/CodeToast";
import { UNKOWN_EXCEPTION } from "../../utils/Constants";
import { VeiculoMarcaService } from "../../services/VeiculoMarcaService";
import CodeUtil from "../../utils/CodeUtil";
import { VeiculoMarcaModel } from "../../models/veiculo_marca";
import { VeiculoModeloModel } from "../../models/veiculo_modelo";
import { VeiculoModeloService } from "../../services/VeiculoModeloService";
import { MapAsCommand } from "../../commands/ClienteVeiculoCommand";
import { useSessionContext } from "../../contexts/SessionContext";

export const useClientesFrotasCadastroPage = (props: ClienteFrotaCadastroFormProps) => {
    const [model, setModel] = useState<ClienteVeiculoModel>(defaultClienteVeiculo);
    const [formProps, setFormProps] = useState<FormProps>({ loaded: false, readonly: props.state === FormStateType.view });
    const [arrayMarcas, setArrayMarcas] = useState<VeiculoMarcaModel[]>([]);
    const [arrayModelos, setArrayModelos] = useState<VeiculoModeloModel[]>([]);
    const [arrayYears, setArrayYears] = useState<number[]>([]);
    const [errors, setErrors] = useState<FluentValidator[]>([]);
    const readonly = props.state === FormStateType.view;
    const currentSession = useSessionContext();
    const userSession = currentSession.session?.user;

    const onLoadMarcas = useCallback(async () => {
        let response = await VeiculoMarcaService.get({ page: 1, limit: 99999999 });
        if (!response.success) {
            ShowToast({ message: CodeUtil.arrayToStr(response.messages) });
            setArrayMarcas([]);
            return;
        }

        setArrayMarcas(response.data);
    }, [setArrayMarcas]);

    const onLoadYearSelect = useCallback(() => {
        const currentYear = (new Date()).getFullYear() + 1;
        setArrayYears(Array.from(new Array(50), (val, index) => currentYear - index));
    }, [setArrayYears]);

    const onLoadModelos = useCallback(async (marcaId: number): Promise<VeiculoModeloModel[]> => {

        let response = await VeiculoModeloService.get({
            page: 1,
            limit: 9999999,
            marcaId: marcaId,
        });

        if (!response.success) {
            ShowToast({ message: CodeUtil.arrayToStr(response.messages) });
            setArrayModelos([]);
            return [];
        }

        setArrayModelos(response.data);
        return response.data ?? [];
    }, [setArrayModelos]);

    const onFormLoad = useCallback(async () => {
        if (!props.show || formProps.loaded) return;

        try {
            await onLoadMarcas();
            await onLoadModelos(model.marca?.id ?? 0);
            onLoadYearSelect();

            if (props.id !== null && props.id !== undefined && props.id > 0) {
                const response = await ClienteVeiculoService.getId(props.id ?? 0);
                if (!response.success) {
                    ShowToast({ message: response.messages });
                    props.onClose?.call(this);
                    return;
                }

                setModel(response.data[0]);
            } else setModel(defaultClienteVeiculo);

            setFormProps({ ...formProps, loaded: true });
        } catch (error) {
            console.log(error);
            ShowToast({ message: UNKOWN_EXCEPTION });
        }
    }, [props, formProps, setFormProps,
        onLoadMarcas, onLoadModelos, onLoadYearSelect,
        model.marca?.id]);

    const onFormClose = () => {
        setFormProps({ ...formProps, loaded: false });
        props.onClose?.call(this);
    };

    const onFormSave = async (e: MouseEvent<HTMLButtonElement> | undefined) => {
        if (!await saveVeiculo()) return;

        props.onSave?.call(this, e);
        setFormProps({ ...formProps, loaded: false });
    };

    const fetchFormProps = (): FormProps => formProps;

    const saveVeiculo = async (): Promise<boolean> => {
        try {
            setFormProps({ ...formProps, saving: true });

            if (props.state === FormStateType.edit)
                return await update();

            if (props.state === FormStateType.add)
                return await insert();

            return false;
        } catch (error) {
            console.log(error);
            ShowToast({ message: UNKOWN_EXCEPTION });
            return false;
        } finally {
            setFormProps({ ...formProps, saving: false });
        }
    }

    const insert = async (): Promise<boolean> => {
        try {
            let command = MapAsCommand(model)!;
            command.clienteId = userSession?.clienteId ?? 0;

            const response = await ClienteVeiculoService.add(command);
            if (!response.success)
                ShowToast({ message: response.messages });
            return response.success;
        } catch (error) {
            console.log(error);
            ShowToast({ message: UNKOWN_EXCEPTION });
            return false;
        }
    };

    const update = async (): Promise<boolean> => {
        try {
            let command = MapAsCommand(model)!;
            command.clienteId = userSession?.clienteId ?? 0;

            const response = await ClienteVeiculoService.update(command);
            if (!response.success)
                ShowToast({ message: response.messages });
            return response.success;
        } catch (error) {
            console.log(error);
            ShowToast({ message: UNKOWN_EXCEPTION });
            return false;
        }
    };



    useEffect(() => {
        onFormLoad();
    });

    return {
        onFormClose, onFormSave, onFormLoad, fetchFormProps,
        model, setModel,
        arrayMarcas, onLoadMarcas,
        arrayModelos, onLoadModelos,
        arrayYears, onLoadYearSelect,
        readonly,
        errors, setErrors
    }
}