import { useCallback, useEffect, useState } from "react";
import { ICodeDataGridPagination, ICodeDataGridRow } from "../../components/CodeDataGrid/types";
import { ShowToast } from "../../components/CodeToast";
import { CotacaoStatusType, FormStateType } from "../../enums";
import { CotacaoModel, getCotacaoStatus, getCotacaoStatusColor } from "../../models/cotacao/CotacaoModel";
import { CotacaoService } from "../../services/CotacaoService";
import { DefaultResponse } from "../../services/types";
import { UNKOWN_EXCEPTION } from "../../utils/Constants";
import { Badge } from "../../components/Badge";
import { IFiltroCotacao, ModalState } from "../../types";
import CodeUtil from "../../utils/CodeUtil";
import { format } from "fecha";
import { SessionManager } from "../../utils/SessionManager";

export const useCotacaoPage = () => {
    const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
    const [dataSource, setDataSource] = useState<CotacaoModel[]>([]);
    const [page, setPage] = useState<number>(1);
    const [pagination, setPagination] = useState<Omit<ICodeDataGridPagination, 'onPageChanged'>>({ page: 1, pageCount: 0, limit: 100, recordCount: 0 });
    const [selectedRow, setSelectedRow] = useState<ICodeDataGridRow>();
    const [modalState, setModalState] = useState<ModalState>({ show: false, state: FormStateType.view });
    const [showPopupDelete, setShowPopupDelete] = useState<boolean>(false);
    const [hiddenMoreFilters, setHiddenMoreFilters] = useState<boolean>(true);
    const sessionUser = SessionManager.getSession().user;

    const [filtros, setFiltros] = useState<IFiltroCotacao>({
        dataInicial: format(CodeUtil.addDays(-30, new Date(Date.now())), 'YYYY-MM-DD'),
        dataFinal: format(new Date(Date.now()), 'YYYY-MM-DD'),
        status: [CotacaoStatusType.AGUARDANDO, CotacaoStatusType.COTACAO_ENVIADA]
    });


    const lancarCotacao = () => {
        setModalState({ show: true, state: FormStateType.add, id: 0 });
    }

    const consultarCotacao = (readonly: boolean) => {
        if (!selectedRow || !selectedRow.item) {
            ShowToast({ message: "Nenhuma cotação selecionada." });
            return;
        }

        setModalState({ show: true, state: readonly ? FormStateType.view : FormStateType.edit, id: (selectedRow.item as CotacaoModel).id });
    }


    const cancelarCotacao = async () => {
        try {
            const response = await CotacaoService.delete((selectedRow?.item as CotacaoModel).id!);
            if (!response.success) {
                console.log(response.messages);
                return;
            }

            await onFormLoad();
        } catch (error) {
            console.log(error);
            ShowToast({ message: UNKOWN_EXCEPTION });
        } finally {
            setShowPopupDelete(false);
        }
    };

    const fetchCotacao = useCallback(async (filter?: IFiltroCotacao): Promise<DefaultResponse<CotacaoModel>> => {
        const _filtros = filter ?? filtros;
        const defaultStatus = [CotacaoStatusType.AGUARDANDO, CotacaoStatusType.COTACAO_ENVIADA, CotacaoStatusType.REPROVADA, CotacaoStatusType.CANCELADA];

        return await CotacaoService.get({
            page: page,
            limit: 100,
            credenciadaId: sessionUser?.credenciadaId,
            clienteId: sessionUser?.clienteId,
            dataCadastroIni: _filtros.dataInicial,
            dataCadastroFim: _filtros.dataFinal,
            status: _filtros.status?.length === 0 ? defaultStatus : _filtros.status
        });
    }, [filtros, page, sessionUser?.credenciadaId, sessionUser?.clienteId]);

    const onFormLoad = useCallback(async (filter?: IFiltroCotacao) => {
        setIsLoadingData(true);

        const response = await fetchCotacao(filter);
        if (response.success) {
            setDataSource(response.data ?? []);

            if (process.env.NODE_ENV !== "production") console.log(response.data);

            setPagination({
                page: response.pagination.page,
                pageCount: response.pagination.pageCount,
                limit: response.pagination.limit,
                recordCount: response.pagination.recordCount,
            });
        }

        setIsLoadingData(false);
    }, [setDataSource, setIsLoadingData, fetchCotacao, setPagination]);

    const onModalClose = useCallback(async () => {
        setModalState({ ...modalState, id: 0, show: false });
        await onFormLoad();
    }, [modalState, setModalState, onFormLoad]);

    const onFormatStatusColumn = (model: CotacaoModel): JSX.Element => {
        return <span><Badge color={getCotacaoStatusColor(model.status)} value={getCotacaoStatus(model.status)} /></span>;
    }

    const onApplyFilter = async (value: IFiltroCotacao) => {
        await onFormLoad(value);
        setHiddenMoreFilters(true);
    }

    useEffect(() => {
        onFormLoad();
    }, [onFormLoad]);

    return {
        isLoadingData, dataSource, pagination, modalState, selectedRow,
        setPage, setSelectedRow, onModalClose,
        lancarCotacao, consultarCotacao, cancelarCotacao,
        onFormatStatusColumn,
        filtros, setFiltros, hiddenMoreFilters, setHiddenMoreFilters, onApplyFilter,
        showPopupDelete, setShowPopupDelete, sessionUser, 
    }
}
