export enum CTAPanelButtonType {
    CTA = 'cta',
    FLAT = 'flat',
    DESTRUCTIVE = 'destructive',
    DROPDOWN = 'dropdown'
}

export type CTAPanelButtonProps = {
    label: string
    disabled?: boolean
    visible?: boolean
    type: CTAPanelButtonType
    action: () => void
    children?: JSX.Element
}

export type CTAPanelProps = {
    insideDataGridCaption?: boolean;
    margin?: string; 
    buttons: CTAPanelButtonProps[];
}