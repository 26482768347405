import { CotacaoStatusType } from "../enums";
import { ViewCotacaoCredenciadaModel } from "../models/cotacao/ViewCotacaoCredenciada";
import { BaseService } from "./BaseService";
import { CotacaoCredenciadaServiceProps } from "./requests/index.";
import { DefaultResponse } from "./types";

const ENDPOINT = `${BaseService.BASE_URL}/cotacao_credenciada`;

export class CotacaoCredenciadaService extends BaseService {
    public static async get(credenciadaId: number, props: CotacaoCredenciadaServiceProps = { limit: 100, page: 1 }): Promise<DefaultResponse<ViewCotacaoCredenciadaModel>> {
        let query = `?limit=${props.limit}&page=${props.page}`;

        if (props.nomeCliente) query += `&nomeCliente=${props.nomeCliente}`;
        if (props.cnpjCliente) query += `&cnpjCliente=${props.cnpjCliente}`;
        if (props.placa) query += `&placa=${props.placa}`;
        if (props.numero) query += `&numero=${props.numero}`;
        if (props.clienteId) query += `&clienteId=${props.clienteId}`;
        if (props.dataCadastroIni ?? false) query += `&dataCadastroIni=${props.dataCadastroIni}`;
        if (props.dataCadastroFim ?? false) query += `&dataCadastroFim=${props.dataCadastroFim}`;
        if (props.dataAlteracaoIni ?? false) query += `&dataAlteracaoIni=${props.dataAlteracaoIni}`;
        if (props.dataAlteracaoFim ?? false) query += `&dataInicial=${props.dataAlteracaoFim}`;
        props.status?.forEach(element => query += `&status=${element as number}`);

        return await this.GET<ViewCotacaoCredenciadaModel>(`${ENDPOINT}/${credenciadaId}${query}`);
    }

    public static async getAll(props: CotacaoCredenciadaServiceProps = { limit: 100, page: 1 }): Promise<DefaultResponse<ViewCotacaoCredenciadaModel>> {
        let query = `?limit=${props.limit}&page=${props.page}`;

        if (props.nomeCliente) query += `&nomeCliente=${props.nomeCliente}`;
        if (props.cnpjCliente) query += `&cnpjCliente=${props.cnpjCliente}`;
        if (props.placa) query += `&placa=${props.placa}`;
        if (props.numero) query += `&numero=${props.numero}`;
        if (props.clienteId) query += `&clienteId=${props.clienteId}`;
        if (props.credenciadaId) query += `&credenciadaId=${props.credenciadaId}`;
        if (props.dataCadastroIni ?? false) query += `&dataCadastroIni=${props.dataCadastroIni}`;
        if (props.dataCadastroFim ?? false) query += `&dataCadastroFim=${props.dataCadastroFim}`;
        if (props.dataAlteracaoIni ?? false) query += `&dataAlteracaoIni=${props.dataAlteracaoIni}`;
        if (props.dataAlteracaoFim ?? false) query += `&dataInicial=${props.dataAlteracaoFim}`;
        props.status?.forEach(element => query += `&status=${element as number}`);

        return await this.GET<ViewCotacaoCredenciadaModel>(`${ENDPOINT}${query}`);
    }

    public static async getId(cotacaoId: number, credenciadaId: number, somenteAprovados: boolean = false): Promise<DefaultResponse<ViewCotacaoCredenciadaModel>> {
        return await this.GET<ViewCotacaoCredenciadaModel>(`${ENDPOINT}/${credenciadaId}/cotacao/${cotacaoId}
            ${(somenteAprovados ? "?somenteAprovados=1" : "")}`);
    }

    public static async insert(command: any): Promise<DefaultResponse> {
        return await this.POST(`${ENDPOINT}`, command);
    }

    public static async update(cotacaoId: number, credenciadaId: number, data: unknown): Promise<DefaultResponse> {
        return await this.PATCH(`${ENDPOINT}/${credenciadaId}/cotacao/${cotacaoId}`, data);
    }

    public static async reprovarCotacao(cotacaoId: number, credenciadaId: number, motivo?: string | null): Promise<DefaultResponse> {
        return await this.PATCH(`${ENDPOINT}/${credenciadaId}/cotacao/${cotacaoId}`, { status: CotacaoStatusType.REPROVADA, motivoReprovacao: motivo });
    }

    public static async cancelarCotacao(cotacaoId: number, credenciadaId: number): Promise<DefaultResponse> {
        return await this.update(cotacaoId, credenciadaId, { status: CotacaoStatusType.CANCELADA });
    }
}