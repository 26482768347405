import { FC } from "react";
import { ModalFormProps } from "../ModalForm/types";
import ModalForm from "../ModalForm";
import { useTwoFactorPopup } from "./index.hook";
import { TextInput } from "flowbite-react";
import { FormStateType } from "../../enums";

export const TwoFactorPopup: FC<ModalFormProps> = (props) => {
    const { onAuthenticate, password, setPassword, invalid, validating } = useTwoFactorPopup(props);

    return (
        <ModalForm {...props}
            title="Confirmação de Autorização"
            onSave={() => onAuthenticate()}
            onClose={() => {
                props.onClose?.call(this);
                setPassword("");
            }}
            size="md"
            isSaving={validating}
            state={FormStateType.unknown}
            saveButtonTitle="Verificar">

            <div className="grid grid-cols-12 px-6 py-5">
                <div className="form-control col-span-12 space-y-3">
                    <span className="font-light">Informe sua senha para confirmar a operação:</span>                    
                    <TextInput
                        id="inputToken"
                        type="password"
                        value={password}
                        readOnly={validating}
                        helperText={invalid ? "Senha incorreta" : ""}
                        color={invalid ? "failure" : "gray"}
                        onChange={(e) => setPassword(e.currentTarget.value)}
                        style={{
                            fontWeight: "",
                            fontSize: 24,
                            textAlign: "center",
                        }}
                        sizing={"lg"}
                        placeholder="Digite sua senha"
                        maxLength={20}
                    />
                </div>
            </div>
        </ModalForm>
    )
}