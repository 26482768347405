import { useState, useCallback, useEffect, MouseEvent } from "react";
import { ShowToast } from "../../components/CodeToast";
import { useSessionContext } from "../../contexts/SessionContext";
import { FormStateType } from "../../enums";
import { ClienteCondutorModel, defaultClienteCondutor } from "../../models/cliente_condutor/ClienteCondutorModel";
import { ClienteCondutorService } from "../../services/ClienteCondutoreService";
import { FluentValidator, FormProps } from "../../types";
import { UNKOWN_EXCEPTION } from "../../utils/Constants";
import { ClientesCondutoresCadastroFormProps } from "./types"
import { MapCondutorAsCommand } from "../../commands/ClienteCondutorCommand";
import CodeUtil from "../../utils/CodeUtil";

export const useClientesCondutoresCadastroPage = (props: ClientesCondutoresCadastroFormProps) => {
    const [model, setModel] = useState<ClienteCondutorModel>(defaultClienteCondutor);
    const [formProps, setFormProps] = useState<FormProps>({ loaded: false, readonly: props.state === FormStateType.view });
    const [errors, setErrors] = useState<FluentValidator[]>([]);
    const readonly = props.state === FormStateType.view;
    const currentSession = useSessionContext();
    const userSession = currentSession.session?.user;

    const onFormLoad = useCallback(async () => {
        if (!props.show || formProps.loaded) return;

        try {
            if (props.id !== null && props.id !== undefined && props.id > 0) {
                const response = await ClienteCondutorService.getId(props.id ?? 0);
                if (!response.success) {
                    ShowToast({ message: response.messages });
                    props.onClose?.call(this);
                    return;
                }

                setModel(response.data[0]);
            } else setModel(defaultClienteCondutor);

            setFormProps({ ...formProps, loaded: true });
        } catch (error) {
            console.log(error);
            ShowToast({ message: UNKOWN_EXCEPTION });
        }
    }, [props, formProps, setFormProps]);

    const onFormClose = () => {
        setFormProps({ ...formProps, loaded: false });
        props.onClose?.call(this);
    };

    const onFormSave = async (e: MouseEvent<HTMLButtonElement> | undefined) => {
        if (!await saveCondutor()) return;

        props.onSave?.call(this, e);
        setFormProps({ ...formProps, loaded: false });
    };

    const fetchFormProps = (): FormProps => formProps;

    const validate = (): boolean => {
        let _errors: FluentValidator[] = [];

        if (CodeUtil.isNullOrEmpty(model.nome)) _errors.push({ field: "nome", message: "O nome deve ser preenchido", isValid: false });
        if (CodeUtil.isNullOrEmpty(model.sobrenome)) _errors.push({ field: "sobrenome", message: "O sobrenome deve ser preenchido", isValid: false });
        if (CodeUtil.isNullOrEmpty(model.cpf)) _errors.push({ field: "cpf", message: "O CPF deve ser preenchido", isValid: false });
        if (CodeUtil.isNullOrEmpty(model.email) || !CodeUtil.isValidEmail(model.email)) _errors.push({ field: "email", message: "O e-mail informado é inválido", isValid: false });

        setErrors(_errors);
        return _errors.length === 0;
    }

    const saveCondutor = async (): Promise<boolean> => {
        try {
            setFormProps({ ...formProps, saving: true });

            if (!validate()) return false;

            if (props.state === FormStateType.edit)
                return await update();

            if (props.state === FormStateType.add)
                return await insert();

            return false;
        } catch (error) {
            console.log(error);
            ShowToast({ message: UNKOWN_EXCEPTION });
            return false;
        } finally {
            setFormProps({ ...formProps, saving: false });
        }
    }

    const insert = async (): Promise<boolean> => {
        try {
            let command = MapCondutorAsCommand(model)!;
            command.clienteId = userSession?.clienteId ?? 0;

            const response = await ClienteCondutorService.add(command);
            if (!response.success)
                ShowToast({ message: response.messages });
            return response.success;
        } catch (error) {
            console.log(error);
            ShowToast({ message: UNKOWN_EXCEPTION });
            return false;
        }
    };

    const update = async (): Promise<boolean> => {
        try {
            let command = MapCondutorAsCommand(model)!;
            command.clienteId = userSession?.clienteId ?? 0;

            const response = await ClienteCondutorService.update(command);
            if (!response.success)
                ShowToast({ message: response.messages });
            return response.success;
        } catch (error) {
            console.log(error);
            ShowToast({ message: UNKOWN_EXCEPTION });
            return false;
        }
    };

    useEffect(() => {
        onFormLoad();
    });

    return {
        onFormClose, onFormSave, onFormLoad, fetchFormProps,
        model, setModel,
        readonly,
        errors, setErrors
    }
}