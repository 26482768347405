import { format } from "fecha";
import { VeloeBaseRequest } from "../models/veloe/VeloeBaseRequest";
import { VeloeFuelSupplyTransactionRequest } from "../models/veloe/VeloeFuelSupplyTransactionRequest";
import { VeloeSupplyHistoryModel } from "../models/veloe/VeloeSupplyHistoryModel";
import { VeloeSupplyHistoryRequest } from "../models/veloe/VeloeSupplyHistoryRequest";
import { VeloeVehiclesModel } from "../models/veloe/VeloeVehiclesModel";
import { BaseService } from "./BaseService";
import { DefaultResponse } from "./types";
import { VeloeCardModel } from "../models/veloe/VeloeCardModel";
import { VeloeAccountBalanceRequest } from "../models/veloe/VeloeAccountBalanceRequest";
import { VeloeAccountBalanceModel } from "../models/veloe/VeloeAccountBalanceModel";
import { VeloeRechargeAccountRequest } from "../models/veloe/VeloeRechargeAccountRequest";
import { VeloeRechargeAccountModel } from "../models/veloe/VeloeRechargeAccountModel";
import { VeloeRechargeAccountCommand } from "../models/veloe/VeloeRechargeAccountCommand";
import { VeloeCalculateRouteModel } from "../models/veloe/VeloeCalculateRouteModel";
import { VeloeCalculateRouteCommand } from "../models/veloe/VeloeCalculateRouteCommand";

const ENDPOINT = `${BaseService.BASE_URL}/veloe`;
const MIN_PAGE_SIZE = 500;


export class VeloeService extends BaseService {
    public static async getVeiculos(props: VeloeBaseRequest = { pageSize: MIN_PAGE_SIZE, pageNumber: 1 }): Promise<DefaultResponse<VeloeVehiclesModel>> {
        let _url = `${ENDPOINT}/veiculos?pageSize=${props.pageSize ?? MIN_PAGE_SIZE}&pageNumber=${props.pageNumber ?? 1}`;
        props.bases?.forEach((base) => _url = _url.concat(`&bases=${base}`));

        return await this.GET<VeloeVehiclesModel>(_url);
    }

    public static async getHistoricoAbastecimento(props: VeloeSupplyHistoryRequest = { pageSize: MIN_PAGE_SIZE, pageNumber: 1 }): Promise<DefaultResponse<VeloeSupplyHistoryModel>> {
        let _url = `${ENDPOINT}/abastecimento/historico?pageSize=${props.pageSize ?? MIN_PAGE_SIZE}&pageNumber=${props.pageNumber ?? 1}`;
        props.bases?.forEach((base) => _url = _url.concat(`&bases=${base}`));
        props.veihclePlates?.forEach((plates) => _url = _url.concat(`&veihclePlates=${plates}`));

        props.startDate ??= format(new Date(), "DD/MM/YYYY");
        props.endDate ??= format(new Date(), "DD/MM/YYYY");
        _url = _url.concat(`&startDate=${props.startDate}&endDate=${props.endDate}`);

        return await this.GET<VeloeSupplyHistoryModel>(_url);
    }

    public static async getHistoricoTransacoes(props: VeloeFuelSupplyTransactionRequest = { pageSize: MIN_PAGE_SIZE, pageNumber: 1 }): Promise<DefaultResponse<VeloeSupplyHistoryModel>> {
        let _url = `${ENDPOINT}/abastecimento/transacoes?pageSize=${props.pageSize ?? MIN_PAGE_SIZE}&pageNumber=${props.pageNumber ?? 1}`;
        props.bases?.forEach((base) => _url = _url.concat(`&bases=${base}`));

        props.startDate ??= format(new Date(), "DD/MM/YYYY HH:mm:ss");
        props.endDate ??= format(new Date(), "DD/MM/YYYY HH:mm:ss");
        _url = _url.concat(`&startDate=${props.startDate}&endDate=${props.endDate}`);

        return await this.GET<VeloeSupplyHistoryModel>(_url);
    }

    public static async getCartoes(props: VeloeBaseRequest = { pageSize: MIN_PAGE_SIZE, pageNumber: 1 }): Promise<DefaultResponse<VeloeCardModel>> {
        let _url = `${ENDPOINT}/conta/cartoes?pageSize=${props.pageSize ?? MIN_PAGE_SIZE}&pageNumber=${props.pageNumber ?? 1}`;
        props.bases?.forEach((base) => _url = _url.concat(`&bases=${base}`));

        return await this.GET<VeloeCardModel>(_url);
    }

    public static async getSaldoConta(props: VeloeAccountBalanceRequest = { pageSize: MIN_PAGE_SIZE, pageNumber: 1 }): Promise<DefaultResponse<VeloeAccountBalanceModel>> {
        let _url = `${ENDPOINT}/conta/saldo?pageSize=${props.pageSize ?? MIN_PAGE_SIZE}&pageNumber=${props.pageNumber ?? 1}`;
        props.bases?.forEach((base) => _url = _url.concat(`&bases=${base}`));
        props.cards?.forEach((cards) => _url = _url.concat(`&cards=${cards}`));

        return await this.GET<VeloeAccountBalanceModel>(_url);
    }

    public static async getRecargaComplementar(props: VeloeRechargeAccountRequest = { pageSize: MIN_PAGE_SIZE, pageNumber: 1 }): Promise<DefaultResponse<VeloeRechargeAccountModel>> {
        let _url = `${ENDPOINT}/conta/recarga-complementar?pageSize=${props.pageSize ?? MIN_PAGE_SIZE}
            &pageNumber=${props.pageNumber ?? 1}
            &processingNumber=${props.processingNumber}`;

        return await this.GET<VeloeRechargeAccountModel>(_url);
    }

    public static async recargaComplementar(command: VeloeRechargeAccountCommand): Promise<DefaultResponse<VeloeRechargeAccountModel>> {
        return await this.POST<VeloeRechargeAccountModel>(`${ENDPOINT}/conta/recarga-complementar`, command);
    }

    public static async getCustoRota(id: string): Promise<DefaultResponse<VeloeCalculateRouteModel>> {
        return await this.GET<VeloeCalculateRouteModel>(`${ENDPOINT}/conta/custo-rota/${id}`);
    }

    public static async calcularRota(command: VeloeCalculateRouteCommand): Promise<DefaultResponse<VeloeCalculateRouteModel>> {
        return await this.POST<VeloeCalculateRouteModel>(`${ENDPOINT}/conta/calcular-custo-rota`, command);
    }
}