import { Badge } from "flowbite-react";
import { HiCheck } from "react-icons/hi";
import { IoMdClose } from "react-icons/io";
import { CredenciadaModel } from ".";
import { ICodeDataGridColumn } from "../../components/CodeDataGrid/types";
import CodeUtil from "../../utils/CodeUtil";
import ProfileImage from "../../images/profile.png";

const useCredenciadaColumns = (): ICodeDataGridColumn[] => {
    return [
        { property: "id", title: "Código", alignment: "center", onFormatValue(value: CredenciadaModel) { return <span>{(value.id as Number).toString().padStart(3, "0")}</span> } },
        {
            property: "bloqueada", title: "Ativo", alignment: "center", onFormatValue(value: CredenciadaModel) {
                return (
                    <div className="flex justify-center flex-wrap items-center gap-2">
                        {(value.bloqueada as boolean) ? (
                            <Badge size="sm" color="failure" icon={IoMdClose} />
                        ) : (
                            <Badge icon={HiCheck} size="sm" color="success" />
                        )}
                    </div>
                );
            },
        },
        {
            property: "razaoSocial", title: "Razão Social", onFormatValue(value: CredenciadaModel) {
                return (
                    <div className="flex">
                        <img
                            className="w-6 h-6 rounded-full"
                            src={value.logomarca ?? ProfileImage}
                            alt=""
                        />
                        <div className="pl-2 pt-0.5">{value.razaoSocial}</div>
                    </div>
                );
            },
        },
        { property: "fantasia", title: "Fantasia" },
        { property: "cnpj", title: "CNPJ", onFormatValue(value: CredenciadaModel) { return (<span>{CodeUtil.mask(value.cnpj, "##.###.###/####-##")}</span>) } },
        { property: "municipio.descricao", title: "Município" },
        { property: "uf.sigla", title: "UF" }
    ];
};

export { useCredenciadaColumns };