import { GestorContaModel } from '../models/gestor_conta';
import { BaseService } from "./BaseService";
import { DefaultResponse, ServiceProps } from "./types";

const ENDPOINT = `${BaseService.BASE_URL}/gestorconta`;

export interface GestorContaServiceProps extends ServiceProps {
    razaoSocial?: string
    fantasia?: string
    cnpj?: number
} 

export class GestorContaService extends BaseService {
    public static async get(props: GestorContaServiceProps = { limit: 100, page: 1 }): Promise<DefaultResponse<GestorContaModel>> {
        let query = `${ENDPOINT}?limit=${props.limit}&page=${props.page}`;
        
        if (props.razaoSocial) query += `&razaoSocial=${props.razaoSocial}`;
        if (props.fantasia) query += `&fantasia=${props.fantasia}`;
        if (props.cnpj) query += `&cnpj=${props.cnpj}`;

        return await this.GET<GestorContaModel>(query);
    }

    public static async getId(id: number): Promise<DefaultResponse<GestorContaModel>> {
        return await this.GET<GestorContaModel>(`${ENDPOINT}/${id}`);
    }

    public static async save(model: GestorContaModel): Promise<DefaultResponse<GestorContaModel>> {
        if (process.env.NODE_ENV !== "production") console.log(model);

        if (!model.id) return await this.POST<GestorContaModel>(`${ENDPOINT}`, model);
        return await this.PATCH<GestorContaModel>(`${ENDPOINT}/${model.id}`, model);
    }

    public static async delete(id: number): Promise<DefaultResponse<GestorContaModel>> {
        return await this.DELETE(`${ENDPOINT}/${id}`);
    }
}