import { format } from "fecha";
import { CotacaoStatusType } from "../../enums";
import { BaseModel } from "../base";
import { ClienteModel } from "../cliente/ClienteModel";
import { ClienteVeiculoModel } from "../cliente_veiculo/ClienteVeiculoModel";
import { CotacaoCredenciadaModel } from "./CotacaoCredenciadaModel";
import { CotacaoItemModel } from "./CotacaoItemModel";
import { EmpenhoModel } from "../empenho";

export interface CotacaoModel extends BaseModel {
    numero: number | null,
    dataCadastro: string,
    dataAlteracao: string,
    dataValidade: string,
    dataCancelamento: string | null,
    status: number,
    statusDescricao?: string | null
    statusCredenciada: number,
    statusCredenciadaDescricao?: string | null
    cliente: ClienteModel | null,
    veiculo: ClienteVeiculoModel | null,
    observacoes: string | null,
    notaFiscalUrl?: string | null
    notaFiscalKey?: string | null
    empenhoId?: number
    empenho?: EmpenhoModel | null
    itens: CotacaoItemModel[],
    credenciadas: CotacaoCredenciadaModel[]
}

export interface ISolicitarCotacaoRequest {
    ordemServicoId: number,
    credenciadaId: number[]
}

export const getCotacaoStatus = (situacao: CotacaoStatusType): string => {
    switch (situacao) {
        case CotacaoStatusType.AGUARDANDO:
        default: return "AGUARDANDO";
        case CotacaoStatusType.APROVADA: return "APROVADA";
        case CotacaoStatusType.REPROVADA: return "REPROVADA";
        case CotacaoStatusType.CANCELADA: return "CANCELADA";
        case CotacaoStatusType.COTACAO_ENVIADA: return "COTAÇÃO ENVIADA";
        case CotacaoStatusType.ENCERRADA: return "ENCERRADA";
        case CotacaoStatusType.EMEXECUCAO: return "EM EXECUÇÃO";
    }
}

export const getCotacaoStatusColor = (situacao: CotacaoStatusType): string => {
    switch (situacao) {
        case CotacaoStatusType.AGUARDANDO:
        default: return "default";
        case CotacaoStatusType.APROVADA: return "green";
        case CotacaoStatusType.EMEXECUCAO: return "primary";
        case CotacaoStatusType.REPROVADA: return "red";
        case CotacaoStatusType.CANCELADA: return "purple";
        case CotacaoStatusType.COTACAO_ENVIADA: return "indigo";
        case CotacaoStatusType.ENCERRADA: return "dark";
    }
}

export const getCotacaoDefault = (): CotacaoModel => {
    return {
        numero: null,
        dataCadastro: format(new Date(Date.now()), 'YYYY-MM-DD'),
        dataAlteracao: format(new Date(Date.now()), 'YYYY-MM-DD'),
        dataValidade: format(new Date(Date.now()), 'YYYY-MM-DD'),
        dataCancelamento: null,
        observacoes: null,
        status: CotacaoStatusType.AGUARDANDO,
        statusCredenciada: CotacaoStatusType.AGUARDANDO,
        cliente: null,
        veiculo: null,
        isSelected: false,
        itens: [],
        credenciadas: []
    };
}