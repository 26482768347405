import { ICodeDataGridColumn } from "../../components/CodeDataGrid/types";
import CodeUtil from "../../utils/CodeUtil";

const useClienteVeiculoColumns = (): ICodeDataGridColumn[] => {
    return [
        { property: "modeloMarca", title: "Marca/Modelo" },
        { property: "anoFabricacao", title: "Ano Fabricação", alignment: 'center' },
        { property: "anoModelo", title: "Ano Modelo", alignment: 'center' },
        { property: "placa", title: "Placa" },
        { property: "renavam", title: "Renavam" },
        { property: "orgao", title: "Órgão" },
        {
            title: "Valor Venal", property: "valorVenal", alignment: 'right', onFormatValue(item) {
                return <span>{CodeUtil.moneyFormat(item.valorVenal, true)}</span>
            },
        }
    ]
}

export { useClienteVeiculoColumns }