import { useState } from "react";
import { IFiltroRelatorioGarantia } from "./types";
import { EnumRelatorio, RelatorioModel } from "../../models/relatorios/RelatorioModel";
import { ICodeDataGridColumn } from "../../components/CodeDataGrid/types";
import { format } from "@formkit/tempo";
import { UNKOWN_EXCEPTION } from "../../utils/Constants";
import { ShowToast } from "../../components/CodeToast";
import { RelatorioService } from "../../services/RelatorioService";
import CodeUtil from "../../utils/CodeUtil";
import { SessionManager } from "../../utils/SessionManager";

export const useRelatorioItensGarantiaPage = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [showFiltro, setShowFiltro] = useState<boolean>(false);
    const [dataSource, setDataSource] = useState<RelatorioModel[]>([]);
    const [filtro, setFiltro] = useState<IFiltroRelatorioGarantia>({});

    const columns: ICodeDataGridColumn[] = [
        { title: "Data", property: "data", alignment: "center", onFormatValue: (item: RelatorioModel) => <span>{format(item.data, "DD/MM/YYYY HH:mm")}</span> },
        { title: "Relatório", property: "relatorio", onFormatValue: () => <span>{"Itens em Garantia"}</span> },
        { title: "Link", property: "link", onFormatValue: (item: RelatorioModel) => <a href={item.link} target="_blank" rel="noreferrer">{item.link}</a> },
    ]

    const handleGerarRelatorio = (params: IFiltroRelatorioGarantia) => {
        setShowFiltro(false);
        setLoading(true);

        setFiltro({ ...filtro, ...params });

        RelatorioService.gerarRelatorioItensGarantiaAsync(params)
            .then(async (report) => {
                if (!report) {
                    ShowToast({ message: "Erro desconhecido ao gerar o relatório. Tente novamente mais tarde." });
                    return;
                }

                const downloadLink = CodeUtil.generateTempFileUrl(report);

                setDataSource([{
                    data: new Date(Date.now()),
                    tipo: EnumRelatorio.ITENS_GARANTIA,
                    isSelected: true,
                    link: downloadLink,
                    usuarioId: SessionManager.getSession().user?.id ?? 0
                }, ...dataSource]);

            }).catch((error) => {
                console.log(error);
                ShowToast({ message: UNKOWN_EXCEPTION });
            }).finally(() => {
                setLoading(false);
            });
    }

    return {
        loading, columns,
        showFiltro, setShowFiltro,
        filtro, setFiltro,
        dataSource,
        handleGerarRelatorio
    }
}