import { IFiltroRelatorioCotacoes, IFiltroRelatorioGarantia, IFiltroRelatorioOrdensServico, IFiltroRelatorioRepasses } from "../pages/relatorios/types";
import { BaseService } from "./BaseService";

const ENDPOINT = `${BaseService.BASE_URL}/relatorio`;

export class RelatorioService extends BaseService {

    public static async gerarRelatorioRepassesAsync(props: IFiltroRelatorioRepasses): Promise<Blob | null> {
        return await this.REQUESTREPORT(`${ENDPOINT}/repasses-servicos`, {
            DataInicial: props.dataInicial,
            DataFinal: props.dataFinal,
            CredenciadaId: props.credenciadaId,
            Orgao: props.orgao,
            Status: props.status
        });
    }

    public static async gerarRelatorioCotacoesAsync(props: IFiltroRelatorioCotacoes): Promise<Blob | null> {
        return await this.REQUESTREPORT(`${ENDPOINT}/cotacoes`, {
            DataInicial: props.dataInicial,
            DataFinal: props.dataFinal,
            CredenciadaId: props.credenciadaId,
            ClienteId: props.clienteId,
            VeiculoId: props.veiculoId,
            Orgao: props.orgao,
            Status: props.status
        });
    }

    public static async gerarRelatorioOrdensServicoAsync(props: IFiltroRelatorioOrdensServico): Promise<Blob | null> {
        return await this.REQUESTREPORT(`${ENDPOINT}/ordens-servico`, {
            DataInicial: props.dataInicial,
            DataFinal: props.dataFinal,
            CredenciadaId: props.credenciadaId,
            ClienteId: props.clienteId,
            VeiculoId: props.veiculoId,
            CondutorId: props.condutorId,
            Orgao: props.orgao,
            Status: props.status
        });
    }

    public static async gerarRelatorioItensGarantiaAsync(props: IFiltroRelatorioGarantia): Promise<Blob | null> {
        return await this.REQUESTREPORT(`${ENDPOINT}/garantia`, {
            ...props,
            expirada: (!props.expirada || props.expirada === 0) ? null : props.expirada === 2,
        });
    }

    public static async gerarImpressaoCotacao(cotacaoId: number): Promise<Blob | null> {
        return await this.REQUESTREPORT(`${ENDPOINT}/cotacoes/${cotacaoId}/imprimir`, null);
    }

    public static async gerarImpressaoCotacaoCredenciada(cotacaoId: number, credenciadaId: number | null): Promise<Blob | null> {
        return await this.REQUESTREPORT(`${ENDPOINT}/cotacoes/${cotacaoId}/credenciada/${credenciadaId}/imprimir`, null);
    }
}