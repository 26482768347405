import { BaseModel } from "../base";

export interface RelatorioModel extends BaseModel {
    usuarioId: number | null
    tipo: EnumRelatorio
    data: Date
    link: string
}

export enum EnumRelatorio {
    REPASSE_SERVICOS = 0,
    COTACOES = 1,
    ITENS_GARANTIA = 2
}

export const getTipoRelatorio = (e: EnumRelatorio): string => {
    switch (e) {
        case EnumRelatorio.REPASSE_SERVICOS:
        default: return "Repasse de Serviços";
        case EnumRelatorio.COTACOES:
            return "Cotações e Serviços";
        case EnumRelatorio.ITENS_GARANTIA:
            return "Itens em Garantia";
    }
}