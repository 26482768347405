import { Checkbox, Label, Select, Spinner, Textarea, TextInput } from "flowbite-react";
import { FC, Fragment, useCallback, useEffect, useState } from "react";
import { CodeLabel } from "../../../../../components/CodeLabel";
import { ShowToast } from "../../../../../components/CodeToast";
import { Groupbox } from "../../../../../components/Groupbox";
import { MaskedInput } from "../../../../../components/MaskedInput";
import { FormStateType } from "../../../../../enums";
import { EstadoModel } from "../../../../../models/estado";
import { MunicipioModel } from "../../../../../models/municipio";
import { ConsultarCepService } from "../../../../../services/ConsutarCepService";
import { EstadoService } from "../../../../../services/EstadoService";
import { MunicipioService } from "../../../../../services/MunicipioService";
import { DefaultCEPResponse } from "../../../../../services/types";
import CodeUtil from "../../../../../utils/CodeUtil";
import { ClienteFormPageDadosProps } from "../../types";
import { MoneyInput } from "../../../../../components/MoneyInput";
import { ConfiguracaoModel } from "../../../../../models/configuracao/ConfiguracaoModel";
import { ConfiguracaoService } from "../../../../../services/ConfiguracaoService";


export const ClientesTabDados: FC<ClienteFormPageDadosProps> = (props) => {
    const [model, setModel] = props.model
    const readOnlyForm = props.state === FormStateType.view;
    const [formLoaded, setFormLoaded] = useState<boolean>(false);
    const [buscandoCep, setBuscandoCep] = useState<boolean>(false);
    const [municipios, setMunicipios] = useState<MunicipioModel[]>([]);
    const [estados, setEstados] = useState<EstadoModel[]>([]);

    const consultarCep = (cep: string): void => {
        if ((CodeUtil.removeSpaces(cep)?.length ?? 0) < 8) {
            setBuscandoCep(false);
            return;
        }

        setBuscandoCep(true);
        setTimeout(() => {
            ConsultarCepService.consultar(cep)
                .then((response) => onAfterBuscarCep(response))
                .finally(() => setBuscandoCep(false));
        }, 1500);
    };

    const onAfterBuscarCep = (response: DefaultCEPResponse | null) => {
        if (response === null) return;

        onLoadMunicipios(response.uf ?? "ND")
            .then((_municipios) => {
                let _municipio = _municipios.find((item) => item.descricao?.toLowerCase() === response.localidade?.toLowerCase());

                setModel({
                    ...model,
                    cep: response.cep ?? "",
                    logradouro: response.logradouro ?? "",
                    bairro: response.bairro ?? "",
                    municipio: {
                        id: _municipio?.id,
                        descricao: response.localidade ?? "",
                    },
                    uf: {
                        id: CodeUtil.parseInt(response.ddd) ?? 0,
                        sigla: response.uf,
                    }
                });
            });
    }

    const onLoadEstados = useCallback(async () => {
        let response = await EstadoService.get({ page: 1, limit: 50 });
        if (!response.success) {
            ShowToast({ message: CodeUtil.arrayToStr(response.messages) });
            setEstados([]);
            return;
        }

        setEstados(response.data);
    }, [setEstados]);

    const onLoadMunicipios = useCallback(async (sigla: string): Promise<MunicipioModel[]> => {

        let response = await MunicipioService.get({
            page: 1,
            limit: 9999999,
            ufSigla: sigla?.trim(),
        });

        if (!response.success) {
            ShowToast({ message: CodeUtil.arrayToStr(response.messages) });
            setMunicipios([]);
            return [];
        }

        setMunicipios(response.data);
        return response.data ?? [];
    }, [setMunicipios]);

    const onLoadConfiguracoes = useCallback(async () => {
        const response = await ConfiguracaoService.get();
        if (!response.success) {
            ShowToast({ message: response.messages });
            return;
        }

        if (!model.id || model.id === 0) {
            const configuracoes: ConfiguracaoModel = response.data;
            model.percentualComissaoRodar = configuracoes?.percentualComissao;
        }
    }, [model]);

    const onPageLoad = useCallback(async () => {
        if (!props.show || formLoaded) return;

        await onLoadConfiguracoes();
        await onLoadEstados();
        await onLoadMunicipios(model.uf?.sigla ?? "ND");
        props.onPageLoad?.call(this);

        setFormLoaded(true);
    }, [props.show, props.onPageLoad, formLoaded, onLoadConfiguracoes, onLoadEstados, onLoadMunicipios, model.uf?.sigla]);

    useEffect(() => { onPageLoad() });

    return (
        <Fragment>
            <div className="grid grid-cols-12">
                <div className="col-span-12">
                    <h2
                        className="ml-auto text-xl font-extrabold text-black/70"
                        hidden={(model.id ?? 0) === 0}
                    >
                        {`ID: #${(model.id ?? 0).toString().padStart(3, "0")}`}
                    </h2>
                </div>

                <div className="form-control mt-5 col-span-12 md:col-span-6 md:mr-6">
                    <CodeLabel className="mb-1" htmlFor="inputRazaoSocial" value="Razão Social:" />
                    <TextInput
                        id="inputRazaoSocial"
                        type="text"
                        readOnly={readOnlyForm}
                        value={model.razaoSocial}
                        onChange={(e) => setModel({ ...model, razaoSocial: e.currentTarget.value })}
                        maxLength={100}
                    />
                </div>

                <div className="form-control mt-5 col-span-12 md:col-span-6">
                    <CodeLabel className="mb-1" htmlFor="inputFantasia" value="Fantasia:" />
                    <TextInput
                        id="inputFantasia"
                        type="text"
                        readOnly={readOnlyForm}
                        value={model.fantasia ?? ""}
                        onChange={(e) => setModel({ ...model, fantasia: e.currentTarget.value })}
                        maxLength={80}
                    />
                </div>

                <div className="form-control mt-5 col-span-12 md:col-span-3 md:mr-6">
                    <CodeLabel className="mb-1" htmlFor="inputCNPJ" value="CNPJ:" />
                    <MaskedInput
                        id="inputCNPJ"
                        type="text"
                        readOnly={readOnlyForm}
                        mask="##.###.###/####-##"
                        pattern="^[0-9]+$"
                        value={model.cnpj}
                        onChange={(value) => setModel({ ...model, cnpj: value })}
                    />
                </div>

                <div className="form-control mt-5 col-span-12 md:col-span-3 md:mr-6">
                    <CodeLabel className="mb-1" htmlFor="inputIE" value="IE:" />
                    <TextInput
                        id="inputIE"
                        type="text"
                        readOnly={readOnlyForm}
                        value={model.inscricaoEstadual ?? ""}
                        onChange={(e) => setModel({ ...model, inscricaoEstadual: CodeUtil.removeSpecialChars(e.currentTarget.value) })}
                        maxLength={20}
                    />
                </div>

                <div className="form-control mt-5 col-span-12 md:col-span-3">
                    <CodeLabel className="mb-1" htmlFor="inputCodigoVeloe" value="ID Veloe:" />
                    <TextInput
                        id="inputCodigoVeloe"
                        type="text"
                        readOnly={readOnlyForm}
                        value={model.codigoVeloe ?? ""}
                        onChange={(e) => setModel({ ...model, codigoVeloe: e.currentTarget.value })}
                        maxLength={20}
                    />
                </div>

                <div className="form-control mt-5 col-span-12">
                    <CodeLabel className="mb-1" htmlFor="inputEmail" value="E-mail:" />
                    <TextInput
                        addon={"@"}
                        id="inputEmail"
                        type="email"
                        readOnly={readOnlyForm}
                        value={model.email ?? ""}
                        onChange={(e) => setModel({ ...model, email: e.currentTarget.value?.trim() })}
                    />
                </div>

                <Groupbox className="col-span-12 mt-5" label="Endereço">
                    <div className="form-control col-span-4 md:col-span-2 mt-5">
                        <CodeLabel className="mb-1" htmlFor="inputCEP" value="CEP:" />
                        <div className="flex">
                            <MaskedInput
                                id="inputCEP"
                                type="text"
                                mask="#####-###"
                                pattern="^[0-9]+$"
                                readOnly={readOnlyForm}
                                value={model.cep ?? ""}
                                onChange={(value) => {
                                    setModel({ ...model, cep: value ?? "" });
                                    consultarCep(CodeUtil.removeSpecialChars(value));
                                }}
                                maxLength={10}
                            />

                            <div className={"ml-3 mt-2 text-white"} hidden={!buscandoCep}>
                                <Spinner size="sm" light={true} />
                            </div>
                        </div>
                    </div>
                    <div className="col-span-10"></div>

                    <div className="form-control mt-5 col-span-9 mr-6">
                        <CodeLabel className="mb-1" htmlFor="inputLogradouro" value="Logradouro:" />
                        <TextInput
                            id="inputLogradouro"
                            type="text"
                            readOnly={readOnlyForm}
                            value={model.logradouro ?? ""}
                            onChange={(e) => setModel({ ...model, logradouro: e.currentTarget.value })}
                            maxLength={100}
                        />
                    </div>

                    <div className="form-control mt-5 col-span-3">
                        <CodeLabel className="mb-1" htmlFor="inputLogradouroNumero" value="Número:" />
                        <TextInput
                            id="inputLogradouroNumero"
                            type="text"
                            readOnly={readOnlyForm}
                            value={model.logradouroNumero ?? ""}
                            onChange={(e) => setModel({ ...model, logradouroNumero: e.currentTarget.value })}
                            maxLength={10}
                        />
                    </div>

                    <div className="form-control mt-5 col-span-12 md:col-span-6 md:mr-6">
                        <CodeLabel className="mb-1" htmlFor="inputBairro" value="Bairro:" />
                        <TextInput
                            id="inputBairro"
                            type="text"
                            readOnly={readOnlyForm}
                            value={model.bairro ?? ""}
                            onChange={(e) => setModel({ ...model, bairro: e.currentTarget.value })}
                            maxLength={80}
                        />
                    </div>

                    <div className="form-control mt-5 col-span-3 md:col-span-2 mr-6">
                        <CodeLabel className="mb-1" htmlFor="selectUF" value="UF:" />
                        <Select id="selectUF" onChange={(e) => {
                            setModel({ ...model, uf: { ...model.uf, sigla: e.currentTarget.value } });
                            onLoadMunicipios(e.currentTarget.value)
                        }}
                            disabled={readOnlyForm}
                            value={model.uf?.sigla ?? "ND"}>
                            <option key={'0'} value={'ND'}>ND</option>
                            {estados.map((estado) => (<option key={estado.sigla} value={estado.sigla}>{estado.sigla}</option>))}
                        </Select>
                    </div>

                    <div className="form-control mt-5 col-span-9 md:col-span-4">
                        <CodeLabel className="mb-1" htmlFor="selectMunicipio" value="Município:" />
                        <Select
                            id="selectMunicipio"
                            disabled={readOnlyForm}
                            onChange={(e) => setModel({ ...model, municipio: { ...model.municipio, id: CodeUtil.parseInt(e.currentTarget.value) ?? 0 } })}
                            value={model.municipio?.id ?? 0}>
                            <option key={0} value={0}>Selecione um município</option>
                            {municipios.map((municipio) => (
                                <option key={municipio.descricao} value={municipio.id}>
                                    {municipio.descricao}
                                </option>
                            ))}
                        </Select>
                    </div>
                </Groupbox>

                <Groupbox className="col-span-12 mt-5" label="Observações">
                    <div className="form-control col-span-12 mt-5">
                        <CodeLabel className="mb-1" htmlFor="inputObservacoes" value="Observações:" />
                        <Textarea
                            id="inputObservacoes"
                            rows={5}
                            readOnly={readOnlyForm}
                            value={model.observacoes ?? ""}
                            onChange={(e) => setModel({ ...model, observacoes: e.currentTarget.value })}
                        />
                    </div>
                </Groupbox>

                <Groupbox className="col-span-12 my-5" label="Configurações">
                    <div className="col-span-6 md:col-span-3 mr-6 mt-5">
                        <CodeLabel value={"Comissão Rodar:"} className="mb-1" htmlFor="inputComissaoRodar" />
                        <MoneyInput id="inputComissaoRodar"
                            value={CodeUtil.moneyFormat(model?.percentualComissaoRodar ?? 0, false)}
                            addon={"%"}
                            readOnly={readOnlyForm}
                            onChange={(_stringValue, numberValue) => setModel({ ...model, percentualComissaoRodar: numberValue })} />
                    </div>

                    <div className="col-span-6 md:col-span-3 mt-5 md:mr-6">
                        <CodeLabel value={"Desconto em Contrato:"} className="mb-1" htmlFor="inputDescontoContrato" />
                        <MoneyInput id="inputDescontoContrato"
                            value={CodeUtil.moneyFormat(model?.percentualDescontoContrato ?? 0, false)}
                            addon={"%"}
                            readOnly={readOnlyForm}
                            onChange={(_stringValue, numberValue) => setModel({ ...model, percentualDescontoContrato: numberValue })} />
                    </div>

                    <div className="form-control mt-12 col-span-12 md:col-span-3 flex space-x-2 items-center">
                        <Checkbox id="checkBoxPermitirCatalogos"
                            disabled={readOnlyForm}
                            checked={model.permitirVisualizarCatalogos}
                            onChange={(e) => setModel({ ...model, permitirVisualizarCatalogos: e.currentTarget.checked })}
                            placeholder="Checked">
                        </Checkbox>

                        <Label htmlFor="checkBoxPermitirCatalogos" value="Permitir Acessar Catálogos?" />
                    </div>

                    <div className="form-control mt-12 col-span-12 md:col-span-3 flex space-x-2 items-center">
                        <Checkbox id="checkBoxBloqueado"
                            disabled={readOnlyForm}
                            checked={model.bloqueado}
                            onChange={(e) => setModel({ ...model, bloqueado: e.currentTarget.checked })}
                            placeholder="Checked">
                        </Checkbox>

                        <Label htmlFor="checkBoxBloqueado" value="Acesso Bloqueado?" />
                    </div>
                </Groupbox>
            </div>
        </Fragment>
    );
};