import { ClienteCondutorModel } from "../models/cliente_condutor/ClienteCondutorModel";

export interface ClienteCondutorCommand 
{
    id?: number | null;
    clienteId: number
    nome: string 
    sobrenome: string
    email: string
    cpf: string
    matricula: string | null
    numeroHabilitacao: string | null
    bloqueado: boolean
    celular: string | null
}

export const MapCondutorAsCommand = (model?: ClienteCondutorModel | null): ClienteCondutorCommand | null => {
    if (model === null || model === undefined)
        return null;

    return {
        id: model.id,
        bloqueado: model.bloqueado,
        celular: model.celular ?? null,
        clienteId: model.clienteId,
        cpf: model.cpf,
        email: model.email,
        matricula: model.matricula ?? null,
        nome: model.nome,
        numeroHabilitacao: model.numeroHabilitacao ?? null,
        sobrenome: model.sobrenome
    }
}
