import { CotacaoTipoItemType, ModelState } from "../../enums";
import { BaseModel } from "../base";
import { ProdutoModel } from "../produto";

export interface CotacaoItemModel extends BaseModel {
    cotacaoId: number,
    codigoFornecedor: string | null,
    descricao: string,
    numeroSerie: string,
    quantidade: number,
    tipo: CotacaoTipoItemType,
    percentualComissao: number,
    precoFabricante?: number,
    observacoes: string | null,
    state: ModelState,
    servico: {
        id: number,
        nome: string
    } | null
    produtoId?: number
    produto?: ProdutoModel
}

export const getCotacaoItemDefault = (): CotacaoItemModel => {
    return {
        cotacaoId: 0,
        codigoFornecedor: "",
        descricao: "",
        numeroSerie: "",
        quantidade: 1,
        observacoes: null,
        isSelected: false,
        percentualComissao: 0,
        tipo: CotacaoTipoItemType.ITEM,
        state: ModelState.unchanged,
        servico: null
    };
}