import { FC } from "react";
import { useLocation } from "react-router-dom";
import { SidebarItemProps } from "../types";
import { SidebarItemTag } from "./Tag";

export const SideBarItem: FC<SidebarItemProps> = (props) => {

    const currentLocation = useLocation()
    const isRouteActive = (expected?: string): boolean => {
        return expected === undefined ? false : currentLocation.pathname.includes(expected)
    }

    const _class = `flex items-center p-2 ${props.dropdown ? 'mt-2 pl-7 w-full' : ''} 
        text-base font-normal text-gray-900 rounded-lg ${(props.active ?? isRouteActive(props.to)) ? 'bg-gray-200' : ''} 
        hover:bg-gray-100 group-hover:text-gray-900`

    return (
        <li>
            <a href={props.to ?? '#'} rel="noopener noreferrer" className={_class} onClick={() => props.onClick?.call(this)}>
                {props.icon}
                <span className={`flex-1 ml-3 whitespace-nowrap ${props.textColor}`}>{props.label}</span>
                {<SidebarItemTag label={props.tag?.label}
                    textColor={props.tag?.textColor}
                    color={props.tag?.color}
                    visible={Boolean(props.tag)} />}
            </a>
        </li>
    )
}