import { useCallback, useEffect, useState, MouseEvent } from "react";
import { ShowToast } from "../../components/CodeToast";
import { ModalFormProps } from "../../components/ModalForm/types";
import { FormStateType, ModelState } from "../../enums";
import { UNKOWN_EXCEPTION } from "../../utils/Constants";
import { CotacaoModel, getCotacaoDefault } from "../../models/cotacao/CotacaoModel";
import { CotacaoService } from "../../services/CotacaoService";
import { Map } from "../../commands/CotacaoCommand";
import { ConfiguracaoService } from "../../services/ConfiguracaoService";
import CodeUtil from "../../utils/CodeUtil";
import { ConfiguracaoModel } from "../../models/configuracao/ConfiguracaoModel";
import { useSessionContext } from "../../contexts/SessionContext";
import { ClienteModel } from "../../models/cliente/ClienteModel";
import { ClienteService } from "../../services/ClienteService";
import { format } from "fecha";

type FormProps = {
    loaded: boolean
    readonly: boolean
    saving?: boolean
}

export const useCotacaoCadastroPage = (props: ModalFormProps) => {
    const [model, setModel] = useState<CotacaoModel>(getCotacaoDefault());
    const [formProps, setFormProps] = useState<FormProps>({ loaded: false, readonly: props.state === FormStateType.view });
    const [cliente, setCliente] = useState<ClienteModel | null>(null);

    const currentSession = useSessionContext();
    const userSession = currentSession.session?.user;

    const readonly = props.state === FormStateType.view ||
        (props.state === FormStateType.edit && model.credenciadas.some((_) => _.state !== ModelState.added));

    const onFormLoad = useCallback(async () => {
        if (!cliente && userSession?.clienteId) {
            var _cliente = (await ClienteService.getId(userSession?.clienteId ?? 0)).data;
            setCliente(_cliente ?? null);
        }

        if (!props.show || formProps.loaded) return;

        try {
            const configuracoes: ConfiguracaoModel = (await ConfiguracaoService.get()).data ?? { percentualComissao: 0, prazoValidade: 0 };
            if (!props.id || props.id === 0) {
                setModel({
                    ...getCotacaoDefault(),
                    dataValidade: format(CodeUtil.addDays(configuracoes.prazoValidade), "YYYY-MM-DD")
                });
                setFormProps({ ...formProps, loaded: true });
                return;
            }

            const response = await CotacaoService.getId(props.id ?? 0);
            if (!response.success) {
                ShowToast({ message: response.messages });
                props.onClose?.call(this);
                return;
            }

            setModel(response.data);
            setFormProps({ ...formProps, loaded: true });
        } catch (error) {
            console.log(error);
            ShowToast({ message: UNKOWN_EXCEPTION });
        }
    }, [props, formProps, setFormProps, userSession?.clienteId, cliente, setCliente]);

    const onFormClose = () => {
        setFormProps({ ...formProps, loaded: false });
        props.onClose?.call(this);
    };

    const onFormSave = async (e: MouseEvent<HTMLButtonElement> | undefined) => {
        if (!await saveCotacao()) return;

        props.onSave?.call(this, e);
        setFormProps({ ...formProps, loaded: false });
    };

    const fetchFormProps = (): FormProps => formProps;

    const saveCotacao = async (): Promise<boolean> => {
        try {
            setFormProps({
                ...formProps,
                saving: true
            });

            if (props.state === FormStateType.add)
                return await add();

            if (props.state === FormStateType.edit)
                return await update();

            return false;
        } catch (error) {
            console.log(error);
            ShowToast({ message: UNKOWN_EXCEPTION });
            return false;
        } finally {
            setFormProps({ ...formProps, saving: false });
        }
    }

    const add = async (): Promise<boolean> => {
        try {
            let newCotacao = Map(model);
            if (newCotacao) newCotacao.clienteId = (model.cliente?.id ?? userSession?.clienteId) ?? null;
            
            const response = await CotacaoService.insert(newCotacao);
            if (!response.success) ShowToast({ message: response.messages });
            return response.success;
        } catch (error) {
            console.log(error);
            ShowToast({ message: UNKOWN_EXCEPTION });
            return false;
        }
    }

    const update = async (): Promise<boolean> => {
        try {
            const response = await CotacaoService.update(model.id!, Map(model));
            if (!response.success)
                ShowToast({ message: response.messages });
            return response.success;
        } catch (error) {
            console.log(error);
            ShowToast({ message: UNKOWN_EXCEPTION });
            return false;
        }
    }

    useEffect(() => {
        onFormLoad();
    });

    return {
        onFormClose, onFormSave, onFormLoad, readonly,
        getFormProps: fetchFormProps, model, setModel, cliente, userSession
    }
}