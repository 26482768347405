import { Button, Modal, Spinner } from "flowbite-react";
import FlatButton from "../FlatButton";
import { HiOutlineExclamationCircle } from 'react-icons/hi'
import { useState } from "react";
import { PopupType } from "./types";

export const Popup: React.FC<PopupType> = (props) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const onConfirmButtonClick = async () => {
        try {
            if (isLoading)
                return;
            setIsLoading(true);
            await props.onConfirm?.call(this);
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <Modal size="md" popup={true} show={props.isShowing} onClose={props.onCancel}>
            <Modal.Header />
            <Modal.Body>
                <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
                <div className="text-center">
                    <h3 className="text-lg font-normal text-gray-500">{props.message}</h3>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="flex ml-auto space-x-2">
                    <FlatButton onClick={props.onCancel} label={props.cancelButtonTitle ?? "Cancelar"} />
                    <Button size="sm" color={(props.isDestructive ?? false) ? "failure" : "success"}
                        disabled={isLoading}
                        onClick={onConfirmButtonClick}>
                        <div hidden={!isLoading} className="mr-3">
                            <Spinner size="sm" light={true} />
                        </div>
                        {props.confirmButtonTitle ?? "Confirmar"}
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    )
} 