import { useState, MouseEvent } from "react";
import { ModalFormProps } from "../../components/ModalForm/types";
import { ShowToast } from "../../components/CodeToast";
import { VeloeService } from "../../services/VeloeService";
import { UNKOWN_EXCEPTION } from "../../utils/Constants";
import CodeUtil from "../../utils/CodeUtil";
import { VeloeCalculateRouteCommand } from "../../models/veloe/VeloeCalculateRouteCommand";

export const useVeloeCalculoRotaModalPage = (props: ModalFormProps) => {
    const [saving, setSaving] = useState<boolean>(false);
    const [getValues, setValue] = useState<VeloeCalculateRouteCommand>({
        departure: "",
        destination: "",
        plate: "",
        literValue: 0,
        tolerance: 0,
        averageVehicle: 0,
        waypoints: []
    });
    const [waypoints, setWaypoints] = useState<string>("");

    const handleFormClose = () => {

        props.onClose?.call(this);
    }

    const validate = (): boolean => {
        if (CodeUtil.isNullOrEmpty(getValues.departure?.trim())) {
            ShowToast({ message: "O ponto de partida deve ser preenchido." });
            return false;
        }

        if (CodeUtil.isNullOrEmpty(getValues.destination?.trim())) {
            ShowToast({ message: "O destino deve ser preenchido." });
            return false;
        }

        if (CodeUtil.isNullOrEmpty(getValues.plate?.trim())) {
            ShowToast({ message: "A placa do veículo deve ser preenchida." });
            return false;
        }

        if (getValues.literValue && getValues.literValue <= 0) {
            ShowToast({ message: "O valor do litro deve ser maior que zero." });
            return false;
        }

        if (getValues.averageVehicle && getValues.averageVehicle <= 0) {
            ShowToast({ message: "A média do veículo deve ser maior que zero." });
            return false;
        }

        setValue({...getValues, waypoints: waypoints.trim().split(',') ?? []});
        return true;
    }

    const handleFormSave = (e: MouseEvent<HTMLButtonElement> | undefined) => {
        if (!validate()) return;

        setSaving(true);
        VeloeService.calcularRota(getValues)
            .then(async (response) => {
                console.log(response);
                if (!response.success) {
                    ShowToast({ message: `O veículo ${getValues.plate} não pertence a este contrato` });
                    return;
                }

                ShowToast({ message: `Número do processo: ${response.data?.id}` })
                props.onSave?.call(this, e);
            }).catch((error) => {
                console.log(error);
                ShowToast({ message: UNKOWN_EXCEPTION });
            }).finally(() => {
                setSaving(false);
            });
    }

    return {
        handleFormClose,
        handleFormSave,
        saving,
        setValue,
        getValues,
        waypoints, 
        setWaypoints
    }
}