import { FC } from "react";
import { SidebarItemTagProps } from "../../types";

export const SidebarItemTag : FC<SidebarItemTagProps> = (props) => {
    return (
        (!props.visible ? <></> :
        <span className={`inline-flex justify-center p-3 ml-3 w-3 h-3 items-center rounded-full text-sm font-medium ${props.textColor} ${props.color}`}>
            {props.label}
        </span>)
    )
}

