import { SessionProps } from "../contexts/types";

const defaultSession : SessionProps = {
    redirectTo: '/',
    rememberMe: false,
    signed: false,
    hiddenSidebar: true
}

export class SessionManager {    
    public static getSession() : SessionProps {

        if (localStorage.getItem('@App:session') === null) {
            return defaultSession;
        }

        return JSON.parse(localStorage.getItem('@App:session') ?? '') as SessionProps;
    }

    public static setSession(session: SessionProps | undefined) : void {
        localStorage.setItem('@App:session', JSON.stringify(session));
    }
}