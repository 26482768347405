import { CotacaoStatusType, ModelState } from "../../enums";
import { CotacaoCredenciadaItemModel } from "./CotacaoCredenciadaItemModel";
export interface CotacaoCredenciadaModel {
    cotacaoId?: number | null,
    credenciadaId: number,
    dataCadastro: Date | null,
    dataAlteracao: Date | null,
    razaoSocial: string,
    fantasia: string | null,
    cnpj: string,
    status: CotacaoStatusType,
    desconto: number,
    descontoPercentual: number,
    frete: number,
    seguro: number,
    outrasDespesas: number,
    valorTotal: number,
    valorComissao: number,
    observacoes: string | null,
    isSelected: boolean,
    state: ModelState,
    itens: CotacaoCredenciadaItemModel[] | null
}

export const getCotacaoCredenciadaDefault = (): CotacaoCredenciadaModel => {
    return {
        credenciadaId: 0,
        razaoSocial: "",
        fantasia: "",
        dataCadastro: null,
        dataAlteracao: null,
        cnpj: "",
        status: CotacaoStatusType.AGUARDANDO,
        desconto: 0,
        descontoPercentual: 0,
        frete: 0,
        isSelected: false,
        observacoes: null,
        outrasDespesas: 0,
        seguro: 0,
        valorTotal: 0,
        valorComissao: 0,
        state: ModelState.unchanged,
        itens: []
    }
}