import { useState } from "react";
import { ICodeDataGridColumn } from "../../components/CondensedCodeDataGrid/types";
import { VeloeService } from "../../services/VeloeService";
import { ShowToast } from "../../components/CodeToast";
import { UNKOWN_EXCEPTION } from "../../utils/Constants";
import { format } from "fecha";
import CodeUtil from "../../utils/CodeUtil";
import { ModalFormProps } from "../../components/ModalForm/types";
import { FormStateType } from "../../enums";
import { VeloeCalculateRouteModel } from "../../models/veloe/VeloeCalculateRouteModel";
import { VeloeCalculateRouteRequest } from "../../models/veloe/VeloeCalculateRouteRequest";

export const useVeloeRotasPage = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [showFiltros, setShowFiltros] = useState<boolean>(false);
    const [dataSource, setDataSource] = useState<VeloeCalculateRouteModel[]>([]);
    const [filtro, setFiltro] = useState<VeloeCalculateRouteRequest>();
    const [modalProps, setModalProps] = useState<ModalFormProps>({
        show: false,
        state: FormStateType.add,
        onClose: () => {
            setModalProps({...modalProps, show: false})
        },
        onSave: () => {
            setModalProps({...modalProps, show: false})
        }
    });

    const columns: ICodeDataGridColumn[] = [
        { title: "Número do Processo", property: "id" },
        { title: "Contrato", property: "contract" },
        { title: "Filial", property: "branch" },
        { title: "Base", property: "base" },
        { title: "Status", property: "status", alignment: "center" },
        {
            title: "Data", property: "calculationData", onFormatValue: (item: VeloeCalculateRouteModel) =>
                <span>{!item.calculationData ? "" : format(new Date(item.calculationData), "DD/MM/YYYY HH:mm")}</span>
        },
        { title: "Valor Recarga", property: "totalChargeValue", alignment: "right", onFormatValue: (item: VeloeCalculateRouteModel) =>
            <span>{CodeUtil.moneyFormat(item.totalChargeValue, true)}</span> },
        { title: "Consumo Médio", property: "averageVehicle", alignment: "right", onFormatValue: (item: VeloeCalculateRouteModel) =>
            <span>{CodeUtil.moneyFormat(item.averageVehicle, false)} Km/L</span> },
        { title: "Valor Litro", property: "literValue", alignment: "right", onFormatValue: (item: VeloeCalculateRouteModel) =>
            <span>{CodeUtil.moneyFormat(item.literValue, true)}</span> },
        { title: "Distância", property: "totalDistance", alignment: "right", onFormatValue: (item: VeloeCalculateRouteModel) =>
            <span>{CodeUtil.moneyFormat(item.totalDistance, false)} Km</span> },
        { title: "Paradas", property: "contrato", alignment: "center", onFormatValue: (item: VeloeCalculateRouteModel) =>
            <span>{item.stretches?.length ?? 0}</span> },
    ]

    const handlePesquisar = () => {
        setShowFiltros(false);
        setLoading(true);

        if (CodeUtil.isNullOrEmpty(filtro?.processingNumber?.trim())) {
            ShowToast({ message: "O número do processo deve ser preenchido." });
            setLoading(false);
            return;
        }

        VeloeService.getCustoRota(filtro?.processingNumber ?? "")
            .then(async (response) => {
                if (!response.success) {
                    ShowToast({ message: response.messages });
                    return;
                }

                if (response.data.length === 0) {
                    ShowToast({message: "Processo não encontrado."});
                }

                setDataSource(response.data);
            }).catch((error) => {
                console.log(error);
                ShowToast({ message: UNKOWN_EXCEPTION });
            }).finally(() => {
                setLoading(false);
            });
    }

    return {
        loading, columns,
        showFiltros, setShowFiltros,
        filtro, setFiltro,
        dataSource,
        handlePesquisar,
        modalProps, setModalProps
    }
}