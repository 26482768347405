import { VeiculoModeloModel } from '../models/veiculo_modelo';
import { BaseService } from "./BaseService";
import { DefaultResponse, ServiceProps } from "./types";

const ENDPOINT = `${BaseService.BASE_URL}/veiculo-modelo`;

type VeiculoModeloRequest = {
    marcaId?: number;
} & ServiceProps;

export class VeiculoModeloService extends BaseService {
    public static async get(props: VeiculoModeloRequest = { limit: 100, page: 1 }): Promise<DefaultResponse<VeiculoModeloModel>> {
        let _url = `${ENDPOINT}?limit=${props.limit}&page=${props.page}`;
        if ((props.marcaId ?? 0) > 0) _url += `&marcaId=${props.marcaId}`
        return await this.GET<VeiculoModeloModel>(_url);
    }

    public static async getId(id: number): Promise<DefaultResponse<VeiculoModeloModel>> {
        return await this.GET<VeiculoModeloModel>(`${ENDPOINT}/${id}`);
    }

    public static async add(model: VeiculoModeloModel): Promise<DefaultResponse<VeiculoModeloModel>> {
        return await this.POST<VeiculoModeloModel>(`${ENDPOINT}`, model);
    }

    public static async update(model: VeiculoModeloModel): Promise<DefaultResponse<VeiculoModeloModel>> {
        return await this.PATCH<VeiculoModeloModel>(`${ENDPOINT}/${model.id}`, model);
    }

    public static async delete(id: number): Promise<DefaultResponse<VeiculoModeloModel>> {
        return await this.DELETE(`${ENDPOINT}/${id}`);
    }
}