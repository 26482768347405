import { useCallback, useEffect, useRef, useState } from "react";
import { EncerramentoServicoModalProps, RegistroVeiculoFormFields } from "../types";
import { ViewCotacaoCredenciadaModel, getDefaultViewCotacaoCredenciada } from "../../../models/cotacao/ViewCotacaoCredenciada";
import { SessionManager } from "../../../utils/SessionManager";
import { CotacaoCredenciadaService } from "../../../services/CotacaoCredenciadaService";
import { ShowToast } from "../../CodeToast";
import { UNKOWN_EXCEPTION } from "../../../utils/Constants";
import { FluentValidator } from "../../../types";
import { CotacaoService } from "../../../services/CotacaoService";
import CodeUtil from "../../../utils/CodeUtil";
import { EntradaSaidaVeiculoService } from "../../../services/EntradaSaidaVeiculoService";
import { EntradaSaidaVeiculoModel } from "../../../models/entrada_saida_veiculo";

export const useEncerramentoServicoModal = (props: EncerramentoServicoModalProps) => {
    const [model, setModel] = useState<ViewCotacaoCredenciadaModel>(getDefaultViewCotacaoCredenciada());
    const inputFile = useRef<HTMLInputElement>(null);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [validation, setValidation] = useState<FluentValidator[]>([]);
    const [saving, setSaving] = useState<boolean>(false);
    const credenciadaId: number = SessionManager.getSession().user?.credenciadaId ?? props.credenciadaId ?? 0;
    const [registroSaida, setRegistroSaida] = useState<RegistroVeiculoFormFields>({
        codigoAutorizacao: '',
        marcaBateria: '',
        observacoes: '',
        quilometragem: 0
    });
    const [ultimoKm, setUltimoKm] = useState<number>(0);

    const fetchRegistrosEntrada = useCallback(async () => {
        try {
            const response = await EntradaSaidaVeiculoService.get(props.id!, credenciadaId);
            if (!response.success) {
                ShowToast({ message: response.messages });
                return;
            }

            const registrosEntrada : EntradaSaidaVeiculoModel[] = response.data;
            setUltimoKm(registrosEntrada.length === 0 ? 0 : registrosEntrada.slice(-1)[0]?.quilometragem ?? 0);
        } catch (error) {
            setUltimoKm(0);
            console.log(error);
        }
    }, [credenciadaId, props.id]);

    const onFormLoad = useCallback(async () => {
        if (!props.show || loaded) return;

        try {
            if (props.id === undefined || props.id === 0) {
                setModel(getDefaultViewCotacaoCredenciada());
                setLoaded(false);
                return;
            }

            const response = await CotacaoCredenciadaService.getId(props.id ?? 0, credenciadaId);

            if (!response.success) {
                ShowToast({ message: response.messages });
                props.onClose?.call(this);
                return;
            }

            await fetchRegistrosEntrada();
            setModel(response.data);
            setLoaded(true);
        } catch (error) {
            console.log(error);
            ShowToast({ message: UNKOWN_EXCEPTION });
        }
    }, [props.show, props.id, props.onClose, loaded, credenciadaId, fetchRegistrosEntrada]);

    
    const validate = (file: File | null): boolean => {
        let _errors: FluentValidator[] = [];

        if (file === null) _errors.push({ field: 'notaFiscal', isValid: false, message: 'A nota fiscal deve ser preenchida' });
        if (registroSaida.quilometragem < ultimoKm) _errors.push({ field: "quilometragem", message: `A quilometragem deve ser superior a ${ultimoKm} Km`, isValid: false });
        if (CodeUtil.isNullOrEmpty(registroSaida.codigoAutorizacao)) _errors.push({ field: "codigoAutorizacao", message: "O código de autorizaçao do condutor deve ser preenchido", isValid: false });

        setValidation(_errors);
        if (_errors.length > 0) ShowToast({ message: "Foram encontradas divergências no registro de saída do veículo."});

        return _errors.length === 0;
    }


    const onEncerrarOrdemServico = async () => {
        try {
        
            setSaving(true);
            const file = inputFile.current?.files !== null &&
                inputFile.current?.files !== undefined && inputFile.current?.files.length > 0 ?
                inputFile.current?.files[0] : null;
            
            if (!validate(file)) return;
    

            const response = await CotacaoService.encerrarCotacao({
                ...registroSaida,                                    
                cotacaoId: model.id!,
                credenciadaId: model.credenciadaId,
                entradaSaida: "Saída",                
            }, file!);

            if (!response.success) {
                ShowToast({ message: response.messages });
                return;
            }

            props.onSave?.call(this, undefined);
        }catch(error) {
            ShowToast({ message: UNKOWN_EXCEPTION });
            console.log(error);
        }finally {
            setSaving(false);
        }
    }

    useEffect(() => {
        onFormLoad();
    }, [onFormLoad]);

    return {
        model, setModel,
        registroSaida, setRegistroSaida,
        validation, saving,
        inputFile, onEncerrarOrdemServico
    }
}