import { FC } from "react";
import styled from "styled-components";
import { CodeStaticListProps } from "./types";

export const CodeStaticList: FC<CodeStaticListProps> = (props) => {
    let key: number = 0;

    return (
        <StyledCodeStaticList>
            {props.children?.map((item) => {
                key++;
                
                return <li key={key} onClick={item.props?.onClick} className={item.props?.selected ? "active" : ""}>
                    {item.props?.children}
                </li>
            })}
        </StyledCodeStaticList>
    );
};

const StyledCodeStaticList = styled.ul`
    > li {        
        padding: 0.75rem;
        border-left: solid 1.75px rgb(226, 232, 240);
        border-right: solid 1.75px rgb(226, 232, 240);
        border-top: solid 1.75px rgb(226, 232, 240);
    }

    > li:hover {
        background-color: rgba(226, 232, 240, 0.20);
        cursor: pointer;
    }

    > li.active {
        background-color: #408aeb39;
    }

    > li:first-child {
        border-radius: 0.55rem 0.55rem 0 0;    
        border-top: solid 1.75px rgb(226, 232, 240);
        border-bottom: none;
    }

    > li:last-child {
        border-bottom: solid 1.75px rgb(226, 232, 240);
        border-radius: 0 0 0.55rem 0.55rem;    
    }
`

export default CodeStaticList;