import { UsuarioService } from "../services/UsuarioService"
import { SessionManager } from "../utils/SessionManager"
import { UsuarioPermissaoModel } from "../models/usuario_permissao";
import { useCallback, useEffect, useState } from "react";

export const useBuscarPermissao = (menu: string) => {
    const [permissao, setPermissao] = useState<UsuarioPermissaoModel | undefined>();    
    const [loaded, setLoaded] = useState<boolean>(false);
    const user = SessionManager.getSession().user;

    const fetchPermissoes = useCallback(async () => {
        if (loaded || !user) return;

        const response = await UsuarioService.buscarPermissao(user?.id ?? 0, menu);
        if (response.success)
             setPermissao(response.data[0]);
        setLoaded(true);
    }, [loaded, menu, user]);

    useEffect(() => {
        fetchPermissoes()
    }, [fetchPermissoes]);

    return {
        permissao
    }
}