import { useState } from "react";
import { ICodeDataGridColumn } from "../../components/CondensedCodeDataGrid/types";
import { VeloeService } from "../../services/VeloeService";
import { ShowToast } from "../../components/CodeToast";
import { UNKOWN_EXCEPTION } from "../../utils/Constants";
import { VeloeSupplyHistoryModel } from "../../models/veloe/VeloeSupplyHistoryModel";
import { VeloeSupplyHistoryRequest } from "../../models/veloe/VeloeSupplyHistoryRequest";

export const useVeloeAbastecimentoPage = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [showFiltros, setShowFiltros] = useState<boolean>(false);
    const [dataSource, setDataSource] = useState<VeloeSupplyHistoryModel[]>([]);
    const [filtro, setFiltro] = useState<VeloeSupplyHistoryRequest>();

    const columns: ICodeDataGridColumn[] = [
        { title: "Nome da Empresa", property: "corporateName" },
        { title: "Placa", property: "vehiclePlate", alignment: "center" },
        { title: "Modelo", property: "vehicleModel" },
        { title: "Motorista", property: "driverName" },
        { title: "Matrícula", property: "registry" },
        { title: "Número do Cartão", property: "card" },
        { title: "Tipo de Combustível", property: "fuelType" },
        { title: "Litros", property: "amountLiters" },
        { title: "Valor Unitário", property: "unitValue", alignment: "right"},
        { title: "Valor Total", property: "stockedValue" },
        { title: "Saldo Cartão", property: "cardBalance" },
        { title: "Data", property: "transactionDate" , onFormatValue: (item: VeloeSupplyHistoryModel) => <span>{item.transactionDate}</span> },
        { title: "Status", property: "transactionStatus" },
        { title: "Posto", property: "supplyLocation" },
        { title: "Posto CNPJ", property: "ecContractee" },
        { title: "Logradouro", property: "gasStationAddress" },
        { title: "Hodômetro Ant.", property: "previousOdometer" },
        { title: "Hodômetro", property: "odometer" },
        { title: "Horímetro Ant.", property: "previousOrimeter" },
        { title: "Horímetro", property: "orimeter" },
        { title: "Km Percorrido", property: "kmTraveled" },
        { title: "Média de Consumo", property: "standardAverage" },
        { title: "Custo Km", property: "valueCostKmTraveled" },
        { title: "Índice Preço Alelo", property: "ipa" },
        { title: "Índice Preço ANP", property: "anpIndex" },
        { title: "Matrícula", property: "registration" },
        { title: "Filial", property: "branchName" },
        { title: "Base", property: "baseName" },
        { title: "Código da Bomba", property: "pumpCode" },
    ]

    const validar = () : boolean => {
        const plates = filtro?.veihclePlates?.filter(i => i);
        if ((plates?.length ?? 0) === 0) {
            setLoading(false);
            ShowToast({ message: "Ao menos uma placa deve ser informada." });
            return false;
        }

        return true;
    }

    const handlePesquisar = () => {
        if (!validar()) return;
        
        setShowFiltros(false);
        setLoading(true);

        

        VeloeService.getHistoricoAbastecimento(filtro)
            .then(async (response) => {
                if (!response.success) {
                    ShowToast({ message: response.messages });
                    return;
                }

                setDataSource(response.data);
            }).catch((error) => {
                console.log(error);
                ShowToast({ message: UNKOWN_EXCEPTION });
            }).finally(() => {
                setLoading(false);
            });
    }

    return {
        loading, columns,
        showFiltros, setShowFiltros,
        filtro, setFiltro,
        dataSource,
        handlePesquisar,
    }
}