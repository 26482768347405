import { EntradaSaidaVeiculoCommand } from "../commands/EntradaSaidaVeiculoCommand";
import { EntradaSaidaVeiculoModel } from "../models/entrada_saida_veiculo";
import { BaseService } from "./BaseService";
import { DefaultResponse, ServiceProps } from "./types";

const ENDPOINT = `${BaseService.BASE_URL}/cotacao_credenciada/entrada_saida_veiculo`;

export class EntradaSaidaVeiculoService extends BaseService {
    public static async get(cotacaoId: number, credenciadaId: number, props: ServiceProps = { limit: 100, page: 1 }): Promise<DefaultResponse<EntradaSaidaVeiculoModel>> {
        return await this.GET<EntradaSaidaVeiculoModel>(`${ENDPOINT}?cotacaoId=${cotacaoId}
            &credenciadaId=${credenciadaId}
            &limit=${props.limit}&page=${props.page}`);
    }

    public static async getId(id: number): Promise<DefaultResponse<EntradaSaidaVeiculoModel>> {
        return await this.GET<EntradaSaidaVeiculoModel>(`${ENDPOINT}/${id}`);
    }

    public static async add(model: EntradaSaidaVeiculoCommand): Promise<DefaultResponse<EntradaSaidaVeiculoModel>> {
        return await this.POST<EntradaSaidaVeiculoCommand>(`${ENDPOINT}`, model);
    }

    public static async update(model: EntradaSaidaVeiculoCommand): Promise<DefaultResponse<EntradaSaidaVeiculoModel>> {
        return await this.PATCH<EntradaSaidaVeiculoCommand>(`${ENDPOINT}/${model.id}`, model);
    }

    public static async delete(id: number): Promise<DefaultResponse<EntradaSaidaVeiculoModel>> {
        return await this.DELETE(`${ENDPOINT}/${id}`);
    }
}