import axios, { AxiosError, AxiosStatic } from "axios";
import { SessionManager } from "../utils/SessionManager";
import { DefaultResponse, AsDefaultResponse, DEFAULT_APIURL } from "./types";
import { Config } from '../env.config';
import { UNKOWN_EXCEPTION } from "../utils/Constants";

export abstract class BaseService {

    public static BASE_URL = `${Config.API_URL ?? DEFAULT_APIURL}/api`;

    protected static refreshAuthorization = (_axios: AxiosStatic) => {
        _axios.defaults.headers.Authorization = `Bearer ${SessionManager.getSession()?.user?.token}`;
    }

    protected static redirectToAuth = (statusCode: number | undefined) => {
        if (statusCode === 401) {
            window.location.href = "/auth/login";
        }
    }

    protected static async GET<T>(endpoint: string): Promise<DefaultResponse<T>> {
        try {
            this.refreshAuthorization(axios)
            return (await axios.get(endpoint)).data as DefaultResponse<T>
        } catch (error: any) {
            let axiosError = (error as AxiosError)

            this.redirectToAuth(axiosError.response?.status);

            if (axiosError.response?.data === null || axiosError.response?.data === undefined) {
                return AsDefaultResponse((error as AxiosError).message, [])
            }

            return axiosError.response!.data as DefaultResponse<T>;
        }
    }

    protected static async POST<T>(endpoint: string, data: unknown): Promise<DefaultResponse<T>> {
        try {

            this.refreshAuthorization(axios);
            return (await axios.post(endpoint, data)).data as DefaultResponse<T>;
        } catch (error: any) {
            console.log(error);
            let axiosError = (error as AxiosError);

            this.redirectToAuth(axiosError.response?.status);

            if (axiosError.response?.data === null || axiosError.response?.data === undefined) {
                return AsDefaultResponse((error as AxiosError).message, []);
            }

            return axiosError.response!.data as DefaultResponse<T>;
        }
    }

    protected static async REQUESTREPORT(endpoint: string, data: unknown): Promise<Blob | null> {
        try {

            axios.defaults.headers.post['Access-Control-Allow-Origin'] = BaseService.BASE_URL;
            this.refreshAuthorization(axios);
            const response = await axios.post(endpoint, data, {
                responseType: 'arraybuffer'
            });

            if (response.status !== 200)
                return null;

            const reponseData = response.data;
            const bufferArray = new Uint8Array(reponseData).buffer;
            return new Blob([bufferArray], { type: "application/pdf"});
        } catch (error: any) {
            console.log(error);
            throw error;
        }
    }

    protected static async POSTFILE<T>(endpoint: string, data: T): Promise<T> {
        try {

            axios.defaults.headers.post['Access-Control-Allow-Origin'] = BaseService.BASE_URL;
            this.refreshAuthorization(axios);
            return (await axios.post(endpoint, data)).data;
        } catch (error: any) {
            let axiosError = (error as AxiosError);

            this.redirectToAuth(axiosError.response?.status);

            if (axiosError.response?.data === null || axiosError.response?.data === undefined) {
                throw error;
            }

            throw new Error(UNKOWN_EXCEPTION);
        }
    }

    protected static async POSTFORM<T>(endpoint: string, data: FormData): Promise<DefaultResponse<T>> {
        try {

            this.refreshAuthorization(axios);
            return (await axios.postForm(endpoint, data)).data as DefaultResponse<T>;
        } catch (error: any) {
            let axiosError = (error as AxiosError);

            this.redirectToAuth(axiosError.response?.status);

            if (axiosError.response?.data === null || axiosError.response?.data === undefined) {
                return AsDefaultResponse((error as AxiosError).message, []);
            }

            return axiosError.response!.data as DefaultResponse<T>;
        }
    }

    protected static async PATCH<T>(endpoint: string, data: T): Promise<DefaultResponse<T>> {
        try {

            this.refreshAuthorization(axios);
            return (await axios.patch(endpoint, data)).data as DefaultResponse<T>;
        } catch (error: any) {
            console.log(error);
            let axiosError = (error as AxiosError);

            this.redirectToAuth(axiosError.response?.status);

            if (axiosError.response?.data === null || axiosError.response?.data === undefined) {
                return AsDefaultResponse((error as AxiosError).message, []);
            }

            return axiosError.response!.data as DefaultResponse<T>;
        }
    }


    protected static async PATCHFORM<T>(endpoint: string, data: FormData): Promise<DefaultResponse<T>> {
        try {

            this.refreshAuthorization(axios);
            return (await axios.patchForm(endpoint, data)).data as DefaultResponse<T>;
        } catch (error: any) {
            console.log(error);
            let axiosError = (error as AxiosError);

            this.redirectToAuth(axiosError.response?.status);

            if (axiosError.response?.data === null || axiosError.response?.data === undefined) {
                return AsDefaultResponse((error as AxiosError).message, []);
            }

            return axiosError.response!.data as DefaultResponse<T>;
        }
    }

    protected static async DELETE(endpoint: string): Promise<DefaultResponse> {
        try {

            this.refreshAuthorization(axios);

            return (await axios.delete(endpoint)).data as DefaultResponse;
        } catch (error: any) {
            let axiosError = (error as AxiosError);

            this.redirectToAuth(axiosError.response?.status);

            if (axiosError.response?.data === null || axiosError.response?.data === undefined) {
                return AsDefaultResponse((error as AxiosError).message, []);
            }

            return axiosError.response!.data as DefaultResponse;
        }
    }
}