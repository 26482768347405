import { useState, useCallback, useEffect } from "react";
import { ShowToast } from "../../components/CodeToast";
import { ModalFormProps } from "../../components/ModalForm/types"
import { FormStateType } from "../../enums";
import { GestorContaModel } from "../../models/gestor_conta";
import { GestorContaService } from "../../services/GestorContaService";
import { UNKOWN_EXCEPTION } from "../../utils/Constants";
import { useConsultarCep } from "../../hooks/useConsultarCep";
import { MunicipioModel } from "../../models/municipio";
import { DefaultCEPResponse } from "../../services/types";
import CodeUtil from "../../utils/CodeUtil";
import { FluentValidator } from "../../types";
import { ConfiguracaoModel } from "../../models/configuracao/ConfiguracaoModel";
import { ConfiguracaoService } from "../../services/ConfiguracaoService";

export const useGestorContasCadastroPage = (props: ModalFormProps) => {
    const [loaded, setLoaded] = useState<boolean>(false);
    const readonly = props.state === FormStateType.view;
    const [model, setModel] = useState<GestorContaModel>({
        isSelected: true
    });
    const [saving, setSaving] = useState<boolean>(false);
    const [formErrors, setFormErrors] = useState<FluentValidator[]>([]);
    const [errors, setErrors] = useState<string[]>([]);
    const [configuracoes, setConfiguracoes] = useState<ConfiguracaoModel>({
        percentualComissao: 0,
        prazoValidade: 7
    });
    const {
        buscandoCep, estados, municipios,
        onLoadEstados, onLoadMunicipios, consultarCep
    } = useConsultarCep({ onAfterBuscarCep: (response, municipio) => handleAfterBuscarCep(response, municipio) })

    const onLoadConfiguracoes = useCallback(async () => {
        const response = await ConfiguracaoService.get();
        if (!response.success) {
            return;
        }

        setConfiguracoes(response.data);
    }, []);

    const onPageLoad = useCallback(async () => {
        if (loaded) return;

        try {
            await onLoadConfiguracoes();
            await onLoadEstados();

            if (props.id) {
                const response = await GestorContaService.getId(props.id ?? 0);
                if (!response.success) {
                    ShowToast({ message: response.messages, error: true });
                    setErrors(response.messages);
                    return;
                }

                setModel(response.data[0]);
                await onLoadMunicipios(response.data[0]?.uf?.sigla);
                return;
            }

            setModel({
                ...model,
                permitirVisualizarCatalogos: false,
                bloqueado: false,
                percentualDescontoContrato: 0,
                percentualComissaoRodar: props.state === FormStateType.add ? configuracoes?.percentualComissao : 0,
            });

        } catch (error) {
            console.log(error);
            ShowToast({ message: UNKOWN_EXCEPTION, error: true });
        } finally {
            setLoaded(true);
        }
    }, [configuracoes?.percentualComissao, loaded, model, onLoadConfiguracoes, onLoadEstados, onLoadMunicipios, props.id, props.state]);

    useEffect(() => {
        onPageLoad();
    }, [onPageLoad]);


    const handleAfterBuscarCep = (response: DefaultCEPResponse | null, municipio?: MunicipioModel | null) => {
        if (!response || !municipio) return;

        setModel({
            ...model,
            cep: response.cep ?? "",
            logradouro: response.logradouro ?? "",
            bairro: response.bairro ?? "",
            municipioId: municipio.id,
            municipio: {
                id: municipio?.id,
                descricao: response.localidade ?? "",
            },
            uf: {
                id: CodeUtil.parseInt(response.ddd) ?? 0,
                sigla: response.uf,
            }
        });
    }

    const validate = (): boolean => {
        const _errors: FluentValidator[] = [];

        if (CodeUtil.isNullOrEmpty(model.razaoSocial))
            _errors.push({ ..._errors, field: "razaoSocial", message: "Razão Social é obrigatório", isValid: false });

        if (CodeUtil.isNullOrEmpty(model.cnpj))
            _errors.push({ ..._errors, field: "cnpj", message: "CNPJ é obrigatório", isValid: false });

        if (!CodeUtil.isValidEmail(model.email ?? ""))
            _errors.push({ ..._errors, field: "email", message: "Informe um e-mail válido", isValid: false });

        if (!model.municipioId)
            _errors.push({ ..._errors, field: "municipioId", message: "Município é obrigatório", isValid: false });

        setFormErrors(_errors);
        return _errors.length === 0;
    }

    const handleSave = async (e: React.MouseEvent<HTMLButtonElement> | undefined) => {
        setSaving(true);
        try {
            if (!validate()) return;

            if (process.env.NODE_ENV !== "production") console.log(model);

            const response = await GestorContaService.save(model);
            if (!response.success) {
                ShowToast({ message: response.messages, error: true });
                setErrors(response.messages);
                return;
            }

            props.onSave?.call(this, e);
        } finally {
            setSaving(false);
        }
    }

    return {
        model, setModel, formErrors, errors, saving, loaded,
        consultarCep, buscandoCep, estados, municipios,
        onLoadEstados, onLoadMunicipios,
        readonly, handleSave
    }
}