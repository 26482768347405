import { useCallback, useEffect, useState } from "react"
import { ICodeDataGridColumn, ICodeDataGridPagination, ICodeDataGridRow } from "../CodeDataGrid/types"
import { ViewCotacaoCredenciadaModel } from "../../models/cotacao/ViewCotacaoCredenciada"
import { IFiltroCotacao } from "../../types"
import CodeUtil from "../../utils/CodeUtil"
import { format } from "fecha"
import { CotacaoStatusType, FormStateType, UserType } from "../../enums"
import { Badge } from "../Badge"
import { getCotacaoStatus, getCotacaoStatusColor } from "../../models/cotacao/CotacaoModel"
import { SessionManager } from "../../utils/SessionManager"
import { CotacaoCredenciadaService } from "../../services/CotacaoCredenciadaService"
import { FILTRO_COTACOES, UNKOWN_EXCEPTION } from "../../utils/Constants"
import { ShowToast } from "../CodeToast"
import { MenuItem } from "primereact/menuitem"
import { PrimeIcons } from "primereact/api"
import { CotacaoService } from "../../services/CotacaoService"
import { CotacaoPanelModalAprovacaoProps, CotacaoPanelPopupProps, TipoCotacaoAutenticacao } from "./types"
import { RelatorioService } from "../../services/RelatorioService"
import { Tooltip } from "flowbite-react"

type CotacaoPanelFormProps = {
    id?: number | null
    credenciadaId?: number | null
    show: boolean
    state: FormStateType
    onClose?: () => void
    onSave?: () => void
}

export const useCotacoesPanel = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [contentLoaded, setContentLoaded] = useState<boolean>(false);
    const [page, setPage] = useState<number>(1);
    const [pagination, setPagination] = useState<Omit<ICodeDataGridPagination, 'onPageChanged'>>({ page: 1, pageCount: 0, limit: 100, recordCount: 0 });
    const [dataSource, setDataSource] = useState<ViewCotacaoCredenciadaModel[]>([]);
    const [selected, setSelected] = useState<ViewCotacaoCredenciadaModel | null>(null);
    const [popup, setPopup] = useState<CotacaoPanelPopupProps>({ title: '', show: false, destructive: false });
    const [form, setForm] = useState<CotacaoPanelFormProps>({ show: false, state: FormStateType.view });
    const [hiddenMoreFilters, setHiddenMoreFilters] = useState<boolean>(true);
    const [twoFactorAuth, setTwoFactorAuth] = useState<TipoCotacaoAutenticacao>({ show: false, type: "APROVACAO", itens: [] });
    const [modalItensState, setModalItensState] = useState<CotacaoPanelModalAprovacaoProps>({
        show: false,
        state: FormStateType.edit,
        saveButtonTitle: "Aprovar",
        onSave: (items: number[]) => {
            setTwoFactorAuth({
                show: true,
                type: "APROVACAO",
                itens: items
            });
        },
        onClose: () => setModalItensState({ ...modalItensState, show: false })
    });

    const sessionUser = SessionManager.getSession().user;

    const getFiltroCotacoes = () => {
        return localStorage.getItem(FILTRO_COTACOES) ? JSON.parse(localStorage.getItem(FILTRO_COTACOES) || "{}") : {
            dataInicial: format(CodeUtil.addDays(-30, new Date(Date.now())), 'YYYY-MM-DD'),
            dataFinal: format(new Date(Date.now()), 'YYYY-MM-DD'),
            status: [CotacaoStatusType.AGUARDANDO, CotacaoStatusType.COTACAO_ENVIADA]
        };
    }
    const [filtros, setFiltros] = useState<IFiltroCotacao>(getFiltroCotacoes());

    const columnMelhorCotacao: ICodeDataGridColumn = {
        title: " ", property: "melhorCotacao", alignment: "center", onFormatValue: (value: ViewCotacaoCredenciadaModel) =>
            <Tooltip content="Melhor cotação" children={value.melhorCotacao && <span className="text-center select-none" children="⭐" />} />
    };

    const columnEmpenho: ICodeDataGridColumn = {
        title: " ",
        property: "empenhoId",
        alignment: "center",
        onFormatValue: (value: ViewCotacaoCredenciadaModel) => <span>{value.empenhoId ? <Badge color={'yellow'} value={'EMPENHO'} /> : <Badge color={'default'} value={'AVULSA'} />}</span>
    };

    const columns: ICodeDataGridColumn[] = [
        { title: "número", property: "id", alignment: "center", onFormatValue: (value: ViewCotacaoCredenciadaModel) => <span>{String(value.id).padStart(4, "0")}</span> },
        { title: "data", property: "dataCadastro", alignment: "center", onFormatValue: (value: ViewCotacaoCredenciadaModel) => <span>{`${CodeUtil.formatStringDate(value.dataCadastro)}`}</span> },
        { title: "validade", property: "dataValidade", alignment: "center", onFormatValue: (value: ViewCotacaoCredenciadaModel) => <span>{`${CodeUtil.formatStringDate(value.dataValidade)}`}</span> },
        { title: "situação", property: "statusDescricao", alignment: "center", onFormatValue: (model) => <span><Badge color={getCotacaoStatusColor(model.status)} value={getCotacaoStatus(model.status)} /></span> },
        { title: `${sessionUser?.gestorContaId ? "unidade" : "cliente"}`, property: "cliente.razaoSocial" },
        { title: "cnpj", property: "cliente.cnpj", alignment: "left", onFormatValue: (value: ViewCotacaoCredenciadaModel) => <span>{CodeUtil.mask(value.cliente?.cnpj ?? "", "##.###.###/####-##")}</span> },
        { title: "credenciada", property: "razaoSocial" },
        { title: "fantasia", property: "fantasia" },
        { title: "marca", property: "veiculo.marca.nome" },
        { title: "modelo", property: "veiculo.modelo.nome" },
        { title: "placa", property: "veiculo.placa" },
        { title: "renavam", property: "veiculo.renavam" },
        { title: "órgão", property: "veiculo.orgao" },
        { title: "desconto", property: "desconto", alignment: "right", onFormatValue: (value: ViewCotacaoCredenciadaModel) => <span>{CodeUtil.moneyFormat(value.desconto)}</span> },
        { title: "valor total", property: "valorTotal", alignment: "right", onFormatValue: (value: ViewCotacaoCredenciadaModel) => <span>{CodeUtil.moneyFormat(value.valorTotal)}</span> },
    ];

    const columnsCliente: ICodeDataGridColumn[] = [
        { title: "número", property: "id", alignment: "center", onFormatValue: (value: ViewCotacaoCredenciadaModel) => <span>{String(value.id).padStart(4, "0")}</span> },
        { title: "data", property: "dataCadastro", alignment: "center", onFormatValue: (value: ViewCotacaoCredenciadaModel) => <span>{`${CodeUtil.formatStringDate(value.dataCadastro)}`}</span> },
        { title: "validade", property: "dataValidade", alignment: "center", onFormatValue: (value: ViewCotacaoCredenciadaModel) => <span>{`${CodeUtil.formatStringDate(value.dataValidade)}`}</span> },
        { title: "situação", property: "statusDescricao", alignment: "center", onFormatValue: (model) => <span><Badge color={getCotacaoStatusColor(model.status)} value={getCotacaoStatus(model.status)} /></span> },
        { title: "credenciada", property: "razaoSocial" },
        { title: "fantasia", property: "fantasia" },
        { title: "marca", property: "veiculo.marca.nome" },
        { title: "modelo", property: "veiculo.modelo.nome" },
        { title: "placa", property: "veiculo.placa" },
        { title: "renavam", property: "veiculo.renavam" },
        { title: "órgão", property: "veiculo.orgao" },
        { title: "desconto", property: "desconto", alignment: "right", onFormatValue: (value: ViewCotacaoCredenciadaModel) => <span>{CodeUtil.moneyFormat(value.desconto)}</span> },
        { title: "valor total", property: "valorTotal", alignment: "right", onFormatValue: (value: ViewCotacaoCredenciadaModel) => <span>{CodeUtil.moneyFormat(value.valorTotal)}</span> },
    ];

    const fetchData = useCallback(async (filter?: IFiltroCotacao, index?: number) => {
        try {

            const _filtros = filter ?? filtros;
            const defaultStatus = [CotacaoStatusType.AGUARDANDO, CotacaoStatusType.COTACAO_ENVIADA, CotacaoStatusType.REPROVADA, CotacaoStatusType.CANCELADA];

            setLoading(true);
            setSelected(null);

            let credenciadaId = sessionUser?.credenciadaId;
            let clienteId = sessionUser?.clienteId;
            let promise = credenciadaId ? CotacaoCredenciadaService.get(credenciadaId, {
                page: index ?? page,
                limit: 100,
                dataCadastroIni: _filtros.dataInicial,
                dataCadastroFim: _filtros.dataFinal,
                status: _filtros.status?.length === 0 ? defaultStatus : _filtros.status
            }) : CotacaoCredenciadaService.getAll({
                clienteId: clienteId,
                page: index ?? page,
                limit: 100,
                dataCadastroIni: _filtros.dataInicial,
                dataCadastroFim: _filtros.dataFinal,
                status: _filtros.status?.length === 0 ? defaultStatus : _filtros.status
            });

            promise.then((response) => {
                setDataSource(response.data);
                setPagination({
                    page: response.pagination.page,
                    pageCount: response.pagination.pageCount,
                    limit: response.pagination.limit,
                    recordCount: response.pagination.recordCount
                });
            }).catch((error) => {
                console.log(error);
                ShowToast({ message: UNKOWN_EXCEPTION });
            }).finally(() => {
                setContentLoaded(true);
            });
        } catch (error) {
            console.log(error);
            setDataSource([]);
        } finally {
            setLoading(false);
            setContentLoaded(true);
        }
    }, [filtros, sessionUser?.credenciadaId, sessionUser?.clienteId, page]);

    const onMount = useCallback(async () => {
        if (contentLoaded) return;

        await fetchData();
    }, [contentLoaded, fetchData]);

    useEffect(() => {
        onMount();
    }, [onMount]);

    const onPageChanged = async (idx: number) => {
        setPage(idx);
        await fetchData(filtros, idx);
    }

    const getColumns = (): ICodeDataGridColumn[] => {
        return sessionUser?.tipoUsuario === UserType.customer ?
            [columnMelhorCotacao, columnEmpenho, ...columnsCliente] :
            sessionUser?.tipoUsuario === UserType.admin || sessionUser?.tipoUsuario === UserType.gestor ?
                [columnMelhorCotacao, columnEmpenho, ...columns] : columns;
    }

    const cantAprovarReprovar = (): boolean => {
        return !selected ||
            selected.status === CotacaoStatusType.REPROVADA ||
            selected.status === CotacaoStatusType.APROVADA ||
            selected.status === CotacaoStatusType.ENCERRADA;
    }

    const getContextMenuItems = (): MenuItem[] => {
        let menuItemCotacao: MenuItem[] = [
            { label: "Ver detalhes", icon: PrimeIcons.EYE, disabled: !selected, command: verCotacao },
        ];

        if (sessionUser?.tipoUsuario !== UserType.dealer) {
            menuItemCotacao.push(
                ...[{
                    separator: true,
                    visible: sessionUser?.permitirAprovarCotacao,
                },
                {
                    label: "Aprovar cotação",
                    icon: PrimeIcons.CHECK,
                    disabled: cantAprovarReprovar(),
                    visible: sessionUser?.permitirAprovarCotacao,
                    command: () => setModalItensState({
                        ...modalItensState,
                        id: selected?.id,
                        credenciadaId: selected?.credenciadaId,
                        show: true
                    })
                },
                {
                    label: "Reprovar cotação",
                    icon: PrimeIcons.TRASH,
                    disabled: cantAprovarReprovar(),
                    visible: sessionUser?.permitirReprovarCotacao,
                    command: () => setPopup({
                        show: true,
                        destructive: true,
                        title: 'Deseja realmente reprovar esta cotação? Atenção: Este processo não poderá ser desfeito.',
                        confirmButtonTitle: 'Reprovar',
                        onCancel: () => setPopup({ ...popup, show: false }),
                        onConfirm: () => {
                            setPopup({ ...popup, show: false });
                            setTwoFactorAuth({ show: true, type: "REPROVACAO" });
                        },
                    })
                },

                { separator: true },
                { label: "Imprimir cotação", icon: PrimeIcons.PRINT, disabled: !selected, command: solicitarImpressao },
                { separator: true },
                ]);
        }

        if (sessionUser?.tipoUsuario !== UserType.customer && sessionUser?.tipoUsuario !== UserType.gestor) {
            menuItemCotacao.push(...[{
                label: "Preencher cotação",
                icon: PrimeIcons.PENCIL,
                disabled: !selected,
                command: handlePreencherCotacao
            },
            { separator: true }]);
        }

        menuItemCotacao.push(...[{ label: "Atualizar", icon: PrimeIcons.REFRESH, command: async () => await fetchData() }]);
        return menuItemCotacao;
    }

    const solicitarImpressao = async () => {
        ShowToast({ message: "Impressão solicitada! Enviaremos uma notificação quando a impressão estiver pronta." });

        const report = await imprimirCotacao();
        if (!report) {
            ShowToast({ message: UNKOWN_EXCEPTION });
            return;
        }

        CodeUtil.generateTempFileUrl(report);
    }

    const imprimirCotacao = async (): Promise<Blob | null> => {
        if (!selected) return null;

        if (selected?.credenciadaId) {
            return await RelatorioService.gerarImpressaoCotacaoCredenciada(selected.id!, selected.credenciadaId!);
        }

        return await RelatorioService.gerarImpressaoCotacao(selected.id!);
    }

    const onRowSelect = (item: ICodeDataGridRow) => {
        if (!item.selected || !item.item) {
            setSelected(null);
            return;
        }

        setSelected(item.item);
    }

    const verCotacao = async () => {
        if (!selected || selected.id === null || selected.id === undefined) return;

        setForm({
            ...form,
            id: selected.id,
            credenciadaId: selected.credenciadaId,
            state: FormStateType.view,
            show: true,
            onClose: () => {
                setForm({ ...form, show: false });
                setContentLoaded(false);
            }
        });
    }

    const handlePreencherCotacao = async () => {
        if (!selected || selected.id === null || selected.id === undefined) return;

        setForm({
            ...form,
            id: selected.id,
            credenciadaId: selected.credenciadaId,
            state: FormStateType.edit,
            show: true,
            onClose: () => {
                setForm({ ...form, show: false });
                setContentLoaded(false);
            }
        });
    }

    const aprovarCotacao = async (itens: number[] | undefined) => {
        if (!selected || selected.id === null || selected.id === undefined) {
            setTwoFactorAuth({ ...twoFactorAuth, show: false });
            return;
        }

        CotacaoService.aprovarCotacao(selected.id, selected.credenciadaId, itens ?? [])
            .then((response) => {
                if (!response.success) ShowToast({ message: response.messages });
            }).catch((error) => {
                ShowToast({ message: "Não foi possível aprovar a cotação." });
                console.log(error);
            }).finally(() => {
                setTwoFactorAuth({ ...twoFactorAuth, show: false });
                setModalItensState({ ...modalItensState, show: false });
                setContentLoaded(false);
            });
    }

    const reprovarCotacao = async () => {
        if (!selected || selected.id === null || selected.id === undefined) {
            setTwoFactorAuth({ ...twoFactorAuth, show: false });
            return;
        }

        const motivoReprovacao = sessionUser?.tipoUsuario === UserType.customer ? "Reprovada pelo cliente" : "Reprovada pela Rodar";
        CotacaoCredenciadaService.reprovarCotacao(selected.id, selected.credenciadaId, motivoReprovacao)
            .then((response) => {
                if (!response.success) ShowToast({ message: response.messages });
            }).catch((error) => {
                ShowToast({ message: "Não foi possível reprovar a cotação." });
                console.log(error);
            }).finally(() => {
                setTwoFactorAuth({ ...twoFactorAuth, show: false });
                setModalItensState({ ...modalItensState, show: false });
                setContentLoaded(false);
            });
    }

    const onAuthenticate = () => {
        if (twoFactorAuth.type === "APROVACAO") aprovarCotacao(twoFactorAuth.itens);
        if (twoFactorAuth.type === "REPROVACAO") reprovarCotacao();
    }

    const onCancelAuthentication = () => setTwoFactorAuth({ ...twoFactorAuth, show: false });

    const onApplyFilter = async (value: IFiltroCotacao) => {
        await fetchData(value);
        setHiddenMoreFilters(true);
    }

    return {
        loading,
        dataSource,
        popup, form,
        pagination, onPageChanged,
        getContextMenuItems,
        getColumns, onRowSelect,
        hiddenMoreFilters, setHiddenMoreFilters,
        filtros, setFiltros, onApplyFilter,
        twoFactorAuth, onAuthenticate, onCancelAuthentication,
        showModalItens: modalItensState
    };
};