import { BaseModel } from "../base";

export interface VeiculoModeloModel extends BaseModel {
    marcaId?: number;
    modelo: string;
    ativo: boolean;
    state: 'UNCHANGED' | 'ADDED' | 'UPDATED' | 'DELETED'
}

export const defaultModelo: VeiculoModeloModel = {
    modelo: "",
    ativo: true,
    isSelected: false,
    state: 'UNCHANGED'
}