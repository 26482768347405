import { FC } from "react"
import { CondensedCodeDataGridCheckboxColumn } from "./CondensedCodeDataGridCheckboxColumn"

export interface CondensedCodeDataGridColumnHeaderProps {
    className?: string
    multiselect?: boolean
    selectedAll?: boolean
    readOnly?: boolean
    children?: JSX.Element | JSX.Element[]
    onSelectAll?: (selected: boolean) => void
}

export const CondensedCodeDataGridColumnHeader: FC<CondensedCodeDataGridColumnHeaderProps> = (props) => {

    const renderCheckboxColumn = () => {
        return !!props.multiselect ?
            <CondensedCodeDataGridCheckboxColumn checked={props.selectedAll ?? false} onCheck={props.onSelectAll} /> :
            <th className="px-6" />;
    };

    return (
        <thead className={`${props.className ?? ''} text-xs text-gray-700 uppercase bg-gray-50 border-b-2`}>
            <tr>
                {props.readOnly ? <></> : renderCheckboxColumn()}
                {props.children}
            </tr>
        </thead>
    )
}