import { useState } from "react";
import { ICodeDataGridColumn } from "../../components/CondensedCodeDataGrid/types";
import { VeloeService } from "../../services/VeloeService";
import { ShowToast } from "../../components/CodeToast";
import { UNKOWN_EXCEPTION } from "../../utils/Constants";
import { format } from "fecha";
import { VeloeRechargeAccountModel } from "../../models/veloe/VeloeRechargeAccountModel";
import { VeloeRechargeAccountRequest } from "../../models/veloe/VeloeRechargeAccountRequest";
import CodeUtil from "../../utils/CodeUtil";
import { FormStateType } from "../../enums";
import { VeloeRecargaModalProps } from "./VeloeRecargaModalPage.hook";

export const useVeloeRecargaPage = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [showFiltros, setShowFiltros] = useState<boolean>(false);
    const [dataSource, setDataSource] = useState<VeloeRechargeAccountModel[]>([]);
    const [filtro, setFiltro] = useState<VeloeRechargeAccountRequest>();
    const [modalProps, setModalProps] = useState<VeloeRecargaModalProps>({
        show: false,
        state: FormStateType.add,
        onClose: () => {
            setModalProps({ ...modalProps, show: false })
        },
        onSave: (_e, processingNumber) => {
            setModalProps({ ...modalProps, show: false });
            handlePesquisar(processingNumber);
        }
    });

    const columns: ICodeDataGridColumn[] = [
        {
            title: "Criado Em", property: "createdDate", onFormatValue: (item: VeloeRechargeAccountModel) =>
                <span>{!item.createdDate ? "" : format(new Date(item.createdDate), "DD/MM/YYYY")}</span>
        },
        {
            title: "Alterado Em", property: "lastModifiedDate", onFormatValue: (item: VeloeRechargeAccountModel) =>
                <span>{!item.lastModifiedDate ? "" : format(new Date(item.lastModifiedDate), "DD/MM/YYYY")}</span>
        },
        { title: "Número do Processo", property: "processingNumber" },
        { title: "Status", property: "status", alignment: "center" },
    ]

    const handlePesquisar = (processingNumber?: string | null) => {
        setShowFiltros(false);
        setLoading(true);

        let process = processingNumber ?? filtro?.processingNumber?.trim();

        if (!processingNumber) {
            if (CodeUtil.isNullOrEmpty(process)) {
                ShowToast({ message: "O número do processo deve ser preenchido." });
                setLoading(false);
                return;
            }
        }

        VeloeService.getRecargaComplementar({ ...filtro, processingNumber: process })
            .then(async (response) => {
                if (!response.success) {
                    ShowToast({ message: response.messages });
                    return;
                }

                if (response.data.length === 0) {
                    ShowToast({ message: "Processo não encontrado." });
                }

                setDataSource(response.data);
            }).catch((error) => {
                console.log(error);
                ShowToast({ message: UNKOWN_EXCEPTION });
            }).finally(() => {
                setLoading(false);
            });
    }

    return {
        loading, columns,
        showFiltros, setShowFiltros,
        filtro, setFiltro,
        dataSource,
        handlePesquisar,
        modalProps, setModalProps
    }
}