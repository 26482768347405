import { FC } from "react";
import { CodeLabel } from "../CodeLabel";
import { Button, Select, TextInput } from "flowbite-react";
import { SidebarFiltroProdutosProps, useSidebarFiltroProdutos } from "./SidebarFiltroProdutos.hook";
import FlatButton from "../FlatButton";

export const SidebarFiltroProdutos: FC<SidebarFiltroProdutosProps> = (props) => {

    const { handleFiltrar, form, setForm, fabricantes } = useSidebarFiltroProdutos(props);

    return (
        <div className={"fixed h-screen w-screen z-50"}>
            <div className="bg-black/30 shadow overflow-y-auto fixed w-screen h-screen z-50 left-0 top-0 transition-transform" onClickCapture={props.onClose} />

            <div className="bg-white shadow overflow-y-auto fixed w-96 h-screen z-50 right-0 top-0 transition-transform p-6">
                <h1 className="pt-6 text-3xl font-bold">Mais Filtros</h1>
                <hr className="mt-3 mb-5" />

                <div className="grid grid-cols-12 gap-3">
                    <div className="col-span-12 md:col-span-9">
                        <CodeLabel value="Código Fornecedor:" htmlFor="inputCodigo" className="mb-1" />
                        <TextInput
                            id="inputCodigo"
                            style={{ textTransform: "uppercase" }}
                            defaultValue={form?.codigoFornecedor}
                            onBlur={(e) => setForm({
                                ...form,
                                codigoFornecedor: e.currentTarget.value?.toUpperCase()
                            })} />
                    </div>

                    <div className="col-span-12">
                        <CodeLabel value="Fabricante:" htmlFor="inputFabricante" className="mb-1" />
                        <Select
                            id="inputFabricante"
                            style={{ textTransform: "uppercase" }}
                            value={form?.fabricante ?? undefined}
                            onChange={(e) => setForm({
                                ...form,
                                fabricante: e.currentTarget.value ? parseInt(e.currentTarget.value) : undefined
                            })}>
                            <option value={0}>Selecione um fabricante</option>
                            {fabricantes.map((fabricante, key) => <option key={key} value={fabricante.id}>{fabricante.marca}</option>)}
                        </Select>
                    </div>

                    <div className="col-span-12">
                        <CodeLabel value="Nome:" htmlFor="inputNome" className="mb-1" />
                        <TextInput
                            id="inputNome"
                            style={{ textTransform: "uppercase" }}
                            defaultValue={form?.nome}
                            onBlur={(e) => setForm({
                                ...form,
                                nome: e.currentTarget.value?.toUpperCase()
                            })} />
                    </div>

                    <div className="col-span-12">
                        <hr className="mt-5 mb-3" />
                        <div className="flex ml-auto items-center gap-x-1.5">
                            <div className="ml-auto">
                                <FlatButton label="Cancelar" onClick={props.onClose} />
                            </div>
                            <Button size={"sm"} color="dark" onClick={handleFiltrar}>Filtrar</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}