import { BaseModel } from "../base";
import { VeiculoModeloModel } from "../veiculo_modelo";

export interface VeiculoMarcaModel extends BaseModel {
    marca: string;
    ativa: boolean;
    logomarca?: string;
    modelos: VeiculoModeloModel[]
}

export const defaultMarca: VeiculoMarcaModel = {
    marca: "",
    ativa: true,
    isSelected: false,
    modelos: []
}