import { AxiosError } from "axios";
import { Checkbox, Label, TextInput } from "flowbite-react";
import { Dispatch, FC, SetStateAction, useCallback, useEffect, useState } from "react";
import { CodeLabel } from "../../../../../../components/CodeLabel";
import { ShowToast } from "../../../../../../components/CodeToast";
import ModalForm from "../../../../../../components/ModalForm";
import { ModalFormProps } from "../../../../../../components/ModalForm/types";
import { FormStateType } from "../../../../../../enums";
import { VeiculoModeloModel } from "../../../../../../models/veiculo_modelo";
import { findValidationField, FluentValidator } from "../../../../../../types";
import CodeUtil from "../../../../../../utils/CodeUtil";

export type VeiculosModelosModalProps<T> = {
    model: [T, Dispatch<SetStateAction<T>>]
} & ModalFormProps

export const VeiculosModelosModal: FC<VeiculosModelosModalProps<VeiculoModeloModel>> = (props) => {
    const [errors, setErrors] = useState<FluentValidator[]>([]);
    const [saving, setSaving] = useState<boolean>(false);
    const [formLoaded, setFormLoaded] = useState<boolean>(false);
    const [model, setModel] = props.model;
    const isReadOnly = props.state === FormStateType.view;

    const onFormClose = () => {
        setErrors([]);
        setFormLoaded(false);
        props.onClose?.call(this);
    };

    const onFormLoad = useCallback(async () => {
        if (!props.show || formLoaded) return;

        setFormLoaded(true);
    }, [props.show, formLoaded, setFormLoaded]);

    const validate = (): FluentValidator[] => {
        let _errors: FluentValidator[] = [];

        if (CodeUtil.isNullOrEmpty(model.modelo)) _errors.push({ field: "modelo", message: "O modelo deve ser preenchido", isValid: false });

        setErrors(_errors);
        return _errors;
    }

    const onFormSave = async (e: React.MouseEvent<HTMLButtonElement> | undefined) => {
        let validationResult = validate();

        if (validationResult.length > 0) return;

        try {
            setSaving(true);
            await setModel({ ...model, state: props.state === FormStateType.add ? 'ADDED' : 'UPDATED' });

            props.onSave?.call(this, e);
            onFormClose();
        } catch (error) {
            console.log(error);
            ShowToast({ message: (error as AxiosError).response?.data as string });
        } finally {
            setSaving(false);
        }
    };

    useEffect(() => {
        onFormLoad();
    });

    if (!props.show) return <></>;

    return (
        <ModalForm
            title={props.title ?? "Cadastro de Modelo"}
            show={props.show}
            state={props.state}
            isSaving={saving}
            size="3xl"
            onClose={onFormClose}
            onSave={onFormSave}
        >
            <div className="grid grid-cols-12 px-6 pb-3">
                <div className="form-control mt-5 col-span-12">
                    <CodeLabel className="mb-1" htmlFor="inputModelo" value="Modelo:" />
                    <TextInput
                        id="inputModelo"
                        type="text"
                        readOnly={isReadOnly}
                        value={model?.modelo}
                        helperText={findValidationField(errors, "modelo").message}
                        color={findValidationField(errors, "modelo").isValid ? "gray" : "failure"}
                        onChange={(e) => setModel({ ...model, modelo: e.currentTarget.value })}
                        maxLength={200}
                    />
                </div>

                <div className="form-control mt-5 col-span-12 flex space-x-2 items-center">
                    <Checkbox id="checkBoxAtivo"
                        disabled={isReadOnly}
                        checked={model.ativo}
                        onChange={(e) => setModel({ ...model, ativo: e.currentTarget.checked })}
                        placeholder="Checked">
                    </Checkbox>

                    <Label htmlFor="checkBoxAtivo" value="Modelo ativo?" />
                </div>
            </div>
        </ModalForm>
    );
};