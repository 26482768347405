import { FC } from "react";
import { IniciarServicoModalProps } from "../types";
import { useIniciarServicoModal } from "./IniciarServicoModal.hook";
import ModalForm from "../../ModalForm";
import { Groupbox } from "../../Groupbox";
import { CodeLabel } from "../../CodeLabel";
import { Textarea, TextInput } from "flowbite-react";
import { format } from "fecha";
import { CodePesquisa } from "../../CodePesquisa";
import { useClienteVeiculoColumns } from "../../../models/cliente_veiculo/useClienteVeiculoColumns";
import CodeUtil from "../../../utils/CodeUtil";
import { MoneyInput } from "../../MoneyInput";
import { findValidationField } from "../../../types";

export const IniciarServicoModal: FC<IniciarServicoModalProps> = (props) => {
    const { model, saving, registroEntrada, setRegistroEntrada, onIniciarOrdemServico, validation } = useIniciarServicoModal(props);

    return (
        <ModalForm
            show={props.show}
            state={props.state}
            id={props.id}
            title={props.title}
            size="4xl"
            isSaving={saving}
            saveButtonTitle="Iniciar O.S."
            onClose={props.onClose}
            onSave={onIniciarOrdemServico}>

            <div className="p-5 grid grid-cols-12">
                <div className="col-span-12 md:col-span-3 mr-6">
                    <CodeLabel value="Data:" htmlFor="inputData" className="mb-1" />
                    <TextInput
                        id="inputData"
                        type={"date"}
                        pattern="\d{4}-\d{2}-\d{2}"
                        value={format(new Date(model.dataCadastro), 'YYYY-MM-DD')}
                        readOnly={true} />
                </div>

                <Groupbox label="Informações do Veículo" id="groupBoxVeiculo" className="col-span-12 mt-6">
                    <div className="form-control mt-5 col-span-12 mr-6">
                        <CodePesquisa
                            id="cpsVeiculo"
                            valueField={{ label: "Código:", property: "id", value: model.veiculo?.id === 0 ? "" : model.veiculo?.id }}
                            textField={{ label: "Modelo:", property: "modeloMarca", value: model.veiculo?.modeloMarca ?? "" }}
                            modal={{
                                title: "Pesquisa de Veículo",
                                dataColumns: useClienteVeiculoColumns(),
                                sort: { columnProperty: "modelo.nome", direction: "asc", columnIndex: 2 }
                            }}
                            endpoint={`cliente/${model.cliente?.id ?? 0}/veiculo`}
                            readOnly={true} />
                    </div>

                    <div className="form-control col-span-6 md:col-span-4 mt-5 md:mr-6">
                        <CodeLabel value="Renavam:" htmlFor="inputRenavam" className="mb-1" />
                        <TextInput id="inputRenavam" value={model.veiculo?.renavam ?? ""}
                            readOnly={true} />
                    </div>

                    <div className="form-control col-span-6 md:col-span-4 mt-5 md:mr-6">
                        <CodeLabel value="Placa:" htmlFor="inputPlaca" className="mb-1" />
                        <TextInput id="inputPlaca" value={model.veiculo?.placa ?? ""}
                            readOnly={true} />
                    </div>
                </Groupbox>

                <Groupbox label="Registro de Entrada do veículo" id="groupBoxRegistroEntrada" className="col-span-12 mt-6">
                    <div className="form-control col-span-6 md:col-span-3 mt-5 md:mr-6">
                        <CodeLabel value="Quilometragem:" htmlFor="inputKm" className="mb-1" />
                        <MoneyInput id="inputKm"
                            precision={0}
                            value={CodeUtil.numberFormat(registroEntrada?.quilometragem ?? 0)}
                            onChange={(_value, numberValue) => setRegistroEntrada({ ...registroEntrada, quilometragem: numberValue })}
                            addon={""}
                            helperText={findValidationField(validation, "quilometragem").message}
                            color={findValidationField(validation, "quilometragem").isValid ? "gray" : "failure"}
                            maxLength={7} />
                    </div>

                    <div className="form-control mt-5 col-span-6 md:col-span-3 md:mr-6">
                        <CodeLabel className="mb-1" htmlFor="inputBateria" value="Bateria:" />
                        <TextInput
                            id="inputBateria"
                            placeholder="Moura, Heliar, Zetta"
                            value={registroEntrada?.marcaBateria}
                            onChange={(e) => setRegistroEntrada({ ...registroEntrada, marcaBateria: e.currentTarget.value })}
                            maxLength={20}
                        />
                    </div>

                    <div className="form-control mt-5 col-span-12 md:col-span-6 md:mr-6">
                        <CodeLabel className="mb-1" htmlFor="inputAutorizacaoCondutor" value="Autorização do Condutor:" />
                        <TextInput
                            id="inputAutorizacaoCondutor"
                            type="password"
                            placeholder="Código de autorização do condutor"
                            value={registroEntrada?.codigoAutorizacao}
                            onChange={(e) => setRegistroEntrada({ ...registroEntrada, codigoAutorizacao: e.currentTarget.value })}
                            helperText={findValidationField(validation, "codigoAutorizacao").message}
                            color={findValidationField(validation, "codigoAutorizacao").isValid ? "gray" : "failure"}
                            maxLength={255}
                        />
                    </div>

                    <div className="form-control col-span-12 mt-5">
                        <CodeLabel className="mb-1" htmlFor="inputObservacoes" value="Observações:" />
                        <Textarea
                            id="inputObservacoes"
                            rows={5}
                            value={registroEntrada?.observacoes}
                            onChange={(e) => setRegistroEntrada({ ...registroEntrada, observacoes: e.currentTarget.value })}
                        />
                    </div>
                </Groupbox>
            </div >
        </ModalForm>
    );
}