import { useState } from "react";
import { ICodeDataGridColumn } from "../../components/CondensedCodeDataGrid/types";
import { VeloeService } from "../../services/VeloeService";
import { ShowToast } from "../../components/CodeToast";
import { UNKOWN_EXCEPTION } from "../../utils/Constants";
import { format } from "fecha";
import { VeloeAccountBalanceModel } from "../../models/veloe/VeloeAccountBalanceModel";
import { VeloeAccountBalanceRequest } from "../../models/veloe/VeloeAccountBalanceRequest";

export const useVeloeSaldoContaPage = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [showFiltros, setShowFiltros] = useState<boolean>(false);
    const [dataSource, setDataSource] = useState<VeloeAccountBalanceModel[]>([]);
    const [filtro, setFiltro] = useState<VeloeAccountBalanceRequest>();

    const columns: ICodeDataGridColumn[] = [
        {
            title: "Data da Transação", property: "registrationDate", onFormatValue: (item: VeloeAccountBalanceModel) =>
                <span>{!item.transactionDate ? "" : format(new Date(item.transactionDate), "DD/MM/YYYY")}</span>
        },
        { title: "Número do Cartão", property: "cardNumber" },
        { title: "Saldo", property: "cardBalance", alignment: "right" },
        { title: "Mensagem", property: "error" },
    ]

    const handlePesquisar = () => {
        setShowFiltros(false);
        setLoading(true);

        const cards = filtro?.cards?.filter(i => i);
        if ((cards?.length ?? 0) === 0) {
            setLoading(false);
            ShowToast({ message: "Ao menos um cartão deve ser informado." });
            return;
        }

        let invalidCards = false;
        cards?.forEach((card) => {
            if (!/^\d+$/.test(card)) {
                invalidCards = true;
            }
        });

        if (invalidCards) {
            ShowToast({ message: "O cartão deve conter apenas números." });
            setLoading(false);
            return;
        }

        VeloeService.getSaldoConta(filtro)
            .then(async (response) => {
                if (!response.success) {
                    ShowToast({ message: response.messages });
                    return;
                }

                setDataSource(response.data);
            }).catch((error) => {
                console.log(error);
                ShowToast({ message: UNKOWN_EXCEPTION });
            }).finally(() => {
                setLoading(false);
            });
    }

    return {
        loading, columns,
        showFiltros, setShowFiltros,
        filtro, setFiltro,
        dataSource,
        handlePesquisar,
    }
}