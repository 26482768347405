import CodeUtil from "../../utils/CodeUtil";
import { BaseModel } from "../base";
import { CredenciadaContatoModel } from "../credenciada_contato";
import { CredenciadaServicoModel } from "../credenciada_servico";

export interface CredenciadaModel extends BaseModel {
    razaoSocial: string;
    fantasia: string | null;
    cnpj: string;
    inscricaoEstadual: string | null;
    logradouro: string | null;
    logradouroNumero: string | null;
    bairro: string | null;
    cep: string | null;
    logomarca: string | null;
    observacoes: string | null;
    email: string;
    municipio?: {
        id?: number;
        descricao?: string;
    };

    uf?: {
        id?: number;
        descricao?: string;
        sigla?: string;
    };

    bloqueada: boolean;
    servicos: CredenciadaServicoModel[];
    contatos: CredenciadaContatoModel[];
};

export const defaultCredenciada: CredenciadaModel = {
    razaoSocial: '',
    fantasia: null,
    cnpj: '',
    email: '',
    inscricaoEstadual: null,
    logradouro: null,
    logradouroNumero: null,
    bairro: null,
    cep: null,
    logomarca: null,
    observacoes: null,
    municipio: {
        descricao: '',
        id: 0
    },
    isSelected: false,
    bloqueada: false,
    contatos: [],
    servicos: []
};

export const validateCredenciada = (model?: CredenciadaModel | null): string[] => {

    let errors: string[] = [];

    if (CodeUtil.isNullOrEmpty(model?.razaoSocial))
        errors.push("O campo 'Razão Social' deve ser preenchida.");

    if (CodeUtil.isNullOrEmpty(CodeUtil.removeSpecialChars(model?.cnpj)))
        errors.push("O campo 'CNPJ' deve ser preenchido.");

    return errors;
}