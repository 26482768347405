import { FC } from "react";
import { CodeDataGrid } from "../../components/CodeDataGrid";
import CodeSpinner from "../../components/CodeSpinner";
import { Popup } from "../../components/Popup";
import { useCotacaoPage } from "./index.hook";
import { CotacaoStatusType } from "../../enums";
import { ICodeDataGridColumn } from "../../components/CodeDataGrid/types";
import { CotacaoModel } from "../../models/cotacao/CotacaoModel";
import CodeUtil from "../../utils/CodeUtil";
import { CotacoesCadastroPage } from "../cotacoes_cadastro";
import { SideBarFiltrosCotacoes } from "../../components/SidebarFiltroCotacoes";
import { useBuscarPermissao } from "../../hooks/useBuscarPermissao";


export const CotacoesPage: FC = () => {
    const { isLoadingData, dataSource, setShowPopupDelete, showPopupDelete,
        selectedRow: selected, setSelectedRow: setSelected,
        pagination, setPage, modalState, onModalClose, 
        lancarCotacao, consultarCotacao, cancelarCotacao, onFormatStatusColumn,
        filtros, setFiltros, hiddenMoreFilters, setHiddenMoreFilters, onApplyFilter
    } = useCotacaoPage();

    const { permissao } = useBuscarPermissao('cotacoes');

    const dataTableColumns: ICodeDataGridColumn[] = [
        { title: "número", property: "id", alignment: "center", onFormatValue: (value: CotacaoModel) => <span>{String(value.id).padStart(4, "0")}</span> },
        { title: "data", property: "dataCadastro", alignment: "center", onFormatValue: (value: CotacaoModel) => <span>{`${CodeUtil.formatStringDate(value.dataCadastro)}`}</span> },
        { title: "validade", property: "dataValidade", alignment: "center", onFormatValue: (value: CotacaoModel) => <span>{`${CodeUtil.formatStringDate(value.dataValidade)}`}</span> },
        { title: "situação", property: "statusDescricao", alignment: "center", onFormatValue: onFormatStatusColumn },
        { title: "cliente", property: "cliente.fantasia" },
        { title: "cnpj", property: "cliente.cnpj", alignment: "left", onFormatValue: (value: CotacaoModel) => <span>{CodeUtil.mask(value.cliente?.cnpj ?? "", "##.###.###/####-##")}</span> },
        { title: "marca", property: "veiculo.marca.nome" },
        { title: "modelo", property: "veiculo.modelo.nome" },
        { title: "placa", property: "veiculo.placa" },
        { title: "renavam", property: "veiculo.renavam" },
        { title: "órgão", property: "veiculo.orgao" },
        { title: "observações", property: "observacoes" },
    ];

    return (
        <>
            <CodeSpinner hidden={!isLoadingData} />

            <div className="mt-5" hidden={isLoadingData}>
                <CodeDataGrid
                    sort={{ columnIndex: 0, direction: "asc", columnProperty: "id" }}
                    datasource={dataSource}
                    striped={true}
                    header={{
                        title: "Cotações",
                        subtitle: "Listagem de cotações aprovadas, aguardando aprovação e reprovadas",
                        filter: true
                    }}
                    actions={[
                        { label: 'Inserir', style: 'colored', onClick: lancarCotacao, disabled: !permissao?.inserir },
                        { label: 'Editar', style: 'flat', disabled: !selected || !permissao?.editar || 
                            ((selected.item as CotacaoModel).status !== CotacaoStatusType.AGUARDANDO && (selected.item as CotacaoModel).status !== CotacaoStatusType.COTACAO_ENVIADA), onClick: () => consultarCotacao(false) },
                        {
                            label: 'Cancelar', style: 'destructive', disabled: !selected || !permissao?.excluir ||
                                ((selected.item as CotacaoModel).status !== CotacaoStatusType.AGUARDANDO && (selected.item as CotacaoModel).status !== CotacaoStatusType.APROVADA), onClick: () => setShowPopupDelete(true)
                        },
                        { label: 'Consultar', style: 'flat', disabled: !selected || !permissao?.consultar, onClick: () => consultarCotacao(true) },
                        { label: 'Mais Filtros', style: 'flat', onClick: () => setHiddenMoreFilters(false) }
                    ]}
                    columns={dataTableColumns}
                    onRowSelect={(item) => setSelected(item.selected ? item : undefined)}
                    pagination={{ ...pagination, onPageChanged: (page) => setPage(page) }}
                />

                {modalState.show && <CotacoesCadastroPage
                    show={modalState.show}
                    id={modalState.id}
                    state={modalState.state}
                    onClose={onModalClose}
                    onSave={onModalClose}
                />}

                <SideBarFiltrosCotacoes
                    filter={[filtros, setFiltros]}
                    hidden={hiddenMoreFilters}
                    onClose={() => setHiddenMoreFilters(true)}
                    onApplyFilter={onApplyFilter} />

                {showPopupDelete && <Popup
                    isShowing={showPopupDelete}
                    message="Confirma o cancelamento da cotação selecionada?"
                    isDestructive={true}
                    onConfirm={cancelarCotacao}
                    onCancel={() => setShowPopupDelete(false)}
                />}
            </div>
        </>
    );
};