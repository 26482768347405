import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
    html {
        font-family: 'Inter', 'Raleway', 'Helvetica Neue',sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        background-color: #E9E9E9;
    }

    .container {
        padding: 2rem;
    }

    div[role=tablist] > button[aria-selected=true] {
        border-color: rgb(240, 82, 82);
        color: rgb(240, 82, 82);
    }

    .z-top {
        z-index: 99999;
    }
`;
