import { CatalogoCommand } from "../commands/CatalogoCommand";
import { CatalogoModel } from "../models/catalogo/CatalogoModel";
import { BaseService } from "./BaseService";
import { DefaultResponse, ServiceProps } from "./types";

const ENDPOINT = `${BaseService.BASE_URL}/catalogo`;

export class CatalogoService extends BaseService {
    public static async get(props: ServiceProps = { limit: 100, page: 1 }): Promise<DefaultResponse<CatalogoModel>> {
        return await this.GET<CatalogoModel>(`${ENDPOINT}?&limit=${props.limit}&page=${props.page}`);
    }

    public static async getId(id: number): Promise<DefaultResponse<CatalogoModel>> {
        return await this.GET<CatalogoModel>(`${ENDPOINT}/${id}`);
    }

    public static async add(model: CatalogoCommand): Promise<DefaultResponse<CatalogoModel>> {
        return await this.POST<CatalogoCommand>(`${ENDPOINT}`, model);
    }

    public static async update(id: number, model: unknown): Promise<DefaultResponse<CatalogoModel>> {
        return await this.PATCH<unknown>(`${ENDPOINT}/${id}`, model);
    }

    public static async updateFile(id: number, file: File | null): Promise<DefaultResponse<CatalogoModel>> {
        var formData = new FormData();
        if (file) formData.append("file", file);

        return await this.PATCHFORM<CatalogoCommand>(`${ENDPOINT}/${id}`, formData);
    }

    public static async delete(id: number): Promise<DefaultResponse<CatalogoModel>> {
        return await this.DELETE(`${ENDPOINT}/${id}`);
    }
}