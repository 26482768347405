import { FC } from "react";
import { ModalFormProps } from "../../components/ModalForm/types";
import ModalForm from "../../components/ModalForm";
import { CodeLabel } from "../../components/CodeLabel";
import { MoneyInput } from "../../components/MoneyInput";
import CodeUtil from "../../utils/CodeUtil";
import { FormStateType } from "../../enums";
import { TextInput } from "flowbite-react";
import { useVeloeCalculoRotaModalPage } from "./VeloeCalculoRotaModalPage.hook";

export const VeloeCalculoRotaModalPage: FC<ModalFormProps> = (props) => {

    const readonly = props.state === FormStateType.view;
    const { handleFormClose, handleFormSave, saving, setValue, getValues, waypoints, setWaypoints } = useVeloeCalculoRotaModalPage(props);

    return (
        <ModalForm
            title={"Cálculo de Custo de Rota"}
            errors={[]}
            show={props.show}
            state={props.state}
            isSaving={saving}
            size="3xl"
            onClose={handleFormClose}
            onSave={handleFormSave}
        >
            <div className="grid grid-cols-12 p-6 gap-x-6 gap-y-3">
                <div className="col-span-12 md:col-span-4">
                    <CodeLabel className="mb-1" htmlFor="inputFilial" value="Filial:" />
                    <TextInput
                        id="inputFilial"
                        type="text"
                        value={getValues.branch ?? ""}
                        placeholder="Número da Filial"
                        onChange={(e) => setValue({ ...getValues, branch: e.currentTarget.value })}
                    />
                </div>

                <div className="col-span-12 md:col-span-4">
                    <CodeLabel className="mb-1" htmlFor="inputBase" value="Base:" />
                    <TextInput
                        id="inputBase"
                        type="text"
                        value={getValues.base ?? ""}
                        placeholder="Número da Base"
                        onChange={(e) => setValue({ ...getValues, base: e.currentTarget.value })}
                    />
                </div>

                <div className="col-span-12 md:col-span-4">
                    <CodeLabel className="mb-1" htmlFor="inputPlate" value="Placa:" />
                    <TextInput
                        id="inputPlate"
                        type="text"
                        value={getValues.plate ?? ""}
                        placeholder="Placa do Veículo"
                        onChange={(e) => setValue({ ...getValues, plate: e.currentTarget.value })}
                    />
                </div>

                <div className="col-span-12 md:col-span-6">
                    <CodeLabel className="mb-1" htmlFor="inputDeparture" value="Ponto de Partida:" />
                    <TextInput
                        id="inputDeparture"
                        type="text"
                        value={getValues.departure ?? ""}
                        placeholder="Sorocaba, São Paulo"
                        onChange={(e) => setValue({ ...getValues, departure: e.currentTarget.value })}
                    />
                </div>

                <div className="col-span-12 md:col-span-6">
                    <CodeLabel className="mb-1" htmlFor="inputDestination" value="Ponto de Destino:" />
                    <TextInput
                        id="inputDestination"
                        type="text"
                        value={getValues.destination ?? ""}
                        placeholder="Belo Horizonte, Minas Gerais"
                        onChange={(e) => setValue({ ...getValues, destination: e.currentTarget.value })}
                    />
                </div>

                <div className="col-span-12">
                    <CodeLabel className="mb-1" htmlFor="inputWaypoints" value="Ponto de Parada:" />
                    <TextInput
                        id="inputWaypoints"
                        type="text"
                        value={waypoints}
                        placeholder="Bragança Paulista, Pouso Alegre"
                        onChange={(e) => setWaypoints(e.currentTarget.value)}
                    />
                </div>

                <div className="col-span-12 md:col-span-4">
                    <CodeLabel value={"Preço/Litro:"} className="mb-1" htmlFor="inputLiterValue" />
                    <MoneyInput id="inputLiterValue"
                        value={CodeUtil.moneyFormat(getValues.literValue, false)}
                        addon={"R$"}
                        readOnly={readonly}
                        onChange={(_stringValue, numberValue) => setValue({ ...getValues, literValue: numberValue })} />
                </div>

                <div className="col-span-12 md:col-span-4">
                    <CodeLabel value={"Tolerância:"} className="mb-1" htmlFor="inputTolerance" />
                    <MoneyInput id="inputTolerance"
                        value={CodeUtil.moneyFormat(getValues.tolerance, false)}
                        addon={"Km/L"}
                        readOnly={readonly}
                        onChange={(_stringValue, numberValue) => setValue({ ...getValues, tolerance: numberValue })} />
                </div>
                
                <div className="col-span-12 md:col-span-4">
                    <CodeLabel value={"Consumo Médio:"} className="mb-1" htmlFor="inputAverage" />
                    <MoneyInput id="inputAverage"
                        value={CodeUtil.moneyFormat(getValues.averageVehicle, false)}
                        addon={"Km/L"}
                        readOnly={readonly}
                        onChange={(_stringValue, numberValue) => setValue({ ...getValues, averageVehicle: numberValue })} />
                </div>
            </div>

        </ModalForm>
    )
}