import { Button } from "flowbite-react";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { ModalStateType } from "../../../../../components/ModalForm/types";
import { Popup } from "../../../../../components/Popup";
import { FormStateType } from "../../../../../enums";
import { ClienteVeiculoModel, defaultClienteVeiculo } from "../../../../../models/cliente_veiculo/ClienteVeiculoModel";
import { useClienteVeiculoColumns } from "../../../../../models/cliente_veiculo/useClienteVeiculoColumns";
import { ClienteFormPageDadosProps } from "../../types";
import { ClientesTabVeiculosModal } from "./components/Modal";
import { CondensedCodeDataGrid } from "../../../../../components/CondensedCodeDataGrid";
import { ContextMenu } from "primereact/contextmenu";
import { PrimeIcons } from "primereact/api";
import { ICodeDataGridRow } from "../../../../../components/CodeDataGrid/types";

export const ClientesTabVeiculos: FC<ClienteFormPageDadosProps> = (props) => {
    const columnsVeiculos = useClienteVeiculoColumns();
    const [model, setModel] = props.model;
    const readonly = props.state === FormStateType.view;
    const [formLoaded, setFormLoaded] = useState<boolean>(false);
    const [selectedModel, setSelectedModel] = useState<ClienteVeiculoModel>(defaultClienteVeiculo);
    const [showPopupDelete, setShowPopupDelete] = useState<boolean>(false);
    const [selectedRow, setSelectedRow] = useState<ICodeDataGridRow | null>(null);
    const [stateModalVeiculo, setStateModalVeiculo] = useState<ModalStateType<ClienteVeiculoModel>>({
        show: false,
        state: FormStateType.add,
    });

    const cm = useRef<ContextMenu>(null);

    const onPageLoad = useCallback(async () => {
        if (!props.show || formLoaded) return;

        props.onPageLoad?.call(this);
        setFormLoaded(true);
    }, [props.show, props.onPageLoad, formLoaded]);

    useEffect(() => { onPageLoad() });

    const findSelectedItem = (): number => selectedRow?.rowindex ?? -1;

    const showModal = (formState: FormStateType): void => {
        if (formState === FormStateType.add)
            setSelectedModel(defaultClienteVeiculo);

        setStateModalVeiculo({
            show: true,
            state: formState
        });
    }

    const updateItem = () => {
        setStateModalVeiculo({ ...stateModalVeiculo, show: false });

        if (stateModalVeiculo.state === FormStateType.add) {
            setModel({ ...model, veiculos: [{ ...(selectedModel ?? defaultClienteVeiculo), state: 'ADDED' }, ...model.veiculos] });
        } else {
            let index = findSelectedItem();
            if (index === null || index < 0) return;

            let updatedVeiculos = model.veiculos;
            updatedVeiculos[index] = selectedModel ?? defaultClienteVeiculo;
            updatedVeiculos[index].state = 'UPDATED';
            setModel({ ...model, veiculos: updatedVeiculos });
        }
    }

    const removeItem = () => {
        let index = findSelectedItem();
        if (index === null || index < 0) return;

        let updatedVeiculos = model.veiculos;
        updatedVeiculos[index] = selectedModel ?? defaultClienteVeiculo;
        updatedVeiculos[index].state = 'DELETED';

        setModel({ ...model, veiculos: updatedVeiculos });
        setShowPopupDelete(false);
        setSelectedModel(defaultClienteVeiculo);
    }

    return (
        <div className="p-0 -m-6">
            <ContextMenu ref={cm} model={[
                { label: "Novo Veículo", icon: PrimeIcons.PLUS, disabled: readonly, command: () => showModal(FormStateType.add) },
                { label: "Editar", icon: PrimeIcons.PENCIL, disabled: readonly || !selectedRow?.selected, command: () => showModal(FormStateType.edit) },
                { label: "Consultar", icon: PrimeIcons.EYE, disabled: !selectedRow?.selected, command: () => showModal(FormStateType.view) },
                { separator: true },
                { label: "Excluir", icon: PrimeIcons.TRASH, disabled: readonly || !selectedRow?.selected, command: () => setShowPopupDelete(true) },
            ]} />

            {model.veiculos.length <= 0 ?
                <div className="flex flex-col space-y-3 ml-auto mr-auto p-5 place-items-center">
                    <span className="text-slate-500">Este cliente não possui veiculos</span>
                    <Button disabled={readonly} onClick={() => showModal(FormStateType.add)} color="dark" size="sm">Novo veiculo</Button>
                </div>
                : <></>
            }

            {model.veiculos.length > 0 ?
                <div>
                    <CondensedCodeDataGrid
                        key={"id"}
                        onContextMenu={(e) => cm.current?.show(e)}
                        sort={{ columnProperty: "placa", columnIndex: columnsVeiculos.findIndex((item) => item.property === "placa") ?? 0, direction: "asc" }}
                        header={{ filter: true }}
                        datasource={model.veiculos.filter((e) => e.state !== 'DELETED')}
                        columns={columnsVeiculos}
                        onRowSelect={(selectedRow) => {
                            setSelectedModel((!selectedRow?.selected) ? defaultClienteVeiculo : selectedRow.item as ClienteVeiculoModel);
                            setSelectedRow(selectedRow);
                        }}
                        multiselect={false}
                    />
                </div> :
                <></>
            }

            <Popup
                isShowing={showPopupDelete}
                message="Confirma a exclusão do registro selecionado?"
                isDestructive={true}
                onConfirm={() => removeItem()}
                onCancel={() => setShowPopupDelete(false)}
            />


            <ClientesTabVeiculosModal
                show={stateModalVeiculo.show}
                title="Veículo"
                state={stateModalVeiculo.state}
                model={[selectedModel, setSelectedModel]}
                onSave={() => updateItem()}
                onClose={() => setStateModalVeiculo({ ...stateModalVeiculo, show: false })}
            />
        </div>
    );
};