import { Button, Spinner } from "flowbite-react";
import { StyledReportPage } from "../_styles/StyledReportPage";
import { FC } from "react";
import { CondensedCodeDataGrid } from "../../components/CondensedCodeDataGrid";
import { useRelatorioCotacoesPage } from "./RelatorioCotacoesPage.hook";
import { FormStateType } from "../../enums";
import { FiltroRelatorioCotacoes } from "./components/FiltroRelatorioCotacoes";

export const RelatorioCotacoesPage: FC = () => {
    const {
        loading, columns, dataSource,
        showFiltroCotacoes, setShowFiltroCotacoes,
        filtroCotacoes, setFiltroCotacoes, handleGerarRelatorioCotacoes
    } = useRelatorioCotacoesPage();

    return (
        <StyledReportPage className="shadow rounded-md py-0.5">
            <div className="py-5">
                <h2 className="px-5 mb-4 text-lg font-semibold">Relatório de Cotações</h2>
                <hr />
            </div>

            <div className="flex gap-x-3 px-3 -mt-2 -mb-3 items-center">
                <Button
                    id="buttonGerarRelatorio"
                    size={'sm'}
                    disabled={loading}
                    onClick={() => setShowFiltroCotacoes(true)}
                    color={'light'}>

                    <div className={(loading ?? false) ? "mr-3 text-white" : "text-white"}>
                        <Spinner size="sm" light={true} hidden={!loading} />
                    </div>
                    <span hidden={loading}><i className="pi pi-print text-sm mr-1.5" /> Gerar Relatório</span>
                    <span hidden={!loading}>Gerando...</span>
                </Button>
            </div>

            <CondensedCodeDataGrid
                columns={columns}
                datasource={dataSource}
                sort={{
                    columnIndex: 0,
                    columnProperty: 'data',
                    direction: "desc"
                }}
                header={{ filter: false }}
                readonly={true}
                pagination={{
                    limit: 100,
                    page: 1,
                    pageCount: 1,
                    recordCount: 0
                }}
                hoverable={true}
                striped={true} />

            {showFiltroCotacoes && <FiltroRelatorioCotacoes
                model={[filtroCotacoes, setFiltroCotacoes]}
                show={showFiltroCotacoes}
                state={FormStateType.edit}
                saveButtonTitle="Gerar Relatório"
                onClose={() => setShowFiltroCotacoes(false)}
                onApplyFilter={handleGerarRelatorioCotacoes}
            />}

        </StyledReportPage>
    );
}