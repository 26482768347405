import axios from "axios"
import { DefaultCEPResponse } from "./types";

export class ConsultarCepService {
    public static consultar = async (cep: string) : Promise<DefaultCEPResponse | null> => {
        try {
            let response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);            
            return response.data as DefaultCEPResponse;
        } catch (error) {
            return null;
        }
    }
}