import { Chip } from "primereact/chip";
import { FC } from "react";
import { CodeSelectableChipProps } from "./types";

export const CodeSelectableChip: FC<CodeSelectableChipProps> = (props) => {
    const backgroundColor: string = props.selected ? "bg-red-700 text-white" : "bg-slate-200 text-slate-900";

    return (
        <Chip label={props.label} className={`${props.className} ${backgroundColor} cursor-pointer`}
            onClick={() => props.onSelectChip?.call(this, props.model)} />
    );
};