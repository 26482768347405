import { useCallback, useState } from "react";
import { ICodeDataGridPagination, ICodeDataGridRow } from "../../components/CodeDataGrid/types";
import { ProdutoModel } from "../../models/produto";
import { ModalStateType } from "../../components/ModalForm/types";
import { FormStateType } from "../../enums";
import { ProdutoService } from "../../services/ProdutoService";
import { ShowToast } from "../../components/CodeToast";
import { UNKOWN_EXCEPTION } from "../../utils/Constants";
import { SidebarFiltroProdutosFormField } from "../../components/SidebarFiltroProdutos";

interface ProdutoFiltro {
    form?: SidebarFiltroProdutosFormField | undefined
    show: boolean
}


export const useProdutoPage = () => {
    const [loaded, setLoaded] = useState<boolean>(false);
    const [showPopupExcluir, setShowPopupExcluir] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [filtro, setFiltro] = useState<ProdutoFiltro>({ show: false });
    const [showImportacao, setShowImportacao] = useState<boolean>(false);
    const [selected, setSelected] = useState<ICodeDataGridRow | null>(null);
    const [dataSource, setDataSource] = useState<ProdutoModel[]>([]);
    const [page, setPage] = useState<number>(1);
    const [pagination, setPagination] = useState<Omit<ICodeDataGridPagination, 'onPageChanged'>>({ page: 1, pageCount: 0, limit: 100, recordCount: 0 });
    const [modalState, setModalState] = useState<ModalStateType<ProdutoModel>>({
        show: false,
        state: FormStateType.add,
    });


    const onPageLoad = useCallback(async () => {
        try {
            if (loaded) return;

            const response = await ProdutoService.get({
                ...filtro.form,
                page: page,
                limit: 100,
            });

            if (!response.success) {
                ShowToast({ message: response.messages });
                return;
            }

            setDataSource(response.data);
            setPagination(response.pagination);
        } catch (error) {
            console.log(error);
            ShowToast({ message: UNKOWN_EXCEPTION });
        } finally {
            setLoaded(true);
            setLoading(false);
        }

        setLoaded(true);
    }, [filtro.form, loaded, page]);

    const handleClose = () => setModalState({ ...modalState, show: false });

    const handleExcluirProduto = async () => {
        try {
            let response = await ProdutoService.delete(selected?.item.id);
            if (!response.success) {
                console.log(response.messages);
                return;
            }

            setLoaded(false);
        } catch (error) {
            console.log(error);
        } finally {
            setShowPopupExcluir(false);
        }
    }


    const handleInserir = () => setModalState({ ...modalState, show: true, state: FormStateType.add });

    const handleConsultar = (readonly: boolean = false) => {
        setModalState({
            ...modalState,
            show: true,
            state: readonly ? FormStateType.view : FormStateType.edit
        });
    }

    const handlePageChanged = (page: number) => {
        setPage(page);
        setLoaded(false);
    }

    const handleFiltrarProdutos = (filtros: SidebarFiltroProdutosFormField | undefined) => {
        setFiltro({ show: false, form: filtros });
        setPage(1);
        setLoaded(false);
    }

    const handleImportacaoClose = () => {
        setShowImportacao(false);
        setLoaded(false);
    }

    return {
        onPageLoad, modalState, loading, dataSource,
        selected, setSelected, handleClose,
        showPopupExcluir, setShowPopupExcluir,
        handleExcluirProduto, handleInserir, handleConsultar, handlePageChanged, 
        handleFiltrarProdutos, handleImportacaoClose,
        pagination, setPagination,
        page, setPage, filtro, setFiltro, showImportacao, setShowImportacao
    }
}