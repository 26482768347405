import { BaseModel } from "../base";

export interface ServicoModel extends BaseModel {
    nome: string;
    descricao?: string;
    ativo: boolean;
}

export const defaultServico: ServicoModel = {
    nome: '',
    ativo: true,
    isSelected: true
}