import { FC } from "react";
import { ICodeDataGridPagination } from "../types";
import { CodeDataGridPagination } from "./CodeDataGridPagination";

type CodeDataGridFooterProps = {
    pagination?: ICodeDataGridPagination
}

export const CodeDataGridFooter: FC<CodeDataGridFooterProps> = (props) => {
    return (
        <div className="bg-gray-50 py-1 px-6 rounded-b-md flex place-content-center border-t-2">
            {props.pagination !== undefined ? <CodeDataGridPagination {...props.pagination} /> : <></>}
        </div>
    );
};