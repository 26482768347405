import { useNavigate, useSearchParams } from "react-router-dom";
import AuthService from "../../../services/AuthService"
import { ForgotPasswordFormFields } from "./types";
import { useForm } from "react-hook-form";
import { ShowToast } from "../../../components/CodeToast";
import CodeUtil from "../../../utils/CodeUtil";
import { useState } from "react";

export const useForgotPasswordPage = () => {
    const [searchParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const { handleSubmit, setValue, getValues, formState: {
        errors
    }, register, setError, setFocus } = useForm<ForgotPasswordFormFields>({});

    const getErrors = () : string[] => {
        let _errors : string[] = [];

        if (!CodeUtil.isNullOrEmpty(errors.novaSenha?.message)) _errors.push(errors.novaSenha?.message ?? "");
        if (!CodeUtil.isNullOrEmpty(errors.novaSenhaConfirmacao?.message)) _errors.push(errors.novaSenhaConfirmacao?.message ?? "");

        return _errors;
    }

    const resetPassword = async (data: ForgotPasswordFormFields) => {
        try {
            if (data.novaSenha !== data.novaSenhaConfirmacao) {
                setError('novaSenhaConfirmacao', { message: "Senhas não conferem" });
                setFocus('novaSenhaConfirmacao');
                return;
            }

            setIsLoading(true);
            let response = await AuthService.resetPassword(data.novaSenha ?? "", searchParams.get('token') ?? "")
            if (!response.success) {
                ShowToast({ message: response.messages });
                return;
            }

            AuthService.logout();            
            ShowToast({ message: "Senha redefinida com sucesso." });
            navigate("/auth/login");
        } catch (e: any) {
            console.log(e);
            ShowToast({ message: String(e) });
        } finally {
            setIsLoading(false);
        }
    }

    return {
        resetPassword, getErrors,
        setValue, getValues, errors, register, handleSubmit, isLoading
    }
}