import { FC } from 'react';
import logo from '../../../images/logo.png';

export const ToolbarBrand: FC = () => {
  return (
    <a href="https://sistema.rodarapp.com.br/" className="flex items-center">
      <img src={logo} className="mr-3 h-10 w-20 lg:h-10" alt="Rodar Logo" />
    </a>
  );
};
