import { useCallback, useEffect, useState } from "react"
import { ConfiguracaoModel } from "../../models/configuracao/ConfiguracaoModel";
import { ConfiguracaoService } from "../../services/ConfiguracaoService";
import { DefaultResponse } from "../../services/types";
import { ShowToast } from "../../components/CodeToast";
import { UNKOWN_EXCEPTION } from "../../utils/Constants";

export const useConfiguracoes = () => {
    const [model, setModel] = useState<ConfiguracaoModel>({ prazoValidade: 0, percentualComissao: 0 });
    const [pageLoaded, setPageLoaded] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [saving, setSaving] = useState<boolean>(false);

    const handleButtonSave = async () => {
        try {
            setSaving(true);
            var response = await ConfiguracaoService.update(model);
            if (!response.success) {
                setSaving(false);
                ShowToast({ message: response.messages });
                return;
            }

            ShowToast({ message: 'Configurações salvas' });
            setModel(response.data ?? { prazoValidade: 0, percentualComissao: 0 });
            setSaving(false);
        } catch (error) {
            setSaving(false);
            console.log(error);
            ShowToast({ message: UNKOWN_EXCEPTION });
        }
    }

    const fetchData = useCallback(async (): Promise<DefaultResponse<ConfiguracaoModel>> => await ConfiguracaoService.get(), []);

    const onFormLoad = useCallback(async () => {
        if (pageLoaded) return;
        setLoading(true);

        fetchData().then((response) => {
            setModel(response.data ?? { percentualComissao: 0, prazoValidade: 0 });
        }).finally(() => {
            setLoading(false);
            setPageLoaded(true);
        }).catch((error) => {
            console.log(error);
            ShowToast({ message: UNKOWN_EXCEPTION });
        });
    }, [pageLoaded, fetchData, setModel]);

    useEffect(() => {
        onFormLoad();
    }, [onFormLoad]);

    return {
        model, setModel, loading, saving, handleButtonSave
    }
}