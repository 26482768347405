import { CatalogoModel } from "../models/catalogo/CatalogoModel";

export interface CatalogoCommand 
{
    id?: number | null;
    nome: string;
    descricao?: string | null;
    url: string;
}

export const MapAsCommand = (model?: CatalogoModel | null): CatalogoCommand | null => {
    if (model === null || model === undefined)
        return null;

    return {
        id: model.id,
        nome: model.nome,
        descricao: model.descricao,
        url: model.url
    }
}

