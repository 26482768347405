import { FC, Fragment, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { SideBarItem } from "./Item";
import { SideBarListItems } from "./ListItems";
import { SidebarDropdownListItems } from "./DropdownListItems";
import { SidebarHeader } from "./Header";
import { SidebarProps } from "./types";
import { useLocation, useNavigate } from "react-router-dom";
import { useSessionContext } from "../../contexts/SessionContext";
import { SessionManager } from "../../utils/SessionManager";
import AuthService from "../../services/AuthService";
import { CodeIcon } from "../CodeIcon";
import { MenuService } from "../../services/MenuService";
import { MenuModel } from "../../models/menu";

export const Sidebar: FC<SidebarProps> = (props) => {
    const [loaded, setLoaded] = useState<boolean>(false);
    const [menu, setMenu] = useState<MenuModel[]>([]);
    const sessionContext = useSessionContext();
    const currentUser = SessionManager.getSession().user;
    const currentLocation = useLocation();
    const navigate = useNavigate();

    const isRouteActive = (expected: string): boolean => {
        return currentLocation.pathname.includes(expected)
    }
    const handleSidebarOutsideClick = () => {
        SessionManager.setSession({ ...SessionManager.getSession(), hiddenSidebar: true });
        sessionContext.refreshSession();

        props.onItemClick?.call(this);
    }

    const handlerLogout = () => {

        AuthService.logout();
        sessionContext.refreshSession();

        navigate("/auth/login");
    }

    const montarMenu = useCallback(async () => {
        if (loaded) return;

        if (!currentUser) {
            setLoaded(true);
            return;
        }

        const response = await MenuService.get(currentUser?.id ?? 0);
        if (response.success) {
            setMenu(response.data);
        }
        setLoaded(true);
    }, [currentUser, loaded]);


    const montarSubMenu = (menuPai: string | undefined | null) => {
        if (!menuPai) return;

        return menu.filter(i => i.submenu && i.menuPaiId === menuPai)
            .map((subMenu, idx) => (
                <Fragment key={`fragment-${menuPai}-${idx}`}>
                    {(!subMenu.separator && (subMenu.permitir ?? true)) && <SideBarItem
                        key={subMenu.menuId}
                        onClick={handleSidebarOutsideClick}
                        to={subMenu.to ?? "/"}
                        dropdown
                        label={subMenu.label ?? ""} />}

                    {subMenu.separator && <hr key={`${menuPai}-${idx}`} />}
                </Fragment>))
    }

    useEffect(() => {
        montarMenu();
    }, [montarMenu]);

    return (
        <>
            {!props.hidden &&
                <StyledSidebar>
                    <div id="sidebar-container" className="flex">
                        <div className="sidebar overflow-y-scroll py-4 px-3 shadow-sm">
                            <SidebarHeader />
                            <SideBarListItems>
                                <SideBarItem key={"home"} onClick={handleSidebarOutsideClick} to="/home" label="Home" icon={<CodeIcon iconName="ri/RiApps2Fill" />} />

                                {menu.filter(_ => !_.submenu).map((menuItem, idx) => (
                                    <Fragment key={`fragment-${idx}`}>
                                        {(!menuItem.possuiSubmenu && !menuItem.separator && (menuItem.permitir ?? true)) &&
                                            <SideBarItem
                                                key={menuItem.menuId}
                                                onClick={handleSidebarOutsideClick}
                                                to={menuItem.to ?? "/"}
                                                label={menuItem.label ?? ""}
                                                icon={<CodeIcon iconName={menuItem.icon ?? "fa/FaPills"} />} />}

                                        {(menuItem.possuiSubmenu && !menuItem.separator && !menuItem.menuPaiId && (menuItem.permitir ?? true)) &&
                                            <SidebarDropdownListItems
                                                key={`submenu-${menuItem.menuId}`}
                                                label={menuItem.label ?? ""}
                                                expanded={isRouteActive(menuItem.to ?? "/")}
                                                icon={<CodeIcon iconName={menuItem.icon ?? "fa/FaPills"} />}>
                                                {montarSubMenu(menuItem.menuId)}
                                            </SidebarDropdownListItems>}

                                        {(menuItem.separator && !menuItem.menuPaiId) && <hr key={`submenu-separator-${idx}`} />}
                                    </Fragment>
                                ))}

                                <SideBarItem key={"logout"} onClick={handlerLogout}
                                    textColor="text-red-600"
                                    icon={<CodeIcon iconName="md/MdLogout" className="text-red-600" />}
                                    label="Sair do Sistema" />
                            </SideBarListItems>                           
                        </div>

                        <div className="sidebar-box" onClickCapture={handleSidebarOutsideClick} />
                    </div>
                </StyledSidebar>
            }
        </>
    )
};

const StyledSidebar = styled.aside`
    height: 100vh;
    width: 100%;
    position: fixed;
    z-index: 50;

    #sidebar-container > .sidebar-box {
        background-color: rgba(0, 0, 0, 0.35);
        min-height: 100vh;
        margin-left: 60;
        width: calc(100% - 60px);
    }

    #sidebar-container > .sidebar {
        height: 100vh;
        background-color: rgb(255, 255, 255);
        min-width: 16rem;
        width: 60px;
    }
`;