import { Badge } from "../components/Badge"
import { ICodeDataGridColumn } from "../components/CondensedCodeDataGrid/types"
import { EmpenhoHistoricoModel, EmpenhoModel } from "../models/empenho"
import CodeUtil from "../utils/CodeUtil"
import { format } from "@formkit/tempo"

export const useEmpenhoColumns = () => {

    const getSituacaoColor = (situacao?: string | null): string => {
        switch (situacao) {
            case "Expirado":
                return "yellow";
            case "Cancelado":
                return "red";
            default:
                return "primary";
        }
    }

    const columns: ICodeDataGridColumn[] = [
        {
            property: "situacao", title: "Situação",
            alignment: "center",
            onFormatValue: (value: EmpenhoModel) => <span><Badge color={getSituacaoColor(value.situacao)} value={value.situacao?.toUpperCase()} /></span>
        },
        { property: "numero", title: "Número" },
        { property: "descricao", title: "Descrição" },
        { property: "dataEmpenho", title: "Data", onFormatValue: (value: EmpenhoModel) => value.dataEmpenho && format(value.dataEmpenho, "DD/MM/YYYY") },
        { property: "dataValidade", title: "Validade", onFormatValue: (value: EmpenhoModel) => value.dataValidade && format(value.dataValidade, "DD/MM/YYYY") },
        { property: "valorEmpenho", title: "Valor do Contrato", onFormatValue: (value: EmpenhoModel) => CodeUtil.moneyFormat(value.valorEmpenho) },
        { property: "saldoDisponivel", title: "Valor Disponível", onFormatValue: (value: EmpenhoModel) => CodeUtil.moneyFormat(value.saldoDisponivel) },
        { property: "saldoConsumido", title: "Valor Utilizado", onFormatValue: (value: EmpenhoModel) => CodeUtil.moneyFormat(value.saldoConsumido) },
    ];

    const columnsHistorico: ICodeDataGridColumn[] = [
        { property: "data", title: "Data", onFormatValue: (value: EmpenhoHistoricoModel) => value.data && format(value.data, "DD/MM/YYYY") },
        { property: "saldoEmpenho", title: "Saldo Anterior", alignment: "right", onFormatValue: (value: EmpenhoHistoricoModel) => CodeUtil.moneyFormat(value.saldoEmpenho) },
        { property: "valor", title: "Valor Utilizado", alignment: "right", onFormatValue: (value: EmpenhoHistoricoModel) => CodeUtil.moneyFormat(value.valor) },
        { property: "usuario", title: "e-mail" },
        { property: "evento", title: "Evento" },
    ];

    return {
        columns, 
        columnsHistorico,
        getSituacaoColor
    }
}