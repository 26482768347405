import { BaseModel } from "../base";

export interface ClienteCondutorModel extends BaseModel {
    clienteId: number
    nome: string 
    sobrenome: string
    nomeCompleto: string
    email: string
    cpf: string
    matricula?: string
    numeroHabilitacao?: string
    bloqueado: boolean
    celular?: string 
    state: 'UNCHANGED' | 'ADDED' | 'UPDATED' | 'DELETED'
}

export const defaultClienteCondutor : ClienteCondutorModel = {
    bloqueado: false,
    clienteId: 0,
    cpf: '',
    email: '',
    isSelected: false,
    nome: '',
    sobrenome: '',
    nomeCompleto: '',
    state: 'UNCHANGED',
}