import { ServicoModel } from '../models/servico';
import { BaseService } from "./BaseService";
import { DefaultResponse, ServiceProps } from "./types";

const ENDPOINT = `${BaseService.BASE_URL}/servico`;

export type ServicoRequest = {
    nome?: string;
    descricao?: string;
    ativo?: boolean | null;
} & ServiceProps;

export class ServicoService extends BaseService {
    public static async get(props: ServicoRequest = { limit: 100, page: 1 }): Promise<DefaultResponse<ServicoModel>> {
        let _url = `${ENDPOINT}?limit=${props.limit}&page=${props.page}`;

        if (props.descricao !== undefined) _url = _url.concat(`&descricao=${props.descricao}`);
        if (props.nome !== undefined) _url = _url.concat(`&nome=${props.nome}`);
        if (props.ativo !== undefined) _url = _url.concat(`&ativo=${props.ativo}`);

        return await this.GET<ServicoModel>(_url);
    }

    public static async getId(id: number): Promise<DefaultResponse<ServicoModel>> {
        return await this.GET<ServicoModel>(`${ENDPOINT}/${id}`);
    }

    public static async add(model: ServicoModel): Promise<DefaultResponse<ServicoModel>> {
        return await this.POST<ServicoModel>(`${ENDPOINT}`, model);
    }

    public static async update(model: ServicoModel): Promise<DefaultResponse<ServicoModel>> {
        return await this.PATCH<ServicoModel>(`${ENDPOINT}/${model.id}`, model);
    }

    public static async delete(id: number): Promise<DefaultResponse<ServicoModel>> {
        return await this.DELETE(`${ENDPOINT}/${id}`);
    }
}