import { Button, Spinner } from "flowbite-react";
import { FC, FormEventHandler, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthType } from "../LoginPage";
import { useSessionContext } from "../../../../contexts/SessionContext";
import AuthService from "../../../../services/AuthService";
import { SessionManager } from "../../../../utils/SessionManager";
import { LoginFormProps } from "../types";
import CodeUtil from "../../../../utils/CodeUtil";
import { ShowToast } from "../../../../components/CodeToast";
import { UNKOWN_EXCEPTION } from "../../../../utils/Constants";

export const LoginForm: FC<LoginFormProps> = (props) => {
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isLoadingForgotPassword, setIsLoadingForgotPassword] = useState<boolean>(false);
    const navigate = useNavigate()
    const { refreshSession } = useSessionContext()

    const submit: FormEventHandler<HTMLFormElement> = async (ev) => {
        try {
            ev.preventDefault()
            setIsLoading(true)

            if (!validate()) return

            let response = await AuthService.auth({
                login: props.viewModel?.login ?? '',
                senha: props.viewModel?.password ?? '',
                lembrarMe: props.viewModel?.rememberMe ?? false,
                tipoAutenticacao: props.viewModel?.authType ?? AuthType.EMAIL
            })

            if (!response.success) {
                props.onValidate?.call(this, response.messages)
                return
            }

            refreshSession()
            navigate(SessionManager.getSession().redirectTo)
        } catch (error: any) {
            props.onValidate?.call(this, [error])
        } finally {
            setIsLoading(false)
        }

    }

    const forgotPassword = async () => {
        let errors: string[] = [];

        if (props.viewModel?.authType !== AuthType.EMAIL) {
            errors.push("O e-mail deve ser informado.");
            props.onValidate?.call(this, errors);
            return;
        }

        if (props.viewModel?.authType === AuthType.EMAIL && !CodeUtil.isValidEmail(props.viewModel?.login)) {
            errors.push("O e-mail informado não é um e-mail válido.");
            props.onValidate?.call(this, errors);
            return;
        }

        props.onValidate?.call(this, []);
        setIsLoadingForgotPassword(true);
        AuthService.forgotPassword(props.viewModel?.login ?? "")
            .then(() => {
                errors.push("Um link de redefinição foi enviado para o e-mail cadastrado.");
                props.onValidate?.call(this, errors);
            })
            .catch((error) => {
                console.log(error);
                ShowToast({ message: UNKOWN_EXCEPTION });
            })
            .finally(() => {
                setIsLoadingForgotPassword(false);
            });
    }

    const validate = (): boolean => {

        let errors: string[] = [];

        if (props.viewModel?.login?.trim() === null || props.viewModel?.login?.trim() === '') {
            errors.push("O login deve ser preenchido.");
        } else if (props.viewModel?.authType === AuthType.EMAIL && !CodeUtil.isValidEmail(props.viewModel?.login)) {
            errors.push("O e-mail informado não é um e-mail válido.");
        }

        if (props.viewModel?.password?.trim() === null || props.viewModel?.password?.trim() === '') {
            errors.push("A senha deve ser preenchida.");
        }

        props.onValidate?.call(this, errors)
        return errors.length === 0
    }

    return (
        <form method="POST" onSubmit={submit}>
            {props.children}

            <div className="mt-5 mb-5 flex justify-end">
                <div className="mr-2">
                    <Button onClick={forgotPassword} size="md" color="light" disabled={isLoadingForgotPassword}>
                        {isLoadingForgotPassword &&
                            <div className="mr-3">
                                <Spinner size="sm" />
                            </div>}
                        Esqueci minha senha
                    </Button>
                </div>
                <Button size="md" color="dark" type="submit">
                    <div className={isLoading ? "mr-3" : ""}>
                        <Spinner size="sm" light={true} hidden={!isLoading} />
                    </div>
                    Entrar
                </Button>
            </div>
        </form>
    )
}