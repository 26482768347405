import { AiOutlineClose } from "@react-icons/all-files/ai/AiOutlineClose";
import { FC } from "react";
import { HiMenuAlt2 } from 'react-icons/hi'
import styled from "styled-components";
import { SessionManager } from "../../../utils/SessionManager";
import { ToolbarToogleProps } from "../types";

const ToolbarToogle: FC<ToolbarToogleProps> = (props) => {
    return (
        <StyledToolbar className="flex items-center mr-3">
            <button type="button" onClick={() => props.onToogle?.call(this)} className="inline-flex items-center p-2 ml-1 text-sm rounded-lg hover:shadow-sm focus:outline-none">
                <span className="sr-only">Open main menu</span>

                {(SessionManager.getSession().hiddenSidebar ?? true) ? <HiMenuAlt2 className="w-5 h-5" /> : <AiOutlineClose className="opened w-5 h-5" />}
            </button>
        </StyledToolbar>
    )
}

const StyledToolbar = styled.div`
    button {
        border-radius: 25%;
        color: #1f2937;
    }

    button:hover, .opened {
        color: rgb(240, 82, 82);
    }
`

export default ToolbarToogle;