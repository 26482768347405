import { Avatar, Tooltip } from "flowbite-react";
import defaultImage from '../../../images/profile.png';
import { useSidebarAccountContext } from "../../../contexts/SidebarAccountContext";
import { useSessionContext } from "../../../contexts/SessionContext";
import { SessionManager } from "../../../utils/SessionManager";

const ToolbarAvatar: React.FC = () => {
    const { session } = useSessionContext();
    const { setSidebarAccountHidden } = useSidebarAccountContext();

    return (
        <div className="flex gap-x-4 cursor-pointer" onClick={() => setSidebarAccountHidden(false)}>
            <div className="cursor-pointer ring-2 ring-offset-blue-200 rounded-full">
                <Tooltip content="Meu perfil" arrow={false}>
                    <Avatar
                        alt="Meu Perfil"
                        rounded={true}
                        img={session?.user?.foto ?? defaultImage} />
                </Tooltip>
            </div>

            <div className="flex flex-col text-sm">
                <span>{`Olá, ${SessionManager.getSession().user?.nome} ${SessionManager.getSession().user?.sobrenome ?? ''}`.trim()}</span>
                <span>{`${SessionManager.getSession().user?.empresaCredenciada ?? SessionManager.getSession().user?.email ?? ""}`}</span>
            </div>
        </div>
    );
};

export default ToolbarAvatar;