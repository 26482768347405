import { PrimeIcons } from "primereact/api";
import { MenuItem } from "primereact/menuitem";
import { FormStateType } from "../../../enums";
import { StaticListItemPecasServicosProps } from "./StaticListItemPecasServicos";

export const useStaticListItemPecasServicos = (props: StaticListItemPecasServicosProps) => {
    const readonly = props.state === FormStateType.view;
    const item = props.model;

    const useContextMenuItems = (): MenuItem[] => [
        { label: `${readonly ? "Visualizar" : "Editar"}`, icon: (readonly ? PrimeIcons.EYE : PrimeIcons.PENCIL), command: () => props.onVisualizar?.call(this, item) },
        { separator: true },
        { label: "Excluir", icon: PrimeIcons.TRASH, disabled: readonly, command: () => props.onExcluir?.call(this, item) },
    ];

    return {
        item,
        useContextMenuItems
    }
}