import { FC, useRef } from "react";
import { TieredMenu } from "primereact/tieredmenu";
import { useStaticListItemCredenciadas } from "./StaticListItemCredenciadas.hook";
import CodeUtil from "../../../utils/CodeUtil";
import { getCotacaoStatus, getCotacaoStatusColor } from "../../../models/cotacao/CotacaoModel";
import { Button } from "flowbite-react";
import { PrimeIcons } from "primereact/api";
import { CotacaoCredenciadaModel } from "../../../models/cotacao/CotacaoCredenciadaModel";
import { FormStateType } from "../../../enums";
import { Popup } from "../../../components/Popup";
import { ModalTabCredenciadasItem } from "./ModalTabCredenciadasItem";
import { CotacaoItemModel } from "../../../models/cotacao/CotacaoItemModel";
import { Badge } from "../../../components/Badge";

export type StaticListItemCredenciadasProps = {
    model: CotacaoCredenciadaModel,
    cotacaoItens: CotacaoItemModel[],
    state: FormStateType,
    onVisualizarCotacao?: (credenciadaId: number) => void
    onCancelarCotacao?: (credenciadaId: number) => void
}

export const StaticListItemCredenciadas: FC<StaticListItemCredenciadasProps> = (props) => {
    const cm = useRef<TieredMenu>(null);
    const { useContextMenuItems, showCancelDialog, showModalItens,
        handleCancelarCotacao, handleCloseCancelDialog, handleCloseModalItens
    } = useStaticListItemCredenciadas(props);

    return (
        <div className="grid grid-cols-12 items-center text-sm">
            <div className="col-span-3 flex flex-col">
                <span className="text-xs font-semibold text-black/60">Razão Social</span>
                <span className="pt-1 flex-wrap break-all">{props.model.razaoSocial}</span>
            </div>
            <div className="col-span-3 ml-6 flex flex-col">
                <span className="text-xs font-semibold text-black/60">Fantasia</span>
                <span className="pt-1 flex-wrap break-all">{props.model.fantasia}</span>
            </div>
            <div className="col-span-2 ml-6 flex flex-col">
                <span className="text-xs font-semibold text-black/60">Valor Total</span>
                <span className="pt-1 flex-wrap break-all">{CodeUtil.moneyFormat(props.model.valorTotal, true)}</span>
            </div>
            <div className="mt-3 col-span-2 md:mt-0 ml-6 flex flex-col">
                <span className="text-xs font-semibold text-black/60 text-center">Status</span>
                <span className="pt-1 font-semibold flex-wrap break-all text-center">
                    <Badge color={getCotacaoStatusColor(props.model.status)} value={getCotacaoStatus(props.model.status)} />
                </span>
            </div>
            <div className="mt-3 col-span-2 md:mt-0 mr-4 ml-auto flex flex-col">
                <TieredMenu model={useContextMenuItems()} popup ref={cm} breakpoint="767px" />
                <Button key={props.model.credenciadaId} color={"light"} size={"sm"}
                    onClick={(e) => cm.current?.toggle(e)}>Mais Opções <i className={`ml-1.5 ${PrimeIcons.CHEVRON_DOWN}`} /></Button>
            </div>

            <Popup
                isShowing={showCancelDialog}
                message="Deseja realmente cancelar a cotação para esta credenciada?"
                isDestructive={true}
                onConfirm={handleCancelarCotacao}
                onCancel={handleCloseCancelDialog}
            />

            <ModalTabCredenciadasItem
                item={props.model}
                cotacaoItens={props.cotacaoItens}
                show={showModalItens}
                state={FormStateType.view}
                fit={true}
                key={"cotacaoItemId"}
                size={"6xl"}
                onClose={handleCloseModalItens} />
        </div>
    );
}