import { MunicipioModel } from '../models/municipio';
import { BaseService } from "./BaseService";
import { MunicipioRequest } from "./requests/index.";
import { DefaultResponse, ServiceProps } from "./types";

const ENDPOINT = `${BaseService.BASE_URL}/municipio`;

export class MunicipioService extends BaseService {
    public static async get(props: MunicipioRequest = { limit: 100, page: 1 }): Promise<DefaultResponse<MunicipioModel>> {
        let _url = `${ENDPOINT}?limit=${props.limit}&page=${props.page}`;

        if (props.descricao !== undefined) _url = _url.concat(`&Descricao=${props.descricao}`);
        if (props.codigoIBGE !== undefined) _url = _url.concat(`&CodigoIBGE=${props.codigoIBGE}`);
        if (props.ufCodigoIBGE !== undefined) _url = _url.concat(`&UFCodigoIBGE=${props.ufCodigoIBGE}`);
        if (props.ufDescricao !== undefined) _url = _url.concat(`&UFDescricao=${props.ufDescricao}`);
        if (props.ufSigla !== undefined) _url = _url.concat(`&UFSigla=${props.ufSigla}`);

        return await this.GET<MunicipioModel>(_url);
    }

    public static async getId(id: number, props: ServiceProps = { limit: 100, page: 1 }): Promise<DefaultResponse<MunicipioModel>> {
        return await this.GET<MunicipioModel>(`${ENDPOINT}/${id}?limit=${props.limit}&page=${props.page}`);
    }
}