import { EstadoModel } from '../models/estado';
import { BaseService } from "./BaseService";
import { DefaultResponse, ServiceProps } from "./types";

const ENDPOINT = `${BaseService.BASE_URL}/estado`;

export class EstadoService extends BaseService {
    public static async get(props: ServiceProps = { limit: 100, page: 1 }): Promise<DefaultResponse<EstadoModel>> {
        return await this.GET<EstadoModel>(`${ENDPOINT}?limit=${props.limit}&page=${props.page}`);
    }

    public static async getId(id: number): Promise<DefaultResponse<EstadoModel>> {
        return await this.GET<EstadoModel>(`${ENDPOINT}/${id}`);
    }
}