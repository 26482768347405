import { useCallback, useEffect, useState } from "react"
import { CotacaoPanelModalAprovacaoProps } from "../../types"
import { ViewCotacaoCredenciadaItemModel } from "../../../../models/cotacao/ViewCotacaoCredenciadaItem";
import { ShowToast } from "../../../CodeToast";
import { UNKOWN_EXCEPTION } from "../../../../utils/Constants";
import { CotacaoCredenciadaService } from "../../../../services/CotacaoCredenciadaService";
import { Badge } from "../../../Badge";
import { getCotacaoStatus, getCotacaoStatusColor } from "../../../../models/cotacao/CotacaoModel";
import { CotacaoStatusType, CotacaoTipoItemType } from "../../../../enums";
import CodeUtil from "../../../../utils/CodeUtil";
import { ICodeDataGridColumn, ICodeDataGridRow } from "../../../CondensedCodeDataGrid/types";
import { getDefaultViewCotacaoCredenciada, ViewCotacaoCredenciadaModel } from "../../../../models/cotacao/ViewCotacaoCredenciada";

export const useCotacaoPanelModalAprovacao = (props: CotacaoPanelModalAprovacaoProps) => {
    const [loaded, setLoaded] = useState<boolean>(false);
    const [model, setModel] = useState<ViewCotacaoCredenciadaModel>(getDefaultViewCotacaoCredenciada())
    const [itens, setItens] = useState<ViewCotacaoCredenciadaItemModel[]>([]);
    const [selectedRows, setSelectedRows] = useState<ICodeDataGridRow[]>([]);

    const columns: ICodeDataGridColumn[] = [
        {
            title: "situação", property: "statusDescricao", alignment: "center", onFormatValue: (model: ViewCotacaoCredenciadaItemModel): JSX.Element => {
                return <span><Badge color={getCotacaoStatusColor(model.status)} value={getCotacaoStatus(model.status)} /></span>;
            }
        },
        { title: 'Tipo', property: 'tipo', onFormatValue: (item: ViewCotacaoCredenciadaItemModel) => <span className="pt-1 flex-wrap">{item.tipo === CotacaoTipoItemType.ITEM ? "PEÇA" : "SERVIÇO"}</span> },
        { title: 'N/S', property: 'numeroSerie' },
        { title: 'Código', property: 'codigoFornecedor' },
        { title: 'Descrição', property: 'descricao' },
        { title: 'Quantidade', property: 'quantidade', alignment: 'center', onFormatValue: (item: ViewCotacaoCredenciadaItemModel) => <span>{CodeUtil.moneyFormat(item.quantidade, false)}</span> },
        { title: 'Desconto', property: 'desconto', alignment: 'right', onFormatValue: (item: ViewCotacaoCredenciadaItemModel) => <span>{CodeUtil.moneyFormat(item.desconto, true)}</span> },
        { title: 'Valor Unitário', property: 'valorUnitario', alignment: 'right', onFormatValue: (item: ViewCotacaoCredenciadaItemModel) => <span>{CodeUtil.moneyFormat(item.valorUnitario, true)}</span> },
        { title: 'Valor Total', property: 'valorTotal', alignment: 'right', onFormatValue: (item: ViewCotacaoCredenciadaItemModel) => <span>{CodeUtil.moneyFormat(item.valorTotal, true)}</span> },
    ];

    const fetchItems = useCallback(async () => {
        try {
            if (loaded) return;

            const response = await CotacaoCredenciadaService.getId(props.id ?? 0,
                props.credenciadaId ?? 0);
            if (!response.success) {
                ShowToast({ message: response.messages });
                return;
            }

            setModel(response.data);

            const _itens = (response.data.itens as ViewCotacaoCredenciadaItemModel[])
                .filter(_ => _.status in [CotacaoStatusType.COTACAO_ENVIADA, CotacaoStatusType.AGUARDANDO]);
            _itens.forEach(_ => _.isSelected = true);
            setItens(_itens);
        } catch (reason) {
            console.log(reason);
            ShowToast({ message: UNKOWN_EXCEPTION });
        } finally {
            setLoaded(true);
        }
    }, [loaded, props]);

    const handleClose = () => props.onClose?.call(this);

    const handleAprovarCotacao = (e: React.MouseEvent<HTMLButtonElement, globalThis.MouseEvent> | undefined) => {
        if (!selectedRows || selectedRows.length === 0) {
            ShowToast({ message: "Para prosseguirmos, selecione os itens da cotação que você deseja aprovar." });
            return;
        }

        const command: number[] | undefined = [];
        selectedRows.map((item) => command.push((item.item as ViewCotacaoCredenciadaItemModel).id!));
        props.onSave?.call(this, command);
    }

    useEffect(() => {
        fetchItems();
    }, [fetchItems])

    return {
        model, columns, itens, setSelectedRows, loaded,
        handleClose, handleAprovarCotacao
    }
}