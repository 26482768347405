import { useCallback, useEffect, useState } from "react";
import { CredenciadaModel } from "../../models/credenciada";
import { CredenciadaService } from "../../services/CredenciadaService";
import { UNKOWN_EXCEPTION } from "../../utils/Constants";
import { ICodeDataGridPagination, ICodeDataGridRow } from "../CodeDataGrid/types";
import { ShowToast } from "../CodeToast";
import { ModalFormProps } from "../ModalForm/types";

export type CredencidadaSearchFormProps = {
    onSelectItems: (items: ICodeDataGridRow[]) => void,
} & ModalFormProps;

export const useCredenciadaSearchForm = (props: CredencidadaSearchFormProps) => {
    const [selectedRows, setSelectedRows] = useState<ICodeDataGridRow[]>([]);
    const [dataSource, setDataSource] = useState<CredenciadaModel[]>([]);
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [pagination, setPagination] = useState<Omit<ICodeDataGridPagination, 'onPageChanged'>>({ page: 1, pageCount: 0, limit: 100, recordCount: 0 });
    const [page, setPage] = useState<number>(1);

    const handlerPaginationPageChange = (pageIndex: number) => setPage(pageIndex);

    const handleSaveButton = () => {
        if (!selectedRows || selectedRows.length === 0) {
            ShowToast({ message: "Nenhuma credenciada selecionada." });
            return;
        }
        setShowPopup(true);
    }

    const handleSelecionarCredenciadas = () => {
        setShowPopup(false);
        setSelectedRows([]);
        props.onSelectItems?.call(this, selectedRows);
    }

    const fetchCredenciadas = useCallback(async () => {
        try {
            const response = await CredenciadaService.get({ page: page, limit: 100 });
            if (!response.success) {
                ShowToast({ message: response.messages });
                setDataSource([]);
                setPagination({
                    page: response.pagination.page,
                    pageCount: response.pagination.pageCount,
                    limit: response.pagination.limit,
                    recordCount: response.pagination.recordCount
                });
            }

            setDataSource(response.data);
            setPagination({
                page: response.pagination.page,
                pageCount: response.pagination.pageCount,
                limit: response.pagination.limit,
                recordCount: response.pagination.recordCount
            });
            return response.data;
        } catch (error) {
            console.log(error);
            ShowToast({ message: UNKOWN_EXCEPTION });
            setDataSource([]);
        }
    }, [setDataSource, setPagination, page]);

    useEffect(() => {
        fetchCredenciadas();
    }, [fetchCredenciadas])


    return {
        dataSource, selectedRows, setSelectedRows, handleSaveButton, handleSelecionarCredenciadas,
        showPopup, setShowPopup, pagination, handlerPaginationPageChange
    }
}