import { FC } from "react";

type CondensedCodeDataGridRowDataProps = {
    className?: string
    alignment?: 'left' | 'center' | 'right' | 'justify'
    children?: JSX.Element | JSX.Element[]
}

export const CondensedCodeDataGridRowData: FC<CondensedCodeDataGridRowDataProps> = (props) => {
    const columnAlingment = props.alignment === 'left' ? 'text-left' :
        props.alignment === 'right' ? 'text-right' : props.alignment === 'center' ? 'text-center' :
            props.alignment === 'justify' ? 'text-justify' : '';

    return (
        <td className={`${props.className} ${columnAlingment} px-6 py-1.5`}>
            {props.children}
        </td>
    )
}