import { FC } from "react";
import ModalForm from "../../ModalForm";
import { CodeLabel } from "../../CodeLabel";
import { FileInput, Textarea, TextInput } from "flowbite-react";
import { format } from "fecha";
import { EncerramentoServicoModalProps } from "../types";
import { useEncerramentoServicoModal } from "./EncerramentoServicoModal.hook";
import { Groupbox } from "../../Groupbox";
import { CodePesquisa } from "../../CodePesquisa";
import { useClienteVeiculoColumns } from "../../../models/cliente_veiculo/useClienteVeiculoColumns";
import { MaskedInput } from "../../MaskedInput";
import { useClienteColumns } from "../../../models/cliente/useClienteColumns";
import { MoneyInput } from "../../MoneyInput";
import CodeUtil from "../../../utils/CodeUtil";
import { findValidationField } from "../../../types";

export const EncerramentoServicoModal: FC<EncerramentoServicoModalProps> = (props) => {
    const { 
        model, inputFile, validation, saving, onEncerrarOrdemServico,
        setRegistroSaida, registroSaida
    } = useEncerramentoServicoModal(props);

    return (
        <ModalForm
            show={props.show}
            state={props.state}
            id={props.id}
            title={props.title}
            size="4xl"
            isSaving={saving}
            saveButtonTitle="Encerrar"
            onClose={props.onClose}
            onSave={onEncerrarOrdemServico}>

            <div className="p-5 grid grid-cols-12">
                <div className="col-span-12 md:col-span-3 mr-6">
                    <CodeLabel value="Data:" htmlFor="inputData" className="mb-1" />
                    <TextInput
                        id="inputData"
                        type={"date"}
                        pattern="\d{4}-\d{2}-\d{2}"
                        value={format(new Date(model.dataCadastro), 'YYYY-MM-DD')}
                        readOnly={true} />
                </div>

                <div className="col-span-12 md:col-span-3">
                    <CodeLabel value="Encerramento:" htmlFor="inputEncerramento" className="mb-1" />
                    <TextInput
                        id="inputEncerramento"
                        type={"date"}
                        disabled={false}
                        pattern="\d{4}-\d{2}-\d{2}"
                        defaultValue={format(new Date(Date.now()), 'YYYY-MM-DD')}
                        readOnly={true} />
                </div>

                <Groupbox label="Informações do Cliente" id="groupBoxCliente" className="col-span-12 mt-6">
                    <div className="form-control mt-3 col-span-12">
                        <CodePesquisa
                            id="cpsCliente"
                            valueField={{ label: "Código:", property: "id", value: model.cliente?.id === 0 ? "" : model.cliente?.id }}
                            textField={{ label: "Razão Social:", property: "razaoSocial", value: model.cliente?.razaoSocial }}
                            modal={{
                                title: "Pesquisa de Clientes",
                                dataColumns: useClienteColumns(),
                                sort: { columnProperty: "razaoSocial", direction: "asc", columnIndex: 2 }
                            }}
                            readOnly={true}
                            endpoint="cliente" />
                    </div>

                    <div className="form-control mt-3 col-span-12">
                        <CodeLabel className="mb-1" htmlFor="inputClienteFantasia" value="Fantasia:" />
                        <TextInput
                            id="inputClienteFantasia"
                            type="text"
                            readOnly={true}
                            value={model.cliente?.fantasia ?? ""}
                            maxLength={150}
                        />
                    </div>

                    <div className="form-control mt-3 col-span-6 md:col-span-4 mr-6">
                        <CodeLabel className="mb-1" htmlFor="inputClienteCnpj" value="CNPJ:" />
                        <MaskedInput
                            id="inputClienteCnpj"
                            mask="##.###.###/####-##"
                            value={model.cliente?.cnpj}
                            readOnly={true}
                        />
                    </div>

                    <div className="form-control mt-3 col-span-6 md:col-span-4 mr-6">
                        <CodeLabel className="mb-1" htmlFor="inputClienteIE" value="Inscrição Estadual:" />
                        <TextInput
                            id="inputClienteIE"
                            value={model.cliente?.inscricaoEstadual ?? ""}
                            readOnly={true}
                        />
                    </div>
                </Groupbox>

                <Groupbox label="Informações do Veículo" id="groupBoxVeiculo" className="col-span-12 mt-6">
                    <div className="form-control mt-3 col-span-12 mr-6">
                        <CodePesquisa
                            id="cpsVeiculo"
                            valueField={{ label: "Código:", property: "id", value: model.veiculo?.id === 0 ? "" : model.veiculo?.id }}
                            textField={{ label: "Modelo:", property: "modeloMarca", value: model.veiculo?.modeloMarca ?? "" }}
                            modal={{
                                title: "Pesquisa de Veículo",
                                dataColumns: useClienteVeiculoColumns(),
                                sort: { columnProperty: "modelo.nome", direction: "asc", columnIndex: 2 }
                            }}
                            endpoint={`cliente/${model.cliente?.id ?? 0}/veiculo`}
                            readOnly={true} />
                    </div>

                    <div className="form-control col-span-6 mr-6 mt-3">
                        <CodeLabel value="Renavam:" htmlFor="inputRenavam" className="mb-1" />
                        <TextInput id="inputRenavam" value={model.veiculo?.renavam ?? ""}
                            readOnly={true} />
                    </div>

                    <div className="form-control col-span-6 mt-3">
                        <CodeLabel value="Placa:" htmlFor="inputPlaca" className="mb-1" />
                        <TextInput id="inputPlaca" value={model.veiculo?.placa ?? ""}
                            readOnly={true} />
                    </div>
                </Groupbox>

                <Groupbox label="Totais" id="groupBoxTotais" className="col-span-12 mt-6">
                    <div className="col-span-6 md:col-span-3 mr-3 mt-3">
                        <CodeLabel value={"Desconto:"} className="mb-1" htmlFor="inputDescontoPercentual" />
                        <MoneyInput id="inputDescontoPercentual"
                            value={CodeUtil.moneyFormat(model?.descontoPercentual ?? 0, false)}
                            addon={"%"}
                            readOnly={true} />
                    </div>

                    <div className="col-span-6 md:col-span-3 mt-3">
                        <CodeLabel value={"Desconto:"} className="mb-1" htmlFor="inputDesconto" />
                        <MoneyInput id="inputDesconto"
                            value={CodeUtil.moneyFormat(model?.desconto ?? 0, false)}
                            addon={"R$"}
                            readOnly={true} />
                    </div>

                    <div className="hidden md:block md:col-span-6" />

                    <div className="col-span-4 md:col-span-3 mr-3 mt-3">
                        <CodeLabel value={"Frete:"} className="mb-1" htmlFor="inputFrete" />
                        <MoneyInput id="inputFrete"
                            value={CodeUtil.moneyFormat(model?.frete ?? 0, false)}
                            addon={"R$"}
                            readOnly={true} />
                    </div>

                    <div className="col-span-4 md:col-span-3 mr-3 mt-3">
                        <CodeLabel value={"Seguro:"} className="mb-1" htmlFor="inputSeguro" />
                        <MoneyInput id="inputSeguro"
                            value={CodeUtil.moneyFormat(model?.seguro ?? 0, false)}
                            addon={"R$"}
                            readOnly={true} />
                    </div>

                    <div className="col-span-4 md:col-span-3 mr-3 mt-3">
                        <CodeLabel value={"Outras Despesas:"} className="mb-1" htmlFor="inputOutrasDespesas" />
                        <MoneyInput id="inputOutrasDespesas"
                            value={CodeUtil.moneyFormat(model?.outrasDespesas ?? 0, false)}
                            addon={"R$"}
                            readOnly={true} />
                    </div>
                    <div className="hidden md:block md:col-span-3" />

                    <div className="col-span-3 mt-3">
                        <CodeLabel value={"Valor Total:"} className="mb-1" htmlFor="inputSubtotal" />
                        <MoneyInput id="inputSubtotal"
                            value={CodeUtil.moneyFormat(model.valorTotal, false)}
                            addon={"R$"}
                            readOnly={true} />
                    </div>
                </Groupbox>

                <Groupbox label="Registro de Saída do veículo" id="groupBoxRegistroSaida" className="col-span-12 mt-6">
                    <div className="form-control col-span-6 md:col-span-3 mt-5 md:mr-6">
                        <CodeLabel value="Quilometragem:" htmlFor="inputKm" className="mb-1" />
                        <MoneyInput id="inputKm"
                            precision={0}
                            value={CodeUtil.numberFormat(registroSaida?.quilometragem ?? 0)}
                            onChange={(_value, numberValue) => setRegistroSaida({ ...registroSaida, quilometragem: numberValue })}
                            addon={""}
                            helperText={findValidationField(validation, "quilometragem").message}
                            color={findValidationField(validation, "quilometragem").isValid ? "gray" : "failure"}
                            maxLength={7} />
                    </div>

                    <div className="form-control mt-5 col-span-6 md:col-span-3 md:mr-6">
                        <CodeLabel className="mb-1" htmlFor="inputBateria" value="Bateria:" />
                        <TextInput
                            id="inputBateria"
                            placeholder="Moura, Heliar, Zetta"
                            value={registroSaida?.marcaBateria}
                            onChange={(e) => setRegistroSaida({ ...registroSaida, marcaBateria: e.currentTarget.value })}
                            maxLength={20}
                        />
                    </div>

                    <div className="form-control mt-5 col-span-12 md:col-span-6 md:mr-6">
                        <CodeLabel className="mb-1" htmlFor="inputAutorizacaoCondutor" value="Autorização do Condutor:" />
                        <TextInput
                            id="inputAutorizacaoCondutor"
                            type="password"
                            placeholder="Código de autorização do condutor"
                            value={registroSaida?.codigoAutorizacao}
                            onChange={(e) => setRegistroSaida({ ...registroSaida, codigoAutorizacao: e.currentTarget.value })}
                            helperText={findValidationField(validation, "codigoAutorizacao").message}
                            color={findValidationField(validation, "codigoAutorizacao").isValid ? "gray" : "failure"}
                            maxLength={255}
                        />
                    </div>

                    <div className="form-control col-span-12 mt-5">
                        <CodeLabel className="mb-1" htmlFor="inputObservacoes" value="Observações:" />
                        <Textarea
                            id="inputObservacoes"
                            rows={5}
                            value={registroSaida?.observacoes}
                            onChange={(e) => setRegistroSaida({ ...registroSaida, observacoes: e.currentTarget.value })}
                        />
                    </div>

                    <div className="col-span-12 mt-3">
                        <CodeLabel value="Nota Fiscal:" htmlFor="inputNotaFiscal" className="mb-1" />
                        <FileInput
                            ref={inputFile}
                            helperText={findValidationField(validation, "notaFiscal").message}
                            color={findValidationField(validation, "notaFiscal").isValid ? "gray" : "failure"}
                            id="inputNotaFiscal"
                            multiple={false}
                            accept=".zip,.rar,.pdf,.xml"
                            disabled={false} />
                    </div>
                </Groupbox>
            </div >

        </ModalForm >
    );
};