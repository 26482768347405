import { UsuarioModel } from '../models/usuario';
import { UsuarioPermissaoModel } from "../models/usuario_permissao";
import { UsuarioProfilePicture } from "../models/usuario_profile_picture";
import { BaseService } from "./BaseService";
import { DefaultResponse, ServiceProps } from "./types";

const ENDPOINT = `${BaseService.BASE_URL}/usuario`;

export class UsuarioService extends BaseService {
    public static async get(props: ServiceProps = { limit: 100, page: 1 }): Promise<DefaultResponse<UsuarioModel>> {
        return await this.GET<UsuarioModel>(`${ENDPOINT}?limit=${props.limit}&page=${props.page}`);
    }

    public static async getId(id: number): Promise<DefaultResponse<UsuarioModel>> {
        return await this.GET<UsuarioModel>(`${ENDPOINT}/${id}`);
    }

    public static async buscarPermissao(usuarioId: number, menuId: string): Promise<DefaultResponse<UsuarioPermissaoModel>> {
        return await this.GET<UsuarioPermissaoModel>(`${ENDPOINT}/${usuarioId}/permissao/${menuId}`);
    }

    public static async add(model: UsuarioModel): Promise<DefaultResponse<UsuarioModel>> {
        return await this.POST<UsuarioModel>(`${ENDPOINT}`, model);
    }

    public static async update(model: UsuarioModel): Promise<DefaultResponse<UsuarioModel>> {
        return await this.PATCH<UsuarioModel>(`${ENDPOINT}/${model.id}`, model);
    }

    public static async updateProfile(id: number, data: unknown): Promise<DefaultResponse<UsuarioModel>> {
        return await this.PATCH(`${ENDPOINT}/${id}/perfil`, data);
    }

    public static async updateProfilePicture(id: number, profilePicture: File): Promise<DefaultResponse<UsuarioProfilePicture>> {
        var formData = new FormData();
        formData.append("profilePicture", profilePicture);
        return await this.POSTFORM<UsuarioProfilePicture>(`${ENDPOINT}/${id}/perfil/foto`, formData);
    }

    public static async delete(id: number): Promise<DefaultResponse<UsuarioModel>> {
        return await this.DELETE(`${ENDPOINT}/${id}`);
    }
}