import { useCallback, useEffect, useState } from "react";
import { ModalFormProps } from "../../components/ModalForm/types";
import { FormStateType } from "../../enums";
import { ProdutoService } from "../../services/ProdutoService";
import CodeUtil from "../../utils/CodeUtil";
import { useFetchVeiculoMarca } from "../../hooks";

export type ProdutoCadastroPageProps = {
} & ModalFormProps

export interface FormFields {
    codigoFornecedor?: string;
    nome?: string;
    preco?: number;
    fabricanteId?: number;
}

export const useProdutoCadastroPage = (props: ProdutoCadastroPageProps) => {
    const readonly = props.state === FormStateType.view;
    const [form, setForm] = useState<FormFields>({
        preco: 0,
    });
    const [loaded, setLoaded] = useState<boolean>(false);
    const [saving, setSaving] = useState<boolean>(false);
    const [errors, setErrors] = useState<string[]>([]);
    const { dataSource: fabricantes } = useFetchVeiculoMarca();

    const onPageLoad = useCallback(async () => {
        try {
            if (loaded) return;

            if (props.id) {
                const response = await ProdutoService.getId(props.id ?? 0);
                if (response.success) {
                    setForm(response.data[0]);
                    return;
                }
            }

        } finally {
            setLoaded(true);
        }

    }, [loaded, props.id]);

    useEffect(() => {
        onPageLoad()
    }, [onPageLoad]);

    const handleClose = () => props.onClose?.call(this);

    const validate = (): boolean => {
        const _erros: string[] = [];

        if (CodeUtil.isNullOrEmpty(form?.codigoFornecedor)) _erros.push('Código do fornecedor é obrigatório.\r\n');
        if (CodeUtil.isNullOrEmpty(form?.nome)) _erros.push('Nome é obrigatório.\r\n');
        if (!form?.fabricanteId || form.fabricanteId <= 0) _erros.push('Fabricante é obrigatório.\r\n');

        setErrors(_erros);
        return _erros.length === 0;
    }

    const handleSave = async (e: React.MouseEvent<HTMLButtonElement> | undefined) => {
        try {
            setSaving(true);
            if (!validate()) return;

            const response = await ProdutoService.save({
                id: props.id ?? 0,
                codigoFornecedor: form?.codigoFornecedor,
                nome: form?.nome,
                fabricanteId: form?.fabricanteId === 0 ? undefined : form?.fabricanteId,
                preco: form?.preco,
                isSelected: false
            });

            if (!response.success) {
                setErrors(response.messages);
                return;
            }

            props.onSave?.call(this, e);
        } finally {
            setSaving(false);
        }
    }

    return {
        form, setForm, 
        saving, readonly, errors,
        fabricantes, 
        handleClose, handleSave
    }
}