import { FC } from "react";
import { useConfiguracoes } from "./index.hook";
import CodeSpinner from "../../components/CodeSpinner";
import styled from "styled-components";
import { Button, Spinner, TextInput } from "flowbite-react";
import { CodeLabel } from "../../components/CodeLabel";
import { MoneyInput } from "../../components/MoneyInput";
import CodeUtil from "../../utils/CodeUtil";

export const ConfiguracoesPage: FC = () => {
    const { model, setModel, loading, saving, handleButtonSave } = useConfiguracoes();

    return (
        <>
            <CodeSpinner hidden={!loading} />

            <div hidden={loading}>
                <StyledContainer className="border-2 rounded-lg shadow-md">
                    <h2 className="border-b-2 text-xl font-bold px-6 py-5">
                        Configurações
                    </h2>

                    <div className="p-6 grid grid-cols-12">
                        <div className="col-span-3 md:col-span-2 mr-3">
                            <CodeLabel value={"Prazo Validade:"} className="mb-1" htmlFor="inputPrazoValidade" />
                            <TextInput id="inputPrazoValidade"
                                value={model.prazoValidade}
                                type={"number"}
                                addon={"Dias"}
                                onChange={(e) => setModel({ ...model, prazoValidade: e.currentTarget.valueAsNumber })} />
                        </div>

                        <div className="col-span-3 md:col-span-2 mr-3">
                            <CodeLabel value={"Comissão Rodar:"} className="mb-1" htmlFor="inputComissaoRodar" />
                            <MoneyInput id="inputComissaoRodar"
                                value={CodeUtil.moneyFormat(model.percentualComissao, false)}
                                addon={"%"}
                                onChange={(_stringValue, numberValue) => setModel({ ...model, percentualComissao: numberValue })} />
                        </div>
                    </div>

                    <footer className="py-3 px-6 mb-6 border-t-2 flex">
                        <div className="ml-auto">
                            <Button type="button" color="dark" size="sm" disabled={saving} onClick={handleButtonSave}>
                                <div className={(saving ?? false) ? "mr-3 text-white" : "text-white"}>
                                    <Spinner size="sm" light={true} hidden={!(saving ?? false)} />
                                </div>
                                Gravar
                            </Button>
                        </div>
                    </footer>
                </StyledContainer>
            </div>
        </>
    );
};

const StyledContainer = styled.div`
    margin-top: 5rem;
    margin-left: 2rem;
    width: 100%;
    height: calc(${props => props.about || "100vh"} - 100px);
    background-color: #fff;
    width: calc(100vw - 4rem);

    footer {
        position: absolute;
        width: calc(100vw - 4rem);
        bottom: 0;
    }
`