import React, { createContext, useContext, useState } from "react";
import { SessionManager } from "../utils/SessionManager";
import { ISessionContext, SessionProviderProps, SessionProps } from "./types";

const SessionContext = createContext<ISessionContext>({ session: SessionManager.getSession(), refreshSession: () => { } })

export const useSessionContext = () => useContext(SessionContext)

export const SessionContextProvider: React.FC<SessionProviderProps> = (props) => {

    const [currentSession, setCurrentSession] = useState<SessionProps>(SessionManager.getSession())
    const onRefreshSession = () => setCurrentSession(SessionManager.getSession())

    return (
        <SessionContext.Provider
            value={{ session: currentSession, refreshSession: onRefreshSession }}
            children={props.children} />
    )
}

export default SessionContext