import { FC } from "react";
import { StyledReportPage } from "../_styles/StyledReportPage";
import { Button, Spinner } from "flowbite-react";
import { CondensedCodeDataGrid } from "../../components/CondensedCodeDataGrid";
import { FormStateType } from "../../enums";
import { useRelatorioItensGarantiaPage } from "./RelatorioItensGarantiaPage.hook";
import { FiltroRelatorioItensGarantia } from "./components/FiltroRelatorioItensGarantia";

export const RelatorioItensGarantiaPage : FC = () => {
    const {
        loading, columns, dataSource,
        showFiltro, setShowFiltro,
        filtro, setFiltro, handleGerarRelatorio
    } = useRelatorioItensGarantiaPage();

    return (
        <StyledReportPage className="shadow rounded-md py-0.5">
            <div className="py-5">
                <h2 className="px-5 mb-4 text-lg font-semibold">Relatório de Itens em Garantia</h2>
                <hr />
            </div>

            <div className="flex gap-x-3 px-3 -mt-2 -mb-3 items-center">
                <Button
                    id="buttonGerarRelatorio"
                    size={'sm'}
                    disabled={loading}
                    onClick={() => setShowFiltro(true)}
                    color={'light'}>

                    <div className={(loading ?? false) ? "mr-3 text-white" : "text-white"}>
                        <Spinner size="sm" light={true} hidden={!loading} />
                    </div>
                    <span hidden={loading}><i className="pi pi-print text-sm mr-1.5" /> Gerar Relatório</span>
                    <span hidden={!loading}>Gerando...</span>
                </Button>
            </div>

            <CondensedCodeDataGrid
                columns={columns}
                datasource={dataSource}
                sort={{
                    columnIndex: 0,
                    columnProperty: 'data',
                    direction: "desc"
                }}
                header={{ filter: false }}
                readonly={true}
                pagination={{
                    limit: 100,
                    page: 1,
                    pageCount: 1,
                    recordCount: 0
                }}
                hoverable={true}
                striped={true} />

            {showFiltro && <FiltroRelatorioItensGarantia
                model={[filtro, setFiltro]}
                show={showFiltro}
                state={FormStateType.edit}
                saveButtonTitle="Gerar Relatório"
                onClose={() => setShowFiltro(false)}
                onApplyFilter={handleGerarRelatorio}
            />}

        </StyledReportPage>
    );
}