import { useState, MouseEvent, Dispatch, SetStateAction, useCallback, useEffect } from "react";
import { FormStateType } from "../../enums";
import { ModalFormProps } from "../../components/ModalForm/types";
import { EmpenhoModel } from "../../models/empenho";
import { EmpenhoService } from "../../services/EmpenhoService";
import { format } from "@formkit/tempo";
import { FluentValidator } from "../../types";
import { isAfter } from "@formkit/tempo";
import { SessionManager } from "../../utils/SessionManager";

interface FormFields {
    numero?: string;
    descricao?: string;
    dataEmpenho?: string;
    dataValidade?: string;
    valorEmpenho?: number;
    saldoDisponivel?: number;
    saldoConsumido?: number;
    observacao?: string;
}

export type EmpenhoCadastroPageProps = {
    model: [EmpenhoModel, Dispatch<SetStateAction<EmpenhoModel>>]
    saving: [boolean, Dispatch<SetStateAction<boolean>>]
    onSave: (e: MouseEvent<HTMLButtonElement> | undefined, model: EmpenhoModel) => void;
} & Omit<ModalFormProps, "onSave">;

export const useEmpenhoCadastroPage = (props: EmpenhoCadastroPageProps) => {
    const [model, setModel] = props.model;
    const [saving, setSaving] = props.saving;
    const readonly = props.state === FormStateType.view;
    const [loaded, setLoaded] = useState(false);
    const [form, setForm] = useState<FormFields>({ ...model });
    const [errors, setErrors] = useState<FluentValidator[]>([]);
    const sessionUser = SessionManager.getSession().user;

    const validate = (): boolean => {
        const _errors: FluentValidator[] = [];
    
        if (!form.numero) {
            _errors.push({
                isValid: false,
                message: "Preenchimento obrigatório",
                field: "numero"
            });
        }

        if (!form.descricao) {
            _errors.push({
                isValid: false,
                message: "Preenchimento obrigatório",
                field: "descricao"
            });
        }

        if (!form.valorEmpenho || form.valorEmpenho <= 0) {
            _errors.push({
                isValid: false,
                message: "Preenchimento obrigatório",
                field: "valorEmpenho"
            });
        }        

        
        if ((form.saldoDisponivel ?? 0) > (form.valorEmpenho ?? 0)) {
            _errors.push({
                isValid: false,
                message: "O valor disponível não pode ser maior que o valor do empenho",
                field: "saldoDisponivel"
            });
        }        

        setErrors(_errors);
        return _errors.length === 0;
    }

    const handleSave = (e: MouseEvent<HTMLButtonElement> | undefined) => {
        setSaving(true);
        if (!validate()) {
            setSaving(false);
            return;
        }

        const postData : EmpenhoModel = {
            ...model,
            ...form,
            clienteId: model.clienteId ?? sessionUser?.clienteId ?? undefined,
            saldoDisponivel: form.saldoDisponivel ?? 0,
            saldoConsumido: form.saldoConsumido ?? 0,
            valorEmpenho: form.valorEmpenho ?? 0,
            dataEmpenho: form.dataEmpenho ? format(new Date(form.dataEmpenho), 'YYYY-MM-DD HH:MM') : format(new Date(Date.now()), 'YYYY-MM-DD HH:MM'),
            dataValidade: form.dataValidade ? format(new Date(form.dataValidade), 'YYYY-MM-DD HH:MM') : undefined,
        };
        postData.situacao = !postData.dataValidade ? "Vigente" : 
            isAfter(new Date(form.dataValidade!), new Date(Date.now())) ? "Vigente" : "Expirado";

        setModel(postData);
        props.onSave?.call(this, e, postData);
    }

    const saldoConsumido = (saldoDisponivel: number, valorEmpenho: number) =>
        (valorEmpenho - saldoDisponivel) < 0 ? 0 : valorEmpenho - saldoDisponivel;

    const handleClose = () => props.onClose?.call(this);

    const onPageLoad = useCallback(async () => {
        try {
            if (loaded) return;

            if (!model?.id || model.id === 0) {
                setModel({
                    isSelected: true,
                    state: 'Added'
                });

                setForm({
                    ...form,
                    ...model,
                });
                return;
            }

            const response = await EmpenhoService.getId(model?.clienteId ?? 0, model?.id ?? 0);
            if (response.success) {
                setModel({
                    ...model,
                    historico: response.data.historico
                });
                setForm({ ...form, ...model });
            }

        } finally {
            setLoaded(true);
        }

    }, [form, loaded, model, setModel]);

    useEffect(() => {
        onPageLoad()
    }, [onPageLoad]);

    return {
        saldoConsumido, handleSave, handleClose, 
        model, setModel, form, setForm, errors, saving, readonly
    }
}