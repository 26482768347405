import { FC } from "react";
import ModalForm from "../../components/ModalForm";
import { CotacaoCredenciadaSolicitarReavaliacaoPageProps, useCotacaoCredenciadaSolicitarReavaliacaoPage } from "./CotacaoCredenciadaSolicitarReavaliacaoPage.hook";
import { CodeLabel } from "../../components/CodeLabel";
import { Textarea } from "flowbite-react";


export const CotacaoCredenciadaSolicitarReavaliacaoPage: FC<CotacaoCredenciadaSolicitarReavaliacaoPageProps> = (props) => {
    const {
        handleSolicitarReavalicacao, form, setForm, error, saving
    } = useCotacaoCredenciadaSolicitarReavaliacaoPage(props);

    return (
        <ModalForm {...props}
            onSave={(e) => handleSolicitarReavalicacao(e)}
            title="Solicitar reavaliação de cotação"
            saveButtonTitle="Solicitar"
            isSaving={saving}
            size="lg"
        >
            <div className="grid grid-cols-12 p-3">
                <div className="col-span-12">
                    <CodeLabel value="Motivo da Solicitação:" htmlFor="inputObservacoes" className="mb-1" />
                    <Textarea
                        id="inputObservacoes"
                        rows={5}
                        helperText={error}
                        color={error ? "failure" : "gray"}
                        value={form?.mensagem}
                        onChange={(e) => setForm({ ...form, mensagem: e.currentTarget.value })} />
                </div>
            </div>

        </ModalForm >
    )
}
