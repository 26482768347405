import { Badge } from "flowbite-react";
import { FC, useCallback, useEffect, useState } from "react";
import { HiCheck } from "react-icons/hi";
import { IoMdClose } from "react-icons/io";
import CodeSpinner from "../../components/CodeSpinner";
import { ShowToast } from "../../components/CodeToast";
import { Popup } from "../../components/Popup";
import { FormStateType } from "../../enums";
import { ClienteModel } from "../../models/cliente/ClienteModel";
import { ClienteService } from "../../services/ClienteService";
import { ModalProps } from "../../types";
import { ClientesPageModal } from "./ClientesPageModal";
import CodeUtil from "../../utils/CodeUtil";
import { KeyboardEvent } from "react";
import { ICodeDataGridPagination, ICodeDataGridRow } from "../../components/CodeDataGrid/types";
import { CodeDataGrid } from "../../components/CodeDataGrid";
import { useBuscarPermissao } from "../../hooks/useBuscarPermissao";
import { SessionManager } from "../../utils/SessionManager";

export const ClientesPage: FC = () => {
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
  const [dataSource, setDataSource] = useState<ClienteModel[]>([]);

  const [page, setPage] = useState<number>(1);
  const [pagination, setPagination] = useState<Omit<ICodeDataGridPagination, 'onPageChanged'>>({ page: 1, pageCount: 0, limit: 100, recordCount: 0 });
  const [selectedRow, setSelectedRow] = useState<ICodeDataGridRow>();

  const [selectedModel, setSelectedModel] = useState<ClienteModel | null>(null);
  const [showPopupDelete, setShowPopupDelete] = useState<boolean>(false);
  const [modalState, setModalState] = useState<ModalProps>({
    show: false,
    state: FormStateType.view,
    id: null,
  });
  const { permissao } = useBuscarPermissao('clientes')
  const sessionUser = SessionManager.getSession().user;

  const onFormLoad = useCallback(async () => {
    setIsLoadingData(true);
    ClienteService.get({ page: page, limit: 100 })
      .then((response) => {
        setDataSource(response.data ?? []);

        setPagination({
          page: response.pagination.page,
          pageCount: response.pagination.pageCount,
          limit: response.pagination.limit,
          recordCount: response.pagination.recordCount
        });
      })
      .finally(() => setIsLoadingData(false));
  }, [setDataSource, setIsLoadingData, setPagination, page]);

  useEffect(() => {
    onFormLoad();
  }, [onFormLoad]);

  const add = () => setModalState({ ...modalState, state: FormStateType.add, show: true, id: 0 });

  const viewOrEdit = (edit: boolean) => {
    if (selectedModel?.id === undefined) {
      ShowToast({
        message: `Nenhum registro selecionado para ${edit ? "edição" : "visualização"
          }`,
      });
      return;
    }

    setModalState({
      state: edit ? FormStateType.edit : FormStateType.view,
      show: true,
      id: selectedModel.id,
    });
  };

  const deleteItem = async () => {
    try {
      let response = await ClienteService.delete(selectedModel!.id!);
      if (!response.success) {
        console.log(response.messages);
        return;
      }

      await onFormLoad();
    } catch (error) {
      console.log(error);
    } finally {
      setShowPopupDelete(false);
    }
  };

  const handleKeydownEvent = (e: KeyboardEvent<HTMLDivElement> | undefined) => {
    if (e?.code?.toLowerCase() === "escape") {
      setModalState({ ...modalState, show: false, id: null });
      return;
    }
  }

  return (
    <div onKeyDown={handleKeydownEvent}>
      <CodeSpinner hidden={!isLoadingData} fullPage={true} />

      <div className="mt-5" hidden={isLoadingData}>
        <CodeDataGrid
          sort={{ columnIndex: 2, direction: "asc", columnProperty: "razaoSocial" }}
          datasource={dataSource}
          striped={true}
          header={{
            title: sessionUser?.gestorContaId ? "Unidades" : "Clientes",
            subtitle: sessionUser?.gestorContaId ? "Listagem de unidades" : "Listagem de clientes",
            filter: true
          }}
          actions={[
            { label: 'Inserir', style: 'colored', disabled: !permissao?.inserir, onClick: add },
            { label: 'Editar', style: 'flat', onClick() { viewOrEdit(true) }, disabled: selectedRow === undefined || !permissao?.editar },
            { label: 'Excluir', style: 'destructive', onClick() { setShowPopupDelete(true) }, disabled: selectedRow === undefined || !permissao?.excluir },
            { label: 'Consultar', style: 'flat', onClick() { viewOrEdit(false) }, disabled: selectedRow === undefined || !permissao?.consultar },
          ]}
          columns={[
            { property: "id", title: "Código", alignment: "center", onFormatValue(value: ClienteModel) { return (<span>{(value.id as Number).toString().padStart(3, "0")}</span>) } },
            {
              property: "bloqueada", title: "Ativo", alignment: "center", onFormatValue(value: ClienteModel) {
                return (
                  <div className="flex justify-center flex-wrap items-center gap-2">
                    {value.bloqueado ? (
                      <Badge size="sm" color="failure" icon={IoMdClose} />
                    ) : (
                      <Badge icon={HiCheck} size="sm" color="success" />
                    )}
                  </div>
                );
              },
            },
            { property: "razaoSocial", title: "Razão Social" },
            { property: "fantasia", title: "Fantasia" },
            { property: "cnpj", title: "CNPJ", onFormatValue(value: ClienteModel) { return (<span>{CodeUtil.mask(value.cnpj, "##.###.###/####-##")}</span>) } },
            { property: "municipio.descricao", title: "Município" },
            { property: "uf.sigla", title: "UF" },
          ]}
          onRowSelect={(item) => { setSelectedModel(item.selected ? item.item : null); setSelectedRow(item.selected ? item : undefined); }}
          pagination={{ ...pagination, onPageChanged: (page) => setPage(page) }}
        />

        {modalState.show && <ClientesPageModal
          show={modalState.show}
          title={sessionUser?.gestorContaId ? "Cadastro de Unidade" : "Cadastro de Cliente"}
          id={modalState.id}
          state={modalState.state}
          onClose={() => {
            setModalState({ ...modalState, show: false, id: null });
          }}
          onSave={async () => {
            setModalState({ ...modalState, show: false, id: null });
            await onFormLoad();
          }}
        />}

        {showPopupDelete && <Popup
          isShowing={showPopupDelete}
          message="Confirma a exclusão do registro selecionado?"
          isDestructive={true}
          onConfirm={async () => await deleteItem()}
          onCancel={() => setShowPopupDelete(false)}
        />}
      </div>
    </div>
  );
};
