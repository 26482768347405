import { Checkbox } from "flowbite-react";
import { FC } from "react";
import CodeUtil from "../../../utils/CodeUtil";
import { ICodeDataGridColumn } from "../types";
import { CondensedCodeDataGridRowData } from "./CondensedCodeDataGridRowData";

type CondensedCodeDataGridRowItemProps = {
    selected: boolean
    className?: string
    striped?: boolean
    hoverable?: boolean
    readonly?: boolean
    item: any
    columns: ICodeDataGridColumn[]
    onRowSelect?: (selected: boolean) => void
}

export const CondensedCodeDataGridRowItem: FC<CondensedCodeDataGridRowItemProps> = (props) => {
    return (
        <tr className={`${props.className ?? ''} bg-gray-100/60 ${(props.striped ?? false) ? 'odd:bg-white' : ''} border-b items-center flex-nowrap whitespace-nowrap`}>
            {props.readonly ? <></> :
                <CondensedCodeDataGridRowData>
                    <Checkbox checked={props.selected} onChange={(e) => props.onRowSelect?.call(this, e.currentTarget.checked)} />
                </CondensedCodeDataGridRowData>}

            {props.columns.map((col: ICodeDataGridColumn, index) => <CondensedCodeDataGridRowData key={index}
                alignment={col.alignment}
                children={col.onFormatValue !== undefined ? col.onFormatValue?.call(this, props.item, index) : CodeUtil.getPropertyValue(props.item, col.property ?? "")} />)}
        </tr>
    )
}