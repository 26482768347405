import { useCallback, useEffect, useState } from "react"
import { ICodeDataGridColumn, ICodeDataGridPagination, ICodeDataGridRow } from "../CodeDataGrid/types"
import { ViewCotacaoCredenciadaModel } from "../../models/cotacao/ViewCotacaoCredenciada"
import { IFiltroCotacao } from "../../types"
import CodeUtil from "../../utils/CodeUtil"
import { format } from "fecha"
import { CotacaoStatusType, UserType } from "../../enums"
import { Badge } from "../Badge"
import { getCotacaoStatus, getCotacaoStatusColor } from "../../models/cotacao/CotacaoModel"
import { SessionManager } from "../../utils/SessionManager"
import { CotacaoCredenciadaService } from "../../services/CotacaoCredenciadaService"
import { FILTRO_ORDENS_SERVICO, UNKOWN_EXCEPTION } from "../../utils/Constants"
import { ShowToast } from "../CodeToast"
import { MenuItem } from "primereact/menuitem"
import { PrimeIcons } from "primereact/api"
import { OrdemServicoPanelFormProps } from "./types"
import { RelatorioService } from "../../services/RelatorioService"
import { Tooltip } from "flowbite-react"

export const useOrdemServicoPanel = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [contentLoaded, setContentLoaded] = useState<boolean>(false);
    const [page, setPage] = useState<number>(1);
    const [pagination, setPagination] = useState<Omit<ICodeDataGridPagination, 'onPageChanged'>>({ page: 1, pageCount: 0, limit: 100, recordCount: 0 });
    const [dataSource, setDataSource] = useState<ViewCotacaoCredenciadaModel[]>([]);
    const [selected, setSelected] = useState<ViewCotacaoCredenciadaModel | null>(null);
    const [form, setForm] = useState<OrdemServicoPanelFormProps>({ show: false });
    const [formEncerramento, setFormEncerramento] = useState<OrdemServicoPanelFormProps>({ show: false });
    const [formIniciarServico, setFormIniciarServico] = useState<OrdemServicoPanelFormProps>({ show: false });
    const [hiddenMoreFilters, setHiddenMoreFilters] = useState<boolean>(true);
    const sessionUser = SessionManager.getSession().user;
    const getFiltroServicos = () => {
        return localStorage.getItem(FILTRO_ORDENS_SERVICO) ? JSON.parse(localStorage.getItem(FILTRO_ORDENS_SERVICO) || "{}") : {
            dataInicial: format(CodeUtil.addDays(-30, new Date(Date.now())), 'YYYY-MM-DD'),
            dataFinal: format(new Date(Date.now()), 'YYYY-MM-DD'),
            status: [CotacaoStatusType.APROVADA, CotacaoStatusType.EMEXECUCAO, CotacaoStatusType.ENCERRADA]
        };
    }
    const [filtros, setFiltros] = useState<IFiltroCotacao>(getFiltroServicos());

    const columnEmpenho: ICodeDataGridColumn = {
        title: " ",
        property: "empenhoId",
        alignment: "center",
        onFormatValue: (value: ViewCotacaoCredenciadaModel) =>
            <Tooltip content={value.empenhoId ? 'Empenho' : 'Avulso'}
                children={value.empenhoId ? <Badge color={'yellow'} value={'EMPENHO'} /> : <Badge color={'default'} value={'AVULSO'} />} />
    };

    const columns: ICodeDataGridColumn[] = [
        { title: "número", property: "id", alignment: "center", onFormatValue: (value: ViewCotacaoCredenciadaModel) => <span>{String(value.id).padStart(4, "0")}</span> },
        { title: "data", property: "dataCadastro", alignment: "center", onFormatValue: (value: ViewCotacaoCredenciadaModel) => <span>{`${CodeUtil.formatStringDate(value.dataCadastro)}`}</span> },
        { title: "validade", property: "dataValidade", alignment: "center", onFormatValue: (value: ViewCotacaoCredenciadaModel) => <span>{`${CodeUtil.formatStringDate(value.dataValidade)}`}</span> },
        { title: "situação", property: "statusDescricao", alignment: "center", onFormatValue: (model) => <span><Badge color={getCotacaoStatusColor(model.status)} value={getCotacaoStatus(model.status)} /></span> },
        { title: `${sessionUser?.gestorContaId ? "unidade" : "cliente"}`, property: "cliente.razaoSocial" },
        { title: "cnpj", property: "cliente.cnpj", alignment: "left", onFormatValue: (value: ViewCotacaoCredenciadaModel) => <span>{CodeUtil.mask(value.cliente?.cnpj ?? "", "##.###.###/####-##")}</span> },
        { title: "credenciada", property: "razaoSocial" },
        { title: "fantasia", property: "fantasia" },
        { title: "marca", property: "veiculo.marca.nome" },
        { title: "modelo", property: "veiculo.modelo.nome" },
        { title: "placa", property: "veiculo.placa" },
        { title: "renavam", property: "veiculo.renavam" },
        { title: "órgão", property: "veiculo.orgao" },
        { title: "desconto", property: "desconto", alignment: "right", onFormatValue: (value: ViewCotacaoCredenciadaModel) => <span>{CodeUtil.moneyFormat(value.desconto)}</span> },
        { title: "valor total", property: "valorTotal", alignment: "right", onFormatValue: (value: ViewCotacaoCredenciadaModel) => <span>{CodeUtil.moneyFormat(value.valorTotal)}</span> },
    ];
    const columnsCliente: ICodeDataGridColumn[] = [
        { title: "número", property: "id", alignment: "center", onFormatValue: (value: ViewCotacaoCredenciadaModel) => <span>{String(value.id).padStart(4, "0")}</span> },
        { title: "data", property: "dataCadastro", alignment: "center", onFormatValue: (value: ViewCotacaoCredenciadaModel) => <span>{`${CodeUtil.formatStringDate(value.dataCadastro)}`}</span> },
        { title: "validade", property: "dataValidade", alignment: "center", onFormatValue: (value: ViewCotacaoCredenciadaModel) => <span>{`${CodeUtil.formatStringDate(value.dataValidade)}`}</span> },
        { title: "situação", property: "statusDescricao", alignment: "center", onFormatValue: (model) => <span><Badge color={getCotacaoStatusColor(model.status)} value={getCotacaoStatus(model.status)} /></span> },
        { title: "credenciada", property: "razaoSocial" },
        { title: "fantasia", property: "fantasia" },
        { title: "marca", property: "veiculo.marca.nome" },
        { title: "modelo", property: "veiculo.modelo.nome" },
        { title: "placa", property: "veiculo.placa" },
        { title: "renavam", property: "veiculo.renavam" },
        { title: "órgão", property: "veiculo.orgao" },
        { title: "desconto", property: "desconto", alignment: "right", onFormatValue: (value: ViewCotacaoCredenciadaModel) => <span>{CodeUtil.moneyFormat(value.desconto)}</span> },
        { title: "valor total", property: "valorTotal", alignment: "right", onFormatValue: (value: ViewCotacaoCredenciadaModel) => <span>{CodeUtil.moneyFormat(value.valorTotal)}</span> },
    ];

    const fetchData = useCallback(async (value?: IFiltroCotacao, index?: number) => {
        try {
            const _filtros = value ?? filtros;
            const defaultStatus = [CotacaoStatusType.APROVADA, CotacaoStatusType.ENCERRADA, CotacaoStatusType.CANCELADA];

            setLoading(true);
            setSelected(null);

            let credenciadaId = sessionUser?.credenciadaId;
            let clienteId = sessionUser?.clienteId;
            let promise = credenciadaId ? CotacaoCredenciadaService.get(credenciadaId, {
                page: index ?? page,
                limit: 100,
                dataCadastroIni: _filtros.dataInicial,
                dataCadastroFim: _filtros.dataFinal,
                status: _filtros.status?.length === 0 ? defaultStatus : _filtros.status
            }) : CotacaoCredenciadaService.getAll({
                clienteId: clienteId,
                page: index ?? page,
                limit: 100,
                dataCadastroIni: _filtros.dataInicial,
                dataCadastroFim: _filtros.dataFinal,
                status: _filtros.status?.length === 0 ? defaultStatus : _filtros.status
            });

            promise.then((response) => {
                setDataSource(response.data);
                setPagination({
                    page: response.pagination.page,
                    pageCount: response.pagination.pageCount,
                    limit: response.pagination.limit,
                    recordCount: response.pagination.recordCount
                });
            }).catch((error) => {
                console.log(error);
                ShowToast({ message: UNKOWN_EXCEPTION });
            }).finally(() => {
                setContentLoaded(true);
            });
        } catch (error) {
            console.log(error);
            setDataSource([]);
        } finally {
            setLoading(false);
            setContentLoaded(true);
        }
    }, [filtros, sessionUser?.credenciadaId, sessionUser?.clienteId, page]);

    const onMount = useCallback(async () => {
        if (contentLoaded) return;

        await fetchData();
    }, [contentLoaded, fetchData]);

    useEffect(() => {
        onMount();
    }, [onMount]);

    const onPageChanged = async (idx: number) => {
        setPage(idx);
        await fetchData(filtros, idx);
    }

    

    const getColumns = (): ICodeDataGridColumn[] => {
        return sessionUser?.tipoUsuario === UserType.customer ? [columnEmpenho, ...columnsCliente] :
            sessionUser?.tipoUsuario === UserType.admin || sessionUser?.tipoUsuario === UserType.gestor ? [columnEmpenho, ...columns] : columns;
    }

    const getContextMenuItems = (): MenuItem[] => {
        let menuItemOrdemServico: MenuItem[] = [
            { label: "Ver detalhes", icon: PrimeIcons.EYE, disabled: !selected, command: verCotacao },
        ];

        if (sessionUser?.tipoUsuario === UserType.dealer || sessionUser?.tipoUsuario === UserType.admin) {
            menuItemOrdemServico.push(...[
                {
                    label: `${(selected && selected.status === CotacaoStatusType.APROVADA ? 'Iniciar Serviço' : 'Encerrar Serviço')}`,
                    icon: PrimeIcons.CAR,
                    disabled: !selected || (selected.status !== CotacaoStatusType.APROVADA && selected.status !== CotacaoStatusType.EMEXECUCAO),
                    command: selected && selected.status === CotacaoStatusType.APROVADA ? iniciarOrdemServico : encerrarOrdemServico
                }
            ]);
        }

        menuItemOrdemServico.push(...[
            { separator: true },
            { label: "Imprimir O.S.", icon: PrimeIcons.PRINT, command: solicitarImpressao }
        ]);

        menuItemOrdemServico.push(...[
            { separator: true },
            { label: "Atualizar", icon: PrimeIcons.REFRESH, command: async () => await fetchData() }]);

        return menuItemOrdemServico;
    }

    const onRowSelect = (item: ICodeDataGridRow) => {
        if (!item.selected || !item.item) {
            setSelected(null);
            return;
        }

        setSelected(item.item);
    }

    const solicitarImpressao = async () => {
        ShowToast({ message: "Impressão solicitada! Enviaremos uma notificação quando a impressão estiver pronta." });

        const report = await imprimirCotacao();
        if (!report) {
            ShowToast({ message: UNKOWN_EXCEPTION });
            return;
        }

        CodeUtil.generateTempFileUrl(report);
    }

    const imprimirCotacao = async (): Promise<Blob | null> => {
        if (!selected) return null;

        if (selected?.credenciadaId) {
            return await RelatorioService.gerarImpressaoCotacaoCredenciada(selected.id!, selected.credenciadaId!);
        }

        return await RelatorioService.gerarImpressaoCotacao(selected.id!);
    }

    const verCotacao = async () => {
        if (!selected || selected.id === null || selected.id === undefined) return;

        setForm({
            ...form,
            id: selected.id,
            credenciadaId: selected.credenciadaId,
            show: true,
            onClose: () => setForm({ ...form, show: false })
        });
    }

    const encerrarOrdemServico = () => {
        if (!selected || selected.id === null || selected.id === undefined) return;

        setFormEncerramento({
            ...form,
            id: selected.id,
            credenciadaId: selected.credenciadaId,
            show: true,
            onClose: () => setFormEncerramento({ ...formEncerramento, show: false }),
            onSave: async () => {
                setFormEncerramento({ ...formEncerramento, show: false });
                await fetchData();
            }
        });
    }

    const iniciarOrdemServico = () => {
        if (!selected || selected.id === null || selected.id === undefined) return;

        setFormIniciarServico({
            ...form,
            id: selected.id,
            credenciadaId: selected.credenciadaId,
            show: true,
            onClose: () => setFormIniciarServico({ ...formIniciarServico, show: false }),
            onSave: async () => {
                setFormIniciarServico({ ...formIniciarServico, show: false });
                await fetchData();
            }
        });
    }

    const onApplyFilter = async (value: IFiltroCotacao) => {
        await fetchData(value);
        setHiddenMoreFilters(true);
    }

    return {
        loading,
        dataSource,
        form, formEncerramento, formIniciarServico,
        pagination, onPageChanged,
        getContextMenuItems,
        getColumns, onRowSelect,
        hiddenMoreFilters, setHiddenMoreFilters,
        filtros, setFiltros, onApplyFilter
    };
};