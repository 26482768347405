import { FC } from "react";
import { ModalFormProps } from "../../components/ModalForm/types";
import ModalForm from "../../components/ModalForm";
import { useCatalogosCadastroPage } from "./index.hook";
import { CodeLabel } from "../../components/CodeLabel";
import { TextInput, Textarea } from "flowbite-react";
import { findValidationField } from "../../types";

export const CatalogosCadastroPage: FC<ModalFormProps> = (props) => {
    const { onFormClose, onFormSave, fetchFormProps,
        model, setModel, readonly, errors,
    } = useCatalogosCadastroPage(props);


    return (
        <ModalForm
            title={'Cadastro de catálogos'}
            errors={[]}
            show={props.show}
            state={props.state}
            isSaving={fetchFormProps().saving}
            size="4xl"
            onClose={onFormClose}
            onSave={onFormSave}
        >
            <div className="grid grid-cols-12 px-6 pb-3">
                <div className="form-control mt-5 col-span-12">
                    <CodeLabel className="mb-1" htmlFor="inputNome" value="Nome:" />
                    <TextInput
                        id="inputNome"
                        type="text"
                        readOnly={readonly}
                        helperText={findValidationField(errors, "nome").message}
                        color={findValidationField(errors, "nome").isValid ? "gray" : "failure"}
                        value={model.nome}
                        onChange={(e) => setModel({ ...model, nome: e.currentTarget.value })}
                        maxLength={120}
                    />
                </div>

                <div className="form-control mt-5 col-span-12">
                    <CodeLabel className="mb-1" htmlFor="inputDescricao" value="Descrição:" />
                    <Textarea
                        id="inputDescricao"
                        rows={5}
                        readOnly={readonly}
                        value={model.descricao}
                        onChange={(e) => setModel({ ...model, descricao: e.currentTarget.value })}
                    />
                </div>

                <div className="form-control mt-5 col-span-12">
                    <CodeLabel className="mb-1" htmlFor="inputCatalogo" value="Catálogo (PDF):" />
                    <TextInput
                        id="inputCatalogo"
                        type="url"
                        placeholder="Link do catálogo"
                        readOnly={readonly}
                        helperText={findValidationField(errors, "url").message}
                        color={findValidationField(errors, "url").isValid ? "gray" : "failure"}
                        value={model.url}
                        onChange={(e) => setModel({ ...model, url: e.currentTarget.value })}
                        maxLength={256}
                    />
                </div>
            </div>
        </ModalForm>
    );
};