import { FC, useEffect } from "react";
import { useProdutoColumns } from "../../hooks";
import { useProdutoPage } from "./ProdutoPage.hook";
import CodeSpinner from "../../components/CodeSpinner";
import { CodeDataGrid } from "../../components/CodeDataGrid";
import { ProdutoCadastroPage } from "./ProdutoCadastroPage";
import { Popup } from "../../components/Popup";
import { FormStateType } from "../../enums";
import { SidebarFiltroProdutos } from "../../components/SidebarFiltroProdutos";
import { ProdutoImportacaoPage } from "./ProdutoImportacaoPage";
import { useBuscarPermissao } from "../../hooks/useBuscarPermissao";

export const ProdutoPage: FC = () => {
    const { columns } = useProdutoColumns();
    const { onPageLoad, modalState, loading, dataSource,
        selected, setSelected, filtro, setFiltro, showImportacao, setShowImportacao,
        showPopupExcluir, setShowPopupExcluir, handleExcluirProduto, handleFiltrarProdutos,
        handleClose, handleInserir, handleConsultar, handlePageChanged, handleImportacaoClose, pagination
    } = useProdutoPage();

    const { permissao } = useBuscarPermissao("produtos")

    useEffect(() => { onPageLoad() }, [onPageLoad]);

    return (
        <>
            <CodeSpinner hidden={!loading} />

            {!loading && <div className="mt-5">
                <CodeDataGrid
                    sort={{ columnIndex: 2, direction: "asc", columnProperty: "nome" }}
                    datasource={dataSource}
                    striped={true}
                    header={{
                        title: "Produtos",
                        subtitle: "Listagem de peças e serviços por fabricante",
                        filter: true
                    }}
                    actions={[
                        { label: 'Novo Produto', disabled: !permissao?.inserir, style: 'colored', onClick: handleInserir },
                        { label: 'Editar', style: 'flat', disabled: !selected?.selected || !permissao?.editar, onClick: () => handleConsultar() },
                        {
                            label: 'Excluir', style: 'destructive', disabled: !selected?.selected || !permissao?.excluir,
                            onClick: () => setShowPopupExcluir(true)
                        },
                        { label: 'Consultar', style: 'flat', disabled: !selected?.selected || !permissao?.consultar, onClick: () => handleConsultar(true) },
                        { label: 'Mais Filtros', style: 'flat', onClick: () => setFiltro({ ...filtro, show: true }) },
                        { label: 'Importar Produtos', disabled: !permissao?.inserir, style: 'flat', onClick: () => setShowImportacao(true) },
                    ]}
                    columns={columns}
                    onRowSelect={(item) => setSelected(item)}
                    pagination={{ ...pagination, onPageChanged: handlePageChanged }}
                />
            </div>}

            {modalState.show && <ProdutoCadastroPage
                show={modalState.show}
                title="Produto"
                state={modalState.state}
                id={modalState.state === FormStateType.add ? null : selected?.item.id}
                onSave={handleClose}
                onClose={handleClose}
            />}

            {showPopupExcluir && <Popup
                isShowing={true}
                message="Confirma a exclusão do produto selecionado?"
                isDestructive={true}
                onConfirm={handleExcluirProduto}
                onCancel={() => setShowPopupExcluir(false)}
            />}

            {filtro?.show &&
                <SidebarFiltroProdutos
                    onClose={() => setFiltro({ ...filtro, show: false })}
                    onFiltrar={handleFiltrarProdutos} />
            }

            {showImportacao && <ProdutoImportacaoPage
                show={showImportacao}
                state={FormStateType.add}
                onClose={handleImportacaoClose}
                onSave={handleImportacaoClose} />}
        </>
    )
}