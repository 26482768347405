import { FC, useCallback, useEffect, useRef, useState } from "react"
import { ClienteFormPageDadosProps } from "../../types"
import { Popup } from "../../../../../components/Popup";
import { FormStateType } from "../../../../../enums";
import { ClienteCondutorModel, defaultClienteCondutor } from "../../../../../models/cliente_condutor/ClienteCondutorModel";
import { ICodeDataGridRow } from "../../../../../components/CodeDataGrid/types";
import { ModalStateType } from "../../../../../components/ModalForm/types";
import { ContextMenu } from "primereact/contextmenu";
import { PrimeIcons } from "primereact/api";
import { ShowToast } from "../../../../../components/CodeToast";
import { Button } from "flowbite-react";
import { CondensedCodeDataGrid } from "../../../../../components/CondensedCodeDataGrid";
import CodeUtil from "../../../../../utils/CodeUtil";
import { ClientesTabCondutoresModal } from "./ClientesTabCondutoresModal";
import { UNKOWN_EXCEPTION } from "../../../../../utils/Constants";
import { ClienteCondutorService } from "../../../../../services/ClienteCondutoreService";

export const ClientesTabCondutores: FC<ClienteFormPageDadosProps> = (props) => {
    const [model, setModel] = props.model;
    const readOnlyForm = props.state === FormStateType.view;
    const [formLoaded, setFormLoaded] = useState<boolean>(false);
    const [selectedModel, setSelectedModel] = useState<ClienteCondutorModel>(defaultClienteCondutor);
    const [selectedRow, setSelectedRow] = useState<ICodeDataGridRow | null>(null);
    const [showPopupDelete, setShowPopupDelete] = useState<boolean>(false);
    const [stateModalCondutor, setStateModalCondutor] = useState<ModalStateType<ClienteCondutorModel>>({
        show: false,
        state: FormStateType.add,
    });

    const cm = useRef<ContextMenu>(null);
    
    const onPageLoad = useCallback(async () => {
        if (!props.show || formLoaded) return;

        props.onPageLoad?.call(this);
        setFormLoaded(true);
    }, [props.show, props.onPageLoad, formLoaded]);

    useEffect(() => { onPageLoad() }, [onPageLoad]);

    const showModal = (formState: FormStateType): void => {
        if (formState === FormStateType.add)
            setSelectedModel(defaultClienteCondutor);

        setStateModalCondutor({
            show: true,
            state: formState
        });
    }

    const updateItem = () => {
        setStateModalCondutor({ ...stateModalCondutor, show: false });

        if (stateModalCondutor.state === FormStateType.add) {
            const novoCondutor = model.condutores;
            novoCondutor.push({ ...selectedModel, state: "ADDED" });

            setModel({ ...model, condutores: novoCondutor });
        } else {
            if (!selectedRow?.selected || selectedRow.rowindex < 0) return;

            let index = selectedRow.rowindex;
            if (index === null || index < 0) return;

            let updatedCondutores = model.condutores;
            updatedCondutores[index] = selectedModel;
            updatedCondutores[index].state = 'UPDATED';
            setModel({ ...model, contatos: updatedCondutores });
        }
    }

    const removeItem = () => {
        if (!selectedRow?.selected || selectedRow.rowindex < 0) return;
        let index = selectedRow.rowindex;
        if (index === null || index < 0) return;

        let updatedCondutores = model.condutores;
        updatedCondutores[index] = selectedModel;
        updatedCondutores[index].state = 'DELETED';

        setModel({ ...model, condutores: updatedCondutores });
        setShowPopupDelete(false);
        setSelectedModel(defaultClienteCondutor);
    }

    const reenviarCredencial = () => {
        try {
            let condutor = selectedRow?.item as ClienteCondutorModel;
            ClienteCondutorService.reenviarCredencial(condutor.id ?? 0)
                .then(() => ShowToast({ message: "Código de autorização enviado."}))
                .catch(() => ShowToast({ message: UNKOWN_EXCEPTION }));            
        } catch (error) {
            ShowToast({ message: UNKOWN_EXCEPTION });
        }
    }

    return (
        <div className="p-0 -m-6">
            <ContextMenu ref={cm} model={[
                { label: "Novo Condutor", icon: PrimeIcons.PLUS, disabled: readOnlyForm, command: () => showModal(FormStateType.add) },
                { label: "Editar", icon: PrimeIcons.PENCIL, disabled: readOnlyForm || !selectedRow?.selected, command: () => showModal(FormStateType.edit) },
                { label: "Consultar", icon: PrimeIcons.EYE, disabled: !selectedRow?.selected, command: () => showModal(FormStateType.view) },
                { separator: true },
                { label: "Reenviar Senha", icon: PrimeIcons.ENVELOPE, disabled: !selectedRow?.selected, command: reenviarCredencial },
                { separator: true },
                {
                    label: "Excluir", icon: PrimeIcons.TRASH, disabled: readOnlyForm || !selectedRow?.selected, command: () => {
                        if (selectedModel === undefined) {
                            ShowToast({ message: 'Nenhum registro selecionado para exclusão' });
                            return;
                        }
                        setShowPopupDelete(true)
                    }
                },
            ]} />

            {model.condutores.length <= 0 ?
                <div className="flex flex-col space-y-3 ml-auto mr-auto p-5 place-items-center">
                    <span className="text-slate-500">Este cliente não possui condutores</span>
                    <Button disabled={readOnlyForm} onClick={() => showModal(FormStateType.add)} color="dark" size="sm">Novo condutor</Button>
                </div>
                : <></>
            }

            {model.condutores.length > 0 ?
                <div className="space-y-4">
                    <CondensedCodeDataGrid
                        key="id"
                        sort={{ columnIndex: 0, direction: "asc", columnProperty: "nome" }}
                        header={{ filter: true }}
                        onContextMenu={(e) => cm.current?.show(e)}
                        datasource={model.condutores.filter((e) => e.state !== 'DELETED')}
                        columns={[
                            { property: "nome", title: "Nome" },
                            { property: "sobrenome", title: "Sobrenome" },
                            { property: "email", title: "E-mail" },
                            { property: "cpf", title: "CPF", onFormatValue: (value: ClienteCondutorModel) => CodeUtil.mask(value.cpf ?? "", "###.###.###-##") },
                            { property: "matricula", title: "Matrícula" },
                            { property: "numeroHabilitacao", title: "Habilitação" },
                            { property: "celular", title: "Celular", onFormatValue: (value: ClienteCondutorModel) => CodeUtil.mask(value.celular ?? "", "(##) #####-####") },
                        ]}
                        onRowSelect={(selectedRow) => {
                            setSelectedModel((!selectedRow?.selected) ? defaultClienteCondutor : selectedRow.item as ClienteCondutorModel);
                            setSelectedRow(selectedRow);
                        }}
                        multiselect={false}
                    />
                </div> :
                <></>
            }


            {stateModalCondutor.show && <ClientesTabCondutoresModal
                show={stateModalCondutor.show}
                title="Condutor"
                state={stateModalCondutor.state}
                model={[selectedModel, setSelectedModel]}
                onSave={() => updateItem()}
                onClose={() => setStateModalCondutor({ ...stateModalCondutor, show: false })}
            />}

            {showPopupDelete && <Popup
                isShowing={showPopupDelete}
                message="Confirma a exclusão do registro selecionado?"
                isDestructive={true}
                onConfirm={() => removeItem()}
                onCancel={() => setShowPopupDelete(false)}
            />}
        </div>
    );
} 