import { FC } from "react";
import { useCotacaoPanelModalAprovacao } from "./CotacaoPanelModalAprovacao.hook";
import ModalForm from "../../../ModalForm";
import { CotacaoPanelModalAprovacaoProps } from "../../types";
import { CondensedCodeDataGrid } from "../../../CondensedCodeDataGrid";
import { TextInput } from "flowbite-react";
import { useClienteColumns } from "../../../../models/cliente/useClienteColumns";
import { useClienteVeiculoColumns } from "../../../../models/cliente_veiculo/useClienteVeiculoColumns";
import { CodeLabel } from "../../../CodeLabel";
import { CodePesquisa } from "../../../CodePesquisa";
import { Groupbox } from "../../../Groupbox";
import { MaskedInput } from "../../../MaskedInput";

export const CotacaoPanelModalAprovacao: FC<CotacaoPanelModalAprovacaoProps> = (props) => {
    const { model, columns, itens, loaded, setSelectedRows, handleClose, 
        handleAprovarCotacao } = useCotacaoPanelModalAprovacao(props);

    return (
        <ModalForm
            show
            title={`Aprovação de Cotação - #${props.id}`}
            state={props.state}
            saveButtonTitle={props.saveButtonTitle}
            onClose={handleClose}
            onSave={handleAprovarCotacao}
            size="5xl">
            <>
                <div className="grid grid-cols-12 py-3 px-5 pb-8">
                    <Groupbox label="Informações do Cliente" id="groupBoxCliente" className="col-span-12">
                        <div className="form-control mt-3 col-span-12 md:col-span-6 md:mr-6">
                            <CodePesquisa
                                id="cpsCliente"
                                valueField={{ label: "Código:", property: "id", value: model.cliente?.id === 0 ? "" : model.cliente?.id }}
                                textField={{ label: "Razão Social:", property: "razaoSocial", value: model.cliente?.razaoSocial }}
                                modal={{
                                    title: "Pesquisa de Clientes",
                                    dataColumns: useClienteColumns(),
                                    sort: { columnProperty: "razaoSocial", direction: "asc", columnIndex: 2 }
                                }}
                                readOnly
                                endpoint="cliente" />
                        </div>

                        <div className="form-control mt-3 col-span-12 md:col-span-6">
                            <CodeLabel className="mb-1" htmlFor="inputClienteFantasia" value="Fantasia:" />
                            <TextInput
                                id="inputClienteFantasia"
                                type="text"
                                readOnly
                                value={model.cliente?.fantasia ?? ""}
                                maxLength={150}
                            />
                        </div>

                        <div className="form-control mt-3 col-span-12 md:col-span-3 mr-6">
                            <CodeLabel className="mb-1" htmlFor="inputClienteCnpj" value="CNPJ:" />
                            <MaskedInput
                                id="inputClienteCnpj"
                                mask="##.###.###/####-##"
                                value={model.cliente?.cnpj}
                                readOnly
                            />
                        </div>

                        <div className="form-control mt-3 col-span-12 md:col-span-3 mr-6">
                            <CodeLabel className="mb-1" htmlFor="inputClienteIE" value="Inscrição Estadual:" />
                            <TextInput
                                id="inputClienteIE"
                                value={model.cliente?.inscricaoEstadual ?? ""}
                                readOnly
                            />
                        </div>
                    </Groupbox>

                    <Groupbox label="Informações do Veículo" id="groupBoxVeiculo" className="col-span-12 mt-6">
                        <div className="form-control mt-3 col-span-12 md:col-span-6 mr-6">
                            <CodePesquisa
                                id="cpsVeiculo"
                                valueField={{ label: "Código:", property: "id", value: model.veiculo?.id === 0 ? "" : model.veiculo?.id }}
                                textField={{ label: "Modelo:", property: "modeloMarca", value: model.veiculo?.modeloMarca ?? "" }}
                                modal={{
                                    title: "Pesquisa de Veículo",
                                    dataColumns: useClienteVeiculoColumns(),
                                    sort: { columnProperty: "modelo.nome", direction: "asc", columnIndex: 2 }
                                }}
                                endpoint={`cliente/${model.cliente?.id ?? 0}/veiculo`}
                                readOnly
                            />
                        </div>

                        <div className="form-control col-span-6 mr-6 md:col-span-3 mt-3">
                            <CodeLabel value="Renavam:" htmlFor="inputRenavam" className="mb-1" />
                            <TextInput id="inputRenavam" value={model.veiculo?.renavam ?? ""}
                                readOnly />
                        </div>

                        <div className="form-control col-span-6 md:col-span-3 mt-3">
                            <CodeLabel value="Placa:" htmlFor="inputPlaca" className="mb-1" />
                            <TextInput id="inputPlaca" value={model.veiculo?.placa ?? ""}
                                readOnly />
                        </div>
                    </Groupbox>
                </div >

                <div className="bg-gray-50 border-y-2 py-1.5 px-3 text-center text-xs text-black font-semibold uppercase">
                    <span>Selecione abaixo os itens aprovados</span>
                </div>

                {loaded && <CondensedCodeDataGrid
                    multiselect
                    striped
                    selectAll
                    datasource={itens}
                    sort={{ columnIndex: 0, direction: "asc", columnProperty: "statusDescricao" }}
                    key={"id"}
                    onRowsSelect={(item) => setSelectedRows(item)}
                    columns={columns}
                />}
            </>
        </ModalForm >

    )
}  