import { AxiosError } from "axios";
import { TextInput } from "flowbite-react";
import { Dispatch, FC, SetStateAction, useCallback, useEffect, useState } from "react";
import { CodeLabel } from "../../../../../../../components/CodeLabel";
import { ShowToast } from "../../../../../../../components/CodeToast";
import { MaskedInput } from "../../../../../../../components/MaskedInput";
import ModalForm from "../../../../../../../components/ModalForm";
import { ModalFormProps } from "../../../../../../../components/ModalForm/types";
import { FormStateType } from "../../../../../../../enums";
import { CredenciadaContatoModel } from "../../../../../../../models/credenciada_contato";
import { findValidationField, FluentValidator } from "../../../../../../../types";
import CodeUtil from "../../../../../../../utils/CodeUtil";

export type CredenciadaTabContatosModalProps<T> = {
    model: [T, Dispatch<SetStateAction<T>>]
} & ModalFormProps

export const CredenciadasTabContatosModal: FC<CredenciadaTabContatosModalProps<CredenciadaContatoModel>> = (props) => {
    const [errors, setErrors] = useState<FluentValidator[]>([]);
    const [saving, setSaving] = useState<boolean>(false);
    const [formLoaded, setFormLoaded] = useState<boolean>(false);
    const [model, setModel] = props.model;
    const isReadOnly = props.state === FormStateType.view;

    const onFormClose = () => {
        setErrors([]);
        setFormLoaded(false);
        props.onClose?.call(this);
    };

    const onFormLoad = useCallback(async () => {
        if (!props.show || formLoaded) return;

        setFormLoaded(true);
    }, [props.show, formLoaded, setFormLoaded]);

    const validate = (): FluentValidator[] => {
        let _errors: FluentValidator[] = [];

        if (CodeUtil.isNullOrEmpty(model.nome)) _errors.push({ field: "nome", message: "O nome deve ser preenchido", isValid: false });
        if (!CodeUtil.isNullOrEmpty(model.nome) && !CodeUtil.isValidEmail(model.email)) _errors.push({ field: "email", message: "O e-mail informado é inválido", isValid: false });

        setErrors(_errors);
        return _errors;
    }

    const onFormSave = async (e: React.MouseEvent<HTMLButtonElement> | undefined) => {
        let validationResult = validate();

        if (validationResult.length > 0) return;

        try {
            setSaving(true);
            await setModel({ ...model, state: props.state === FormStateType.add ? 'ADDED' : 'UPDATED' });

            props.onSave?.call(this, e);
            onFormClose();
        } catch (error) {
            console.log(error);
            ShowToast({ message: (error as AxiosError).response?.data as string });
        } finally {
            setSaving(false);
        }
    };

    useEffect(() => {
        onFormLoad();
    });

    if (!props.show) return <></>;

    return (
        <ModalForm
            title={props.title ?? "Cadastro de Credenciadas"}
            show={props.show}
            state={props.state}
            isSaving={saving}
            size="3xl"
            onClose={onFormClose}
            onSave={onFormSave}
        >
            <div className="grid grid-cols-12 px-6 pb-3">
                <div className="form-control mt-5 col-span-12">
                    <CodeLabel className="mb-1" htmlFor="inputNome" value="Nome:" />
                    <TextInput
                        id="inputNome"
                        type="text"
                        readOnly={isReadOnly}
                        value={model?.nome}
                        helperText={findValidationField(errors, "nome").message}
                        color={findValidationField(errors, "nome").isValid ? "gray" : "failure"}
                        onChange={(e) => setModel({ ...model, nome: e.currentTarget.value })}
                        maxLength={200}
                    />
                </div>

                <div className="form-control mt-5 col-span-12">
                    <CodeLabel className="mb-1" htmlFor="inputEmail" value="E-mail:" />
                    <TextInput
                        id="inputEmail"
                        type="email"
                        placeholder="seumelhoremail@email.com.br"
                        helperText={findValidationField(errors, "email").message}
                        color={findValidationField(errors, "email").isValid ? "gray" : "failure"}
                        readOnly={isReadOnly}
                        value={model?.email}
                        onChange={(e) => setModel({ ...model, email: e.currentTarget.value })}
                        maxLength={200}
                    />
                </div>

                <div className="form-control mt-5 col-span-12 md:col-span-6 md:mr-6">
                    <CodeLabel className="mb-1" htmlFor="inputTelefone" value="Telefone:" />
                    <MaskedInput
                        id="inputTelefone"
                        type="text"
                        pattern="^[0-9]+$"
                        mask="(##) ####-####"
                        readOnly={isReadOnly}
                        value={model?.telefone}
                        onChange={(e) => setModel({ ...model, telefone: e })}
                    />
                </div>

                <div className="form-control mt-5 col-span-12 md:col-span-6">
                    <CodeLabel className="mb-1" htmlFor="inputTelefone2" value="Telefone 2:" />
                    <MaskedInput
                        id="inputTelefone2"
                        type="text"
                        pattern="^[0-9]+$"
                        mask="(##) ####-####"
                        readOnly={isReadOnly}
                        value={model?.telefone2}
                        onChange={(e) => setModel({ ...model, telefone2: e })}
                    />
                </div>


                <div className="form-control mt-5 col-span-12 md:col-span-6 md:mr-6">
                    <CodeLabel className="mb-1" htmlFor="inputCelular" value="Celular:" />
                    <MaskedInput
                        id="inputCelular"
                        type="text"
                        mask="(##) #####-####"
                        pattern="^[0-9]+$"
                        readOnly={isReadOnly}
                        value={model?.celular}
                        onChange={(e) => setModel({ ...model, celular: e })}
                    />
                </div>

                <div className="form-control mt-5 col-span-12 md:col-span-6">
                    <CodeLabel className="mb-1" htmlFor="inputCelular2" value="Celular 2:" />
                    <MaskedInput
                        id="inputCelular2"
                        type="text"
                        pattern="^[0-9]+$"
                        mask="(##) #####-####"
                        readOnly={isReadOnly}
                        value={model?.celular2}
                        onChange={(e) => setModel({ ...model, celular2: e })}
                    />
                </div>

                <div className="form-control mt-5 col-span-12">
                    <CodeLabel className="mb-1" htmlFor="inputSetor" value="Setor:" />
                    <TextInput
                        id="inputSetor"
                        type="text"
                        readOnly={isReadOnly}
                        value={model?.setor}
                        onChange={(e) => setModel({ ...model, setor: e.currentTarget.value })}
                        maxLength={40}
                    />
                </div>
            </div>
        </ModalForm>
    );
};