import { useState } from "react";
import { ModalFormTabProps } from "../../../components/ModalForm/types";
import { CotacaoStatusType, CotacaoTipoItemType, FormStateType, ModelState } from "../../../enums";
import { ViewCotacaoCredenciadaModel } from "../../../models/cotacao/ViewCotacaoCredenciada";
import { ViewCotacaoCredenciadaItemModel, getDefaultViewCotacaoCredenciadaItem } from "../../../models/cotacao/ViewCotacaoCredenciadaItem";
import { ICodeDataGridColumn } from "../../../components/CondensedCodeDataGrid/types";
import CodeUtil from "../../../utils/CodeUtil";
import { Button } from "flowbite-react";
import { IModalState } from "../../cotacoes_cadastro/components/ModalTabItens.hook";
import { Badge } from "../../../components/Badge";
import { getCotacaoStatus, getCotacaoStatusColor } from "../../../models/cotacao/CotacaoModel";

type Totais = {
    frete: number
    seguro: number
    outrasDespesas: number
    valorTotal: number
    desconto: number
    descontoPercentual: number
    valorComissao: number
}

export const useTabItens = (props: ModalFormTabProps<ViewCotacaoCredenciadaModel>) => {
    const [item, setItem] = props.model;
    const [itemIndex, setItemIndex] = useState<number | null>(null);
    const [itemModel, setItemModel] = useState<ViewCotacaoCredenciadaItemModel>(getDefaultViewCotacaoCredenciadaItem());
    const [modalState, setModalState] = useState<IModalState>({ show: false, state: FormStateType.add });

    const isReadOnly = (model: ViewCotacaoCredenciadaItemModel) => props.state === FormStateType.view 
        || model?.status !== CotacaoStatusType.AGUARDANDO;

    const columns: ICodeDataGridColumn[] = [
        {
            title: 'AÇÕES', property: '', alignment: 'center', onFormatValue: (model: ViewCotacaoCredenciadaItemModel) =>
                <Button color={"light"}
                    size="sm"
                    onClick={() => handlePreencherItem(model)}><i className={`mr-1.5 pi ${isReadOnly(model) ? 'pi-eye' : 'pi-pencil'}`} />
                    {isReadOnly(model) ? "Consultar" : "Preencher"}
                </Button>
        },
        {
            title: "situação", property: "statusDescricao", alignment: "center", onFormatValue: (model: ViewCotacaoCredenciadaItemModel): JSX.Element => {
                return <span><Badge color={getCotacaoStatusColor(model.status)} value={getCotacaoStatus(model.status)} /></span>;
            }
        },
        { title: 'Tipo', property: 'tipo', onFormatValue: (item: ViewCotacaoCredenciadaItemModel) => <span className="pt-1 flex-wrap">{item.tipo === CotacaoTipoItemType.ITEM ? "PEÇA" : "SERVIÇO"}</span> },
        { title: 'N/S', property: 'numeroSerie' },
        { title: 'Código', property: 'codigoFornecedor' },
        { title: 'Descrição', property: 'descricao' },
        { title: 'Quantidade', property: 'quantidade', alignment: 'center', onFormatValue: (item: ViewCotacaoCredenciadaItemModel) => <span>{CodeUtil.moneyFormat(item.quantidade, false)}</span> },
        { title: 'Desconto', property: 'desconto', alignment: 'right', onFormatValue: (item: ViewCotacaoCredenciadaItemModel) => <span>{CodeUtil.moneyFormat(item.desconto, true)}</span> },
        { title: 'Valor Unitário', property: 'valorUnitario', alignment: 'right', onFormatValue: (item: ViewCotacaoCredenciadaItemModel) => <span>{CodeUtil.moneyFormat(item.valorUnitario, true)}</span> },
        { title: 'Valor Total', property: 'valorTotal', alignment: 'right', onFormatValue: (item: ViewCotacaoCredenciadaItemModel) => <span>{CodeUtil.moneyFormat(item.valorTotal, true)}</span> },
    ];

    const handlePreencherItem = (value: ViewCotacaoCredenciadaItemModel) => {
        if (!value) return;

        setItemIndex(item.itens.findIndex((_) => _.id === value.id));

        setItemModel({
            ...getDefaultViewCotacaoCredenciadaItem(),
            ...value,
            percentualComissao: item.cliente?.percentualComissaoRodar ?? 0,
            descontoPercentual: item.cliente?.percentualDescontoContrato ?? 0
        });

        setModalState({
            show: true,
            state: isReadOnly(value) ? FormStateType.view : FormStateType.edit
        });
    }

    const handleCloseLancamentoItem = () => {
        setModalState({ ...modalState, show: false });
    }

    const handleButtonInserir = () => {
        setItemModel({
            ...getDefaultViewCotacaoCredenciadaItem(),
            percentualComissao: item.cliente?.percentualComissaoRodar ?? 0,
            descontoPercentual: item.cliente?.percentualDescontoContrato ?? 0
        });

        setModalState({ show: true, state: FormStateType.add });
    }

    const handleSaveLancamentoitem = (obj: ViewCotacaoCredenciadaItemModel) => {
        let _items = item.itens;

        if (modalState.state === FormStateType.edit) {
            _items[itemIndex!] = { ...obj, state: ModelState.updated };
        } else {
            _items.push(obj);
        }

        const totais = atualizarTotais(_items);
        setItem({
            ...item,
            itens: _items,
            frete: totais.frete,
            seguro: totais.seguro,
            outrasDespesas: totais.outrasDespesas,
            desconto: totais.desconto,
            descontoPercentual: totais.descontoPercentual,
            valorTotal: totais.valorTotal,
            valorComissao: totais.valorComissao
        });

        setModalState({ ...modalState, show: false });
    }

    const atualizarTotais = (_items: ViewCotacaoCredenciadaItemModel[]): Totais => {
        let totais: Totais = { desconto: 0, frete: 0, seguro: 0, outrasDespesas: 0, valorTotal: 0, descontoPercentual: 0, valorComissao: 0 };

        _items.forEach(_ => {
            totais.frete += _.frete;
            totais.seguro += _.seguro;
            totais.outrasDespesas += _.outrasDespesas;
            totais.valorTotal += _.valorTotal;
            totais.desconto += _.desconto;
            totais.valorComissao += _.valorComissao;
        });

        totais.descontoPercentual = calcularPercentualDesconto(totais.desconto, totais.valorTotal - (totais.desconto + totais.frete + totais.seguro + totais.outrasDespesas));
        return totais;
    }

    const calcularPercentualDesconto = (valorDesconto: number, subtotal: number): number => subtotal === 0 ? 0 : (valorDesconto / subtotal) * 100;

    return {
        item, setItem, itemModel, setItemModel,
        itemIndex, setItemIndex,
        readonly: isReadOnly,
        modalState, columns,
        handlePreencherItem, handleCloseLancamentoItem, handleSaveLancamentoitem, handleButtonInserir
    }
}