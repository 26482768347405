import { FC, useRef } from "react";
import CodeSpinner from "../CodeSpinner";
import { useCotacoesPanel } from "./index.hook";
import { CondensedCodeDataGrid } from "../CondensedCodeDataGrid";
import { ContextMenu } from "primereact/contextmenu";
import { Popup } from "../Popup";
import { FormStateType } from "../../enums";
import { CotacoesCredenciadasCadastroPage } from "../../pages/cotacoes_credenciadas_cadastro/CotacoesCredenciadasCadastroPage";
import { SideBarFiltrosCotacoes } from "../SidebarFiltroCotacoes";
import { TwoFactorPopup } from "../2FA";
import { CotacaoPanelModalAprovacao } from "./components/CotacaoPanelAprovacao/CotacaoPanelModalAprovacao";

export const CotacoesPanel: FC = () => {
    const { loading, popup, form, dataSource, pagination,
        hiddenMoreFilters, setHiddenMoreFilters, filtros, setFiltros, onApplyFilter,
        getColumns, onPageChanged, getContextMenuItems, onRowSelect, twoFactorAuth,
        onAuthenticate, onCancelAuthentication, showModalItens
    } = useCotacoesPanel();
    const cm = useRef<ContextMenu>(null);

    return (
        <>
            {loading && <CodeSpinner />}

            {!loading &&
                <div className="w-full mb-8">
                    <ContextMenu ref={cm} model={getContextMenuItems()} />

                    <div className="-m-4">
                        <CondensedCodeDataGrid
                            sort={{ columnIndex: 2, direction: "desc", columnProperty: "id" }}
                            datasource={dataSource}
                            striped={true}
                            onContextMenu={(e) => cm.current?.show(e)}
                            onRowSelect={onRowSelect}
                            height={'37.7rem'}
                            header={{ filter: true }}
                            onMoreFilter={() => setHiddenMoreFilters(false)}
                            columns={getColumns()}
                            pagination={{ ...pagination, onPageChanged: onPageChanged }}
                        />
                    </div>

                    {form.show && <CotacoesCredenciadasCadastroPage
                        show={form.show}
                        credenciadaId={form.credenciadaId}
                        id={form.id}
                        state={form.state}
                        onSave={form.onClose}
                        onClose={form.onClose}
                    />}

                    {popup.show && <Popup
                        isShowing={popup.show}
                        message={popup.title}
                        confirmButtonTitle={popup.confirmButtonTitle}
                        isDestructive={popup.destructive}
                        onConfirm={popup.onConfirm}
                        onCancel={popup.onCancel}
                    />}

                    {twoFactorAuth.show && <TwoFactorPopup
                        show={twoFactorAuth.show}
                        state={FormStateType.unknown}
                        onClose={onCancelAuthentication}
                        onSave={onAuthenticate} />}

                    {!hiddenMoreFilters && <SideBarFiltrosCotacoes
                        filter={[filtros, setFiltros]}
                        hidden={hiddenMoreFilters}
                        onClose={() => setHiddenMoreFilters(true)}
                        onApplyFilter={onApplyFilter} />}

                    {showModalItens.show && <CotacaoPanelModalAprovacao {...showModalItens} />}
                </div>}
        </>
    );
};
