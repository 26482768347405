import { ICodeDataGridColumn } from "../../components/CodeDataGrid/types"
import { CatalogoModel } from "./CatalogoModel";

const useCatalogoColumns = (): ICodeDataGridColumn[] => {
    return [
        { property: "id", title: "Código", alignment: "center", onFormatValue: (item: CatalogoModel) => (<span>{item.id?.toString().padStart(4, "0")}</span>) },        
        { property: "nome", title: "Nome"},
        { property: "descricao", title: "Descrição" },
    ]
}

export { useCatalogoColumns };