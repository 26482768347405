import { AvatarProps } from "flowbite-react";
import { ChangeEvent, FC, MouseEvent, useRef, useState } from "react";
import { FaCamera } from "react-icons/fa";


export type ProfilePictureProps = {
    onSelectImage?: (file: File | null, fileUrl?: string) => void;
} & AvatarProps;

export const ProfilePicture: FC<ProfilePictureProps> = (props) => {
    const [hover, setHover] = useState<boolean>(false);
    const inputProfileImage = useRef<HTMLInputElement>(null);

    const handleMouseLeave = (e: MouseEvent<HTMLDivElement>) => setHover(false);
    const handleMouseEnter = (e: MouseEvent<HTMLDivElement>) => setHover(true);
    const handleSelectImage = (e: MouseEvent<HTMLDivElement>) => inputProfileImage.current?.click();
    const handleChangeImage = (e: ChangeEvent<HTMLInputElement>) => {
        const file = inputProfileImage.current?.files !== null &&
            inputProfileImage.current?.files !== undefined && inputProfileImage.current?.files.length > 0 ?
            inputProfileImage.current?.files[0] : null;

        if (file === null) return;

        const fileUrl = URL.createObjectURL(file);
        props.onSelectImage?.call(this, file, fileUrl);
    }

    return (
        <div className="flex col-span-12 justify-center mt-6 mb-8">
            <input ref={inputProfileImage} className="hidden"
                type="file" name="inputProfileImage" id="inputProfileImage"
                value=""
                accept="image/*" multiple={false} onChange={handleChangeImage} />


            <div className="relative ring-4 ring-offset-blue-200 w-24 h-24 rounded-full" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <img src={props.img} alt="" className="rounded-full absolute w-24 h-24" />
                <div className="absolute w-24 h-24 rounded-full bg-black/40 cursor-pointer" hidden={!hover} onClick={handleSelectImage}>
                    <div className="flex w-full h-full place-items-center justify-center">
                        <FaCamera className="text-white/60 w-8 h-8" />
                    </div>
                </div>
            </div>
        </div>
    );
};