import { Button, Spinner, TextInput } from "flowbite-react";
import { StyledReportPage } from "../_styles/StyledReportPage";
import { FC } from "react";
import { CondensedCodeDataGrid } from "../../components/CondensedCodeDataGrid";
import { CodeLabel } from "../../components/CodeLabel";
import { useVeloeCartoesPage } from "./VeloeCartoesPage.hook";

export const VeloeCartoesPage: FC = () => {
    const {
        loading, columns, dataSource, handlePesquisar, setFiltro, filtro
    } = useVeloeCartoesPage();

    return (
        <StyledReportPage className="shadow rounded-md py-0.5">
            <div className="py-5">
                <h2 className="px-5 mb-4 text-lg font-semibold">Cartões</h2>
                <hr />
            </div>

            <div className="flex gap-x-3 px-3 -mt-2 -mb-3 items-end">
                <div>
                    <CodeLabel className="mb-1" htmlFor="inputBases" value="Bases:" />
                    <TextInput
                        id="inputBases"
                        type="text"
                        sizing={"sm"}
                        placeholder="005,006"
                        onChange={(e) => setFiltro({ ...filtro, bases: e.currentTarget.value.split(',') })}
                    />
                </div>

                <Button
                    id="buttonGerarRelatorio"
                    size={'sm'}
                    disabled={loading}
                    onClick={handlePesquisar}
                    color={'light'}>

                    {loading && <div className={(loading ?? false) ? "mr-3 text-white" : "text-white"}>
                        <Spinner size="sm" light={true} />
                    </div>}

                    {!loading && <span><i className="text-sm" /> Pesquisar</span>}
                    {loading && <span>Pesquisando...</span>}
                </Button>
            </div>

            <CondensedCodeDataGrid
                columns={columns}
                datasource={dataSource}
                header={{ filter: false }}
                readonly={true}
                pagination={{
                    limit: 500,
                    page: 1,
                    pageCount: 1,
                    recordCount: 0
                }}
                hoverable={true}
                striped={true} />

        </StyledReportPage>
    );
}