import { FC } from "react";
import CodeUtil from "../../utils/CodeUtil";
import { GroupboxProps } from "./types";

export const Groupbox: FC<GroupboxProps> = (props) => {
    return (
        <div id={props.id} className={props.className} hidden={props.hidden}>
            {CodeUtil.isNullOrEmpty(props.label) ? (<></>) : (<h2 className="mt-4 text-xs text-black/60 font-semibold uppercase">{props.label}</h2>)}
            <hr className="mt-1.5" />

            <div className="grid grid-cols-12">
                {props.children}
            </div>
        </div>
    );
};