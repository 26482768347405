import { useState } from "react";
import { ICodeDataGridColumn } from "../../components/CondensedCodeDataGrid/types";
import { VeloeService } from "../../services/VeloeService";
import { ShowToast } from "../../components/CodeToast";
import { UNKOWN_EXCEPTION } from "../../utils/Constants";
import { VeloeBaseRequest } from "../../models/veloe/VeloeBaseRequest";
import { VeloeCardModel } from "../../models/veloe/VeloeCardModel";
import { format } from "fecha";

export const useVeloeCartoesPage = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [showFiltros, setShowFiltros] = useState<boolean>(false);
    const [dataSource, setDataSource] = useState<VeloeCardModel[]>([]);
    const [filtro, setFiltro] = useState<VeloeBaseRequest>();

    const columns: ICodeDataGridColumn[] = [
        { title: "Nome da Filial", property: "branchName" },
        { title: "CNPJ", property: "branchDocumentNumber", alignment: "center" },
        { title: "Cód. Base", property: "baseCode" },
        { title: "Base", property: "baseName" },
        { title: "Número", property: "cardNumber" },
        { title: "Limite", property: "limit", alignment: "right" },
        { title: "Saldo", property: "balance", alignment: "right" },
        { title: "Perfil de Uso", property: "accountProfileName" },
        { title: "Tipo", property: "type" },
        { title: "Centro de Custo", property: "costCenter" },
        { title: "Placa", property: "licensePlate" },
        { title: "Veículo", property: "name" },
        { title: "Modelo", property: "veihcleModel" },
        { title: "CPF Motorista", property: "driverDocumentNumber", alignment: "center" },
        { title: "Motorista", property: "driverName" },
        { title: "Grupo do Motorista", property: "driverGroup" },
        { title: "Data de Validade", property: "registrationDate", alignment: "center", onFormatValue: (item: VeloeCardModel) => 
            <span>{!item.expirationDate ? "" : format(new Date(item.expirationDate), "DD/MM/YYYY")}</span> },
        { title: "Data de Registro", property: "profileRegistrationDate", alignment: "center", onFormatValue: (item: VeloeCardModel) => 
            <span>{!item.expirationDate ? "" : format(new Date(item.expirationDate), "DD/MM/YYYY")}</span> },
        { title: "Status", property: "status" },
    ]

    const handlePesquisar = () => {
        setShowFiltros(false);
        setLoading(true);

        VeloeService.getCartoes(filtro)
            .then(async (response) => {
                if (!response.success) {
                    ShowToast({ message: response.messages });
                    return;
                }

                setDataSource(response.data);
            }).catch((error) => {
                console.log(error);
                ShowToast({ message: UNKOWN_EXCEPTION });
            }).finally(() => {
                setLoading(false);
            });
    }

    return {
        loading, columns,
        showFiltros, setShowFiltros,
        filtro, setFiltro,
        dataSource,
        handlePesquisar,
    }
}