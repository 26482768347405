import { FC, useCallback, useEffect, useRef, useState } from "react";
import { EmpenhoModel } from "../../../../../models/empenho";
import { ModalFormTabProps, ModalStateType } from "../../../../../components/ModalForm/types";
import { ContextMenu } from "primereact/contextmenu";
import { ClienteModel } from "../../../../../models/cliente/ClienteModel";
import { Button } from "flowbite-react";
import { CondensedCodeDataGrid } from "../../../../../components/CondensedCodeDataGrid";
import { useEmpenhoColumns } from "../../../../../hooks";
import { PrimeIcons } from "primereact/api";
import { ICodeDataGridRow } from "../../../../../components/CondensedCodeDataGrid/types";
import { FormStateType } from "../../../../../enums";
import { ShowToast } from "../../../../../components/CodeToast";
import { Popup } from "../../../../../components/Popup";
import { EmpenhoCadastroPage } from "../../../../empenhos/EmpenhoCadastroPage";

export type ClienteTabEmpenhosProps = {
    onPageLoad?: () => void;
} & ModalFormTabProps<ClienteModel>;

export const ClientesTabEmpenhos: FC<ClienteTabEmpenhosProps> = (props) => {
    const [model, setModel] = props.model;
    const [loaded, setLoaded] = useState<boolean>(false);
    const [saving, setSaving] = useState<boolean>(false);
    const [showPopupCancelar, setShowPopupCancelar] = useState<boolean>(false);
    const [selectedRow, setSelectedRow] = useState<ICodeDataGridRow | null>(null);
    const [selectedModel, setSelectedModel] = useState<EmpenhoModel>({
        isSelected: false,
        state: "Unchanged"
    });
    const [modalState, setModalState] = useState<ModalStateType<EmpenhoModel>>({
        show: false,
        state: FormStateType.add,
    });

    const { columns } = useEmpenhoColumns();
    const cm = useRef<ContextMenu>(null);
    const readonly = props.state === FormStateType.view;

    const onPageLoad = useCallback(async () => {
        try {
            if (loaded) return;

            props.onPageLoad?.call(this);
        } finally {
            setLoaded(true);
        }

        setLoaded(true);
    }, [loaded, props.onPageLoad]);

    const showForm = (formState: FormStateType): void => {
        if (formState === FormStateType.add) setSelectedModel({ isSelected: false, state: "Added" });
        setModalState({ ...modalState, show: true, state: formState });
    }

    const handleSave = (_e: React.MouseEvent<HTMLButtonElement> | undefined, data: EmpenhoModel): void => {
        if (modalState.state === FormStateType.add) {
            const novoEmpenho = model.empenhos;
            novoEmpenho.push({ ...data, state: "Added" });
            setModel({ ...model, empenhos: novoEmpenho });
        } else {
            if (!selectedRow?.selected || selectedRow.rowindex < 0) return;

            let index = selectedRow.rowindex;
            if (index === null || index < 0) return;

            let updateEmpenhos = model.empenhos;
            updateEmpenhos[index] = data;
            updateEmpenhos[index].state = 'Modified';
            setModel({ ...model, empenhos: updateEmpenhos });
        }

        setSaving(false);
        setModalState({ ...modalState, show: false });
    }

    useEffect(() => { onPageLoad() }, [onPageLoad]);

    return (
        <div className="p-0 -m-6">
            <ContextMenu ref={cm} model={[
                { label: "Novo Empenho", icon: PrimeIcons.PLUS, disabled: readonly, command: () => showForm(FormStateType.add) },
                {
                    label: "Editar",
                    icon: PrimeIcons.PENCIL,
                    disabled: readonly || !selectedRow?.selected || (selectedRow!.item as EmpenhoModel).situacao === "Cancelado",
                    command: () => showForm(FormStateType.edit)
                },
                { label: "Consultar", icon: PrimeIcons.EYE, disabled: !selectedRow?.selected, command: () => showForm(FormStateType.view) },
                {
                    label: "Cancelar",
                    icon: PrimeIcons.TRASH,
                    disabled: readonly || !selectedRow?.selected || (selectedRow!.item as EmpenhoModel).situacao === "Cancelado",
                    command: () => {
                        if (selectedModel === undefined) {
                            ShowToast({ message: 'Nenhum empenho selecionado' });
                            return;
                        }
                        setShowPopupCancelar(true)
                    }
                },
            ]} />

            {(model.empenhos.length <= 0 && !readonly) &&
                <div className="flex flex-col space-y-3 ml-auto mr-auto p-5 place-items-center">
                    <span className="text-slate-500">Este cliente não possui empenhos</span>
                    <Button disabled={readonly} onClick={() => {
                        setModalState({
                            show: true,
                            state: FormStateType.add
                        })
                    }} color="dark" size="sm">Novo empenho</Button>
                </div>}

            {(model.empenhos.length > 0 || readonly) &&
                <div className="space-y-4">
                    <CondensedCodeDataGrid
                        key="id"
                        height="47.3svh"
                        sort={{ columnIndex: 3, direction: "desc", columnProperty: "data" }}
                        header={{ filter: true }}
                        onContextMenu={(e) => cm.current?.show(e)}
                        datasource={model.empenhos}
                        columns={columns}
                        onRowSelect={(selectedRow) => {
                            setSelectedModel(selectedRow.item as EmpenhoModel);
                            setSelectedRow(selectedRow);
                        }}
                        multiselect={false}
                    />
                </div>}

            {modalState.show && <EmpenhoCadastroPage
                show={modalState.show}
                title="Empenho"
                state={modalState.state}
                saving={[saving, setSaving]}
                model={[selectedModel, setSelectedModel]}
                onSave={handleSave}
                onClose={() => setModalState({ ...modalState, show: false })}
            />}

            {showPopupCancelar && <Popup
                isShowing={true}
                message="Confirma o cancelamento do empenho selecionado?"
                isDestructive={true}
                onConfirm={() => {
                    model.empenhos[selectedRow?.rowindex ?? 0].situacao = "Cancelado";
                    model.empenhos[selectedRow?.rowindex ?? 0].state = "Modified";
                    setShowPopupCancelar(false);
                }}
                onCancel={() => setShowPopupCancelar(false)}
            />}
        </div>
    )
}