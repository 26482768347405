import { useCallback, useEffect, useState } from "react";
import { SidebarFiltroProdutosFormField } from ".";
import { useFetchVeiculoMarca } from "../../hooks";

export interface SidebarFiltroProdutosProps {
    onClose: () => void;
    onFiltrar: (filtros: SidebarFiltroProdutosFormField | undefined) => void;
}

export const useSidebarFiltroProdutos = (props: SidebarFiltroProdutosProps) => {
    const [form, setForm] = useState<SidebarFiltroProdutosFormField>();
    const [loaded, setLoaded] = useState<boolean>(false);
    const { dataSource: fabricantes } = useFetchVeiculoMarca();

    const onPageLoad = useCallback(async () => {
        try {
            if (loaded) return;
            setForm(JSON.parse(localStorage.getItem("@App:filtroProdutos") ?? "{}"));
        } finally {
            setLoaded(true);
        }
    }, [loaded]);

    useEffect(() => {
        onPageLoad()
    }, [onPageLoad]);

    const handleFiltrar = () => {
        const _postForm =  {
            ...form,
            fabricante: form?.fabricante === 0 ? undefined : form?.fabricante,
        };

        setForm(_postForm);
        
        localStorage.removeItem("@App:filtroProdutos");
        localStorage.setItem("@App:filtroProdutos", JSON.stringify(_postForm));
        props.onFiltrar.call(this, _postForm);
    }

    return {
        form, setForm, fabricantes,
        handleFiltrar
    }
}