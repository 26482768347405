import { Checkbox, Tooltip } from "flowbite-react";
import { FC, useCallback, useEffect, useState } from "react";
import { CodeLabel } from "../../../../../components/CodeLabel";
import { ShowToast } from "../../../../../components/CodeToast";
import { ServicoModel } from "../../../../../models/servico";
import { ServicoService } from "../../../../../services/ServicoService";
import CodeUtil from "../../../../../utils/CodeUtil";
import { CredenciadaFormPageDadosProps } from "../../types";
import { FormStateType } from "../../../../../enums";

export const CredenciadasTabServicos: FC<CredenciadaFormPageDadosProps> = (props) => {
    const [model, setModel] = props.model;
    const [formLoaded, setFormLoaded] = useState<boolean>(false);
    const [servicosModel, setServicosModel] = useState<ServicoModel[]>([]);

    const onLoadServicos = useCallback(async () => {
        let response = await ServicoService.get({ limit: 9999999, page: 1, ativo: true });
        if (!response.success) {
            ShowToast({ message: CodeUtil.arrayToStr(response.messages) });
            return;
        }

        setServicosModel(response.data);
    }, [setServicosModel]);

    const onPageLoad = useCallback(async () => {
        if (!props.show || formLoaded) return;

        await onLoadServicos();
        props.onPageLoad?.call(this);
        setFormLoaded(true);
    }, [props.show, props.onPageLoad, formLoaded, onLoadServicos]);

    const onCheckboxChange = (servico: ServicoModel, checked: boolean) => {
        if (checked) setModel({ ...model, servicos: [{ credenciadaId: model.id!, servicoId: servico.id! }, ...model.servicos] })
        else setModel({ ...model, servicos: model.servicos.filter((item) => item.servicoId !== servico.id) });
    }

    useEffect(() => { onPageLoad() });

    return (
        <div>
            <div className="grid grid-cols-12">
                {servicosModel.map((item) => (
                    <div key={item.id} className="mb-2 col-span-4 items-center">
                        {!CodeUtil.isNullOrEmpty(item.descricao) ? (<Tooltip content={item.descricao} arrow={false}>
                            <div className="flex form-control">
                                <Checkbox
                                    id={`checkbox-${item.id}`}
                                    disabled={!item.ativo || props.state === FormStateType.view}
                                    checked={model.servicos.some((servico) => servico.servicoId === item.id)}
                                    onChange={(e) => onCheckboxChange(item, e.currentTarget.checked)}
                                    placeholder="Checked"
                                />

                                <CodeLabel htmlFor={`checkbox-${item.id}`} value={item.nome} className="ml-2 -mt-1" disabled={!item.ativo} />
                            </div>
                        </Tooltip>) : (
                            <div className="flex form-control">
                                <Checkbox
                                    id={`checkbox-${item.id}`}
                                    disabled={!item.ativo || props.state === FormStateType.view}
                                    checked={model.servicos.some((servico) => servico.servicoId === item.id)}
                                    onChange={(e) => onCheckboxChange(item, e.currentTarget.checked)}
                                    placeholder="Checked"
                                />

                                <CodeLabel htmlFor={`checkbox-${item.id}`} value={item.nome} className="ml-2 -mt-1" disabled={!item.ativo} />
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};