import { useRef, useState } from "react";
import { useFetchVeiculoMarca } from "../../hooks";
import { ModalFormProps } from "../../components/ModalForm/types";
import { ProdutoService } from "../../services/ProdutoService";
import { ShowToast } from "../../components/CodeToast";

export interface ProdutoImportacaoPageProps extends ModalFormProps { }

interface FormFields {
    fabricanteId?: number
    file?: File | null
}

export const useProdutoImportacaoPage = (props: ProdutoImportacaoPageProps) => {
    const { dataSource: fabricantes } = useFetchVeiculoMarca();
    const [errors, setErrors] = useState<string[]>([]);
    const [form, setForm] = useState<FormFields>();
    const [saving, setSaving] = useState<boolean>(false);
    const inputFile = useRef<HTMLInputElement>(null);

    const validate = (): boolean => {
        const _errors = [];
        if (inputFile.current?.files?.length === 0 || inputFile.current?.files![0].size === 0) {
            _errors.push("Nenhum arquivo selecionado ou o arquivo selecionado está vazio.");
        }

        if (form?.fabricanteId === undefined || form?.fabricanteId === 0) {
            _errors.push("Fabricante não informado.");
        }

        setErrors(_errors);
        return _errors.length === 0;
    }

    const handleImportar = async () => {
        if (saving || !validate()) return;

        setSaving(true);

        const formData = new FormData();
        formData.append("fabricanteId", form?.fabricanteId!.toString()!);
        formData.append("file", inputFile.current?.files![0]!);

        ProdutoService
            .importFile(formData)
            .then((response) => {
                if (response.success)
                    ShowToast({ message: 'A importação foi iniciada. Em breve você receberá um e-mail com o resultado da importação.' });
                else
                    ShowToast({ message: response.messages });
            })
            .catch((error) => {
                ShowToast({ message: error.message });
                setSaving(false);
                return;
            }).finally(() => setSaving(false));
    }

    const handleClose = () => {
        if (saving) return;
        props.onClose?.call(this);
    }

    return {
        fabricantes, form, setForm, inputFile, saving,
        errors, handleImportar, handleClose
    }
}