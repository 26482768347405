import { useCallback, useEffect, useState } from "react";
import { ModalState } from "../../types";
import { FormStateType } from "../../enums";
import { GestorContaModel } from "../../models/gestor_conta";
import { ICodeDataGridActionButton, ICodeDataGridColumn, ICodeDataGridPagination, ICodeDataGridRow } from "../../components/CodeDataGrid/types";
import { Badge } from "flowbite-react";
import { HiCheck } from "react-icons/hi";
import { IoMdClose } from "react-icons/io";
import CodeUtil from "../../utils/CodeUtil";
import { GestorContaService } from "../../services/GestorContaService";
import { ShowToast } from "../../components/CodeToast";
import { useBuscarPermissao } from "../../hooks/useBuscarPermissao";
import { UNKOWN_EXCEPTION } from "../../utils/Constants";

export interface GestorContasPageProps extends Omit<ModalState, "id"> {
    id?: number | null
}

export const useGestorContasPage = () => {
    const [modalState, setModalState] = useState<GestorContasPageProps>({ show: false, state: FormStateType.view });
    const [loading, setLoading] = useState<boolean>(false);
    const [pageLoaded, setPageLoaded] = useState<boolean>(false);
    const [showPopupDelete, setShowPopupDelete] = useState<boolean>(false);
    const [dataSource, setDataSource] = useState<GestorContaModel[]>([]);
    const [selected, setSelected] = useState<ICodeDataGridRow>();
    const [pagination, setPagination] = useState<ICodeDataGridPagination>({
        page: 1,
        pageCount: 0,
        limit: 100,
        recordCount: 0,
        onPageChanged: (e: number) => handlePageChanged(e)
    });
    const [page, setPage] = useState<number>(1)
    const { permissao } = useBuscarPermissao('gestoresconta')

    const formButtons: ICodeDataGridActionButton[] = [
        { label: 'Inserir', style: 'colored', disabled: !permissao?.inserir, onClick: () => handleInserir() },
        { label: 'Editar', style: 'flat', onClick: () => handleEditar(), disabled: !selected || !permissao?.editar },
        { label: 'Excluir', style: 'destructive', onClick: () => setShowPopupDelete(true), disabled: !selected || !permissao?.excluir },
        { label: 'Consultar', style: 'flat', onClick: () => handleConsultar(), disabled: !selected || !permissao?.consultar },
    ]

    const columns: ICodeDataGridColumn[] = [
        { property: "id", title: "Código", alignment: "center", onFormatValue(value: GestorContaModel) { return (<span>{(value.id as Number).toString().padStart(3, "0")}</span>) } },
        {
            property: "bloqueada", title: "Ativo", alignment: "center", onFormatValue(value: GestorContaModel) {
                return (
                    <div className="flex justify-center flex-wrap items-center gap-2">
                        {value.bloqueado ? (
                            <Badge size="sm" color="failure" icon={IoMdClose} />
                        ) : (
                            <Badge icon={HiCheck} size="sm" color="success" />
                        )}
                    </div>
                );
            },
        },
        { property: "razaoSocial", title: "Razão Social" },
        { property: "fantasia", title: "Fantasia" },
        {
            property: "cnpj", title: "CNPJ", onFormatValue(value: GestorContaModel) {
                return (<span>{CodeUtil.mask(value.cnpj ?? "", "##.###.###/####-##")}</span>)
            }
        },
        { property: "municipio.descricao", title: "Município" },
        { property: "uf.sigla", title: "UF" },
    ]

    const handleOnKeyDown = (e: React.KeyboardEvent<HTMLDivElement> | undefined) => {
        if (e?.code?.toLowerCase() === "escape") {
            setModalState({ ...modalState, show: false, id: null });
            return;
        }
    }

    const handleRowSelect = (item: ICodeDataGridRow) => setSelected(item);

    const handleFormCadastroClose = () => {
        setModalState({ ...modalState, show: false, id: null });
        setPageLoaded(false);
    }

    const handleFormCadastroSave = () => {
        setModalState({ ...modalState, show: false, id: null });
        setPageLoaded(false);
    }

    const handleOnConfirmDelete = async () => {
        try {
            let response = await GestorContaService.delete(selected?.item.id);
            if (!response.success) {
                console.log(response.messages);
                return;
            }

            setPageLoaded(false);
        } catch (error) {
            console.log(error);
        } finally {
            setShowPopupDelete(false);
        }      
    }

    const handleOnCancelDelete = () => setShowPopupDelete(false);

    const handlePageChanged = (e: number) => setPage(e);

    const handleInserir = () => {
        setModalState({
            ...modalState,
            id: null,
            show: true,
            state: FormStateType.add
        });
    }

    const handleEditar = () => {
        setModalState({
            ...modalState,
            id: (selected?.item as GestorContaModel).id,
            show: true,
            state: FormStateType.edit
        });
    }

    const handleConsultar = () => {
        setModalState({
            ...modalState,
            id: (selected?.item as GestorContaModel).id,
            show: true,
            state: FormStateType.view
        });
    }

    const onPageLoad = useCallback(async () => {
        if (pageLoaded) return;

        try {
            setLoading(true);
            const response = await GestorContaService.get({
                limit: pagination.limit,
                page: page
            });

            if (!response.success) {
                setDataSource([]);
                ShowToast({
                    message: response.messages,
                    error: true
                });
                return;
            }

            setDataSource(response.data);
        } catch (error) {
            console.log(error);
            ShowToast({ message: UNKOWN_EXCEPTION });
        } finally {
            setLoading(false);
            setPageLoaded(true);
        }
    }, [page, pageLoaded, pagination.limit]);

    useEffect(() => {
        onPageLoad();
    }, [onPageLoad])

    return {
        loading,
        dataSource, columns, formButtons,
        modalState, setModalState,
        pageLoaded, setPageLoaded,
        pagination, setPagination,
        showPopupDelete,
        handleOnKeyDown, handleRowSelect,
        handleFormCadastroClose, handleFormCadastroSave,
        handleOnConfirmDelete, handleOnCancelDelete
    }
}