import { FC } from "react";
import styled from "styled-components";
import { Tabs } from "flowbite-react";
import { CotacoesPanel } from "../../components/CotacoesPanel";
import { OrdemServicoPanel } from "../../components/OrdemServicoPanel";

export const MainPage: FC = () => {
    return (
        <StyledDashboard>
            <StyledCotacoesPanel className="rounded-md shadow m-0 p-0">
                {/* eslint-disable-next-line */}
                <Tabs.Group aria-label="Abas" style="underline">
                    <Tabs.Item title="Cotações">
                        <CotacoesPanel />
                    </Tabs.Item>

                    <Tabs.Item title="Ordens de Serviço">
                        <OrdemServicoPanel />
                    </Tabs.Item>
                </Tabs.Group>
            </StyledCotacoesPanel>
        </StyledDashboard>
    );
};

const StyledDashboard = styled.div`
    padding: 2rem;
`
const StyledCotacoesPanel = styled.div`
    background-color: #ffffff;
    width: 100%;
    height: calc(100vh - 8rem);
`;


