import { Button } from "flowbite-react";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { ShowToast } from "../../../../../components/CodeToast";
import { ModalStateType } from "../../../../../components/ModalForm/types";
import { Popup } from "../../../../../components/Popup";
import { FormStateType } from "../../../../../enums";
import { CredenciadaContatoModel, defaultCredenciadaContato } from "../../../../../models/credenciada_contato";
import CodeUtil from "../../../../../utils/CodeUtil";
import { CredenciadaFormPageDadosProps } from "../../types";
import { CredenciadasTabContatosModal } from "./components/Modal";
import { CondensedCodeDataGrid } from "../../../../../components/CondensedCodeDataGrid";
import { ContextMenu } from "primereact/contextmenu";
import { ICodeDataGridRow } from "../../../../../components/CondensedCodeDataGrid/types";
import { PrimeIcons } from "primereact/api";

export const CredenciadasTabContatos: FC<CredenciadaFormPageDadosProps> = (props) => {
    const [model, setModel] = props.model;
    const readOnlyForm = props.state === FormStateType.view;
    const [formLoaded, setFormLoaded] = useState<boolean>(false);
    const [selectedModel, setSelectedModel] = useState<CredenciadaContatoModel>(defaultCredenciadaContato);
    const [selectedRow, setSelectedRow] = useState<ICodeDataGridRow | null>(null);
    const [showPopupDelete, setShowPopupDelete] = useState<boolean>(false);
    const [stateModalContato, setStateModalContato] = useState<ModalStateType<CredenciadaContatoModel>>({
        show: false,
        state: FormStateType.add,
    });
    const cm = useRef<ContextMenu>(null);

    const onPageLoad = useCallback(async () => {
        if (!props.show || formLoaded) return;

        props.onPageLoad?.call(this);
        setFormLoaded(true);
    }, [props.show, props.onPageLoad, formLoaded]);

    useEffect(() => { onPageLoad() });

    const showModal = (formState: FormStateType): void => {
        if (formState === FormStateType.add)
            setSelectedModel(defaultCredenciadaContato);

        setStateModalContato({
            show: true,
            state: formState
        });
    }

    const updateItem = () => {
        setStateModalContato({ ...stateModalContato, show: false });

        if (stateModalContato.state === FormStateType.add) {
            setModel({ ...model, contatos: [{ ...selectedModel, state: 'ADDED' }, ...model.contatos] });
        } else {
            if (!selectedRow?.selected || selectedRow.rowindex < 0) return;
            let index = selectedRow.rowindex;
            if (index === null || index < 0) return;

            let updatedContatos = model.contatos;
            updatedContatos[index] = selectedModel;
            updatedContatos[index].state = 'UPDATED';
            setModel({ ...model, contatos: updatedContatos });
        }
    }

    const removeItem = () => {
        if (!selectedRow?.selected || selectedRow.rowindex < 0) return;
        let index = selectedRow.rowindex;
        if (index === null || index < 0) return;

        let updatedContatos = model.contatos;
        updatedContatos[index] = selectedModel;
        updatedContatos[index].state = 'DELETED';

        setModel({ ...model, contatos: updatedContatos });
        setShowPopupDelete(false);
        setSelectedModel(defaultCredenciadaContato);
    }

    return (
        <div className="p-0 -m-6">
            <ContextMenu ref={cm} model={[
                { label: "Novo Contato", icon: PrimeIcons.PLUS, disabled: readOnlyForm, command: () => showModal(FormStateType.add) },
                { label: "Editar", icon: PrimeIcons.PENCIL, disabled: readOnlyForm || !selectedRow?.selected, command: () => showModal(FormStateType.edit) },
                { label: "Consultar", icon: PrimeIcons.EYE, disabled: !selectedRow?.selected, command: () => showModal(FormStateType.view) },
                { separator: true },
                {
                    label: "Excluir", icon: PrimeIcons.TRASH, disabled: readOnlyForm || !selectedRow?.selected, command: () => {
                        if (selectedModel === undefined) {
                            ShowToast({ message: 'Nenhum registro selecionado para exclusão' });
                            return;
                        }
                        setShowPopupDelete(true)
                    }
                },
            ]} />

            {model.contatos.length <= 0 ?
                <div className="flex flex-col space-y-3 ml-auto mr-auto p-5 place-items-center">
                    <span className="text-slate-500">Esta credenciada não possui contatos</span>
                    <Button disabled={readOnlyForm} onClick={() => showModal(FormStateType.add)} color="dark" size="sm">Novo contato</Button>
                </div>
                : <></>
            }

            {model.contatos.length > 0 ?
                <div className="space-y-4">
                    <CondensedCodeDataGrid
                        key="id"
                        sort={{ columnIndex: 0, direction: "asc", columnProperty: "nome" }}
                        header={{ filter: true }}
                        onContextMenu={(e) => cm.current?.show(e)}
                        datasource={model.contatos.filter((e) => e.state !== 'DELETED')}
                        columns={[
                            { property: "nome", title: "Nome" },
                            { property: "email", title: "E-mail" },
                            { property: "setor", title: "Setor" },
                            { property: "telefone", title: "Fixo", onFormatValue: (value: CredenciadaContatoModel) => CodeUtil.mask(value.telefone ?? "", "(##) ####-####") },
                            { property: "telefone2", title: "Fixo 2", onFormatValue: (value: CredenciadaContatoModel) => CodeUtil.mask(value.telefone2 ?? "", "(##) ####-####") },
                            { property: "celular", title: "Celular", onFormatValue: (value: CredenciadaContatoModel) => CodeUtil.mask(value.celular ?? "", "(##) #####-####") },
                            { property: "celular2", title: "Celular 2", onFormatValue: (value: CredenciadaContatoModel) => CodeUtil.mask(value.celular2 ?? "", "(##) #####-####") },
                        ]}
                        onRowSelect={(selectedRow) => {
                            setSelectedModel((!selectedRow?.selected ?? false) ? defaultCredenciadaContato : selectedRow.item as CredenciadaContatoModel);
                            setSelectedRow(selectedRow);
                        }}
                        multiselect={false}
                    />
                </div> :
                <></>
            }

            <Popup
                isShowing={showPopupDelete}
                message="Confirma a exclusão do registro selecionado?"
                isDestructive={true}
                onConfirm={() => removeItem()}
                onCancel={() => setShowPopupDelete(false)}
            />


            <CredenciadasTabContatosModal
                show={stateModalContato.show}
                title="Contato"
                state={stateModalContato.state}
                model={[selectedModel, setSelectedModel]}
                onSave={() => updateItem()}
                onClose={() => setStateModalContato({ ...stateModalContato, show: false })}
            />
        </div>
    );
};