/* eslint-disable jsx-a11y/iframe-has-title */
import { FC } from "react";
import styled from "styled-components";
import { DocumentViewerProps } from "./types";
import { Modal } from "flowbite-react";
import FlatButton from "../FlatButton";

export const DocumentViewer: FC<DocumentViewerProps> = ({ size, onClose, title, uri, width, height, hideControls }) => {
    return (
        <Modal
            show
            title={title}
            size={size ?? "7xl"}
            onContextMenu={(e) => e.preventDefault()}
            onClose={onClose}>

            <Modal.Header>
                <span className="text-lg font-semibold text-left text-gray-900">{title}</span>
            </Modal.Header>

            <SPdfContainer src={`${uri}`}
                width={width}
                height={height}/>

            <footer className="p-3 border-t-2 flex justify-center items-center">
                <div className="mr-auto" />

                <div className="inline-flex space-x-2 ml-auto">
                    <FlatButton onClick={onClose} label="Fechar" />
                </div>
            </footer>
        </Modal>
    );
};

const SPdfContainer = styled.iframe`
    height: 75vh;
    width: 100%;
`

// const SPdfContainer = styled.div`
//     height: 65vh;
//     width: 100%;
//     pointer-events: none; 
// `