import { FC } from "react";
import { ModalFormTabProps } from "../../../components/ModalForm/types";
import { ViewCotacaoCredenciadaModel } from "../../../models/cotacao/ViewCotacaoCredenciada";
import { useTabItens } from "./TabItens.hook";
import styled from "styled-components";
import { TabItensLancamento } from "./TabItensLancamento";
import { CondensedCodeDataGrid } from "../../../components/CondensedCodeDataGrid";
import { FormStateType } from "../../../enums";
import { Button } from "primereact/button";

export const TabItens: FC<ModalFormTabProps<ViewCotacaoCredenciadaModel>> = (props) => {
    const { item, itemIndex, modalState, columns, itemModel, setItemModel,
        handleCloseLancamentoItem, handleSaveLancamentoitem, handleButtonInserir
    } = useTabItens(props);

    return (
        <StyledContainer>
            <CondensedCodeDataGrid
                columns={columns}
                datasource={item.itens}
                striped={true}
                readonly={props.state === FormStateType.view}
                key={"id"}
                onContextMenu={(e) => { }} />

            {modalState.show && <TabItensLancamento
                index={itemIndex}
                item={[itemModel, setItemModel]}
                show={modalState.show}
                state={modalState.state}
                onClose={handleCloseLancamentoItem}
                onSaveItem={handleSaveLancamentoitem}
                title={itemIndex ? `Cotação ${item.itens[itemIndex ?? 0]?.descricao}` : 'Adicionar Peça/Serviço'}
            />}

            {props.state === FormStateType.add && <Button icon="pi pi-plus" 
                onClick={handleButtonInserir} rounded aria-label="Filter" style={{ position: "absolute", right: 30, bottom: 160 }} />}

        </StyledContainer>
    );
}

const StyledContainer = styled.div`
    min-height: 18rem;
    margin: -1.4rem -1.5rem;
`