import { useCallback, useEffect, useState } from "react";
import { VeiculoMarcaService } from "../services/VeiculoMarcaService";
import { VeiculoMarcaModel } from "../models/veiculo_marca";

export const useFetchVeiculoMarca = () => {
    const [loaded, setLoaded] = useState<boolean>(false);
    const [dataSource, setDataSource] = useState<VeiculoMarcaModel[]>([]);

    const fetchFabricantes = useCallback(async () => {
        try {
            if (loaded) return;
            
            const response = await VeiculoMarcaService.get({
                page: 1,
                limit: 999999
            });

            setDataSource(response.data ?? []);
        } finally {
            setLoaded(true);
        }
    }, [loaded]);

    useEffect(() => {
        fetchFabricantes()
    }, [fetchFabricantes]);

    return {
        dataSource
    }
}