import { Badge } from "flowbite-react";
import React, { useCallback, useEffect, useState } from "react";
import { HiCheck } from "react-icons/hi/index";
import { IoMdClose } from "react-icons/io/index";
import { VeiculoMarcaModel } from "../../models/veiculo_marca";
import { FormStateType } from "../../enums";
import { ShowToast } from "../../components/CodeToast";
import CodeSpinner from "../../components/CodeSpinner";
import { Popup } from "../../components/Popup";
import { VeiculoMarcaService } from "../../services/VeiculoMarcaService";
import { ModalProps } from "../../types";
import { KeyboardEvent } from "react";
import { VeiculosMarcasModal } from "./components/Modal";
import { CodeDataGrid } from "../../components/CodeDataGrid";
import { ICodeDataGridPagination, ICodeDataGridRow } from "../../components/CodeDataGrid/types";
import { useBuscarPermissao } from "../../hooks/useBuscarPermissao";

export const VeiculosMarcasPage: React.FC = () => {
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
  const [dataSource, setDataSource] = useState<VeiculoMarcaModel[]>([]);
  const [pagination, setPagination] = useState<Omit<ICodeDataGridPagination, 'onPageChanged'>>({ page: 1, pageCount: 0, limit: 100, recordCount: 0 });
  const [selectedModel, setSelectedModel] = useState<VeiculoMarcaModel | null>(null);
  const [selectedRow, setSelectedRow] = useState<ICodeDataGridRow>();
  const [showPopupDelete, setShowPopupDelete] = useState<boolean>(false);
  const [modalState, setModalState] = useState<ModalProps>({
    show: false,
    state: FormStateType.view,
    id: null,
  });

  const { permissao } = useBuscarPermissao('marcas_modelos')

  const onFormLoad = useCallback(async (page: number = 1) => {
    setIsLoadingData(true);
    VeiculoMarcaService.get({ page: page, limit: 100 })
      .then((response) => {
        setDataSource(response.data ?? []);
        setPagination({
          page: response.pagination.page,
          pageCount: response.pagination.pageCount,
          limit: response.pagination.limit,
          recordCount: response.pagination.recordCount
        });
      })
      .finally(() => setIsLoadingData(false));
  }, [setDataSource, setIsLoadingData, setPagination]);

  useEffect(() => {
    onFormLoad();
  }, [onFormLoad]);

  const add = () =>
    setModalState({
      ...modalState,
      state: FormStateType.add,
      show: true,
      id: 0,
    });

  const viewOrEdit = (edit: boolean) => {
    if (selectedModel?.id === undefined) {
      ShowToast({
        message: `Nenhum registro selecionado para ${edit ? "edição" : "visualização"
          }`,
      });
      return;
    }

    setModalState({
      state: edit ? FormStateType.edit : FormStateType.view,
      show: true,
      id: selectedModel.id,
    });
  };

  const deleteItem = async () => {
    try {
      let response = await VeiculoMarcaService.delete(selectedModel!.id!);
      if (!response.success) {
        console.log(response.messages);
        return;
      }

      await onFormLoad();
    } catch (error) {
      console.log(error);
    } finally {
      setShowPopupDelete(false);
    }
  };

  const formatAtivaColumn = (item: VeiculoMarcaModel) => {
    return (
      <div className="flex justify-center flex-wrap items-center gap-2">
        {(item.ativa as boolean) ? (
          <Badge icon={HiCheck} size="sm" color="success" />
        ) : (
          <Badge size="sm" color="failure" icon={IoMdClose} />
        )}
      </div>
    );
  };

  const handleKeydownEvent = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.code.toLowerCase() === "escape") {
      setModalState({ ...modalState, show: false, id: null });
      return;
    }
  }

  return (
    <div onKeyDown={handleKeydownEvent}>
      <CodeSpinner hidden={!isLoadingData} fullPage={true} />

      <div className="mt-5" hidden={isLoadingData}>
        <CodeDataGrid
          striped={true}
          header={{ title: 'Marcas de Veículos', subtitle: 'Listagem de marcas e modelos de veículos', filter: true }}
          actions={[
            { label: 'Inserir', style: 'colored', disabled: !permissao?.inserir, onClick: add },
            { label: 'Editar', style: 'flat', onClick() { viewOrEdit(true) }, disabled: selectedRow === undefined || !permissao?.editar },
            { label: 'Excluir', style: 'destructive', onClick() { setShowPopupDelete(true) }, disabled: selectedRow === undefined || !permissao?.excluir },
            { label: 'Consultar', style: 'flat', onClick() { viewOrEdit(false) }, disabled: selectedRow === undefined || !permissao?.consultar },
          ]}
          columns={[
            { property: 'id', title: 'Código', alignment: 'center', onFormatValue(item: VeiculoMarcaModel) { return String(item.id).padStart(4, "0") } },
            { property: 'ativa', title: 'Ativa', alignment: 'center', onFormatValue: formatAtivaColumn },
            { property: 'marca', title: 'Marca', alignment: 'left' },
          ]}
          onRowSelect={(item) => { setSelectedModel(item.selected ? item.item : null); setSelectedRow(item.selected ? item : undefined); }}
          pagination={{ ...pagination, onPageChanged: (page) => onFormLoad(page) }}
          sort={{ columnIndex: 2, columnProperty: "marca", direction: "asc" }}
          datasource={dataSource}
        />

        <VeiculosMarcasModal
          show={modalState.show}
          id={modalState.id}
          state={modalState.state}
          title="Cadastro de Marcas e Modelos"
          onClose={() => setModalState({ ...modalState, show: false, id: null })}
          onSave={async () => {
            setModalState({ ...modalState, show: false, id: null });
            await onFormLoad();
          }}
        />

        <Popup
          isShowing={showPopupDelete}
          message="Confirma a exclusão do registro selecionado?"
          isDestructive={true}
          onConfirm={async () => await deleteItem()}
          onCancel={() => setShowPopupDelete(false)}
        />
      </div>
    </div>
  );
};