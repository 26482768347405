import { FC } from "react";
import { useCredenciadaColumns } from "../../models/credenciada/useCredenciadaColumns";
import ModalForm from "../ModalForm";
import { Popup } from "../Popup";
import { CredencidadaSearchFormProps, useCredenciadaSearchForm } from "./index.hook";
import { CondensedCodeDataGrid } from "../CondensedCodeDataGrid";

export const CredenciadasSearchForm: FC<CredencidadaSearchFormProps> = (props) => {
    const {
        dataSource, setSelectedRows,
        handleSaveButton, handleSelecionarCredenciadas,
        showPopup, setShowPopup, pagination, handlerPaginationPageChange
    } = useCredenciadaSearchForm(props);

    return (
        <ModalForm {...props}
            title="Selecione as credenciadas"
            saveButtonTitle="Selecionar"
            fit={true}
            onSave={handleSaveButton}
            size="5xl">
            <CondensedCodeDataGrid
                sort={{ columnIndex: 2, direction: "asc", columnProperty: "razaoSocial" }}
                datasource={dataSource}
                striped={true}
                header={{ filter: true }}
                multiselect={true}
                pagination={{ ...pagination, onPageChanged: handlerPaginationPageChange }}
                onRowsSelect={(items) => setSelectedRows(items)}
                columns={useCredenciadaColumns()}
            />

            <Popup
                isShowing={showPopup}
                message="Confirma a solicitação de cotação para as credenciadas selecionadas?"
                isDestructive={true}
                onConfirm={handleSelecionarCredenciadas}
                onCancel={() => setShowPopup(false)}
            />
        </ModalForm>
    );
};