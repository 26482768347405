import { FC, useCallback, useEffect, useState, MouseEvent } from "react";
import { Pagination } from "../../../services/types";
import CodeUtil from "../../../utils/CodeUtil";
import { UNKOWN_EXCEPTION } from "../../../utils/Constants";
import { ShowToast } from "../../CodeToast";
import ModalForm from "../../ModalForm";
import { ModalFormProps } from "../../ModalForm/types";
import { CodePesquisaService } from "../services/CodePesquisaService";
import { ICodePesquisaModal } from "../types"
import { CondensedCodeDataGrid } from "../../CondensedCodeDataGrid";

export type CodePesquisaModalProps = ModalFormProps & {
    modalInfo: ICodePesquisaModal
    endpoint: string
    params?: string
    onItemSelect?: (item: unknown | null) => void;
}

export const CodePesquisaModal: FC<CodePesquisaModalProps> = (props) => {
    const [datasource, setDatasource] = useState<unknown[]>([]);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [pagination, setPagination] = useState<Pagination>({ limit: 500, page: 1, pageCount: 0, recordCount: 0 });
    const [disableSave, setDisableSave] = useState<boolean>(true);
    const [selectedItem, setSelectedItem] = useState<unknown | null>(null);

    const loadDatasource = useCallback(async () => {
        try {
            const response = await CodePesquisaService.get(`${props.endpoint}?limit=${pagination.limit}&page=${pagination.page}${props.params ?? ""}`);
            if (!response.success) {
                ShowToast({ message: CodeUtil.arrayToStr(response.messages) });
                return;
            }

            setDatasource(response.data);
            setPagination(response.pagination);
        } catch (error) {
            console.log(error);
            ShowToast({ message: UNKOWN_EXCEPTION });
        }
    }, [pagination, props.endpoint, props.params]);

    const onModalLoad = useCallback(async () => {
        if (!props.show || loaded) return;

        await loadDatasource();

        setSelectedItem(null);
        setDisableSave(true);
        setLoaded(true);
    }, [setLoaded, loaded, props.show, loadDatasource]);

    const onModalClose = () => {
        setLoaded(false);
        props.onClose?.call(this);
    };

    const onModalSave = (e: MouseEvent<HTMLButtonElement> | undefined) => {
        props.onItemSelect?.call(this, selectedItem);
        onModalClose();
    }

    useEffect(() => { onModalLoad(); }, [onModalLoad]);

    return (
        <ModalForm
            title={props.title ?? "Consulta de dados"}
            errors={[]}
            show={props.show}
            fit={true}
            state={props.state}
            isSaving={false}
            size="5xl"
            selecao={true}
            saveButtonTitle={"Selecionar"}
            onClose={onModalClose}
            onSave={onModalSave}
            overflow="hidden"
            disableSave={[disableSave, setDisableSave]}
        >
            <CondensedCodeDataGrid
                datasource={datasource}
                header={{ filter: true }}
                columns={props.modalInfo.dataColumns}
                sort={props.modalInfo.sort}
                striped={true}
                pagination={{
                    ...pagination,
                    onPageChanged: (cursor) => {
                        setPagination({ ...pagination, page: cursor });
                        setLoaded(false);
                    }
                }}
                onRowSelect={(item) => { if (item && item.selected) setSelectedItem(item.item); }}
                onRowsSelect={(items) => { setDisableSave(items.length === 0) }}
            />
        </ModalForm >
    );
};